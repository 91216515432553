<div class="transfer-container">
  <h2 class="title-heading">MES VALEURS</h2>
  <div class="tabs-container">
    <div class="tabs-header">
      <ng-container>
        <button
          class="tab-item"
          (click)="selectTab('CHEQUE_PAYER')"
          [class.active]="isActive('CHEQUE_PAYER')"
        >
          MES CHÉQUES À PAYER
          <span *ngIf="getChequesPayerTotal() > 0">{{
            getChequesPayerTotal()
          }}</span>
          <span *ngIf="getChequesPayerTotal() == -1">Alert icon</span>
        </button>
        <button
          class="tab-item"
          (click)="selectTab('EFFET_PAYER')"
          [class.active]="isActive('EFFET_PAYER')"
        >
          MES EFFETS À PAYER
          <span *ngIf="getEffetsPayerTotal() > 0">{{
            getEffetsPayerTotal()
          }}</span>
          <span *ngIf="getEffetsPayerTotal() == -1">Alert icon</span>
        </button>
        <button
          class="tab-item"
          (click)="selectTab('CHEQUE_IMPAYE')"
          [class.active]="isActive('CHEQUE_IMPAYE')"
        >
          MES CHÉQUES IMPAYÉS
          <span *ngIf="getChequesImpayesTotal() > 0">{{
            getChequesImpayesTotal()
          }}</span>
          <span *ngIf="getChequesImpayesTotal() == -1">Alert icon</span>
        </button>
        <button
          class="tab-item"
          (click)="selectTab('EFFET_IMPAYE')"
          [class.active]="isActive('EFFET_IMPAYE')"
        >
          MES EFFETS IMPAYÉS
          <span *ngIf="getEffetsImpayesTotal() > 0">{{
            getEffetsImpayesTotal()
          }}</span>
          <span *ngIf="getEffetsImpayesTotal() == -1">Alert icon</span>
        </button>
      </ng-container>
    </div>
    <div class="tabs-content">
      <app-sv-cheque-impaye
        *ngIf="isActive('CHEQUE_IMPAYE')"
      ></app-sv-cheque-impaye>
      <app-sv-cheque-payer
        *ngIf="isActive('CHEQUE_PAYER')"
      ></app-sv-cheque-payer>
      <app-sv-effet-impaye
        *ngIf="isActive('EFFET_IMPAYE')"
      ></app-sv-effet-impaye>
      <app-sv-effet-payer *ngIf="isActive('EFFET_PAYER')"></app-sv-effet-payer>
      <div *ngIf="isActive('ROLES-ERROR')">
        <div></div>
      </div>
    </div>
  </div>
</div>
