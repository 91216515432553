import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-consult-cards-oprerations-filter',
  templateUrl: './consult-cards-oprerations-filter.component.html',
  styleUrls: ['./consult-cards-oprerations-filter.component.scss'],
})
export class ConsultCardsOprerationsFilterComponent implements OnInit {
  filter = {
    longDescription: '',
    startDate: '',
    endDate: '',
    minAmount: null,
    maxAmount: null,
  };
  minDate: Date;
  constructor(
    public dialogRef: MatDialogRef<ConsultCardsOprerationsFilterComponent>
  ) {}

  ngOnInit() {
    const today = new Date();
    this.minDate = new Date(
      today.getFullYear() - 1,
      today.getMonth(),
      today.getDate()
    );
  }

  applyFilter() {
    this.dialogRef.close(this.filter);
  }

  resetFilter(): void {
    this.filter = {
      longDescription: '',
      startDate: '',
      endDate: '',
      minAmount: null,
      maxAmount: null,
    };
  }

  close(): void {
    this.applyFilter();
  }
}
