import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  Type,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { State } from 'src/app/core/store/state';
import {
  appLoaded,
  selectSelectedCustomerNumber,
} from 'src/app/core/store/state/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'portail-customer-ui';
  apps = [];
  public userApps: string[];
  public showNewsDetails = false;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectedCustomerNumberSub: any;

  constructor(
    private store: Store<State>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {}

  private getActivatedComponentName(route: ActivatedRoute): string {
    let currentRoute = route;
    //while (currentRoute.firstChild) {
    currentRoute = currentRoute.firstChild;
    //}
    const componentType = currentRoute.component as Type<any>;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(componentType);

    return componentFactory.componentType.name;
  }

  public showDetails(): void {
    this.showNewsDetails = true;
  }

  public cancel(): void {
    this.showNewsDetails = false;
  }
}
