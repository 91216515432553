import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.state';
import { Application } from 'src/app/models/application';

export const selectUser = createFeatureSelector<UserState>('user');

export const selectConnectedUser = createSelector(
  selectUser,
  (state: UserState) => state.connectedUser
);

export const selectSelectedCustomerNumber = createSelector(
  selectUser,
  (state: UserState) => state.selectedCustomerNumber
);

export const selectUserApplications = createSelector(
  selectUser,
  (state: UserState) => state.userApplications
);

export const selectUserClients = createSelector(
  selectUser,
  (state: UserState) => state.userClients
);

export const selectUserSoftToken = createSelector(
  selectUser,
  (state: UserState) => state.hasSoftToken
);
