import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { IdTypeCode } from '../../models/id-type-code';
import { Console } from '@angular/core/src/console';

@Component({
  selector: 'app-sell-card-personal-info',
  templateUrl: './sell-card-personal-info.component.html',
  styleUrls: ['./sell-card-personal-info.component.scss'],
})
export class SellCardPersonalInfoComponent implements OnInit {
  ninType: boolean = false;
  requiredNin: boolean = true;
  idTypeCodes = Object.values(IdTypeCode);
  @Input() proposUpdate: any;
  @Input() userLoaded: boolean = true;
  @Output() stepEvent = new EventEmitter<number>();
  @Output() closedPopine = new EventEmitter<Boolean>();
  @Output() formInfoData = new EventEmitter<any>();
  private readonly MAX_FILE_SIZE_MB: number = 45;
  @Input() isPopupVisible: boolean = true;
  public uploadedFileRecto: File | null = null;
  public uploadedFileVerso: File | null = null;
  closed: boolean = true;
  title = 'Demander Pour un Bénéficaire';
  propos = {
    firstname: '',
    cin: '',
    lastname: '',
    birthDate: '',
    expirationDate: '',
    birthCountry: '',
    birthCity: '',
    selectedIdType: IdTypeCode.CIN,
    recto: null as File | null,
    verso: null as File | null,
  };
  showFileViewer = false;
  pdfSrc = '';

  pdfReference = '';
  proposfill: boolean = false;
  format = 'dd/MM/yyyy';
  minDate: Date;
  filenameRecto: string;
  filenameVerso: string;
  maxDate: Date;
  maxDateExpiration: Date;
  maxExpirationDate: Date;
  isDatePickerDisabled: boolean;
  isBirthDatePickerDisabled: boolean;
  showSizeError: boolean = false;
  showPopup: boolean = false;

  showRecto: boolean = false;
  showVerso: boolean = false;
  selectedImage: string | ArrayBuffer | null = null;
  public showDeleteButtonRecto: boolean = false;
  public showDeleteButtonVerso: boolean = false;
  public updateMode: boolean = false;
  constructor(private userService: UserService) {
    this.maxExpirationDate = new Date();
    this.minDate = new Date(1920, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    this.maxDateExpiration = new Date();
    this.maxDateExpiration.setFullYear(
      this.maxDateExpiration.getFullYear() + 15
    );

    const birthDate = new Date(this.propos.birthDate);
    this.isBirthDatePickerDisabled =
      birthDate < this.minDate || birthDate > this.maxDate;
    this.isDatePickerDisabled = this.maxExpirationDate > new Date();
  }

  ngOnInit() {
    if (this.userLoaded) {
      this.title = 'Demander Pour  Moi meme';
      this.loadUser();
    }
    if (this.proposUpdate != null && this.proposUpdate != undefined) {
      this.propos = this.proposUpdate;
    }
    this.propos.selectedIdType = IdTypeCode.CIN;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('I AM HERE ', this.proposUpdate);
    if (this.userLoaded) {
      this.loadUser();
    } else {
      this.propos = {
        firstname: '',
        cin: '',
        lastname: '',
        birthDate: '',
        expirationDate: '',
        birthCountry: '',
        birthCity: '',
        selectedIdType: IdTypeCode.CIN,
        recto: null as File | null,
        verso: null as File | null,
      };
    }
    if ('userLoaded' in changes) {
      this.cleanPropos();

      this.propos.cin = '';
      this.propos.lastname = '';
      this.propos.firstname = '';

      this.proposUpdate = this.propos;
      if (this.userLoaded) {
        this.title = 'Demander Pour  Moi meme';

        this.loadUser();
      }
      if (!this.userLoaded) {
        this.title = 'Demander Pour un Bénéficaire';
        this.propos.cin = '';
        this.propos.lastname = '';
        this.propos.firstname = '';
        this.propos.birthCity = '';
        this.propos.birthCountry = '';
        this.propos.birthDate = '';
      }
    }

    if (this.proposUpdate == undefined) {
      this.propos = {
        firstname: '',
        cin: '',
        lastname: '',
        birthDate: '',
        expirationDate: '',
        birthCountry: '',
        birthCity: '',
        selectedIdType: IdTypeCode.CIN,
        recto: null as File | null,
        verso: null as File | null,
      };

      if (this.userLoaded) {
        this.loadUser();
      }
    }
    if (this.proposUpdate != null && this.proposUpdate != undefined) {
      this.propos = this.proposUpdate;
    }
  }
  closePopup() {
    this.showPopup = false;
    // this.cleanPropos();
    this.stepEvent.emit(0);
  }
  cleanPropos() {
    this.propos = {
      firstname: '',
      cin: '',
      lastname: '',
      birthDate: '',
      expirationDate: '',
      birthCountry: '',
      birthCity: '',
      selectedIdType: IdTypeCode.CIN,
      recto: null as File | null,
      verso: null as File | null,
    };
  }

  loadUser() {
    if (this.proposUpdate === undefined || this.proposUpdate.cin === '') {
      this.userService.getUser().subscribe((user) => {
        this.propos = {
          firstname: user.firstName,
          cin: user.idNumber,
          lastname: user.lastName,
          birthDate: '',
          expirationDate: '',
          birthCountry: '',
          birthCity: '',
          selectedIdType: IdTypeCode.CIN,
          recto: null as File | null,
          verso: null as File | null,
        };
        this.propos.cin = user.idNumber;
        this.propos.lastname = user.lastName;
        this.propos.firstname = user.firstName;
        this.proposUpdate = this.propos;
      });
    }
  }

  onSelectionChange(event) {
    /*this.clearUploadRecto();
    this.clearUploadVerso();*/

    if (event.value !== 'CARTE SEJOUR') {
      this.ninType = false;
    } else {
      this.ninType = true;
    }
  }

  send() {
    this.formInfoData.emit(this.propos);
    this.stepEvent.emit(3);
  }
  receiveStatusPopin(closedPopin: boolean) {
    if (closedPopin == true) {
      this.propos = {
        firstname: '',
        cin: '',
        lastname: '',
        birthDate: '',
        expirationDate: '',
        birthCountry: '',
        birthCity: '',
        selectedIdType: IdTypeCode.CIN,
        recto: null as File | null,
        verso: null as File | null,
      };
    }
    this.closedPopine.emit(closedPopin);
  }

  onFileChange(event: any, type: string) {
    const file = event.target.files[0];

    if (file) {
      if (!this.validateFile(file.name)) {
        // Le fichier n'a pas une extension valide
        console.error('Extension de fichier non valide.');
      } else if (file.size <= this.MAX_FILE_SIZE_MB * 1024 * 1024) {
        // La taille du fichier est valide, vous pouvez le télécharger
        this.uploadFile(file, type);
      } else {
        this.showSizeError = true;
        console.error('La taille du fichier dépasse la limite de 5 Mo.');
      }
    }
  }

  private isPdfFile(file: File): boolean {
    return (
      file.type === 'application/pdf' ||
      file.name.toLowerCase().endsWith('.pdf')
    );
  }

  private uploadFile(file: File, type: string) {
    if (type === 'recto') {
      this.showDeleteButtonRecto = true;
      this.uploadedFileRecto = file;
      this.filenameRecto = this.uploadedFileRecto.name;

      this.propos.recto = file;
    }
    if (type === 'verso') {
      this.showDeleteButtonVerso = true;
      this.uploadedFileVerso = file;
      this.filenameVerso = this.uploadedFileVerso.name;
      this.propos.verso = file;
    }

    if (
      !this.ninType &&
      this.propos.recto !== null &&
      this.propos.verso !== null
    ) {
      this.requiredNin = false;
    }
    if (this.ninType && this.propos.recto) {
      this.requiredNin = false;
    }
  }

  clearUploadRecto() {
    this.requiredNin = true;
    this.showDeleteButtonRecto = false;
    this.uploadedFileRecto = null;
    this.propos.recto = null;
    this.filenameRecto = null;
  }
  clearUploadVerso() {
    this.requiredNin = true;
    this.showDeleteButtonVerso = false;
    this.propos.verso = null;
    this.uploadedFileVerso = null;
    this.showDeleteButtonVerso = false;
    this.filenameVerso = null;
  }
  back() {
    this.selectedImage = null;
  }

  viewFile(types: string) {
    console.log(this.propos);
    //let blob = null;
    const reader = new FileReader();
    reader.onload = (e) => (this.selectedImage = reader.result);

    if (types === 'verso') {
      reader.readAsDataURL(this.propos.verso);
      this.showVerso = true;
      /* blob = new Blob([this.propos.verso], { type: 'application/pdf' });
      this.pdfReference = this.filenameVerso + '.pdf';*/
    }
    if (types === 'recto') {
      reader.readAsDataURL(this.propos.recto);
      this.showRecto = true;
      /* blob = new Blob([this.propos.recto], { type: 'application/pdf' });
      this.pdfReference = this.filenameRecto + '.pdf';*/
    }
    /*let url = window.URL.createObjectURL(blob);
    this.pdfSrc = url;

    this.showFileViewer = true;*/
  }
  // Dans votre composant Angular
  validateFile(name: string): boolean {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    return (
      ext.toLowerCase() === 'png' ||
      ext.toLowerCase() === 'jpg' ||
      ext.toLowerCase() === 'jpeg'
    );
  }
}
