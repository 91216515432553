<div class="wrapper">
  <div class="header-container">
    <h2>RECHERCHE AVANCÉE</h2>
    <button type="button" class="close-btn" (click)="close()">
      <img src="assets/images/close-white.svg" alt="" />
    </button>
  </div>
  <div class="form-wrapper">
    <div class="form-container">
      <h5>TITULAIRE DE LA CARTE</h5>
      <div class="grouped-inputs">
        <mat-form-field class="custom-field">
          <mat-label>Nom</mat-label>
          <input [(ngModel)]="lastName" type="text" id="lastName" name="lastName" matInput />
        </mat-form-field>
        <mat-form-field class="custom-field">
          <mat-label>Prénom</mat-label>
          <input [(ngModel)]="firstName" type="text" id="firstName" name="firstName" matInput />
        </mat-form-field>
      </div>
    </div>
    <div class="form-container">
      <h5>DATE</h5>
      <div class="grouped-inputs">
        <mat-form-field>
          <mat-label>Date de début</mat-label>
          <input [(ngModel)]="startOperationDate" name="startOperationDate" [matDatepicker]="picker" matInput
            required />
          <mat-datepicker-toggle [ngClass]="{ 'has-value': startOperationDate }" matSuffix matIconSuffix [for]="picker">
            <img matDatepickerToggleIcon src="/assets/images/date-picker.svg" alt="picker-icon" />
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Date de fin</mat-label>
          <input [(ngModel)]="endOperationDate" name="endOperationDate" [matDatepicker]="picker2" matInput required />
          <mat-datepicker-toggle [ngClass]="{ 'has-value': endOperationDate }" matSuffix matIconSuffix [for]="picker2">
            <img matDatepickerToggleIcon src="/assets/images/date-picker.svg" alt="picker-icon" />
          </mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="form-container">
      <h5>ETAT DU TRAITEMENT</h5>
      <div class="toggle-buttons">
        <mat-slide-toggle *ngFor="let status of inputStatus" (change)="changeStatus(status)"
          [checked]="selectedStatus.indexOf(status) >= 0">{{ mappingCardEpStatus(status) }}</mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="white-bg" mat-button (click)="reinitForm()">
      RÉINITIALISER
    </button>
    <button mat-button (click)="applyFilter()">FILTRER</button>
  </div>
</div>