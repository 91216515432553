<div class="tabs-container" translate="no">
  <!-- <div class="tabs">
     <button class="tab-button" [ngClass]="selectedTab === 'historique' ? 'activeTab' : ''"
      (click)="selectTab('historique')">
      HISTORIQUE
    </button>
    <button
      class="tab-button"
      [ngClass]="selectedTab === 'plafonds' ? 'activeTab' : ''"
      (click)="selectTab('plafonds')"
    >
      PLAFONDS
    </button> 
  </div> -->

  <div class="tab-content">
    <!-- Affichage de l'historique des opérations -->
    <div class="tab-history" *ngIf="selectedTab === 'historique'">
      <div class="list-header">
        <h5>Historique</h5>
        <div class="list-filter-wrapper">
          <div class="list-filter">
            <span *ngIf="filterOn">ANNULER LE FILTRE</span>
            <button *ngIf="filterOn" (click)="renitialiser()" class="btn-filter">
              <img src="/assets/images/filter-cancel.svg" alt="filter-icon" />
            </button>
          </div>
          <hr *ngIf="filterOn" />
          <div class="list-filter">
            <span *ngIf="!filterOn">Filtre</span>
            <span *ngIf="filterOn">MODIFIER LE FILTRE</span>
            <button (click)="openFilterPopup()" class="btn-filter" [ngClass]="filterOn ? 'edit' : ''"
              [attr.data-after]="countFilterRes()">
              <ng-container *ngIf="filterOn; else templateFilter">
                <img src="/assets/images/filter-edit.svg" alt="'menu-icon" />
              </ng-container>

              <ng-template #templateFilter>
                <img src="/assets/images/filter.svg" alt="'menu-icon" />
              </ng-template>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="loading" class="loading-indicator">
        Chargement des opérations...
      </div>

      <div class="files-container" *ngIf="!loading && filteredOperations?.length > 0">
        <div class="operations-container" (scroll)="onScroll($event)">
          <div class="file-tab" *ngFor="let operation of filteredOperations">
            <div class="file-info">
              <div class="file-elm">
                <p class="label">Libellé</p>
                <p class="info">{{ operation.longDescription }}</p>
              </div>
              <div class="file-elm">
                <p class="label">Montant En MAD</p>
                <p class="info" [ngClass]="
                    operation.convertedAmount > 0 ? 'positive' : 'negative'
                  ">
                  {{
                  operation.convertedAmount
                  | currency : " " : "" : "" : "fr"
                  }}
                  {{ operation.accountCurrency }}
                </p>
              </div>

              <div class="file-elm">
                <p class="label">Montant En Devises</p>
                <p class="info" [ngClass]=" 
                    operation.transactionAmount > 0 ? 'positive' : 'negative'
                  " *ngIf="operation.transactionCurrency !=='MAD'">
                  {{
                  operation.transactionAmount
                  | currency : " " : "" : "" : "fr"
                  }}
                  {{ operation.transactionCurrency }}
                </p>
              </div>


              <div class="file-elm">
                <p class="label">Date</p>
                <p class="info" *ngIf="operation.transactionStatus !== 'P'">{{ operation.date | date : "dd/MM/yyyy" }}
                </p>
              </div>

              <div class="file-elm">
                <p class="label">Statut</p>

                <p class="info" *ngIf="operation.transactionStatus === 'A'">
                  Accepté
                </p>
                <p class="info" *ngIf="operation.transactionStatus === 'C'">
                  Confirmé
                </p>
                <p class="info" *ngIf="operation.transactionStatus === 'P'">
                  En cours
                </p>
              </div>


            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!loading && filteredOperations?.length === 0" class="no-data">
        Aucune opération trouvée.
      </div>
    </div>

    <!-- Affichage des plafonds de carte -->
    <div *ngIf="selectedTab === 'plafonds'">
      <h3>Plafonds de carte</h3>
      <div *ngIf="loading" class="loading-indicator">
        Chargement des plafonds...
      </div>
      <div class="card-limits" *ngIf="!loading">
        <div class="files-container">
          <div class="file-info">
            <div class="file-elm">
              <p class="label">Retrait</p>
              <p class="info">
                {{
                currentCardSelectionner.ceilingWithdrawal
                | currency : " " : "" : "" : "fr"
                }}
              </p>
            </div>
            <div class="file-elm">
              <p class="label">Paiement</p>
              <p class="info">
                {{
                currentCardSelectionner.ceilingPayment
                | currency : " " : "" : "" : "fr"
                }}
              </p>
            </div>
            <div class="file-elm">
              <p class="label">Retrait GAB</p>
              <p class="info">
                {{
                currentCardSelectionner.ceilingWithdrawalGabSG
                | currency : " " : "" : "" : "fr"
                }}
              </p>
            </div>
            <div class="file-elm">
              <p class="label">Paiement En ligne</p>
              <p class="info">
                {{
                currentCardSelectionner.ceilingECommerce
                | currency : " " : "" : "" : "fr"
                }}
              </p>
            </div>
            <div class="file-elm">
              <p class="label">International</p>
              <p class="info">
                {{
                currentCardSelectionner.ceilingWithdrawalInternational
                | currency : " " : "" : "" : "fr"
                }}
              </p>
            </div>
            <div class="file-elm">
              <p class="label">Paiement International</p>
              <p class="info">
                {{
                currentCardSelectionner.ceilingPaymentInternational
                | currency : " " : "" : "" : "fr"
                }}
              </p>
            </div>
            <div class="file-elm">
              <p class="label">En ligne International</p>
              <p class="info">
                {{
                currentCardSelectionner.ceilingECommerceInternational
                | currency : " " : "" : "" : "fr"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>