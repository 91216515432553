import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store/state';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { selectESignatoryList } from '../../../core/store/state/e-sign';
import { ESignatory } from '../models/eSignatory';
import { MatDialogRef } from '@angular/material/dialog';
import { eSign } from '../models/eSign';
@Component({
  selector: 'app-e-signatory',
  templateUrl: './e-signatory.component.html',
  styleUrls: ['./e-signatory.component.scss'],
})
export class ESignatoryComponent implements OnInit {
  eSignatoryList: ESignatory[];
  getColor = {
    DRAFT: {  bgColor: 'rgba(1, 0, 53, 0.05)', color: '#010035', label: 'Draft' },
    PENDING: {  bgColor: 'rgba(51, 0, 115, 0.05)', color: '#330073', label: 'En attente de signature' },
    IN_PROGRESS: {  bgColor: 'rgba(17, 60, 84, 0.05)', color: '#113C54', label: 'En cours de signature' },
    SIGNED: {  bgColor: 'rgba(112, 179, 20, 0.05)', color: '#70B314', label: 'Signée' },
    COMPLETED: {  bgColor: 'rgba(20, 179, 84, 0.05)', color: '#14B354', label: 'Complet' },
    CANCELED: {  bgColor: 'rgba(238, 91, 111, 0.05)', color: '#EE5B6F', label: 'Annulée' },
    EXPIRED: {  bgColor: 'rgba(128, 128, 128, 0.05)', color: '#808080', label: 'Expirée' },
  };
  eSign: eSign;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ESignatoryComponent>,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.store.select(selectESignatoryList).subscribe((eSignatory) => {
      if (eSignatory != null && eSignatory.result != null) {
        this.eSignatoryList = eSignatory.result;
        this.eSign = this.eSignatoryList[0].envelope;
        this.eSign.advisorName = eSignatory.result[0].advisorName;
      }
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
