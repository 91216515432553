import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-popin-content',
  templateUrl: './popin-content.component.html',
  styleUrls: ['./popin-content.component.scss'],
})
export class PopinContentComponent implements OnInit {
  @Input() isHeader: boolean = false;
  @Input() title: string;
  @Input() width: string;
  @Input() height: string;
  @Output() closed = new EventEmitter<boolean>();
  @Input() isPopupVisible: boolean = true;

  constructor() {}

  ngOnInit() {}

  closePopup() {
    this.isPopupVisible = false;
    this.closed.emit(true);
  }
}
