import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CardEpStatus } from 'src/app/models/CardStatusEnum';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { Subscription } from 'rxjs';
import {
  cancelRefillRequest,
  fetchTrackedRefillRequests,
  selectTrackedRefillRequests,
  selectCancelRequest,
  fetchCardsHistoryFilter,
  fetchTrackedRefillRequestsFilter,
  getEscaleProChallengeCode,
  signEscaleProRequest,
  selectChallengeCode,
  selectSignRequest,
  initCardsTracking,
  initSignatory,
} from 'src/app/core/store/state/escale-pro';
import { handleCallState, CallStateResponse } from 'src/app/models/CallState';
import { Page } from 'src/app/models/page';
import { CardFillElementDtoList } from 'src/app/models/CardFillElementDtoList';
import { CardRefillElementDto } from 'src/app/models/CardRefillElementDto';
import { CardRefillSearchRequestDto } from 'src/app/models/CardRefillSearchRequestDto';
import { EpCardTrackingFilterComponent } from '../ep-card-tracking-filter/ep-card-tracking-filter.component';
import { MatDialog } from '@angular/material/dialog';
import { ChallengeService } from 'src/app/service/challenge.service';
import { SignatureChallengeAndDresponse } from 'src/app/models/signatureChallengeAndDresponse';
import { DirectDebitService } from 'src/app/service/direct-debit.service';
import { CardService } from 'src/app/service/card.service';
import { CardSignatoryHistoryDTO } from 'src/app/models/CardSignatoryHistoryDTO';
import { EpSignatureHistoryComponent } from '../ep-signature-history/ep-signature-history.component';

@Component({
  selector: 'app-ep-to-sign',
  templateUrl: './ep-to-sign.component.html',
  styleUrls: ['./ep-to-sign.component.scss'],
})
export class EpToSignComponent implements OnInit, OnDestroy {
  @Input() userCanSign: boolean = false;
  @Input() userCanConsult: boolean = false;
  escalProStatus = CardEpStatus;
  refillRequests: Array<CardRefillElementDto> = [];
  handleCallStateCardFillList: CallStateResponse;
  selectedCustomerNumberSub: Subscription;
  selectTrackedRefillRequestsSub: Subscription;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = null;
  selectTrackedRefillRequests$ = this.store.select(selectTrackedRefillRequests);
  selectChallengeCode$ = this.store.select(selectChallengeCode);
  selectChallengeCodeSub: Subscription;
  selectSignRequest$ = this.store.select(selectSignRequest);
  selectSignRequestSub: Subscription;
  handleCallStateChallengeCode: CallStateResponse;
  handleCallStateSignRequest: CallStateResponse;

  page = new Page();
  showLoader: boolean = false;
  displayError: boolean;
  uploadDefaultMessageError =
    'Le service est indisponible,  merci de réessayer ultérieurement';
  uploadDefaultMessageSuccess = 'Opération terminée avec succès';
  openPopinMessage = this.uploadDefaultMessageError;
  openPopinMessageSuccess = false;
  openCancelPopinConfirmFlag: boolean;
  selectedRefillRequest: CardRefillElementDto;
  selectCancelRequest$ = this.store.select(selectCancelRequest);
  selectCancelRequestSub: Subscription;
  handleCallStateCancelRefillRequest: CallStateResponse;
  openPopinSignatureFlag = false;
  challengeCode = '';
  isPendingRefreshChalCodBtn = false;
  isSignaturePending = false;
  filter: CardRefillSearchRequestDto;
  challengeKey: string;

  constructor(
    private store: Store<State>,
    private challengeService: ChallengeService,
    private cardService: CardService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectTrackedRefillRequestsSub.unsubscribe();
    this.selectCancelRequestSub.unsubscribe();
    this.store.dispatch(initCardsTracking());
    this.store.dispatch(initSignatory());
  }

  initFilter() {
    this.filter = new CardRefillSearchRequestDto(
      this.selectedCustomerNumber,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [CardEpStatus.PENDING, CardEpStatus.IN_PROGRESS]
    );
  }
  ngOnInit() {
    this.initHandleCallState();

    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
          this.page.init();
          this.initFilter();
          this.store.dispatch(
            fetchTrackedRefillRequests({
              page: this.page.currentPage,
              filter: this.filter,
            })
          );
        }
      }
    );

    this.selectTrackedRefillRequestsSub =
      this.selectTrackedRefillRequests$.subscribe((trackedRefillRequests) => {
        this.page.init();
        if (
          trackedRefillRequests &&
          trackedRefillRequests.result &&
          trackedRefillRequests.result.contentList
        ) {
          this.refillRequests = trackedRefillRequests.result.contentList;
          this.page.totalPages = trackedRefillRequests.result.totalPages;
          this.page.totalElements = trackedRefillRequests.result.totalElements;
        }
        this.handleCallStateCardFillList = handleCallState(
          trackedRefillRequests
        );
        this.displayError = this.getDisplayError();
        this.showLoader = this.getShowLoader();
      });

    this.selectCancelRequestSub = this.selectCancelRequest$.subscribe(
      (cancelRefillRequest) => {
        if (cancelRefillRequest && cancelRefillRequest.result) {
          this.getCurrentPage();
        }
        this.handleCallStateCancelRefillRequest =
          handleCallState(cancelRefillRequest);
        this.displayError = this.getDisplayError();
        this.showLoader = this.getShowLoader();
      }
    );

    this.selectChallengeCodeSub = this.selectChallengeCode$.subscribe(
      (challengeCode) => {
        if (challengeCode && challengeCode.result) {
          this.challengeCode = challengeCode.result.challenge;
          this.challengeKey = challengeCode.result.challengeKey;
        }
        this.handleCallStateChallengeCode = handleCallState(challengeCode);
        this.displayError = this.getDisplayError();
        this.showLoader = this.getShowLoader();
      }
    );

    this.selectSignRequestSub = this.selectSignRequest$.subscribe(
      (signRequest) => {
        if (signRequest && signRequest.result) {
          this.openPopinSignatureFlag = false;
          this.getCurrentPage();
        }
        this.handleCallStateSignRequest = handleCallState(signRequest);
        this.displayError = this.getDisplayError();
        this.showLoader = this.getShowLoader();
      }
    );
  }
  getCurrentPage() {
    if (this.selectedCustomerNumber) {
      this.store.dispatch(
        fetchTrackedRefillRequests({
          page: this.page.currentPage,
          filter: this.filter,
        })
      );
    }
  }
  initHandleCallState() {
    this.handleCallStateCardFillList = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
    this.handleCallStateCancelRefillRequest = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
    this.handleCallStateChallengeCode = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
    this.handleCallStateSignRequest = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
  }
  getShowLoader(): boolean {
    let showLoader =
      (this.handleCallStateCardFillList.showLoader ||
        this.handleCallStateCancelRefillRequest.showLoader ||
        this.handleCallStateChallengeCode.showLoader ||
        this.handleCallStateSignRequest.showLoader) &&
      !this.displayError;

    return showLoader;
  }

  getDisplayError(): boolean {
    let displayError =
      this.handleCallStateCardFillList.displayError ||
      this.handleCallStateCancelRefillRequest.displayError ||
      (this.handleCallStateChallengeCode.displayError &&
        !this.handleCallStateChallengeCode.openPopinMessageSuccess) ||
      this.handleCallStateSignRequest.displayError;

    if (displayError) {
      if (this.handleCallStateCardFillList.displayError) {
        this.openPopinMessage =
          this.handleCallStateCardFillList.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateCardFillList.openPopinMessageSuccess;
        this.handleCallStateCardFillList.displayError = false;
      }
      if (this.handleCallStateCancelRefillRequest.displayError) {
        this.openPopinMessage =
          this.handleCallStateCancelRefillRequest.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateCancelRefillRequest.openPopinMessageSuccess;
        this.handleCallStateCancelRefillRequest.displayError = false;
      }
      if (this.handleCallStateChallengeCode.displayError) {
        this.openPopinMessage =
          this.handleCallStateChallengeCode.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateChallengeCode.openPopinMessageSuccess;
        this.handleCallStateChallengeCode.displayError = false;
      }
      if (this.handleCallStateSignRequest.displayError) {
        this.openPopinSignatureFlag = false;
        this.openPopinMessage =
          this.handleCallStateSignRequest.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateSignRequest.openPopinMessageSuccess;
        this.handleCallStateSignRequest.displayError = false;
      }
    }
    return displayError;
  }

  closeCancelConfirmPopin(res: boolean) {
    this.openCancelPopinConfirmFlag = false;
    if (res) {
      this.store.dispatch(
        cancelRefillRequest({
          customerNumber: this.selectedCustomerNumber,
          requestId: this.selectedRefillRequest.id,
        })
      );
    }
  }

  openCancelConfirmPopin(request: CardRefillElementDto) {
    this.openCancelPopinConfirmFlag = true;
    this.selectedRefillRequest = request;
  }

  closePopin() {
    this.displayError = false;
  }

  mapEscaleProStatus(stat: CardEpStatus): string {
    switch (stat) {
      case CardEpStatus.PENDING:
        return 'En attente de signature';
      case CardEpStatus.IN_PROGRESS:
        return 'En cours de signature';
    }
  }

  openFilterPopup(): void {
    const dialogRef = this.dialog.open(EpCardTrackingFilterComponent, {
      data: {
        defaultStatus: [CardEpStatus.PENDING, CardEpStatus.IN_PROGRESS],
        filter: this.filter,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filter = result;
        this.filter.customerNumber = this.selectedCustomerNumber;
        this.page.init();

        this.store.dispatch(
          fetchTrackedRefillRequestsFilter({
            page: this.page.currentPage,
            filter: this.filter,
          })
        );
      }
    });
  }

  openSignatureHistory(signatureHistory: Array<CardSignatoryHistoryDTO>): void {
    const dialogRef = this.dialog.open(EpSignatureHistoryComponent, {
      width: '70%',
      data: {
        signatureHistory,
      },
    });
  }

  closeSignaturePopin() {
    this.openPopinSignatureFlag = false;
  }

  refreshChallengeCode() {
    this.store.dispatch(getEscaleProChallengeCode());
  }
  onSign(request: CardRefillElementDto) {
    this.selectedRefillRequest = request;
    this.refreshChallengeCode();
    this.openPopinSignatureFlag = true;
  }
  signHandler(data: SignatureChallengeAndDresponse) {
    this.store.dispatch(
      signEscaleProRequest({
        customerNumber: this.selectedCustomerNumber,
        requestId: this.selectedRefillRequest.id,
        challengeKey: data.challengeKey,
        dResponse: data.dResponse,
      })
    );
  }
  countFilterRes(): string {
    return (
      (this.filter.firstName ||
        this.filter.lastName ||
        this.filter.startOperationDate ||
        this.filter.endOperationDate ||
        this.filter.signatureStatus.length == 1) &&
      this.page.totalElements &&
      this.page.totalElements.toString()
    );
  }
}
