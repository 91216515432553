import { Action, createReducer, on } from '@ngrx/store';
import * as ConsultCardsActions from './consult-cards.actions';
import { initialState, ConsultCardsState } from './consult-cards.state';

const ConsultCardsReducer = createReducer(
  initialState,
  on(
    ConsultCardsActions.fetchCards,
    ConsultCardsActions.fetchCardsFilter,
    (state, { customerNumber, cardFilterDto }) => {
      return {
        ...state,
        consultCardsListing: {
          ...state.consultCardsListing,
          cards: {
            result: null,
            callState: {
              error: null,
              isLoaded: false,
              isLoading: true,
              displayMessage: true,
            },
          },
        },
      };
    }
  ),
  on(ConsultCardsActions.fetchCardsSuccess, (state, { cards }) => {
    return {
      ...state,
      consultCardsListing: {
        ...state.consultCardsListing,
        cards: {
          result: cards,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(ConsultCardsActions.fetchCardsFailed, (state, { error }) => {
    return {
      ...state,
      consultCardsListing: {
        ...state.consultCardsListing,
        operations: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),

  on(ConsultCardsActions.getCardOperations, (state) => {
    return {
      ...state,
      eSignListing: {
        ...state.operations,
        operation: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(ConsultCardsActions.getCardOperationsSuccess, (state, { op }) => {
    return {
      ...state,
      operations: {
        ...state.operations,
        operation: {
          result: op,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ConsultCardsActions.getCardOperationsFailed, (state, { error }) => {
    return {
      ...state,
      operations: {
        ...state.operations,
        operation: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  })
);

export function reducer(state: ConsultCardsState | undefined, action: Action) {
  return ConsultCardsReducer(state, action);
}
