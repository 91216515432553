export enum StatusESign {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  HISTORIQUE = 'HISTORIQUE',
  REJECTED = 'REJECTED',
  EXECUTED = 'EXECUTED',
  CANCELED = 'CANCELED',
  CREATE = 'CREATE',
}
