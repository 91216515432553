import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetFileResponseDtoList } from '../modules/external-transfer/models/getFileResponseDtoList';

import { GetFileRequestDto } from '../modules/external-transfer/models/getFileRequestDto';
import { environment } from '../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ExternalTransferService {
  constructor(private http: HttpClient) {}

  public signTransfer(
    clientId: number,
    fileId: number,
    challengeKey: string,
    dpResponse: string
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/files/${fileId}/clients/${clientId}/sign?challengeKey=${challengeKey}&dpResponse=${dpResponse}`,
      httpOptions
    );
  }

  public getFilesWithInProgressSignature(
    clientId: number,
    currentPage: number,
    request: GetFileRequestDto
  ): Observable<GetFileResponseDtoList> {
    return this.http.post<GetFileResponseDtoList>(
      `${environment.apiUrl}/files/clients/${clientId}/signature-in_progress?page=${currentPage}`,
      request,
      httpOptions
    );
  }

  public getFilesWithSignatureCompleted(
    clientId: number,
    currentPage: number,
    request: GetFileRequestDto
  ): Observable<GetFileResponseDtoList> {
    return this.http.post<GetFileResponseDtoList>(
      `${environment.apiUrl}/files/clients/${clientId}/signature-completed?page=${currentPage}`,
      request,
      httpOptions
    );
  }

  public cancelOrder(id: any, customerNumber: any) {
    return this.http.delete<void>(
      `${environment.apiUrl}/files/${id}/clients/${customerNumber}`,
      httpOptions
    );
  }
}
