import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CardEpStatus,
  mappingCardEpStatus,
} from '../../../models/CardStatusEnum';
import { CardRefillSearchRequestDto } from '../../../models/CardRefillSearchRequestDto';

@Component({
  selector: 'app-ep-card-tracking-filter',
  templateUrl: './ep-card-tracking-filter.component.html',
  styleUrls: ['./ep-card-tracking-filter.component.scss'],
})
export class EpCardTrackingFilterComponent implements OnInit {
  protected readonly CardEpStatus = CardEpStatus;

  firstName = null;
  lastName = null;
  startOperationDate: Date = null;
  endOperationDate: Date = null;
  inputStatus;
  selectedStatus: CardEpStatus[] = [];
  filter: CardRefillSearchRequestDto;

  constructor(
    public dialogRef: MatDialogRef<EpCardTrackingFilterComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: { defaultStatus: CardEpStatus[]; filter: CardRefillSearchRequestDto }
  ) {
    this.inputStatus = data.defaultStatus;
    this.filter = data.filter;
  }

  ngOnInit() {
    this.firstName = this.filter.firstName;
    this.lastName = this.filter.lastName;
    this.startOperationDate = this.filter.startOperationDate;
    this.endOperationDate = this.filter.endOperationDate;
    this.selectedStatus = this.filter.signatureStatus;
  }

  getStatus() {
    if (this.selectedStatus.length > 0) {
      return this.selectedStatus;
    } else {
      return this.inputStatus;
    }
  }

  applyFilter(): void {
    const { start, end } = this.adjustDates();

    const filterObject = new CardRefillSearchRequestDto(
      null,
      this.firstName,
      this.lastName,
      null,
      null,
      start,
      end,
      null,
      this.getStatus()
    );
    this.dialogRef.close(filterObject);
  }

  reinitForm(): void {
    this.firstName = '';
    this.lastName = '';
    this.endOperationDate = null;
    this.startOperationDate = null;
    this.selectedStatus = [...this.inputStatus];
    this.applyFilter();
  }

  close(): void {
    this.dialogRef.close();
  }

  mappingCardEpStatus(cardEpStatus: CardEpStatus) {
    return mappingCardEpStatus(cardEpStatus);
  }

  changeStatus(status) {
    let index = this.selectedStatus.indexOf(status);
    index < 0
      ? this.selectedStatus.push(status)
      : this.selectedStatus.splice(index, 1);
  }

  cancelFilter() {
    this.reinitForm();
    this.applyFilter();
  }
  adjustDates(): { start: Date | null; end: Date | null } {
    if (!this.startOperationDate || !this.endOperationDate) {
      return { start: null, end: null };
    }

    const start = new Date(this.startOperationDate);
    start.setHours(0, 0, 0, 0);

    const end = new Date(this.endOperationDate);
    end.setHours(23, 59, 59, 999);

    return { start, end };
  }
}
