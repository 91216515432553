import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { environment } from '../../environments/environment';
import { CardEscaleProDetailsDTO } from '../models/CardEscaleProDetailsDTO';
import { CardSearchRequestDTO } from '../models/CardSearchRequestDTO';
import { CardRefillSearchRequestDto } from '../models/CardRefillSearchRequestDto';
import { CardRefillElementDto } from '../models/CardRefillElementDto';
import { CardReloadDTO, CardRelodAccountsDTO } from '../models/CardReloadDTO';
import { CardRefillDto } from '../models/CardRefillDto';
import { CardFillElementDtoList } from '../models/CardFillElementDtoList';
import { CardEscaleProDTO } from '../models/CardEscaleProDTO';
import { CardEpStatus } from '../models/CardStatusEnum';
import { ActivatedRoute, Router } from '@angular/router';
import { CardSignatoryHistoryDTO } from '../models/CardSignatoryHistoryDTO';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  getEscaleProCards(
    customerNumber: string,
    filter: CardSearchRequestDTO
  ): Observable<Array<CardEscaleProDTO>> {
    return this.http.post<Array<CardEscaleProDTO>>(
      `${environment.apiUrl}/cards/escale-pro/${customerNumber}`,
      filter,
      httpOptions
    );
  }

  getEscaleProCardsDetails(
    customerNumber: string,
    cardNumber: string,
    accountNumber: string
  ): Observable<CardEscaleProDetailsDTO> {
    return this.http.get<CardEscaleProDetailsDTO>(
      `${environment.apiUrl}/clients/${customerNumber}/cards/escale-pro/${cardNumber}/details?rib=${accountNumber}`,
      httpOptions
    );
  }

  getAccountToBeDebited(customerNumber: string): Observable<CardReloadDTO> {
    //cards/escale-pro/{customerNumber}/issuer-account
    return this.http.get<CardReloadDTO>(
      `${environment.apiUrl}/cards/escale-pro/${customerNumber}/issuer-account`,
      httpOptions
    );
  }

  refillEscaleProCard(
    customerNumber: string,
    cardNumber: string,
    refillInfo: CardRefillDto
  ): Observable<number> {
    return this.http.post<number>(
      `${environment.apiUrl}/clients/${customerNumber}/cards/escale-pro/${cardNumber}/refill`,
      refillInfo,
      httpOptions
    );
  }

  getEscaleProRefillCards(
    page: number,
    filter: CardRefillSearchRequestDto
  ): Observable<CardFillElementDtoList> {
    return this.http.post<CardFillElementDtoList>(
      `${environment.apiUrl}/cards/escale-pro/refill-track?page=${page}`,
      filter,
      httpOptions
    );
  }

  cancelEscaleProRefillRequest(
    customerNumber: string,
    requestId: number
  ): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiUrl}/clients/${customerNumber}/cards/escale-pro/${requestId}/cancel`,
      httpOptions
    );
  }

  signEscaleProRequest(
    customerNumber: string,
    requestId: number,
    challengeKey: string,
    dResponse: string
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/clients/${customerNumber}/cards/escale-pro/${requestId}/signature?challengeKey=${challengeKey}&dpResponse=${dResponse}`,
      httpOptions
    );
  }

  downloadFile(customerNumber: string, id: number) {
    return this.http.get(
      `${environment.apiUrl}/clients/${customerNumber}/cards/escale-pro/${id}/recap`,
      {
        responseType: 'arraybuffer',
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    );
  }
}
