import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Client } from '../models/client';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SearchNoticeCriteria } from '../models/search-notice-criteria';
import { SearchNcrmCriteria } from '../models/search-ncrm-criteria';
import { CustomerDetail } from '../modules/sell-card/models/customer-detail';
import { environment } from '../../environments/environment';
import { OverdraftAuthorizationDto } from '../modules/dashboard/models/OverdraftAuthorizationDto';
import { OverdraftAuthorizationDetailsDto } from '../modules/dashboard/models/OverdraftAuthorizationDetailsDto';
import { AccountCustomerDetails } from '../models/AccountCustomerDetails';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  public getClients(): Observable<Client[]> {
    return this.http.get<Client[]>(
      `${environment.apiUrl}/users/clients`,
      httpOptions
    );
  }

  public getAccountsByClient(
    customerNumber: string,
    docType: string
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.apiUrl}/users/clients/${customerNumber}/accounts?docType=${docType}`,
      httpOptions
    );
  }

  public getTypeDocuments(customerNumber: string): Observable<any[]> {
    return this.http.get<string[]>(
      `${environment.apiUrl}/ged/document-types/${customerNumber}`,
      httpOptions
    );
  }

  public findDocumentsbyCriteria(
    customerNumber: string,
    searchNoticeCriteria: SearchNoticeCriteria
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/ged/search-document/${customerNumber}`,
      searchNoticeCriteria,
      httpOptions
    );
  }

  public findImagesbyCriteria(
    searchNcrmCriteria: SearchNcrmCriteria
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/ged/ncrm/get-document`,
      searchNcrmCriteria,
      httpOptions
    );
  }

  public getOverdraftsAuthorize(
    customerNumber: string
  ): Observable<Array<OverdraftAuthorizationDto>> {
    return this.http.get<Array<OverdraftAuthorizationDto>>(
      `${environment.apiUrl}/clients/` +
        customerNumber +
        `/overdraft-authorizations`,
      httpOptions
    );
  }

  public getOverdraftAuthorizeDetails(
    accountNumber: string,
    branchCode: string,
    currencyCode: string
  ): Observable<OverdraftAuthorizationDetailsDto> {
    const params = new HttpParams()
      .set('accountNumber', accountNumber)
      .set('branchCode', branchCode)
      .set('currencyCode', currencyCode);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: params,
    };
    return this.http.get<OverdraftAuthorizationDetailsDto>(
      `${environment.apiUrl}/accounts/overdraft-authorization-details`,
      httpOptions
    );
  }

  public getCustomerClient(userName): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/clients/selected-customer-client`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: new HttpParams().set(
          'username',
          userName ? userName.toLowerCase() : null
        ),
      }
    );
  }

  public storeCustomerClient(
    customerNumber: string,
    userName: string
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/clients/store-customer-client`,
      {
        username: userName ? userName.toLowerCase() : null,
        customerNumber: customerNumber,
      },
      httpOptions
    );
  }
  public getCustomerDetail(customerNumber: string): Observable<CustomerDetail> {
    return this.http.get<CustomerDetail>(
      `${environment.apiUrl}/clients/` + customerNumber + `/detail`,
      httpOptions
    );
  }

  public getClientDetail(customerNumber: string): Observable<Client> {
    return this.http.get<Client>(
      `${environment.apiUrl}/clients/` + customerNumber + `?status=VALIDATED`,
      httpOptions
    );
  }
  public getClientCodeMarche(
    customerNumber: string
  ): Observable<AccountCustomerDetails> {
    return this.http.get<AccountCustomerDetails>(
      `${environment.apiUrl}/clients/advisor/` + customerNumber,
      httpOptions
    );
  }
}
