import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { attachPatchData } from '@angular/core/src/render3/context_discovery';
import { UserService } from 'src/app/service/user.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectUserApplications } from 'src/app/core/store/state/user';
import { environment } from '../../../environments/environment';
import { navigateToApp } from 'src/app/models/applications-enum';

@Component({
  selector: '[app-app-popup]',
  templateUrl: './app-popup.component.html',
  styleUrls: ['./app-popup.component.scss'],
})
export class AppPopupComponent implements OnInit {
  public apps;
  public myInterval;
  selectUserApplications$ = this.store.select(selectUserApplications);

  constructor(
    private router: Router,
    private userService: UserService,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.selectUserApplications$.subscribe((userApplications) => {
      this.apps = userApplications;
    });
  }
  public getIcon(code): string {
    switch (code) {
      case 'SOGETRADE': {
        return './assets/images/apps/app-icon.png';
      }
      case 'SOGECASHNET': {
        return './assets/images/apps/cashnet.png';
      }
      case 'REPORTING': {
        return './assets/images/apps/webged.png';
      }
      case 'WebAffacturage': {
        return './assets/images/apps/facture.png';
      }
      default: {
        return '';
      }
    }
  }

  public navigateToApp(code) {
    navigateToApp(code, this.router);
  }
}
