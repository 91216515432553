export enum CardEpStatus {
  PENDING = 'PENDING', //"WAITING FOR SIGNATURE"
  IN_PROGRESS = 'IN_PROGRESS', //("WAITING FOR CONTRE SIGNATURE"),
  COMPLETED = 'COMPLETED',
  EXECUTED = 'EXECUTED', //("EXECUTED"),
  CANCELED = 'CANCELED', //("CANCELED BY THE CLIENT")?
  REJECTED = 'REJECTED', //("CANCELED BY THE CLIENT")?
}

export function mappingCardEpStatus(stat: CardEpStatus): string {
  switch (stat) {
    case CardEpStatus.COMPLETED:
      return 'En cours de traitement';
    case CardEpStatus.EXECUTED:
      return 'Exécuté';
    case CardEpStatus.CANCELED:
      return 'Annulé';
    case CardEpStatus.IN_PROGRESS:
      return 'En cours de signature';
    case CardEpStatus.PENDING:
      return 'En attente de signature';
    case CardEpStatus.REJECTED:
      return 'Rejeté';
  }
}
