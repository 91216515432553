<!-------------------------------- View File --------------------------------------->
<div class="pdf-viewer-popup"></div>
<div class="pdf-viewer-popup-container">
  <div class="pdf-viewer-popup-header">
    <h2>Visualiser</h2>
    <button type="button" class="close-btn" (click)="closeFileViewer.emit()">
      <img src="assets/images/close-white.svg" alt="" />
    </button>
  </div>
  <div class="pdf-viewer-popup-file">
    <ngx-doc-viewer [url]="pdfSrc" [viewer]="viewer" style="width: 100%; height: 100%"></ngx-doc-viewer>
  </div>
  <div class="download-action">
    <button (click)="downloadFile()">
      <span>Télécharger</span>
      <img src="../../../../assets/images/upload-cloud.svg" alt="'menu-icon" />
    </button>
  </div>
  <!----------------------------------------------------------------------------------->