import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorComponent } from './error/error.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DialogSignComponent } from './dialog-sign/dialog-sign.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { NumberFormatSignPipe } from './pipes/number-format-sign-pipe';
import { NumberStyleSignPipe } from './pipes/number-style-sign-pipe';
import { BarProgressClassPipe } from './pipes/bar-progress-class-pipe';
import { RefreshPageComponent } from './refresh-page/refresh-page/refresh-page.component';
import { MessagePopinComponent } from './message-popin/message-popin.component';
import { ConfirmPopinComponent } from './confirm-popin/confirm-popin.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PopinContentComponent } from './popin-content/popin-content.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SignatureComponent } from './signature/signature.component';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { TimerComponent } from './timer/timer.component';
import { ProgressComponent } from './progress/progress.component';

@NgModule({
  declarations: [
    PaginationComponent,
    ErrorComponent,
    DialogSignComponent,
    LoaderComponent,
    NumberFormatSignPipe,
    NumberStyleSignPipe,
    BarProgressClassPipe,
    RefreshPageComponent,
    MessagePopinComponent,
    ConfirmPopinComponent,
    PopinContentComponent,
    PdfViewerComponent,
    SignatureComponent,
    TimerComponent,
    ProgressComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PdfViewerModule,
    NgxDocViewerModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
  ],
  exports: [
    PaginationComponent,
    ErrorComponent,
    DialogSignComponent,
    LoaderComponent,
    NumberFormatSignPipe,
    NumberStyleSignPipe,
    BarProgressClassPipe,
    ConfirmPopinComponent,
    PopinContentComponent,
    MessagePopinComponent,
    PdfViewerComponent,
    SignatureComponent,
    TimerComponent,
    ProgressComponent
  ],
})
export class SharedModule {}
