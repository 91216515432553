import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { Page } from '../../../models/page';

import { eSign } from '../models/eSign';
import { ESignatory } from '../models/eSignatory';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import {
  initSignature,
  selectESignList,
  checkSignature,
  timeOutInit,
  selectESignatoryList,
  downloadFile,
  fetcheSignatory,
  closeSignature,
} from '../../../core/store/state/e-sign';
import { initESignature } from '../../../core/store/state/e-sign';
import { selectDownloadFile } from '../../../core/store/state/e-sign';
import { checkESign } from '../../../core/store/state/e-sign';
import { Store } from '@ngrx/store';
import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { State } from '../../../core/store/state';
import { fetchESign } from '../../../core/store/state/e-sign';
import { ESingIframeComponent } from '../e-sing-iframe/e-sing-iframe.component';
import { ESignatoryComponent } from '../e-signatory/e-signatory.component';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-e-sing-list',
  templateUrl: './e-sing-list.component.html',
  styleUrls: ['./e-sing-list.component.scss'],
})
export class ESingListComponent implements OnInit {
  selectESing$ = this.store.select(selectESignList);
  advisor: string;
  initESign$ = this.store.select(initESignature);
  dialogRef: MatDialogRef<ESingIframeComponent>;
  dialogRefSignatory: MatDialogRef<ESignatoryComponent>;
  displayedColumns: string[] = [
    'reference',
    'advisorName',
    'sentAt',
    'status',
    'signed',
    'actions',
  ];
  displayedColumnsHistorique: string[] = [
    'reference',
    'advisorName',
    'sentAt',
    'status',
    'actions',
  ];
  getColor = {
    DRAFT: {
      bgColor: 'rgba(1, 0, 53, 0.05)',
      color: '#010035',
      label: 'Draft',
    },
    PENDING: {
      bgColor: 'rgba(51, 0, 115, 0.05)',
      color: '#330073',
      label: 'En attente de signature',
    },
    IN_PROGRESS: {
      bgColor: 'rgba(17, 60, 84, 0.05)',
      color: '#113C54',
      label: 'En cours de signature',
    },
    SIGNED: {
      bgColor: 'rgba(112, 179, 20, 0.05)',
      color: '#70B314',
      label: 'Signée',
    },
    COMPLETED: {
      bgColor: 'rgba(20, 179, 84, 0.05)',
      color: '#14B354',
      label: 'Complet',
    },
    CANCELED: {
      bgColor: 'rgba(238, 91, 111, 0.05)',
      color: '#EE5B6F',
      label: 'Annulée',
    },
    EXPIRED: {
      bgColor: 'rgba(128, 128, 128, 0.05)',
      color: '#808080',
      label: 'Expirée',
    },
  };
  selectESignatory$ = this.store.select(selectESignatoryList);
  eSignatoryList: ESignatory[];
  fileReference: string;
  @Input() status: string;
  showFileViewer = false;
  statusSuivi = ['PENDING', 'IN_PROGRESS'];
  signatureTransactionId: string;
  statusSuiviToggles: { label: string; value: boolean }[] =
    this.statusSuivi.map((status) => ({ label: status, value: false }));
  statusHistorique = ['COMPLETED', 'CANCELED', 'EXPIRED', 'SIGNED'];
  statusHistoriqueToggles: { label: string; value: boolean }[] =
    this.statusHistorique.map((status) => ({ label: status, value: false }));
  eSignList: ESignatory[] = [];
  pdfSrc = '';
  page = new Page();
  pdfReference = '';
  listStatus: string[];
  dialogOpened: boolean = false;

  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';

  selectedCustomerNumberSub: any;
  constructor(
    private dialog: MatDialog,
    private store: Store<State>,
    private snackBar: MatSnackBar
  ) {
    this.page.init();
    this.page.totalElements = 2;
  }

  ngOnInit() {
    this.advisor = null;
    this.page.init();
    this.fetchData();
  }

  fetchData() {
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (this.status === 'PENDING') {
          this.listStatus = ['PENDING', 'IN_PROGRESS'];
        } else if (this.status === 'HISTORIQUE') {
          this.listStatus = ['COMPLETED', 'CANCELED', 'EXPIRED', 'SIGNED'];
        }

        this.store.dispatch(
          fetchESign({
            page: this.page.currentPage,
            status: this.listStatus,
            customerNumber: customerNumber,
          })
        );
      }
    );
    this.store.select(selectESignList).subscribe((eSigns) => {
      if (eSigns.result != null) {
        this.page.totalPages = eSigns.result.totalPages;
        this.page.totalElements = eSigns.result.totalElements;
        this.eSignList = eSigns.result.contentList;
      }
    });
  }

  getCurrentPage() {
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        this.store.dispatch(
          fetchESign({
            page: this.page.currentPage,
            status: this.listStatus,
            customerNumber: customerNumber,
          })
        );
      }
    );
  }

  initEsignatures(requestIds: string) {
    if (this.dialogOpened) {
      return;
    }
    this.store.dispatch(
      checkSignature({
        requestId: requestIds,
      })
    );

    var checkESignStore = this.store
      .select(checkESign)
      .subscribe((checkESign) => {
        if (checkESign != null && checkESign.result != null) {
          if (!checkESign.result.valueOf()) {
            this.store.dispatch(
              initSignature({
                requestId: requestIds,
              })
            );
            var initESignatureStore = this.store
              .select(initESignature)
              .subscribe((initESign) => {
                if (initESign != null && initESign.result != null) {
                  this.redirectToPageWithParam(requestIds);
                  checkESignStore.unsubscribe();
                  initESignatureStore.unsubscribe();
                }
              });
          } else if (checkESign.result.valueOf()) {
            {
              this.showSnackbarAction('already', requestIds);
              checkESignStore.unsubscribe();
              initESignatureStore.unsubscribe();
            }
          }
        }
      });
  }

  downloadPdf(requestIds: string, show: boolean) {
    this.store.dispatch(
      downloadFile({
        requestId: requestIds,
      })
    );
    if (show) {
      var downloadstore = this.store
        .select(selectDownloadFile)
        .subscribe((file) => {
          if (file != null && file.result != null) {
            this.downLoadFilePdf(file.result, 'application/pdf', requestIds);
            downloadstore.unsubscribe();
          }
        });
    }
    this.showFileViewer = false;
  }
  viewPdf(requestIds: string) {
    this.downloadPdf(requestIds, false);
    var downloadstore = this.store
      .select(selectDownloadFile)
      .subscribe((file) => {
        if (file != null && file.result != null) {
          let blob = new Blob([file.result], { type: 'application/pdf' });
          let url = window.URL.createObjectURL(blob);
          this.pdfSrc = url;
          this.pdfReference = requestIds + '.pdf';
          this.showFileViewer = true;
          downloadstore.unsubscribe();
        }
      });
  }
  downLoadFilePdf(data: any, type: string, ref: string) {
    let blob = new Blob([data], { type: type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = ref + '.pdf';
    link.click();
  }

  redirectToPageWithParam(requestIds: string) {
    this.dialogOpened = true;
    this.dialogRef = this.dialog.open(ESingIframeComponent, {
      width: '80%',
      height: '632px',
      data: { requestIds },
      disableClose: true,
      panelClass: 'eSingIframeModal',
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this.store.select(initESignature).subscribe((initESign) => {
        if (result === 'timeout') {
          if (initESign != null && initESign.result != null) {
            this.store.dispatch(
              timeOutInit({
                signatureTransactionId: initESign.result.signatureTransactionId,
                requestId: requestIds,
              })
            );
          }
          this.fetchData();
          this.dialogOpened = false;
        } else {
          this.store.dispatch(
            closeSignature({
              signatureTransactionId: initESign.result.signatureTransactionId,
            })
          );
          this.fetchData();
          this.dialogOpened = false;
        }
      });
    });
  }
  showESignator(requestIds: string, advisor: string) {
    this.advisor = advisor;
    this.store.dispatch(
      fetcheSignatory({
        requestId: requestIds,
      })
    );
    this.store.select(selectESignatoryList).subscribe((eSignatory) => {
      if (eSignatory != null) {
        this.eSignatoryList = eSignatory.result;
      }
    });
  }

  redirectToSignatoryWithParam(requestId: string, eSign: eSign) {
    this.store.dispatch(
      fetcheSignatory({
        requestId: requestId,
      })
    );
    this.dialogOpened = true;
    this.dialogRefSignatory = this.dialog.open(ESignatoryComponent, {
      width: '80%',
      height: '632px',
      data: { eSign },
      disableClose: true,
      panelClass: 'infosSignataireModal',
    });
    this.dialogRefSignatory.afterClosed().subscribe(() => {
      this.dialogOpened = false;
    });
  }
  showSnackbarAction(action: string, requestId: string) {
    let message = '';
    if (action === 'already') {
      message = 'Une signature et deja encours de ce envelope';
    } else if (action === 'Annuler') {
      message = 'Votre enveloppe est annulée ';
    } else {
      console.error('Action non gérée.');
      return;
    }
    this.snackBar.open(message + requestId, 'Fermer', {
      duration: 5000,
    });
  }
  ngOnDestroy(): void {}
}
