import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SharedModule} from '../shared-module/shared.module';
import {EscaleProRoutingModule} from './soge-valeur-routing.module';
import { SvChequeImpayeComponent } from './sv-cheque-impaye/sv-cheque-impaye.component';
import { SvChequePayerComponent } from './sv-cheque-payer/sv-cheque-payer.component';
import { SvDashboardComponent } from './sv-dashboard/sv-dashboard.component';
import { SvDownloadComponent } from './sv-download/sv-download.component';
import { SvEffetImpayeComponent } from './sv-effet-impaye/sv-effet-impaye.component';
import { SvEffetPayerComponent } from './sv-effet-payer/sv-effet-payer.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [SvDashboardComponent, SvEffetImpayeComponent, SvDownloadComponent, SvEffetPayerComponent, SvChequePayerComponent, SvChequeImpayeComponent],
  imports: [
    EscaleProRoutingModule,
    CommonModule,
    MatTooltipModule,
    SharedModule,
    MatMenuModule,
    MatButtonModule
  ],
})
export class SogeValeurModule { }
