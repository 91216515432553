export class CardRelodAccountsDTO {
  indicativeBalance: number;
  accountingBalance: number;
  ibanFormatAccount: string;
  constructor(
    indicativeBalance: number,
    accountingBalance: number,
    ibanFormatAccount: string
  ) {
    this.indicativeBalance = indicativeBalance;
    this.accountingBalance = accountingBalance;
    this.ibanFormatAccount = ibanFormatAccount;
  }
}
export class CardReloadDTO {
  accounts: Array<CardRelodAccountsDTO>;
  product: string;
  currencyAlphaCode: string;
  corporateName: string;
  customerNumber: string;
  country: string;
  city: string;
  address: string;
  userAloneInSigningSchema: boolean;

  constructor(
    accounts: Array<CardRelodAccountsDTO>,
    product: string,
    currencyAlphaCode: string,
    corporateName: string,
    customerNumber: string,
    country: string,
    city: string,
    address: string,
    userAloneInSigningSchema: boolean
  ) {
    this.accounts = accounts;
    this.product = product;
    this.currencyAlphaCode = currencyAlphaCode;
    this.corporateName = corporateName;
    this.customerNumber = customerNumber;
    this.country = country;
    this.city = city;
    this.address = address;
    this.userAloneInSigningSchema = userAloneInSigningSchema;
  }
}
