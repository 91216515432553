<div class="infos-modal-header">
  <h2>INFORMATIONS SIGNATAIRE</h2>
  <button type="button" class="close-btn" (click)="closeDialog()">
    <img src="assets/images/close-white.svg" alt="" />
  </button>
</div>

<div class="infos-wrapper">
  <div class="signataire-container">
    <h2>Client Signataire</h2>
    <div *ngFor="let eSignatory of eSignatoryList; let i = index">
      <div class="signataire-row">
        <div class="custom-col">
          <span class="label">
            <ng-container *ngIf="i === 0">{{ i + 1 }}<sup>er</sup></ng-container>
            <ng-container *ngIf="i !== 0">
              {{ i + 1 }}<sup>ème</sup></ng-container>
            signataire</span>
        </div>
        <div class="custom-col">
          <span class="label">Nom</span>
          <span class="value">{{ eSignatory.user.firstName }}
            {{ eSignatory.user.lastName }}</span>
        </div>
        <div class="custom-col">
          <span class="label">Date reçu</span>
          <span class="value">{{
            eSign?.sentAt ? (eSign?.sentAt | date : "dd/MM/yyyy") : "-"
            }}</span>
        </div>
        <div class="custom-col">
          <span class="label">Date d’action</span>
          <span class="value">{{
            eSignatory.dateSignature
            ? (eSignatory.dateSignature | date : "dd/MM/yyyy")
            : "-"
            }}</span>
        </div>
        <div class="custom-col">
          <span class="label">Statut</span>
          <div class="client-status" [style.background-color]="
              eSignatory.status !== null
                ? getColor[eSignatory.status].bgColor
                : getColor['PENDING'].bgColor
            " [style.color]="
              eSignatory.status !== null
                ? getColor[eSignatory.status].color
                : getColor['PENDING'].color
            " [style.border-left-color]="
              eSignatory.status !== null
                ? getColor[eSignatory.status].color
                : getColor['PENDING'].color
            ">
            {{
            eSignatory.status !== null
            ? getColor[eSignatory.status].label
            : getColor["PENDING"].label
            }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="gestionnaire-container">
    <h2>gestionnaire</h2>
    <div class="gestionnaire-row">
      <div class="custom-col">
        <span class="label">
          {{ eSignatoryList?.length + 1 }}<sup>ème</sup> signataire</span>
      </div>
      <div class="custom-col">
        <span class="label">Nom</span>
        <span class="value">{{ eSign?.advisorName }}</span>
      </div>
      <div class="custom-col">
        <span class="label">Date reçu</span>
        <span class="value">{{
          eSign?.sentAt ? (eSign?.sentAt | date : "dd/MM/yyyy") : "-"
          }}</span>
      </div>
      <div class="custom-col">
        <span class="label">Date d’action</span>
        <span class="value">{{
          eSign?.sentAt
          ? (eSign?.sentAt | date : "dd/MM/yyyy")
          : "-"
          }}</span>
      </div>
      <div class="custom-col">
        <span class="label">Statut</span>
        <div class="client-status" [style.background-color]="
            eSign?.status !== null
              ? getColor[eSign?.status].bgColor
              : getColor['PENDING'].bgColor
          " [style.color]="
            eSign?.status !== null
              ? getColor[eSign?.status].color
              : getColor['PENDING'].color
          " [style.border-left-color]="
            eSign?.status !== null
              ? getColor[eSign?.status].color
              : getColor['PENDING'].color
          ">
          {{
          eSign?.status !== null
          ? getColor[eSign?.status].label
          : getColor["PENDING"].label
          }}
        </div>
      </div>
    </div>
  </div>
</div>