<div
  class="app-container"
  [ngClass]="{ clientListIsOpened: clientListIsOpened == true }"
>
  <div
    class="aside-client-list"
    [ngClass]="{ clientListIsOpened: clientListIsOpened == true }"
  >
    <button
      type="button"
      class="close-btn"
      (click)="clientListIsOpened = false"
    >
      <img src="assets/images/cross-icon@2x.png" width="11" alt="" />
    </button>
    <div class="clients-search">
      <div class="control-container">
        <div class="control-input">
          <input
            type="text"
            placeholder="Recherche clients"
            class="control-input-field search-input"
            [(ngModel)]="searchClient"
            (keyup)="changeStatus()"
          />
        </div>
      </div>
    </div>
    <ul class="clients-list">
      <li *ngFor="let cl of filtredClientList; let i = index">
        <button
          type="button"
          [ngClass]="{ selectedItem: selectedItem === i }"
          (click)="selectedItem = i; onChange(cl)"
        >
          {{ cl.name }}
        </button>
      </li>
    </ul>
  </div>
  <div
    class="aside-client-list-cover"
    [ngClass]="{ clientListIsOpened: clientListIsOpened == true }"
  ></div>
  <div class="app-aside-container" *ngIf="loadedclient">
    <div class="side-menu-container">
      <h2 class="title-heading-model02" style="padding-left: 15px">
        Mes Documents
      </h2>
      <div class="aside-links-list-container">
        <ul class="aside-links-list">
          <li id="id-{{ item?.family?.code }}" *ngFor="let item of families">
            <a
              routerLinkActive="active"
              class="menu-item-body"
              (click)="chooseFamily(item)"
              [class]="item == selectedFamily ? 'active' : ''"
            >
              <span [innerHTML]="item?.family?.label"></span>
            </a>

          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="app-content-container">
    <div class="app-content">
      <!-- Critères de recherche-->
      <div class="page-title-container" *ngIf="loadedclient">
        <div class="page-title-content">
          <h2 class="title-heading-model02">
            <div [innerHTML]="selectedFamily?.family?.label"></div>
            <span class="tagLine-text">Critères de recherche</span>
          </h2>
        </div>
      </div>
      <div class="accordion-container" *ngIf="loadedclient" [ngClass]="{'selectIsOpened' : selectIsOpened}">
        <squeezebox>
          <sb-item [collapsed]="false">
            <sb-item-head>
              <div class="accordion-head-content">
                <h2>
                  Recherche avancée
                </h2>
              </div>
              <span class="accordion-arrow">
                <img src="assets/images/Arrow-top-icn@2x.png" width="13" alt="">
              </span>
            </sb-item-head>
            <sb-item-body>
              <form [formGroup]="filterForm" class="controls-form-container" #searchEventForm="ngForm" (ngSubmit)="onSubmitFilter()">
                <div class="accordion-body-container">
                  <div class="form-grid">
                    <div class="control-container required">
                      <label class="control-label">Type de documents</label>
                      <div class="control-input control-select-container">
                        <ngx-select
                          [items]="getDocumentTypes()"
                          optionValueField="code"
                          optionTextField="label"
                          [noAutoComplete]="true"
                          [defaultValue]="getDefaultDocumentType()"
                          class="control-select"
                          autoSelectSingleOption="true"
                          (select)="chooseDocType($event)"
                          formControlName="documentType"
                          (open)="selectIsopened()"
                          (close)="selectIsclosed()"
                        >
                        </ngx-select>
                      </div>
                    </div>
                    <div class="control-container required">
                      <label class="control-label">Comptes</label>
                      <div class="control-input control-select-container">
                        <ngx-select
                          [items]="getAccounts()"
                          optionValueField="code"
                          optionTextField="label"
                          [noAutoComplete]="true"
                          [defaultValue]="getDefaultAccount()"
                          class="control-select"
                          autoSelectSingleOption="true"
                          formControlName="account"
                          (open)="selectIsopened()"
                          (close)="selectIsclosed()"
                        >
                        </ngx-select>
                      </div>
                    </div>
                    <div class="control-container required">
                      <label class="control-label">Periode du</label>
                      <div class="control-input">
                        <input
                          type="text"
                          placeholder="Choisir une date"
                          [bsConfig]="datePickerConfig"
                          class="control-input-field Datepicker"
                          bsDatepicker
                          [minDate]="setMinDate()"
                          [maxDate]="setMaxDate()"
                          formControlName="startDate"
                        />
                        <p
                          class="error-msg"
                          *ngIf="
                            startDate.errors?.required &&
                            (startDate.dirty || startDate.touched)
                          "
                        >
                          * Ce champ est obligatoire
                        </p>
                      </div>
                    </div>
                    <div class="control-container required">
                      <label class="control-label">Au</label>
                      <div class="control-input">
                        <input
                          type="text"
                          placeholder="Choisir une date"
                          [bsConfig]="datePickerConfig"
                          class="control-input-field Datepicker"
                          bsDatepicker
                          [minDate]="setMinDate()"
                          [maxDate]="setMaxDate()"
                          formControlName="endDate"
                        />
                        <p
                          class="error-msg"
                          *ngIf="
                            endDate.errors?.required &&
                            (endDate.dirty || endDate.touched)
                          "
                        >
                          * Ce champ est obligatoire
                        </p>
                      </div>
                    </div>
                    <div
                      class="control-container"
                      *ngIf="
                        ['RELEVE', 'SWIFTS'].indexOf(
                          selectedFamily?.family?.code
                        ) == -1
                      "
                    >
                      <label class="control-label">Montant min</label>
                      <div class="control-input">
                        <input
                          type="text"
                          placeholder=""
                          formControlName="montantMin"
                          class="control-input-field"
                        />
                      </div>
                    </div>

                    <div
                      class="control-container"
                      *ngIf="
                        ['RELEVE', 'SWIFTS'].indexOf(
                          selectedFamily?.family?.code
                        ) == -1
                      "
                    >
                      <label class="control-label">Montant max</label>
                      <div class="control-input">
                        <input
                          type="text"
                          placeholder=""
                          formControlName="montantMax"
                          class="control-input-field"
                        />
                      </div>
                    </div>

                    <div
                      class="control-container"
                      *ngIf="
                        ['RELEVE', 'SWIFTS', 'IMG_VALEUR'].indexOf(
                          selectedFamily?.family?.code
                        ) == -1
                      "
                    >
                      <label class="control-label">Devises</label>
                      <div class="control-input control-select-container">
                        <ngx-select
                          [items]="getCurrencies()"
                          optionValueField="code"
                          optionTextField="label"
                          [noAutoComplete]="true"
                          class="control-select"
                          autoSelectSingleOption="true"
                          (open)="selectIsopened()"
                          (close)="selectIsclosed()"
                          formControlName="currency"
                          [defaultValue]="['ALL']"
                        >
                        </ngx-select>
                      </div>
                    </div>

                    <div
                      class="control-container"
                      *ngIf="
                        ['IMG_VALEUR'].indexOf(selectedFamily?.family?.code) >
                        -1
                      "
                    >
                      <label class="control-label">N° valeur</label>
                      <div class="control-input">
                        <input
                          type="text"
                          placeholder=""
                          formControlName="numValue"
                          class="control-input-field"
                        />
                      </div>
                    </div>

                    <div
                      class="control-container"
                      *ngIf="
                        ['IMG_VALEUR'].indexOf(selectedFamily?.family?.code) >
                        -1
                      "
                    >
                      <label class="control-label">N° remise</label>
                      <div class="control-input">
                        <input
                          type="text"
                          placeholder=""
                          formControlName="numRemise"
                          class="control-input-field"
                        />
                      </div>
                    </div>

                    <div
                      class="control-container"
                      *ngIf="
                        ['IMG_VALEUR'].indexOf(selectedFamily?.family?.code) >
                          -1 && ['CHQA', 'LCNA'].includes(documentType.value)
                      "
                    >
                      <label class="control-label">Code banque tiré</label>
                      <div class="control-input control-select-container">
                        <ngx-select
                          [items]="listBankCode"
                          optionValueField="code"
                          optionTextField="label"
                          [noAutoComplete]="false"
                          class="control-select"
                          autoSelectSingleOption="true"
                          formControlName="codeBanqueTire"
                          (open)="selectIsopened()"
                          (close)="selectIsclosed()"
                        >
                        </ngx-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-action">
                  <button type="submit" class="main-btn">
                    <img
                      src="assets/images/search-icn@2x.png"
                      class="icon-btn"
                      width="14"
                      alt=""
                    />
                    <span class="text-btn">Rechercher</span>
                  </button>
                  <button
                    type="button"
                    class="main-btn"
                    (click)="resetTransactions()"
                  >
                    <img
                      src="assets/images/reset-icn@2x.png"
                      class="icon-btn"
                      width="13"
                      alt=""
                    />
                    <span class="text-btn">Retablir</span>
                  </button>
                </div>
              </form>
            </sb-item-body>
          </sb-item>
        </squeezebox>
      </div>
      <!-- Résultat-->
      <div
        class="loader-container"
        [class]="
          loadedclient === false
            ? 'loader-container full-size'
            : 'loader-container small-size'
        "
        *ngIf="loaded === false || loadedclient === false"
      >
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div *ngIf="showWebGedResult()">
        <div *ngIf="technicalError === true" class="message-erreur-icon">
          <p class="error-msg warning">
            <img
              class="error-icn"
              src="../../../assets/images/dangerous.svg"
              alt="alert"
            />
            <span> Erreur, Veuillez contacter votre équipe support. </span>
          </p>
        </div>
        <div *ngIf="exceeded === true" class="message-erreur-icon">
          <p class="error-msg warning">
            <img
              class="error-icn"
              src="../../../assets/images/dangerous.svg"
              alt="alert"
            />
            <span>
              Nombre maximum de réultats atteint, merci d'affiner votre
              recherche.
            </span>
          </p>
        </div>
        <div *ngIf="noResult === true" class="message-erreur-icon">
          <p class="error-msg warning">
            <img
              class="error-icn"
              src="../../../assets/images/dangerous.svg"
              alt="alert"
            />
            <span> Aucun document pour ces critères. </span>
          </p>
        </div>
        <div
          class="page-title-container"
          *ngIf="transactions?.documents?.length > 0"
        >
          <div class="page-title-content">
            <h2 class="title-heading-model02">
              Visualisez et téléchargez vos documents
              <br />
              <span class="tagLine-text">Liste des documents</span>
            </h2>
          </div>
          <div class="page-options">
            <!--<button
              type="button"
              class="main-btn noborder-btn"
              (click)="downloadAllFiles()"
            >
              <img
                src="assets/images/download-icn@2x.png"
                class="icon-btn"
                width="21"
                alt=""
              />
              <span class="text-btn">Tout télécharger</span>
            </button>-->
            <button
              type="button"
              class="main-btn noborder-btn"
              (click)="downloadSelectedFiles()"
            >
              <img
                src="assets/images/download-icn@2x.png"
                class="icon-btn"
                width="21"
                alt=""
              />
              <span class="text-btn">Télécharger la sélection</span>
            </button>
          </div>
        </div>
        <div class="table-container">
          <div
            class="table-row"
            *ngFor="let transaction of transactions?.documents"
          >
            <div class="fixed-size" style="width: 44px">
              <label class="bordred-check-container">
                <input type="checkbox" (click)="checkStatement(transaction)" />
                <span class="fake-check-mask">
                  <span class="fake-check-item"></span>
                </span>
              </label>
            </div>

            <ng-container
              class="table-row"
              *ngIf="
                selectedFamily.family.code == 'IMG_VALEUR' &&
                ['REMCHQ', 'REMLCN'].includes(documentType.value)
              "
            >
              <div class="data-col">
                <span class="data-label">N° remise</span>
                <span class="data-val">{{ transaction["numRemise"] }}</span>
              </div>

              <div class="data-col">
                <span class="data-label">Type remise</span>
                <span class="data-val">{{ transaction["typeRemise"] }}</span>
              </div>

              <div class="data-col">
                <span class="data-label">Montant</span>
                <span class="data-val">{{ transaction["montant"] }}</span>
              </div>

              <div class="data-col">
                <span class="data-label">Nbr valeurs</span>
                <span class="data-val">{{ transaction["nbValues"] }}</span>
              </div>

              <div class="data-col">
                <span class="data-label">Date traitement</span>
                <span class="data-val">{{
                  transaction["dtetrt"] | date : "dd/MM/yyyy"
                }}</span>
              </div>
            </ng-container>
            <ng-container
              class="table-row"
              *ngIf="
                this.selectedFamily.family.code == 'IMG_VALEUR' &&
                ['CHQA', 'CHQR', 'LCNA', 'LCNR'].includes(documentType.value)
              "
            >
              <div class="data-col">
                <span class="data-label">N° valeur</span>
                <span class="data-val">{{ transaction["numValue"] }}</span>
              </div>

              <div class="data-col">
                <span class="data-label">N° remise</span>
                <span class="data-val">{{
                  transaction["numRemise"] == ""
                    ? "-"
                    : transaction["numRemise"]
                }}</span>
              </div>

              <div class="data-col">
                <span class="data-label">Montant</span>
                <span class="data-val">{{ transaction["montant"] }}</span>
              </div>

              <div class="data-col">
                <span class="data-label">Date traitement</span>
                <span class="data-val">{{
                  transaction["dtetrt"] | date : "dd/MM/yyyy"
                }}</span>
              </div>
            </ng-container>
            <ng-container
              class="table-row"
              *ngIf="
                ['RELEVE', 'SWIFTS'].includes(selectedFamily.family.code) &&
                [
                  'RELEVE_TOS',
                  'RELCPT',
                  'TITRES',
                  'ECHELLE',
                  'MONETIQUE',
                  'RELEVE_RECAP',
                  'CAUTIONS',
                  'SWIFTS',
                  'SWIFT_SWIFTR',
                  'SWIFT_GOS'
                ].includes(documentType.value)
              "
            >
              <div class="data-col">
                <span class="data-label">N° compte</span>
                <span
                  *ngIf="documentType.value == 'RELEVE_TOS'"
                  class="data-val"
                  >{{ transaction["N COMPTE"] }}</span
                >
                <span
                  *ngIf="['SWIFT_GOS'].includes(documentType.value)"
                  class="data-val"
                  >{{ transaction["COMPTE"] }}</span
                >
                <span
                  *ngIf="
                    [
                      
                      'RELEVE_RECAP',
                 
                      'SWIFT_SWIFTR'
                    ].includes(documentType.value)
                  "
                  class="data-val"
                  >{{ transaction["rib"] }}</span
                >
                <span
                  *ngIf="['RELCPT', 'SWIFTS', 'TITRES', 'MONETIQUE','ECHELLE','CAUTIONS'].includes(documentType.value)"
                  class="data-val"
                  >{{ searchNoticeCriteria.account }}</span
                >
              </div>

              <div class="data-col">
                <span class="data-label">Titre</span>
                <span
                  *ngIf="['RELEVE_TOS', 'RELCPT'].includes(documentType.value)"
                  class="data-val"
                  >Relevé de compte</span
                >
                <span
                  *ngIf="['TITRES'].includes(documentType.value)"
                  class="data-val"
                  >Relevé titre</span
                >
                <span
                  *ngIf="['ECHELLE'].includes(documentType.value)"
                  class="data-val"
                  >Echelle d'intérêt</span
                >
                <span
                  *ngIf="['MONETIQUE'].includes(documentType.value)"
                  class="data-val"
                  >Relevé de cartes monétiques</span
                >
                <span
                  *ngIf="['RELEVE_RECAP'].includes(documentType.value)"
                  class="data-val"
                  >Relevé des frais et commissions bancaires</span
                >
                <span
                  *ngIf="['CAUTIONS'].includes(documentType.value)"
                  class="data-val"
                  >Relevé des cautions</span
                >
                <span
                  *ngIf="
                    ['SWIFTS', 'SWIFT_SWIFTR', 'SWIFT_GOS'].includes(
                      documentType.value
                    )
                  "
                  class="data-val"
                  >Swift MT103</span
                >
              </div>

              <div class="data-col">
                <span class="data-label">Date</span>
                <span
                  *ngIf="documentType.value == 'RELEVE_TOS'"
                  class="data-val"
                  >{{ transaction["PERIODE"] }}</span
                >
                <span
                  *ngIf="['SWIFT_GOS'].includes(documentType.value)"
                  class="data-val"
                  >{{ transaction["DATE RECEPTION"] }}</span
                >
                <span
                  *ngIf="
                    [
                     
                      'RELEVE_RECAP',
                     
                      'SWIFT_SWIFTR'
                    ].includes(documentType.value)
                  "
                  class="data-val"
                  >{{ transaction["dateDocument"] }}</span
                >
                <span
                  *ngIf="['RELCPT'].includes(documentType.value)"
                  class="data-val"
                  >{{ transaction["creationDate"] }}</span
                >
                 <span
                  *ngIf="['TITRES'].includes(documentType.value)"
                  class="data-val"
                  >{{ transaction["creationDate"] }}</span
                >
                 <span
                  *ngIf="['MONETIQUE'].includes(documentType.value)"
                  class="data-val"
                  >{{ transaction["creationDate"] }}</span
                >
                <span
                  *ngIf="['CAUTIONS'].includes(documentType.value)"
                  class="data-val"
                  >{{ transaction["creationDate"] }}</span
                >
                 <span
                  *ngIf="['ECHELLE'].includes(documentType.value)"
                  class="data-val"
                  >{{ transaction["creationDate"] }}</span
                >
                <span
                  *ngIf="['SWIFTS'].includes(documentType.value)"
                  class="data-val"
                  >{{ transaction["creationDate"] }}</span
                >
              </div>
            </ng-container>
            <ng-container
              class="table-row"
              *ngIf="
                [
                  'AVIS',
                  'IMPAYES_CHQ_LCN',
                  'FORMULES',
                  'TAB_AMORTISSEMENT'
                ].includes(selectedFamily.family.code) &&
                [
                  'AVIS_OP',
                  'AVIS_TOS_CREDIT',
                  'AVIS_TOS_DEBIT',
                  'SWIFTS',
                  'SWIFT_SWIFTR',
                  'SWIFT_GOS',
                  'IMPAYES_CHQ',
                  'IMPAYES_EFFET',
                  'AMORTISSEMENT_AMORTISSEMENT',
                  'FORMULE1',
                  'FORMULE2',
                  'FORMULES_FORMULE3',
                  'FORMULES_FORMULE5',
                  'FORMULES_FORMULE6'
                ].includes(documentType.value)
              "
            >
              <div class="data-col">
                <span class="data-label">N° compte</span>
                <span
                  *ngIf="
                    ['AVIS_OP', 'AVIS_TOS_CREDIT', 'AVIS_TOS_DEBIT'].includes(
                      documentType.value
                    )
                  "
                  class="data-val"
                  >{{ transaction["NUMERO COMPTE"] }}</span
                >
                <span
                  *ngIf="
                    ['IMPAYES_CHQ', 'IMPAYES_EFFET', 'FORMULE1','FORMULE2'].includes(
                      documentType.value
                    )
                  "
                  class="data-val"
                  >{{ transaction["N COMPTE"] }}</span
                >
                <span
                  *ngIf="['SWIFT_GOS'].includes(documentType.value)"
                  class="data-val"
                  >{{ transaction["COMPTE"] }}</span
                >
                <span
                  *ngIf="
                    [
                      'SWIFTS',
                      'SWIFT_SWIFTR',
                      'AMORTISSEMENT_AMORTISSEMENT',
                      'FORMULES_FORMULE3',
                      'FORMULES_FORMULE5',
                      'FORMULES_FORMULE6'
                    ].includes(documentType.value)
                  "
                  class="data-val"
                  >{{ transaction["rib"] }}</span
                >
              </div>

              <div class="data-col">
                <span class="data-label">Montant</span>
                <span
                  *ngIf="
                    ['AVIS_OP', 'AVIS_TOS_CREDIT', 'AVIS_TOS_DEBIT','IMPAYES_CHQ', 'IMPAYES_EFFET'].includes(
                      documentType.value
                    )
                  "
                  class="data-val"
                  >{{ transaction["MONTANT"] }}</span
                >
                <span
                  *ngIf="
                    ['FORMULE1','FORMULE2'].includes(
                      documentType.value
                    )
                  "
                  class="data-val"
                  >{{ transaction["MONTANT EN DEVISE"] }}</span
                >
                <span
                  *ngIf="
                    [
                     
                      'AMORTISSEMENT_AMORTISSEMENT',
                     
                      'FORMULES_FORMULE3',
                      'FORMULES_FORMULE5',
                      'FORMULES_FORMULE6'
                    ].includes(documentType.value)
                  "
                  class="data-val"
                  >{{ transaction["montantdocument"] }}</span
                >
              </div>

              <div class="data-col">
                <span class="data-label">Devise</span>
                <span
                  *ngIf="
                    ['AVIS_OP', 'AVIS_TOS_CREDIT', 'AVIS_TOS_DEBIT','IMPAYES_CHQ', 'IMPAYES_EFFET', 'FORMULE1','FORMULE2'].includes(
                      documentType.value
                    )
                  "
                  class="data-val"
                  >{{ transaction["DEVISE"] }}</span
                >
                <span
                  *ngIf="
                    [
                      'AMORTISSEMENT_AMORTISSEMENT',
                      'FORMULES_FORMULE3',
                      'FORMULES_FORMULE5',
                      'FORMULES_FORMULE6'
                    ].includes(documentType.value)
                  "
                  class="data-val"
                  >{{ transaction["devise"] }}</span
                >
              </div>

              <div class="data-col">
                <span class="data-label">Date</span>
                <span
                  *ngIf="
                    ['AVIS_OP', 'AVIS_TOS_CREDIT', 'AVIS_TOS_DEBIT','IMPAYES_CHQ', 'IMPAYES_EFFET', 'FORMULE1','FORMULE2'].includes(
                      documentType.value
                    )
                  "
                  class="data-val"
                  >{{ formatAviTosDate(transaction["creationDate"]) }}</span
                >
                <span
                  *ngIf="['SWIFT_GOS'].includes(documentType.value)"
                  class="data-val"
                  >{{ transaction["DATE RECEPTION"] }}</span
                >
                <span
                  *ngIf="
                    [
                      'SWIFTS',
                      'SWIFT_SWIFTR',
                      'AMORTISSEMENT_AMORTISSEMENT',
                      'FORMULES_FORMULE3',
                      'FORMULES_FORMULE5',
                      'FORMULES_FORMULE6'
                    ].includes(documentType.value)
                  "
                  class="data-val"
                  >{{ transaction["dateDocument"] | date : "dd/MM/yyyy" }}</span
                >
              </div>
            </ng-container>

            <div class="fixed-size" style="width: 62px">
              <button
                type="button"
                class="main-btn noborder-btn"
                (click)="downloadFile(transaction)"
              >
                <img
                  src="assets/images/simple-download-icn@2x.png"
                  class="icon-btn"
                  width="14px"
                  alt=""
                />
              </button>
            </div>
            <div class="fixed-size" style="width: 62px">
              <button
                type="button"
                class="main-btn noborder-btn"
                (click)="
                  viewFile(
                    transaction['idDocument'],
                    transaction['numDocument'],
                    transaction['baseName']
                  )
                "
              >
                <img
                  src="assets/images/show-icn@2x.png"
                  class="icon-btn"
                  width="18px"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
