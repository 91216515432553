<app-popin-content [isHeader]="true" [title]="title" (closed)="receiveStatusPopin($event)"
  [isPopupVisible]="isPopupVisible">
  <hr class="separator" />

  <div class="step-header">
    <app-sell-card-stepper [stepper]="4" [totalStepper]="5"></app-sell-card-stepper>
    <h4>PLAFONDS <span>(hebdomadaire)</span></h4>
  </div>

  <form (ngSubmit)="send()" [formGroup]="ceilingFormGroup">
    <div class="step-container">
      <img src="/assets/images/step4.svg" alt="Step 4 picture" *ngIf="!step" />
      <div class="retrait-container" *ngIf="step">
        <div class="retrait-header">
          <span>NATIONAL (MAD)</span>
        </div>
        <div class="retrait-body">
          <app-sell-card-celling-form [cardSelected]="cardSelected" [control]="purchaseNationalForm" label="Retrait SG"
            [max]="max" [min]="min">
          </app-sell-card-celling-form>

          <app-sell-card-celling-form [control]="withdrawalNationalColleaguesForm" label="Retrait Confrére" [max]="max"
            [cardSelected]="cardSelected" [min]="min">
          </app-sell-card-celling-form>

          <app-sell-card-celling-form [control]="withdrawal_national_sgForm" label="Paiement"
            [cardSelected]="cardSelected" [max]="max" [min]="min">
          </app-sell-card-celling-form>

          <app-sell-card-celling-form [control]="purchaseNationalOnlineForm" label="Paiement Internet" [max]="max"
            [cardSelected]="cardSelected" [min]="min">
          </app-sell-card-celling-form>
        </div>
      </div>
      <div class="achat-container">
        <div class="achat-header">
          <span>INTERNATIONAL (MAD)</span>
        </div>
        <div class="achat-body">
          <app-sell-card-celling-form [control]="purchaseInternationalForm" label="Retrait à l'etranger" [max]="max"
            [cardSelected]="cardSelected" [min]="min">
          </app-sell-card-celling-form>

          <app-sell-card-celling-form [control]="purchaseInternationalOnlineForm" label="Paiement Internet à l'etranger"
            [cardSelected]="cardSelected" [max]="max" [min]="min">
          </app-sell-card-celling-form>

          <app-sell-card-celling-form [control]="withdrawalInterNationalForm" label="Paiement TPE Etranger" [max]="max"
            [cardSelected]="cardSelected" [min]="min">
          </app-sell-card-celling-form>
        </div>
      </div>
    </div>

    <button type="button" class="step-action-btn" type="submit" [disabled]="!ceilingFormGroup.valid">
      SUIVANT
    </button>
  </form>
</app-popin-content>