import { createAction, props } from '@ngrx/store';
import { CardEscaleProDTO } from 'src/app/models/CardEscaleProDTO';
import { CardEscaleProDetailsDTO } from 'src/app/models/CardEscaleProDetailsDTO';
import { CardReloadDTO } from 'src/app/models/CardReloadDTO';
import { CardRefillDto } from 'src/app/models/CardRefillDto';
import { CardRefillSearchRequestDto } from 'src/app/models/CardRefillSearchRequestDto';
import { CardFillElementDtoList } from 'src/app/models/CardFillElementDtoList';
import { UserRolesDTO } from 'src/app/models/UserRolesDto';
import { IdentityChallengeDTO } from 'src/app/models/identityChallengeDTO';
import { CardSearchRequestDTO } from 'src/app/models/CardSearchRequestDTO';

export const fetchCardsHistory = createAction(
  '[ESCALE PRO] Get Cards From History listing',
  props<{
    page: number;
    filter: CardRefillSearchRequestDto;
  }>()
);

export const fetchCardsHistoryFilter = createAction(
  '[ESCALE PRO] Get Cards From History listing by filter',
  props<{
    page: number;
    filter: CardRefillSearchRequestDto;
  }>()
);

export const fetchCardsHistorySuccess = createAction(
  '[ESCALE PRO] Get Cards From History listing Success',
  props<{ cards: CardFillElementDtoList }>()
);

export const fetchCardsHistoryError = createAction(
  '[ESCALE PRO] Get Cards From History listing Failed',
  props<{ error: any }>()
);

export const fetchCards = createAction(
  '[ESCALE PRO] Fetch Cards From Cards Listing',
  props<{ customerNumber: string; cardFilterDto: CardSearchRequestDTO }>()
);

export const fetchCardsFilter = createAction(
  '[ESCALE PRO] Fetch Cards From Cards Listing by filter',
  props<{ customerNumber: string; cardFilterDto: CardSearchRequestDTO }>()
);

export const fetchCardsSuccess = createAction(
  '[ESCALE PRO] Fetch Cards From Cards Listing Success',
  props<{ cards: CardEscaleProDTO[] }>()
);

export const fetchCardsFailed = createAction(
  '[ESCALE PRO] Fetch Cards From Cards Listing Failed',
  props<{ error: any }>()
);

export const getCardDetails = createAction(
  '[ESCALE PRO] Get Card Details From Cards Listing',
  props<{ customerNumber: string; cardNumber: string; accountNumber: string }>()
);

export const getCardDetailsSuccess = createAction(
  '[ESCALE PRO] Get Card Details From Cards Listing Success',
  props<{ cardDetails: CardEscaleProDetailsDTO }>()
);

export const getCardDetailsFailed = createAction(
  '[ESCALE PRO] Get Card Details From Cards Listing Failed',
  props<{ error: any }>()
);

export const selectCard = createAction(
  '[ESCALE PRO] select card From Cards Listing Success',
  props<{ card: CardEscaleProDTO }>()
);

export const initCardCallStateData = createAction(
  '[ESCALE PRO] Init CallState From Cards Listing'
);

export const getAccountToBeDebited = createAction(
  '[ESCALE PRO] Get AccountToBeDebited From Cards Listing',
  props<{ customerNumber: string }>()
);

export const getAccountToBeDebitedSuccess = createAction(
  '[ESCALE PRO] Get AccountToBeDebited From Cards Listing Success',
  props<{ accountToBeDebited: CardReloadDTO }>()
);

export const getAccountToBeDebitedFailed = createAction(
  '[ESCALE PRO] Get AccountToBeDebited From Cards Listing Failed',
  props<{ error: any }>()
);

export const refillCard = createAction(
  '[ESCALE PRO] Refill selected Card From Cards Listing',
  props<{
    customerNumber: string;
    cardNumber;
    cardRefillInfos: CardRefillDto;
  }>()
);

export const refillCardSuccess = createAction(
  '[ESCALE PRO] Refill selected Card From Cards Listing Success',
  props<{ resp: any }>()
);

export const refillCardFailed = createAction(
  '[ESCALE PRO] Refill selected Card From Cards Listing Failed',
  props<{ error: any }>()
);

export const fetchTrackedRefillRequestsFilter = createAction(
  '[ESCALE PRO] Fetch Tracked Refill Requests From Cards Tracking Listing by filter',
  props<{
    page: number;
    filter: CardRefillSearchRequestDto;
  }>()
);

export const fetchTrackedRefillRequests = createAction(
  '[ESCALE PRO] Fetch Tracked Refill Requests From Cards Tracking Listing',
  props<{ page: number; filter: CardRefillSearchRequestDto }>()
);

export const fetchTrackedRefillRequestsSuccess = createAction(
  '[ESCALE PRO] Fetch Tracked Refill Requests From Cards Tracking Listing Success',
  props<{ refillRequests: CardFillElementDtoList }>()
);

export const fetchTrackedRefillRequestsFailed = createAction(
  '[ESCALE PRO] Fetch Tracked Refill Requests From Cards Tracking Listing Failed',
  props<{ error: any }>()
);

export const cancelRefillRequest = createAction(
  '[ESCALE PRO] Cancel Refill Request From Cards Tracking Listing',
  props<{ customerNumber: string; requestId: number }>()
);

export const cancelRefillRequestSuccess = createAction(
  '[ESCALE PRO] Cancel Refill Request From Cards Tracking Listing Success'
);

export const cancelRefillRequestFailed = createAction(
  '[ESCALE PRO] Cancel Refill Request From Cards Tracking Listing Failed',
  props<{ error: any }>()
);

export const initCardsListing = createAction(
  '[ESCALE PRO] Init cardsListing slice From Cards Listing Destroy'
);

export const fetchEscaleProUserRoles = createAction(
  '[ESCALE PRO] Fetch EscalePro UserRoles From Cards Dashboard',
  props<{ customerNumber: string; username: string }>()
);

export const fetchEscaleProUserRolesSuccess = createAction(
  '[ESCALE PRO] Fetch EscalePro UserRoles From Cards Dashboard Success',
  props<{ roles: UserRolesDTO }>()
);

export const fetchEscaleProUserRolesFailed = createAction(
  '[ESCALE PRO] Fetch EscalePro UserRoles From Cards Dashboard Failed',
  props<{ error: any }>()
);

export const signEscaleProRequest = createAction(
  '[ESCALE PRO] Sign Refill Request From Cards Tracking Listing',
  props<{
    customerNumber: string;
    requestId: number;
    challengeKey: string;
    dResponse: string;
  }>()
);

export const signEscaleProRequestFromInit = createAction(
  '[ESCALE PRO] Sign Refill Request From Cards Listing',
  props<{
    customerNumber: string;
    requestId: number;
    challengeKey: string;
    dResponse: string;
  }>()
);

export const signEscaleProRequestSuccess = createAction(
  '[ESCALE PRO] Sign Refill Request From Cards INIT / Tracking Listing Success'
);

export const signEscaleProRequestFailed = createAction(
  '[ESCALE PRO] Sign Refill Request From Cards INIT / Tracking Listing Failed',
  props<{ error: any }>()
);

export const getEscaleProChallengeCode = createAction(
  '[ESCALE PRO] Get Challenge Code Sign Refill Request From Cards Tracking Listing Signatory'
);

export const getEscaleProChallengeCodeFromInit = createAction(
  '[ESCALE PRO] Get Challenge Code Sign Refill Request From Cards Listing'
);

export const getEscaleProChallengeCodeSuccess = createAction(
  '[ESCALE PRO] Get Challenge Code Sign Refill Request From Cards INIT / Tracking Listing Signatory Success',
  props<{ challengeCode: IdentityChallengeDTO }>()
);

export const getEscaleProChallengeCodeFailed = createAction(
  '[ESCALE PRO] Get Challenge Code Sign Refill Request From Cards INIT / Tracking Listing Signatory Failed',
  props<{ error: any }>()
);

export const downloadRecapFile = createAction(
  '[ESCALE PRO] Download Recap File From Cards History',
  props<{ customerNumber: string; requestId: number }>()
);

export const downloadRecapFileSuccess = createAction(
  '[ESCALE PRO] Download Recap File From Cards History Success',
  props<{ file: ArrayBuffer }>()
);

export const downloadRecapFileFailed = createAction(
  '[ESCALE PRO] Download Recap File From Cards History Failed',
  props<{ error: any }>()
);

export const initCardsTracking = createAction(
  '[ESCALE PRO] Init CardsTracking slice From Cards Tracking Destroy'
);

export const initCardsHistory = createAction(
  '[ESCALE PRO] Init CardsHistory slice From Cards History Destroy'
);

export const initSignatory = createAction(
  '[ESCALE PRO] Init Signatory slice From Cards Tracking Destroy'
);

export const initSignatoryFromInit = createAction(
  '[ESCALE PRO] Init Signatory slice From Cards Listing Destroy'
);
