import { Application } from 'src/app/models/application';
import { User } from '../../../../models/user';
import { Client } from 'src/app/models/client';

export interface UserState {
  connectedUser: User;
  selectedCustomerNumber: any;
  userApplications: Array<Application>;
  userClients: Array<Client>;
  hasSoftToken: Boolean;
}

export const initialState: UserState = {
  connectedUser: null,
  selectedCustomerNumber: null,
  userApplications: [],
  userClients: [],
  hasSoftToken: false,
};
