import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdvisorExpertHistoryService } from '../../../service/advisor-expert-history.service';
import { AdvisorService } from '../../../service/advisor.service';
import { ExpertService } from '../../../service/expert.service';
import { QrImageService } from '../../../service/qr-image.service';
import { AdvisorExpertHistory } from 'src/app/models/advisorExpertHistory';
import { ClientSharedService } from 'src/app/service/client-shared.service';
import { CafExpertService } from 'src/app/service/caf-expert.service';
import { Advisor } from 'src/app/models/advisor';
import { QrImage } from 'src/app/models/qrImage';
import { Expert } from 'src/app/models/expert';
import { Client } from 'src/app/models/client';
import { EmailExpert } from 'src/app/models/emailExpert';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Page } from '../../../models/page';
import { AssitanceDetailRequestComponent } from './assitance-detail-request/assitance-detail-request.component';
import { CafExpert } from 'src/app/models/CafExpert';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss'],
})
export class AssistanceComponent implements OnInit {
  form = new FormGroup({
    subject: new FormControl('', [Validators.required, Validators.required]),
    message: new FormControl('', [Validators.required, Validators.required]),
  });
  formExpert = new FormGroup({
    subjectExpert: new FormControl({ value: '', disabled: true }),
    messageExpert: new FormControl({ value: '', disabled: true }),
  });
  page = new Page();
  managerSelect: string;
  advisor: Advisor;
  expert: Expert;
  cafExpert: CafExpert;
  qrImage: QrImage;
  experts: Expert[];
  cafExperts: CafExpert[];
  advisorExpertHistorys : AdvisorExpertHistory[];
  managers = ['conseiler', 'Expert'];
  url: SafeUrl;
  width = 100;
  height = 100;
  succesMessage:string;
  errorMessage:string;
  managerLabel:string;
  sendCheck:boolean;
  popup:boolean;
  public client: Client;
  advisorExpertHistorySelected:AdvisorExpertHistory;
  constructor(
    public dialog: MatDialog,
    private advisorService: AdvisorService,
    private qrImageService: QrImageService,
    private expertService: ExpertService,
     private advisorExpertHistoryService : AdvisorExpertHistoryService,
     private clientSharedService: ClientSharedService,
     private CafExpertService:CafExpertService,
    private sanitizer: DomSanitizer,
    private dialogRefs: MatDialogRef<AssistanceComponent>,
    @Inject(MAT_DIALOG_DATA) data: {}
  ) {
    dialogRefs.disableClose = true;
    this.page.init();
    this.page.totalElements=5;
  }

  stepNumber: number;
  ngOnInit(): void {
    this.stepNumber=-1;
    this.client=this.clientSharedService.getClient();
    this.formExpert.get('messageExpert').disable;
  }

  nextStep(): void {
    this.formExpert.setValue({ messageExpert: '', subjectExpert: '' });
    this.form.setValue({ message: '', subject: '' });
    if (this.managerSelect == 'conseiler') {
      this.stepNumber++;
      switch (this.stepNumber) {
        case 1: {
          this.advisorService
            .findAdvisorByClient(this.client.toString())
            .subscribe((advisor) => {
              this.advisor = advisor;
              this.qrImageService
                .generateQR(this.advisor, this.width, this.height)
                .subscribe((qr) => {
                  this.qrImage = qr;
                  this.url = this.sanitizer.bypassSecurityTrustUrl(
                    'data:image/;base64,' + this.qrImage.image
                  );
                });
            });
        }
      }
    } else if (this.managerSelect == 'Expert') {
      this.formExpert.get('messageExpert').disable();
      this.formExpert.get('subjectExpert').disable();
     this.expert=null;
      this.stepNumber = 3;
      this.expertService.findExpert().subscribe((value) => {
        this.experts = value;
      });
    }
  }

  get message(): any {
    return this.form.get('message');
  }
  get subject(): any {
    return this.form.get('subject');
  }
  get messageExpert(): any {
    return this.formExpert.get('messageExpert');
  }
  get subjectExpert(): any {
    return this.formExpert.get('subjectExpert');
  }

  sendmailExpert() {
    let emailExpert = new EmailExpert();
    emailExpert.id = this.expert.id;
    emailExpert.body = this.messageExpert.value;
    emailExpert.subject = this.subjectExpert.value;
    this.client=this.clientSharedService.getClient();
    emailExpert.codeClient=  this.client.toString();
    emailExpert.email =this.expert.email;
    emailExpert.types= this.expert.type;
    if(this.expert.alias != 'CCI' && this.expert.alias != 'CCM'){
      emailExpert.email= this.cafExpert.email;
    }
    this.expertService
      .sendMailToExpert(emailExpert)
      .toPromise()
      .then(() => {
        this.sendCheck=true;
        this.stepNumber=4;
        this.managerLabel='Experts';
        this.succesMessage='Votre message à été envoyé avec succès';
        this.formExpert.setValue({ messageExpert: '', subjectExpert: '' });
      })        .catch(()=> { 
        this.sendCheck=true;
        this.stepNumber=5;
        this.errorMessage="le message n'a pas pu être envoyé"}
        );
  }

  sendmailAdvisor() {}

  out() {
    this.stepNumber = -2;
  }

  previews(step:number) {

    if (step != null && step == 3 ) {
      this.stepNumber = 0;
    }
     if (step != null && (step  == 1 ||   step == 2)) {
      this.stepNumber--;
    }  
    if (step!= null  && (  step == 0 || step >= 4 )) {
      this.stepNumber =-1;
    }
  }

  onChangeCAF(cafExpertId) {
    this.cafExpert=this.cafExperts.find((cafExpert) => cafExpert.id == cafExpertId);
    let advisor=new  Advisor();
    advisor.email=this.cafExpert.email;
    advisor.codeClient=this.client.toString();
    advisor.name=this.cafExpert.name;
    advisor.phoneNumber=this.cafExpert.phoneNumber;
    this.advisor=advisor;

    this.qrImageService
    .generateQR(advisor, this.width, this.height)
    .subscribe((qr) => {
      this.qrImage = qr;
      this.url = this.sanitizer.bypassSecurityTrustUrl(
        'data:image/;base64,' + this.qrImage.image
      );
    });

  }
  onChange(expertId) {
    this.expert = this.experts.find((expert) => expert.id == expertId);
    if (this.expert != null && this.expert != undefined) {
      this.formExpert.get('messageExpert').enable();
      this.formExpert.get('subjectExpert').enable();
      if(this.expert.alias != 'CCI' && this.expert.alias != 'CCM'){
        this.cafExpert=null;
      this.CafExpertService.findCafExpert(this.expert.type).subscribe((cafExpert) =>{
        this.cafExperts=cafExpert;
        
      });
    }

    } else if (this.expert == null || this.expert == undefined) {
      this.cafExperts=[];
      this.formExpert.get('subjectExpert').disable();
      this.formExpert.get('messageExpert').disable();
    }
  }

  showHistorique(){
    this.client=this.clientSharedService.getClient();
    this.advisorExpertHistoryService.
    findAdvisorExpertHistoryByCodeClient(this.page.currentPage,this.client.toString())
    .subscribe( (result) => {
      this.page.totalPages = result.totalPages;
      this.page.totalElements = result.totalElements;
      this.advisorExpertHistorys = result.contentList;
      this.stepNumber = 6;
    });
  }
  getAdvisorExpertHistoryPage(){
    this.advisorExpertHistoryService.
    findAdvisorExpertHistoryByCodeClient(this.page.currentPage,this.client.toString())
    .subscribe( (result) => {
      this.page.totalPages = result.totalPages;
      this.page.totalElements = result.totalElements;
      this.advisorExpertHistorys = result.contentList;
    });
  }


  firstStep(){
    this.stepNumber=0;
  }

  showDetailAdvisorExpertHistory(advisorExpertHistory:AdvisorExpertHistory) {
    const dialogRef = this.dialog.open(AssitanceDetailRequestComponent, {
      data: {
        subject: advisorExpertHistory.subject,
        message: advisorExpertHistory.message,
        numTicket : advisorExpertHistory.numTicket
      },
    });
  }
  closeAlert() {
    this.dialogRefs.close();
  }

}
