<div class="step-header">
  <app-sell-card-stepper [stepper]="step" [totalStepper]="step"></app-sell-card-stepper>
  <h4>RÉCAPITULATIF</h4>
</div>

<div class="recap-wrapper">
  <div class="recap-container">
    <div class="recap-box">
      <div class="recap-header">
        <span>CHOIX DU COMPTE</span>
        <div class="edit-action" (click)="sendaccount()">
          <img src="/assets/images/icon-edit.svg" alt="Step 5 picture" />
        </div>
      </div>
      <div class="recap-body">
        <div class="info">
          <span class="title">Compte</span>
          <span class="descriptin">
            {{ groupedForms?.receivedAccountData }}</span>
        </div>
      </div>
    </div>
    <div class="recap-box">
      <div class="recap-header">
        <span>Identité porteur</span>
        <div class="edit-action" (click)="sendInfo()">
          <img src="/assets/images/icon-edit.svg" alt="Step 5 picture" />
        </div>
      </div>
      <div class="recap-body">
        <div class="info">
          <span class="title">Nom et prénom du porteur</span>
          <span class="descriptin">{{ groupedForms?.receivedInfoData.firstname }}
            {{ groupedForms?.receivedInfoData.lastname }}</span>
        </div>
        <div class="info">
          <span class="title">CIN et sa date d’expiration</span>
          <div class="grp-infos">
            <span class="descriptin"> {{
              groupedForms?.receivedInfoData.cin
              }}</span>
            <span class="descriptin">{{
              groupedForms?.receivedInfoData.selectedIdType
              }}</span>
            <span class="grp-separator"></span>

            <span class="grp-separator"></span>
            <span class="descriptin">{{
              groupedForms?.receivedInfoData.expirationDate
              | date : "dd-MM-yyyy"
              }}</span>
          </div>
        </div>
        <div class="info">
          <span class="title">Date de naissance</span>
          <span class="descriptin">{{
            groupedForms?.receivedInfoData.birthDate | date : "dd-MM-yyyy"
            }}</span>
        </div>
        <div class="info">
          <span class="title">Pays et ville de naissance</span>
          <div class="grp-infos">
            <span class="descriptin">{{
              groupedForms?.receivedInfoData.birthCountry
              }}</span>
            <span class="grp-separator"></span>
            <span class="descriptin">{{
              groupedForms?.receivedInfoData.birthCity
              }}</span>
          </div>
        </div>






      </div>
    </div>
    <div class="recap-box">
      <div class="recap-header">
        <span>Contact</span>
        <div class="edit-action" (click)="sendContact()">
          <img src="/assets/images/icon-edit.svg" alt="Step 5 picture" />
        </div>
      </div>
      <div class="recap-body">
        <div class="info">
          <span class="title">Adresse e-mail</span>
          <span class="descriptin">{{ groupedForms?.receivedContactData.email }}</span>
        </div>
        <div class="info">
          <span class="title">N° de téléphone</span>
          <span class="descriptin">{{ groupedForms?.receivedContactData.phoneNumber }}</span>
        </div>




      </div>
    </div>
    <div class="recap-box" *ngIf="step != 4">
      <div class="recap-header">
        <span>Plafonds</span>
        <div class="edit-action" (click)="sendCeiling()">
          <img src="/assets/images/icon-edit.svg" alt="Step 5 picture" />
        </div>
      </div>
      <div class="recap-body">
        <div class="info" *ngIf="cardBusiness">
          <span class="title">Retrait SG National</span>
          <span class="descriptin">{{
            formatNumber( groupedForms?.receivedCeilingData?.purchase_national)
            }}
            MAD</span>
        </div>
        <div class="info" *ngIf="cardBusiness">
          <span class="title">Retrait Confrére National</span>
          <span class="descriptin">{{
            formatNumber( groupedForms?.receivedCeilingData?.withdrawal_national_colleagues)
            }}
            MAD</span>
        </div>
        <div class="info" *ngIf="cardBusiness">
          <span class="title">Paiement National</span>
          <span class="descriptin">{{
            formatNumber( groupedForms?.receivedCeilingData?.withdrawal_national_sg)
            }}
            MAD</span>
        </div>
        <div class="info" *ngIf="cardBusiness">
          <span class="title">Paiement Internet National</span>
          <span class="descriptin">{{
            formatNumber( groupedForms?.receivedCeilingData?.purchase_national_online)
            }}
            MAD</span>
        </div>
        <div class="info">
          <span class="title">Retrait à l'etranger</span>
          <span class="descriptin">{{
            formatNumber( groupedForms?.receivedCeilingData?.purchase_international)
            }}
            MAD</span>
        </div>
        <div class="info">
          <span class="title">Paiement Internet à l'etranger</span>
          <span class="descriptin">{{
            formatNumber( groupedForms?.receivedCeilingData?.purchase_international_online)
            }}
            MAD</span>
        </div>
        <div class="info">
          <span class="title">Paiement TPE Etranger</span>
          <span class="descriptin">{{
            formatNumber( groupedForms?.receivedCeilingData?.withdrawal_international)
            }}
            MAD</span>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="step-action-btn" (click)="sendMail()">
    Demander
  </button>
</div>