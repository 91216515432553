<div class="body-wrapper">
  <div class="transfer-filter-container">
    <div class="filter-form-container">
      <div class="search-control-container">
        <input
          type="text"
          class="input-search"
          placeholder="Recherche ..."
          [(ngModel)]="searchCriteria"
        />
        <button
          type="submit"
          class="submit-search-btn"
          (click)="searchFileProcess()"
        >
          <img src="../../../assets/images/search-icon@2x.png" width="19" />
        </button>
      </div>
    </div>
  </div>

  <br />
  <div *ngIf="noFilesFound; then noResultFound; else filesProcess"></div>
  <ng-template #filesProcess>
    <div class="striped-table" *ngFor="let file of files">
      <app-transfer-widget
        [transfer]="file"
        (startSignature)="startSigningOrder($event)"
        (startCancelSignature)="startCancelOrder($event)"
        [userCanSign]="userCanSign"
      ></app-transfer-widget>
    </div>
    <app-pagination
      [page]="page"
      (getPage)="getCurrentPageTransfers()"
    ></app-pagination>
    <!---------------------------------------->
    <ng-container>
      <app-sign-dialog
        *ngIf="showSignatureForm"
        [signMessages]="signMessages"
        (sign)="signOrder($event)"
        (cancelled)="cancelValidation()"
      ></app-sign-dialog>
    </ng-container>
    <!---------------------------------------->
  </ng-template>

  <ng-template #noResultFound> Vous n'avez aucun ordre à signer </ng-template>
</div>

<!-------------------------------- Signature management ----------------------------------->
<app-signature
  *ngIf="openPopinSignatureFlag"
  [challengeCode]="challengeCode"
  [challengeKey]="challengeKey"
  [loaderMode]="isPendingRefreshChalCodBtn || isSignaturePending"
  (refreshChallengeCode)="refreshChallengeCode()"
  (closeSignaturePopup)="closeSignaturePopin()"
  (onSign)="signHandler($event)"
></app-signature>
<!----------------------------------------------------------------------------------------->
<!-------------------------------- Error management --------------------------------------->
<app-message-popin
  [openPopinFlag]="openPopinMessageFlag"
  mode=""
  [message]="openPopinMessage"
  [success]="openPopinMessageSuccess"
  (popinClosedEvent)="closePopin()"
></app-message-popin>
<!----------------------------------------------------------------------------------------->
<!----------------------------------------------------------------------------------------->
<app-confirm-popin
  [openPopinFlag]="openCancelPopinConfirmFlag"
  message="Etes vous sûr de vouloir annuler votre ordre de virement ?"
  (popinClosedEvent)="closeCancelConfirmPopin($event)"
></app-confirm-popin>
<!----------------------------------------------------------------------------------------->
