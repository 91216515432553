import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { interval, of } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  mergeMap,
  concatMap,
} from 'rxjs/operators';
import { UserService } from '../../../../service/user.service';
import { ClientService } from '../../../../service/client.service';
import * as UserActions from './user.actions';
import { Application } from 'src/app/models/application';
import { SoftTokenActivationService } from 'src/app/service/soft-token-activation.service';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions<any>,
    private userService: UserService,
    private clientService: ClientService,
    private softTokenActivationService: SoftTokenActivationService
  ) {}

  fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(UserActions.appLoaded),
      switchMap(() =>
        this.userService.getUser().pipe(
          switchMap((user) => {
            return this.userService.getAllApplications().pipe(
              switchMap((applications) => {
                return [
                  UserActions.fetchUserSuccess({ user }),
                  UserActions.getSelectedCustomerNumber({
                    username: user.username,
                  }),
                  UserActions.getUserApplications({
                    userApplications: getAppFilter(
                      user.applications,
                      applications
                    ),
                  }),
                  UserActions.getUserClients(),
                  UserActions.getUserSoftToken(),
                  UserActions.getSelectedCustomerNumberMultipleTime({
                    username: user.username,
                  }),
                ];
              })
            );
          }),
          catchError((error) =>
            of(UserActions.fetchUserFailed({ error: error }))
          )
        )
      )
    )
  );

  fetchCustomerNumber$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(UserActions.getSelectedCustomerNumber),
      switchMap((action) =>
        this.clientService.getCustomerClient(action.username).pipe(
          map((obj) => {
            return UserActions.getSelectedCustomerNumberSuccess({
              obj,
            });
          }),
          catchError((error) =>
            of(UserActions.fetchUserFailed({ error: error }))
          )
        )
      )
    )
  );

  ChangeCustomerNumber$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(UserActions.changeSelectedCustomerNumber),
      switchMap((action) =>
        this.clientService
          .storeCustomerClient(action.customerNumber, action.userName)
          .pipe(
            map((obj) => {
              return UserActions.changeSelectedCustomerNumberSuccess({ obj });
            }),
            catchError((error) =>
              of(
                UserActions.changeSelectedCustomerNumberFailed({ error: error })
              )
            )
          )
      )
    )
  );

  fetchCustomerNumberMultipleTime$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(UserActions.getSelectedCustomerNumberMultipleTime),
      switchMap((action) =>
        interval(environment.multiClientFetshInterval).pipe(
          // Adjust the interval time (in milliseconds) as needed
          map(() =>
            // Return an array of actions to dispatch them at each interval
            UserActions.getSelectedCustomerNumber({
              username: action.username,
            })
          )
        )
      )
    )
  );

  fetchMenus$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(UserActions.getUserClients),
      switchMap(() =>
        this.clientService.getClients().pipe(
          map((clients) =>
            UserActions.getUserClientsSuccess({ userClients: clients })
          ),
          catchError((error) =>
            of(UserActions.getUserClientsFailed({ error: error }))
          )
        )
      )
    )
  );

  fetchUserSoftToken$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(UserActions.getUserSoftToken),
      switchMap(() =>
        this.softTokenActivationService.getSoftToken().pipe(
          map((hasSoftToken) =>
            UserActions.getUserSoftTokenSuccess({ hasSoftToken })
          ),
          catchError((error) =>
            of(UserActions.getUserSoftTokenFailed({ error: error }))
          )
        )
      )
    )
  );
}

function getAppFilter(
  userApplications: Array<Application>,
  allApplications: Array<Application>
): Array<Application> {
  let allApps = [];
  let codes: string[] = [];
  userApplications.forEach((app) => {
    codes.push(app.code);
  });
  allApplications.forEach((app) => {
    if (codes.includes(app.code)) {
      app.assigned = true;
    } else {
      app.assigned = false;
    }
    allApps.push(app);
  });

  // sort by value
  allApps.sort((a, b) => {
    if (a.appOrder < b.appOrder) {
      return -1;
    }
    if (a.appOrder > b.appOrder) {
      return 1;
    }
    return 0;
  });

  return allApps.filter((a) => a.assigned);
}
