import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store/state';
import { initESignature } from '../../../core/store/state/e-sign';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';
import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  checkSignature,
  fetchESignCountingHistoric,
  fetchESignCountingPending,
} from '../../../core/store/state/e-sign';

@Component({
  selector: 'app-e-sing-iframe',
  templateUrl: './e-sing-iframe.component.html',
  styleUrls: ['./e-sing-iframe.component.scss'],
})
export class ESingIframeComponent implements OnInit {
  @ViewChild('iframeRef') iframeRef: ElementRef;
  requestId: string;
  linkEsign: SafeResourceUrl;
  statusSuivi = ['PENDING', 'IN_PROGRESS'];
  statusHistoric = ['COMPLETED', 'CANCELED', 'EXPIRED', 'SIGNED'];
  private subscription: Subscription;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumberSub: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<State>,
    private dialogRef: MatDialogRef<ESingIframeComponent>,
    private sanitizer: DomSanitizer
  ) {
    this.requestId = data.requestIds;
  }

  ngOnInit() {
    this.store.select(initESignature).subscribe((initESign) => {
      if (initESign != null && initESign.result != null) {
        this.linkEsign = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://' + initESign.result.linkValue
        );
      }
    });

    const delay = 900000;
    // const delay = 20000;
    this.subscription = timer(delay).subscribe(() => {
      this.dialogRef.close('timeout');
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  closeDialog() {
    this.store.dispatch(
      checkSignature({
        requestId: this.requestId,
      })
    );
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        this.store.dispatch(
          fetchESignCountingPending({
            status: this.statusSuivi,
            customerNumber: customerNumber,
          })
        );
        this.store.dispatch(
          fetchESignCountingHistoric({
            status: this.statusHistoric,
            customerNumber: customerNumber,
          })
        );
      }
    );
    this.dialogRef.close();
  }
}
