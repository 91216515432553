<div class="wrapper">
  <div class="header-container">
    <h2>RECHERCHE AVANCÉE</h2>
    <button type="button" class="close-btn" (click)="close()">
      <img src="assets/images/close-white.svg" alt="Fermer" />
    </button>
  </div>

  <div class="form-wrapper">
    <div class="form-container">
      <h5>LIBELLE</h5>
      <div class="grouped-inputs">
        <mat-form-field class="custom-field">
          <mat-label>Libellé</mat-label>
          <input matInput [(ngModel)]="filter.longDescription" type="text" />
        </mat-form-field>
      </div>
    </div>
    <div class="form-container">
      <h5>MONTANT</h5>
      <div class="grouped-inputs">
        <mat-form-field class="custom-field">
          <mat-label>Montant Min</mat-label>
          <input matInput [(ngModel)]="filter.minAmount" type="number" />
        </mat-form-field>
        <mat-form-field class="custom-field">
          <mat-label>Montant Max</mat-label>
          <input matInput [(ngModel)]="filter.maxAmount" type="number" />
        </mat-form-field>
      </div>
    </div>
    <div class="form-container">
      <h5>DATE</h5>
      <div class="grouped-inputs">
        <mat-form-field>
          <mat-label>Date Début</mat-label>
          <input [(ngModel)]="filter.startDate" name="startDate" [matDatepicker]="picker1" [min]="minDate" matInput
            required />
          <mat-datepicker-toggle [ngClass]="{ 'has-value': filter.startDate }" matSuffix matIconSuffix [for]="picker1">
            <img matDatepickerToggleIcon src="/assets/images/date-picker.svg" alt="picker-icon" />
          </mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Date fin</mat-label>
          <input [(ngModel)]="filter.endDate" name="endDate" [matDatepicker]="picker2" matInput required />
          <mat-datepicker-toggle [ngClass]="{ 'has-value': filter.endDate }" matSuffix matIconSuffix [for]="picker2">
            <img matDatepickerToggleIcon src="/assets/images/date-picker.svg" alt="picker-icon" />
          </mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="white-bg" [disabled]="
        !(
          filter.endDate !== '' ||
          filter.startDate !== '' ||
          filter.longDescription !== '' ||
          filter.maxAmount !== null ||
          filter.minAmount !== null
        )
      " (click)="resetFilter()">
      RÉINITIALISER
    </button>
    <button (click)="applyFilter()" [disabled]="
        !(
          filter.endDate !== '' ||
          filter.startDate !== '' ||
          filter.longDescription !== '' ||
          filter.maxAmount !== null ||
          filter.minAmount !== null
        )
      ">
      FILTRER
    </button>
  </div>
</div>