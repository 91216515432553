import { createAction, props } from '@ngrx/store';
import { eSignPagination } from '../../../../modules/e-sign/models/eSignPagination';
import { InitSignatureResponse } from '../../../../modules/e-sign/models/init-signature-response';
import { ESignatory } from '../../../../modules/e-sign/models/eSignatory';

export const fetchESign = createAction(
  '[ESIGN] Get E-SIGN From  listing',
  props<{
    page: number;
    status: string[];
    customerNumber: string;
  }>()
);
export const fetchESignSuccess = createAction(
  '[ESIGN] Get E-SIGN From  listing Succes',
  props<{ eSign: eSignPagination }>()
);
export const fetchESignFailure = createAction(
  '[ESIGN] Get  E-SIGN listing Failed',
  props<{ error: any }>()
);
export const initSignature = createAction(
  '[ESIGN] Init  E-SIGN Begin',
  props<{ requestId: string }>()
);
export const initSignatureSucces = createAction(
  '[ESIGN] Init  E-SIGN Succes',
  props<{ initESign: InitSignatureResponse }>()
);
export const initSignatureFailure = createAction(
  '[ESIGN] Init  E-SIGN createAction',
  props<{ error: any }>()
);
export const checkSignature = createAction(
  '[ESIGN] check  E-SIGN Begin',
  props<{ requestId: string }>()
);
export const checkSignatureSucces = createAction(
  '[ESIGN] check  E-SIGN Succes',
  props<{ checkESign: Boolean }>()
);
export const checkSignatureFailure = createAction(
  '[ESIGN] check  E-SIGN createAction',
  props<{ error: any }>()
);
export const downloadFile = createAction(
  '[ESIGN] Download Recap File From  E-SIGN',
  props<{ requestId: string }>()
);

export const downloadFileSuccess = createAction(
  '[ESIGN] Download Recap File From  E-SIGN Success',
  props<{ file: ArrayBuffer }>()
);

export const downloadFileFailed = createAction(
  '[ESIGN] Download Recap File From  E-SIGN Failed',
  props<{ error: any }>()
);
export const signeEnvelope = createAction(
  '[ESIGN] Sign   File From  E-SIGN',
  props<{ signatureTransactionId: string; requestId: string }>()
);

export const signeEnvelopeSuccess = createAction(
  '[ESIGN] Sign File E-SIGN with Success'
);

export const signeEnvelopeFailed = createAction(
  '[ESIGN] Sign File  E-SIGN  with Failed',
  props<{ error: any }>()
);

export const fetcheSignatory = createAction(
  '[ESIGN] Get E-SIGN From  listing ',
  props<{ requestId: string }>()
);

export const fetcheSignatorySucces = createAction(
  '[ESIGN] Get E-SIGN From  listing Succes',
  props<{ eSignatory: ESignatory[] }>()
);
export const fetcheSignatorFailed = createAction(
  '[ESIGN] Get E-SIGN From  listing Failed',
  props<{ error: any }>()
);
export const timeOutInit = createAction(
  '[ESIGN] time out  From  E-SIGN',
  props<{ signatureTransactionId: string; requestId: string }>()
);

export const timeOutInitSuccess = createAction(
  '[ESIGN] time out E-SIGN with Success'
);

export const timeOutInitFailed = createAction(
  '[ESIGN] time out E-SIGN  with Failed',
  props<{ error: any }>()
);

export const fetchESignCountingPending = createAction(
  '[ESIGN] Get E-SIGN counting Pending From  listing',
  props<{
    customerNumber: string;
    status: string[];
  }>()
);
export const fetchESignCountingPendingSuccess = createAction(
  '[ESIGN] Get E-SIGN counting Pending From  listing Succes',
  props<{ countingPending: number }>()
);
export const fetchESignCountingPendingFailure = createAction(
  '[ESIGN] Get  E-SIGN counting Pending listing Failed',
  props<{ error: any }>()
);
export const fetchESignCountingHistoric = createAction(
  '[ESIGN] Get E-SIGN counting Historic From  listing',
  props<{
    customerNumber: string;
    status: string[];
  }>()
);
export const fetchESignCountingHistoricSuccess = createAction(
  '[ESIGN] Get E-SIGN counting Historic From  listing Succes',
  props<{ countingHistoric: number }>()
);
export const fetchESignCountingHistoricFailure = createAction(
  '[ESIGN] Get  E-SIGN counting Historic listing Failed',
  props<{ error: any }>()
);
export const closeSignature = createAction(
  '[ESIGN] close signature  From  E-SIGN',
  props<{ signatureTransactionId: string }>()
);

export const closeSignatureSuccess = createAction(
  '[ESIGN] close signature E-SIGN with Success'
);

export const closeSignatureFailed = createAction(
  '[ESIGN] close signature  E-SIGN  with Failed',
  props<{ error: any }>()
);
