import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BeneficiaryService } from '../../../../service/beneficiary.service';
import { Beneficiary } from '../../../../models/beneficiary';
import Swal from 'sweetalert2';
import { ClientSharedService } from 'src/app/service/client-shared.service';
import { Client } from 'src/app/models/client';

@Component({
  selector: 'app-init-beneficiary',
  templateUrl: './init-beneficiary.component.html',
  styleUrls: ['./init-beneficiary.component.scss'],
})
export class InitBeneficiaryComponent implements OnInit {
  public registerForm: FormGroup;
  public submitted = false;
  private beneficiary: Beneficiary;
  public confirmDisableButon: boolean = true;
  public client: Client;
  window = window;

  constructor(
    private beneficiaryService: BeneficiaryService,
    private router: Router,
    private route_active: ActivatedRoute,
    private formBuilder: FormBuilder,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.registerForm = this.formBuilder.group({
      identityType: ['', Validators.required],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      identityNumber: ['', Validators.required],
      phoneNumber: ['', Validators.required],
    });
  }

  getDisabledButton() {
    if (!this.submitted || !this.confirmDisableButon) {
      return this.registerForm.invalid;
    } else return true;
  }

  private mapFormToBenefeciary(): Beneficiary {
    const beneficiary = new Beneficiary();
    beneficiary.identityType = this.identityType.value;
    beneficiary.lastName = this.lastName.value;
    beneficiary.firstName = this.firstName.value;
    beneficiary.identityNumber = this.identityNumber.value;
    beneficiary.phoneNumber = this.phoneNumber.value;
    return beneficiary;
  }

  get identityType(): FormControl {
    return this.registerForm.get('identityType') as FormControl;
  }
  get lastName(): FormControl {
    return this.registerForm.get('lastName') as FormControl;
  }
  get firstName(): FormControl {
    return this.registerForm.get('firstName') as FormControl;
  }

  get identityNumber(): FormControl {
    return this.registerForm.get('identityNumber') as FormControl;
  }
  get phoneNumber(): FormControl {
    return this.registerForm.get('phoneNumber') as FormControl;
  }

  public onSubmit(): void {
    if (this.registerForm.invalid) {
      return;
    }
    this.submitted = true;

    this.beneficiary = this.mapFormToBenefeciary();
    this.beneficiaryService
      .createBeneficiary(this.client.customerNumber, this.beneficiary)
      .subscribe((b) => {
        Swal.fire({
          text: 'Le nouveau bénéficiaire a bien été créé. Il doit à présent être signé dans le menu BENEFICIAIRES A SIGNER',
          icon: 'warning',
          dangerMode: false,
        } as any),
          window.location.reload();
      });
  }
}
