<div class="wrapper">
  <div class="header-container">
    <button type="button" class="close-btn" (click)="close()">
      <img src="assets/images/arrow-go.svg" alt="" />
    </button>
    <h2>CHARGER LA CARTE</h2>
  </div>

  <div class="body-container">
    <div class="infos-container">
      <div class="infos-details">
        <h5>INFORMATIONS DE L’ENTREPRISE</h5>
        <div class="info-item">
          <label>Nom</label>
          <p>{{ accountToBeDebited.corporateName }}</p>
        </div>
        <div class="info-item">
          <label>Pays</label>
          <p>{{ accountToBeDebited.country }}</p>
        </div>
        <div class="info-item">
          <label>Ville</label>
          <p>{{ accountToBeDebited.city }}</p>
        </div>
        <div class="info-item">
          <label>Adresse</label>
          <p>{{ accountToBeDebited.address }}</p>
        </div>
      </div>
      <div class="infos-details middle">
        <h5>Informations de la carte</h5>
        <div class="info-item">
          <label>Nom du porteur</label>
          <p>{{ selectedCard.card.embossedName }}</p>
        </div>
        <div class="info-item">
          <label>Type de carte</label>
          <p>VISA - ESCALE PRO</p>
        </div>
        <div class="info-item">
          <label>Numéro de la carte</label>
          <p>{{ selectedCard.card.cardNumber }}</p>
        </div>
      </div>
      <div class="infos-details last">
        <h5>Compte à débiter</h5>
        <div class="form-container account-form">
          <div class="select-container">
            <mat-form-field class="custom-field-account">
              <mat-label>COMPTE EN DIRHAMS CONVERTIBLES </mat-label>
              <mat-select [(ngModel)]="selectedAccountToBeDebited" [value]="selectedAccountToBeDebited"
                disableOptionCentering panelClass="custom-panel-account" (selectionChange)="selectionChanges($event)">
                <mat-option *ngFor="let item of accountToBeDebited.accounts" [value]="item.ibanFormatAccount">
                  {{ item.ibanFormatAccount }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div>
            <label
              >Compte chèque en
              {{ accountToBeDebited.currencyAlphaCode }}</label
            >
            <ngx-select
              [items]="accountToBeDebited.accounts"
              optionValueField="ibanFormatAccount"
              optionTextField="ibanFormatAccount"
              [noAutoComplete]="true"
              [defaultValue]="accountToBeDebited.accounts[0]?.ibanFormatAccount"
              class="control-select"
              autoSelectSingleOption="true"
              [(ngModel)]="selectedAccountToBeDebited"
              (select)="selectionChanges($event)"
            >
            </ngx-select>
          </div> -->
          <div class="info-item account-info">
            <label>Solde intraday</label>
            <p>
              {{
              selectedAccountToBeDebitedObject.indicativeBalance
              | currency : " " : "" : "" : "fr"
              }}
              MAD
            </p>
          </div>
          <div class="info-item account-info">
            <label>Solde comptable</label>
            <p>
              {{
              selectedAccountToBeDebitedObject.accountingBalance
              | currency : " " : "" : "" : "fr"
              }}
              MAD
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-container">
    <h5>Veuillez saisir le montant que vous souhaitez charger et le motif :</h5>
    <div class="inputs">
      <mat-form-field class="custom-field">
        <mat-label>Montant en MAD</mat-label>
        <input [(ngModel)]="amount" type="number" id="amount" name="amount" matInput />
      </mat-form-field>
      <mat-form-field class="custom-motif">
        <mat-label>Motif</mat-label>
        <input [(ngModel)]="motif" type="text" id="motif" name="motif" matInput required minlength="1" maxlength="100"
          #motifText="ngModel" />
      </mat-form-field>
      <div class="custom-date">
        <span class="label">Date :</span>
        <span class="value">{{ curDate | date : "dd/MM/yyyy" }}</span>
      </div>
    </div>
    <button (click)="refillCard()" [disabled]="motifText.errors || !isAmountValid()">
      CHARGER LA CARTE
    </button>
  </div>
</div>