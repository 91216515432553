import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ESingListComponent } from './e-sing-list/e-sing-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ESignRoutingModule } from './e-sign-routing.module';
import { SharedModule } from '../shared-module/shared.module';
import { ESingIframeComponent } from './e-sing-iframe/e-sing-iframe.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ESignatoryComponent } from './e-signatory/e-signatory.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MatMenuModule,
  MatTableModule,
  MatButtonModule,
  MatIconModule,
} from '@angular/material';

@NgModule({
  declarations: [
    ESingListComponent,
    DashboardComponent,
    ESingIframeComponent,
    ESignatoryComponent,
  ],
  entryComponents: [ESingIframeComponent, ESignatoryComponent],
  imports: [
    CommonModule,
    MatTableModule,
    ESignRoutingModule,
    MatSnackBarModule,
    SharedModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class ESignModule {}
