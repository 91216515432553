import { ESignState, initialState } from './e-sign.state';

import * as ESignActions from './e-sign.actions';
import { Action, createReducer, on } from '@ngrx/store';
const ESignReducer = createReducer(
  initialState,
  on(ESignActions.fetchESign, (state) => {
    return {
      ...state,
      eSignListing: {
        ...state.eSignListing,
        eSigns: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(ESignActions.fetchESignSuccess, (state, { eSign }) => {
    return {
      ...state,
      eSignListing: {
        ...state.eSignListing,
        eSigns: {
          result: eSign,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.fetchESignFailure, (state, { error }) => {
    return {
      ...state,
      eSignListing: {
        ...state.eSignListing,
        eSigns: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.initSignature, (state) => {
    return {
      ...state,
      initialSignature: {
        ...state.initialSignature,
        initialSign: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.initSignatureSucces, (state, { initESign }) => {
    return {
      ...state,
      initialSignature: {
        ...state.initialSignature,
        initialSign: {
          result: initESign,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.initSignatureFailure, (state, { error }) => {
    return {
      ...state,
      initialSignature: {
        ...state.initialSignature,
        eSigns: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.checkSignature, (state) => {
    return {
      ...state,
      checkSignature: {
        ...state.checkSignature,
        checkESign: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.checkSignatureSucces, (state, { checkESign }) => {
    return {
      ...state,
      checkSignature: {
        ...state.checkSignature,
        checkESign: {
          result: checkESign,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.checkSignatureFailure, (state, { error }) => {
    return {
      ...state,
      checkSignature: {
        ...state.checkSignature,
        checkESign: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),

  on(ESignActions.downloadFile, (state, args) => {
    return {
      ...state,
      downloadFileESign: {
        ...state.downloadFileESign,
        downloadFile: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.downloadFileSuccess, (state, args) => {
    return {
      ...state,
      downloadFileESign: {
        ...state.downloadFileESign,
        downloadFile: {
          result: args.file,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.downloadFileFailed, (state, { error }) => {
    return {
      ...state,
      downloadFileESign: {
        ...state.downloadFileESign,
        downloadFile: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.signeEnvelope, (state) => {
    return {
      ...state,
      signeEnvelope: {
        ...state.signeEnvelope,
        sign: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.signeEnvelopeSuccess, (state) => {
    return {
      ...state,
      signeEnvelope: {
        ...state.signeEnvelope,
        sign: {
          result: 'succes',
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.signeEnvelopeFailed, (state, { error }) => {
    return {
      ...state,
      signeEnvelope: {
        ...state.signeEnvelope,
        sign: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.fetcheSignatory, (state) => {
    return {
      ...state,
      eSignatoryListing: {
        ...state.eSignatoryListing,
        eSignatorys: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(ESignActions.fetcheSignatorySucces, (state, { eSignatory }) => {
    return {
      ...state,
      eSignatoryListing: {
        ...state.eSignatoryListing,
        eSignatorys: {
          result: eSignatory,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(ESignActions.fetcheSignatorFailed, (state, { error }) => {
    return {
      ...state,
      eSignatoryListing: {
        ...state.eSignatoryListing,
        eSignatorys: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.timeOutInit, (state) => {
    return {
      ...state,
      timeOutInit: {
        ...state.timeOutInit,
        timeOut: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.timeOutInitSuccess, (state) => {
    return {
      ...state,
      timeOutInit: {
        ...state.timeOutInit,
        timeOut: {
          result: 'succes',
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.timeOutInitFailed, (state, { error }) => {
    return {
      ...state,
      timeOutInit: {
        ...state.timeOutInit,
        timeOut: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.fetchESignCountingPending, (state) => {
    return {
      ...state,
      countingeSignatoryPending: {
        ...state.countingeSignatoryPending,
        countingPending: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(
    ESignActions.fetchESignCountingPendingSuccess,
    (state, { countingPending }) => {
      return {
        ...state,
        countingeSignatoryPending: {
          ...state.countingeSignatoryPending,
          countingPending: {
            result: countingPending,
            callState: {
              error: null,
              isLoaded: false,
              isLoading: false,
              displayMessage: true,
            },
          },
        },
      };
    }
  ),
  on(ESignActions.fetchESignCountingPendingFailure, (state, { error }) => {
    return {
      ...state,
      countingeSignatoryPending: {
        ...state.countingeSignatoryPending,
        countingPending: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.fetchESignCountingHistoric, (state) => {
    return {
      ...state,
      countingeSignatoryHistoric: {
        ...state.countingeSignatoryHistoric,
        countingHistoric: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(
    ESignActions.fetchESignCountingHistoricSuccess,
    (state, { countingHistoric }) => {
      return {
        ...state,
        countingeSignatoryHistoric: {
          ...state.countingeSignatoryHistoric,
          countingHistoric: {
            result: countingHistoric,
            callState: {
              error: null,
              isLoaded: false,
              isLoading: false,
              displayMessage: true,
            },
          },
        },
      };
    }
  ),
  on(ESignActions.fetchESignCountingHistoricFailure, (state, { error }) => {
    return {
      ...state,
      countingeSignatoryHistoric: {
        ...state.countingeSignatoryHistoric,
        countingHistoric: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.closeSignature, (state) => {
    return {
      ...state,
      closeSignature: {
        ...state.closeSignature,
        close: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.closeSignatureSuccess, (state) => {
    return {
      ...state,
      closeSignature: {
        ...state.closeSignature,
        close: {
          result: 'succes',
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(ESignActions.closeSignatureFailed, (state, { error }) => {
    return {
      ...state,
      closeSignature: {
        ...state.closeSignature,
        close: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  })
);
export function reducer(state: ESignState | undefined, action: Action) {
  return ESignReducer(state, action);
}
