import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State } from 'src/app/core/store/state';
import {
  fetchCards,
  getCardOperations,
  selectConsultCards,
  selectCards,
  selectCardOperations,
  selectSelectedCard,
} from 'src/app/core/store/state/consult-cards';

import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { CallStateResponse, handleCallState } from 'src/app/models/CallState';
import { ConsultCardsDTO } from 'src/app/models/ConsultCardsDTO';

import { CardConsultSearchRequestDTO } from 'src/app/models/CardConsultSearchRequestDTO';
import { Page } from 'src/app/models/page';
import { CardType } from 'src/app/models/CardTypeEnum';

import { MatDialog } from '@angular/material';
import { ConsultCardsFilterComponent } from '../consult-cards-filter/consult-cards-filter.component';
import { RequestHistoricCard } from '../models/request-historic-card.model';
import { HistoricCardResponse } from '../models/historic-card-response.model';
import { ConsultCardsOperationsDTO } from '../models/consult-cards-operations-dto.model';

@Component({
  selector: 'app-consult-cards-init',
  templateUrl: './consult-cards-init.component.html',
  styleUrls: ['./consult-cards-init.component.scss'],
})
export class ConsultCardsInitComponent implements OnInit {
  selectCardOperations$ = this.store.select(selectCardOperations);

  selectCardOperationssSub: Subscription;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectConsultCards$ = this.store.select(selectCards);
  selectSelectedCard$ = this.store.select(selectSelectedCard);
  displayError: boolean;
  showLoader: boolean = false;
  showOperation: boolean = false;
  page = new Page();
  operations: ConsultCardsOperationsDTO[];
  selectedCustomerNumberSub: Subscription;
  selectConsultCardsSub: Subscription;
  selectSelectedCardSub: Subscription;
  requestHistoric: RequestHistoricCard;
  handleCallStateCards: CallStateResponse;
  cardsListFromServer: ConsultCardsDTO[];
  historicCardResponse: HistoricCardResponse = new HistoricCardResponse();
  cardsList: ConsultCardsDTO[];
  currentCardsList: ConsultCardsDTO[];
  currentCardSelectionner: ConsultCardsDTO;
  openPopinMessage: string;
  openPopinMessageSuccess: boolean;
  filterOn: boolean;
  test = 0;
  CardTypeEnum: CardType;
  filter: CardConsultSearchRequestDTO;

  constructor(private store: Store<State>, private dialog: MatDialog) {
    this.page.init();
    this.page.totalElements = 5;
  }

  initHandleCallState() {
    this.filterOn = false;
    this.operations = [];
    this.handleCallStateCards = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
  }
  initFilter() {
    this.filter = new CardConsultSearchRequestDTO(
      '',
      '',
      new Array<CardType>()
    );
  }
  ngOnDestroy() {
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectConsultCardsSub.unsubscribe();
  }
  countFilterRes(): string {
    return (this.filter.firstName ||
      this.filter.lastName ||
      this.filter.cardsType.length > 0) &&
      this.page.totalElements
      ? this.page.totalElements.toString()
      : '';
  }
  ngOnInit() {
    this.filterOn = false;
    this.currentCardSelectionner = new ConsultCardsDTO();
    this.initHandleCallState();
    this.initFilter();
    this.requestHistoric = new RequestHistoricCard();
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
          this.store.dispatch(
            fetchCards({
              customerNumber: this.selectedCustomerNumber,
              cardFilterDto: null,
            })
          );
        }
      }
    );

    this.selectConsultCardsSub = this.selectConsultCards$.subscribe((cards) => {
      this.page.init();
      if (cards && cards.result) {
        this.cardsList = cards.result;
        this.cardsListFromServer = cards.result;
        if (this.cardsList.length > 0) {
          this.page.totalPages = Math.ceil(this.cardsList.length / 12);
          this.page.totalElements = this.cardsList.length;
          this.getCurrentPageCards();
        }
      }
      this.handleCallStateCards = handleCallState(cards);
      this.displayError = this.getDisplayError();
      this.showLoader = this.getShowLoader();
    });
  }

  getOperations(card: ConsultCardsDTO) {
    this.currentCardSelectionner = card;

    this.showOperation = !this.showOperation;
    this.requestHistoric = new RequestHistoricCard();
    this.historicCardResponse = new HistoricCardResponse();
    this.requestHistoric.cardNumber = card.cardNumber;
    this.requestHistoric.page = 1;
    this.requestHistoric.pageSize = 10;
    this.requestHistoric.rib = card.accountNumber;
    this.requestHistoric.customerNumber = this.selectedCustomerNumber;
  }

  getDisplayError() {
    let displayError = this.handleCallStateCards.displayError;

    if (displayError) {
      if (this.handleCallStateCards.displayError) {
        this.openPopinMessage = this.handleCallStateCards.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateCards.openPopinMessageSuccess;
        this.handleCallStateCards.displayError = false;
      }
    }
    return displayError;
  }

  getShowLoader(): boolean {
    let showLoader = this.handleCallStateCards.showLoader && !this.displayError;
    return showLoader;
  }

  getCurrentPageCards(): void {
    const start = this.page.currentPage * 12;
    const end =
      this.cardsList.length >= start + 12
        ? start + 12
        : start + (this.cardsList.length % 12);
    this.currentCardsList = this.cardsList.slice(start, end);
    this.removeDuplicates();
  }

  openFilterPopup(): void {
    this.filterOn = false;
    const dialogRef = this.dialog.open(ConsultCardsFilterComponent, {
      data: {
        filter: this.filter,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (
        result &&
        (result.cardsType.length > 0 ||
          result.firstName !== '' ||
          result.lastName !== '')
      ) {
        this.filter = result;
        this.filterOn = true;
        this.consultCardsFilter(this.filter);
      } else {
        this.renitialiser();
      }
    });
  }

  renitialiser() {
    this.initFilter();
    this.consultCardsFilter(this.filter);
    this.filterOn = false;
  }
  consultCardsFilter(filter: CardConsultSearchRequestDTO) {
    this.cardsList = [...this.cardsListFromServer];

    if (
      this.cardsListFromServer.length > 0 &&
      (filter.firstName || filter.lastName || filter.cardsType.length > 0)
    ) {
      this.cardsList = this.cardsList.filter((card) => {
        const matchesFirstName = filter.firstName
          ? card.firstName
              .toLocaleLowerCase()
              .includes(filter.firstName.toLocaleLowerCase())
          : true;

        const matchesLastName = filter.lastName
          ? card.familyName
              .toLocaleLowerCase()
              .includes(filter.lastName.toLocaleLowerCase())
          : true;

        const matchesCardType =
          filter.cardsType.length > 0
            ? filter.cardsType.some((type) => type === card.productName)
            : true;

        return matchesFirstName && matchesLastName && matchesCardType;
      });
    }

    this.page.totalPages = Math.ceil(this.cardsList.length / 12);
    this.page.totalElements = this.cardsList.length;

    this.getCurrentPageCards();
  }

  removeDuplicates() {
    const seen = new Set();
    this.currentCardsList = this.currentCardsList.filter((card) => {
      const duplicate = seen.has(card.cardNumber);
      seen.add(card.cardNumber);
      return !duplicate;
    });
  }
}
