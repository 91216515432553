import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/';
import { CardEscaleProDetailsDTO } from 'src/app/models/CardEscaleProDetailsDTO';
@Component({
  selector: 'ep-card-details',
  templateUrl: './ep-card-details.component.html',
  styleUrls: ['./ep-card-details.component.scss'],
})
export class EpCardDetailsComponent implements OnInit {
  cardDetails: CardEscaleProDetailsDTO;
  constructor(
    private dialogRef: MatDialogRef<EpCardDetailsComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: { cardDetails: CardEscaleProDetailsDTO }
  ) {
    this.cardDetails = data.cardDetails;
  }
  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
  }
  chargeCard() {
    this.dialogRef.close('CHARGE_CARD');
  }
}
