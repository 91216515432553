import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State } from '../../../core/store/state';
import { initCardsListing } from '../../../core/store/state/escale-pro';
import {
  downloadAllSv,
  downloadChequesToPay,
  selectChequePayerFromListing,
  selectDownloadAllSv,
  selectDownloadChequesToPay,
  initDownloadCallStateData,
} from '../../../core/store/state/soge-valeur';
import { selectSelectedCustomerNumber } from '../../../core/store/state/user';
import { CallStateResponse, handleCallState } from '../../../models/CallState';
import { ChequesToPayResponse } from '../../../models/SogeValeurDto';
import { Page } from 'src/app/models/page';
import { ChequeToPayDTO } from 'src/app/models/ChequeToPayDTO';

@Component({
  selector: 'app-sv-cheque-payer',
  templateUrl: './sv-cheque-payer.component.html',
  styleUrls: ['./sv-cheque-payer.component.scss'],
})
export class SvChequePayerComponent implements OnInit {
  selectChequePayerSub: Subscription;
  chequesPayerResponse: ChequesToPayResponse;
  currentChequesList: ChequeToPayDTO[] = [];
  selectChequePayer$ = this.store.select(selectChequePayerFromListing);
  selectedCustomerNumberSub: Subscription;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber;
  selectDownloadChequesToPayFileSub: Subscription;
  selectDownloadChequesToPayFile$ = this.store.select(
    selectDownloadChequesToPay
  );
  handleCallStateDownloadChequesToPayFile: CallStateResponse;
  selectDownloadAllSvFileSub: Subscription;
  selectDownloadAllSvFile$ = this.store.select(selectDownloadAllSv);
  handleCallStateDownloadAllSvFileFile: CallStateResponse;
  showLoader = false;
  page = new Page();

  constructor(private store: Store<State>) {}

  ngOnDestroy(): void {
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectChequePayerSub.unsubscribe();
    this.selectDownloadChequesToPayFileSub.unsubscribe();
    this.selectDownloadAllSvFileSub.unsubscribe();
    this.store.dispatch(initDownloadCallStateData(this.selectedCustomerNumber));
  }

  ngOnInit() {
    this.page.init();
    this.selectChequePayerSub = this.selectChequePayer$.subscribe(
      (sogeValeurs) => {
        if (sogeValeurs && sogeValeurs) {
          this.chequesPayerResponse = sogeValeurs.result;
          this.page.totalPages = Math.ceil(
            this.chequesPayerResponse.cheques.length / 12
          );
          this.page.totalElements = this.chequesPayerResponse.cheques.length;
          this.getCurrentPageCheques();
        }
      }
    );

    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
        }
      }
    );

    this.selectDownloadChequesToPayFileSub =
      this.selectDownloadChequesToPayFile$.subscribe((file) => {
        if (file && file.result) {
          const blob = new Blob([file.result]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'MES CHEQUES A PAYER.xlsx';
          link.click();
        }
        this.handleCallStateDownloadChequesToPayFile = handleCallState(file);
        this.showLoader =
          this.handleCallStateDownloadChequesToPayFile.showLoader;
      });

    this.selectDownloadAllSvFileSub = this.selectDownloadAllSvFile$.subscribe(
      (file) => {
        if (file && file.result) {
          const blob = new Blob([file.result]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'MES VALEURS.xlsx';
          link.click();
        }
        this.handleCallStateDownloadAllSvFileFile = handleCallState(file);
        this.showLoader = this.handleCallStateDownloadAllSvFileFile.showLoader;
      }
    );
  }

  getCurrentPageCheques(): void {
    const start = this.page.currentPage * 12;
    const end =
      this.chequesPayerResponse.cheques.length >= start + 12
        ? start + 12
        : start + (this.chequesPayerResponse.cheques.length % 12);
    this.currentChequesList = this.chequesPayerResponse.cheques.slice(
      start,
      end
    );
  }

  getTotalNumber(): number {
    return this.chequesPayerResponse !== null &&
      this.chequesPayerResponse !== undefined
      ? this.chequesPayerResponse.total
      : -2;
  }

  download(): void {
    this.showLoader = true;
    this.store.dispatch(
      downloadChequesToPay({
        customerNumber: this.selectedCustomerNumber,
      })
    );
  }

  downloadAll(): void {
    this.showLoader = true;
    this.store.dispatch(
      downloadAllSv({
        customerNumber: this.selectedCustomerNumber,
      })
    );
  }
}
