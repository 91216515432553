import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SellCardService } from 'src/app/service/sell-card.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { formatDate } from '@angular/common';
import { Cards } from 'src/app/models/cards';
import { CardMail } from 'src/app/models/cardMail';
import { UserService } from 'src/app/service/user.service';
import { OperatorService } from 'src/app/service/operator.service';
import { Operator } from 'src/app/models/operator';
import { Router } from '@angular/router';
import { CardPlafonds } from '../sell-card-list/sell-card-enum/card_plafonds';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CustomerFreeAttributes } from 'src/app/models/customerFreeAttributes';
import { AccountCustomerDetails } from 'src/app/models/AccountCustomerDetails';
import { ClientService } from 'src/app/service/client.service';
import { BusinessConfigsService } from 'src/app/service/business-configs.service';
import { CafService } from 'src/app/service/caf.service';
@Component({
  selector: 'app-sell-card-list',
  templateUrl: './sell-card-list.component.html',
  styleUrls: ['./sell-card-list.component.scss'],
})
export class SellCardListComponent implements OnInit {
  @Output() closed = new EventEmitter<Boolean>();
  proposUpdate: any;
  contactUpdate: any;
  adressUpdated: any;
  ceilingUpdate: any;
  accountUpdated: any;
  step: number;
  propos = {
    firstname: '',
    cin: '',
    lastname: '',
    birthDate: '',
    expirationDate: '',
    birthCountry: '',
    birthCity: '',
    selectedIdType: '',
    recto: null as File | null,
    verso: null as File | null,
  };
  contact = { email: '', phoneNumber: '', operator: '' };
  adress = {
    taxAddress: '',
    birthCountry: '',
    birthCity: '',
    mailCountry: '',
    mailCity: '',
    postcode: '',
  };
  account = { acount: '' };

  ceiling = {
    withdrawal_national_sg: '',
    withdrawal_national_colleagues: '',
    purchase_national: '',
    purchase_national_online: '',
    withdrawal_international: '',
    purchase_international: '',
    purchase_international_online: '',
  };
  groupedForms: any;
  receivedInfoData: any;
  receivedContactData: any;
  receivedCeilingData: any;
  receivedAccountData: any;
  accountData: any;
  askCard: number;
  public operatorSelect: Operator;
  cardMail: CardMail;
  public operators: Operator[];
  proposfill: boolean = false;
  contactsfill: boolean = false;
  format = 'dd/MM/yyyy';

  stepFiveTitle = 'Demander Pour un Bénéficaire';
  indexs: string;
  adressfill: boolean = false;
  radioOptions: string;
  public cards: Cards[];
  public cardSelectionner: Cards;
  public current: string;
  public target: string;
  public rate: number;
  public currentSlide: number;
  customerFreeAttributes: CustomerFreeAttributes;
  public slidesLength: number;
  public numStep: number;
  public numStepForm: number;
  public showPropos: boolean;
  public showContact: boolean;
  cardShow: boolean = false;
  public showAdress: boolean = false;
  public userLoaded: boolean = null;
  public showAskCard: boolean = false;
  public adressDetailShow: boolean = false;
  public emailSended = false;
  activateCss: Boolean;
  public souscriptionEtape: number;
  public confirmSendMail: boolean = false;
  customerNumberSelected: string = '';

  finalStep: number;
  showFileViewer = false;
  locale = 'en-US';
  fileSrc: string;
  fileReference: string;
  pdfSrc = '';
  pdfReference = '';
  checked: boolean;
  showPopup: boolean = false;
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: false,
    arrows: true,
    dots: false,
  };

  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  constructor(
    private operatorService: OperatorService,
    private router: Router,
    private clientService: ClientService,
    private businessConfigsService: BusinessConfigsService,
    private cafService: CafService,
    private userService: UserService,
    private store: Store<State>,
    private sellCardService: SellCardService
  ) {}

  ngOnInit() {
    this.selectedCustomerNumber$.subscribe((customerNumber) => {
      if (customerNumber && customerNumber !== this.customerNumberSelected) {
        this.customerNumberSelected = customerNumber;
      }
      forkJoin([
        this.clientService.getClientCodeMarche(customerNumber),
        this.businessConfigsService.findBusinessConfig(
          'CARD_MARKET_CODE_AUTHORIZED'
        ),
        this.businessConfigsService.findBusinessConfig('CARD_CAF_AUTHORIZED'),
      ])
        .pipe(
          switchMap(
            ([clientCodeMarcheResult, cardMarketConfig, cardCafConfig]) => {
              this.customerFreeAttributes =
                this.getCustomerFreeAttributeWithCodeM(clientCodeMarcheResult);

              if (customerNumber && cardMarketConfig) {
                this.cardShow = cardMarketConfig.includes(
                  this.customerFreeAttributes.alphaNumericValue
                );
                if (this.cardShow === false) {
                  this.toDashboard();
                }
              }

              if (this.cardShow && customerNumber && cardCafConfig) {
                return this.cafService.findCafByClient(customerNumber).pipe(
                  switchMap((caf) => {
                    this.cardShow = cardCafConfig.includes(caf.code);
                    if (this.cardShow === false) {
                      this.toDashboard();
                    }
                    return []; // Observable vide pour compléter la chaîne
                  })
                );
              }

              return []; // Observable vide pour compléter la chaîne
            }
          )
        )
        .subscribe();
    });
    this.finalStep = 0;
    this.cardSelectionner = null;
    this.confirmSendMail = false;
    this.emailSended = false;
    this.showPropos = true;
    this.adressDetailShow = false;
    this.showContact = true;
    this.showAdress = true;
    let operator = new Operator();
    operator.code = 'more';
    this.activateCss = true;
    operator.libelle = 'Autre Opérateur';
    this.numStep = 0;
    this.numStepForm = 0;
    this.sellCardService.findCards().subscribe((values) => {
      this.cards = values;
      this.cards.forEach(
        (card) => (card.icone = '/assets/images/card_' + card.alias + '.png')
      );
      this.slidesLength = this.cards.length;
    });

    this.operatorService.getOperators().subscribe((operators) => {
      this.operators = operators;
      this.operators.push(operator);
      operators.forEach((op) => {
        op.icone =
          '/assets/images/softToken/ic_op_' + op.libelle.toLowerCase() + '.svg';
        if (op.code == 'more') {
          op.icone = '/assets/images/softToken/ic_op_more.svg';
        }
      });
    });
  }
  getCustomerFreeAttributeWithCodeM(
    accountDetails: AccountCustomerDetails
  ): CustomerFreeAttributes | undefined {
    return accountDetails.customerFreeAttributes.find(
      (attribute) => attribute.code === 'MCH'
    );
  }

  slickInit(e) {
    this.slidesLength = this.cards.length;
    this.currentSlide = e.slick.currentSlide + 1;
    this.currentSlide = e.slick.currentSlide + 1;
  }

  afterChange(e) {
    this.currentSlide = e.slick.currentSlide + 1;
  }

  selectCard(card: Cards) {
    this.cardSelectionner = card;
    if (
      this.cardSelectionner != null &&
      this.cardSelectionner != undefined &&
      this.cardSelectionner.alias === 'deposit'
    ) {
      this.step = 4;
    } else {
      this.step = 5;
    }
  }
  souscrire() {
    if (this.numStep == 1) {
      this.souscriptionEtape = 1;
      this.showPropos = true;
      this.showPopup = false;
      this.checked = false;
    } else {
      this.numStep = this.next(this.numStep);
    }
  }
  next(step: number): number {
    return step + 1;
  }
  sendMail() {
    this.propos = this.proposUpdate;
    this.contact = this.contactUpdate;
    this.account = this.accountUpdated;
    let cardMail: CardMail = new CardMail();
    cardMail.birthCity = this.propos.birthCity;
    cardMail.operator = this.contact.operator;
    cardMail.birthCountry = this.propos.birthCountry;
    cardMail.selectedIdType = this.propos.selectedIdType;
    cardMail.birthDate = formatDate(
      this.propos.birthDate,
      this.format,
      this.locale
    );
    cardMail.email = this.contact.email;
    cardMail.expirationDate = formatDate(
      this.propos.expirationDate,
      this.format,
      this.locale
    );
    cardMail.cin = this.propos.cin;
    cardMail.name = this.propos.firstname + '  ' + this.propos.lastname;
    cardMail.phoneNumber = this.contact.phoneNumber;
    cardMail.operator = this.contact.operator;
    cardMail.recto = this.propos.recto;
    cardMail.verso = this.propos.verso;
    if (this.step != 4) {
      cardMail.purchaseNationalOnline = this.formatNumber(
        this.ceilingUpdate.purchase_national_online
      );
      cardMail.purchaseInternational = this.formatNumber(
        this.ceilingUpdate.purchase_international
      );
      cardMail.purchaseInternationalOnline = this.formatNumber(
        this.ceilingUpdate.purchase_international_online
      );
      cardMail.purchaseNational = this.formatNumber(
        this.ceilingUpdate.purchase_national
      );
      cardMail.withdrawalNationalColleagues = this.formatNumber(
        this.ceilingUpdate.withdrawal_national_colleagues
      );
      cardMail.withdrawalNationalSg = this.formatNumber(
        this.ceilingUpdate.withdrawal_national_sg
      );
      cardMail.withdrawalInternational = this.formatNumber(
        this.ceilingUpdate.withdrawal_international
      );
    }
    cardMail.libelleCard = this.cardSelectionner.libelle;
    cardMail.account = this.receivedAccountData;
    cardMail.codeClient = this.customerNumberSelected;

    if (this.askCard == 1) {
      this.sellCardService
        .sendMailToUser(cardMail)
        .pipe(
          catchError((error) => {
            this.emailSended = false;

            this.userLoaded = !this.userLoaded;
            // Vous pouvez choisir de propager l'erreur plus loin si nécessaire.
            return throwError(
              'Une erreur  est produite lors de envoi du courrier.'
            );
          })
        )
        .subscribe(() => {
          this.emailSended = true;
          this.souscriptionEtape = 7;
        });
    }
    if (this.askCard == 2) {
      this.sellCardService
        .sendMailToBeneficiaries(cardMail)
        .pipe(
          catchError((error) => {
            this.renitialiserForm();
            this.emailSended = false;
            this.souscriptionEtape = 7;
            this.userLoaded = !this.userLoaded;
            // Vous pouvez effectuer ici un traitement spécifique à l'erreur si nécessaire.

            // Vous pouvez également choisir de propager l'erreur plus loin si nécessaire.
            return throwError(
              'Une erreur s est produite lors de envoi du courrier.'
            );
          })
        )
        .subscribe(() => {
          this.emailSended = true;
          this.souscriptionEtape = 7;
          this.userLoaded = !this.userLoaded;
          // Le reste de votre logique ici si l'opération a réussi.
        });
    }
  }
  loadUser() {
    this.userService.getUser().subscribe((user) => {
      this.proposUpdate = {
        firstname: user.firstName,
        cin: user.idNumber,
        lastname: user.lastName,
        birthDate: '',
        expirationDate: '',
        birthCountry: '',
        birthCity: '',
      };
      this.propos.cin = user.idNumber;
      this.propos.lastname = user.lastName;
      this.propos.firstname = user.firstName;
      this.contact.email = user.email;
      this.contact.phoneNumber = user.phoneNumber;
    });
  }

  firstStep() {
    this.souscriptionEtape = 0;
    this.numStep = 1;
    this.finalStep = 0;
    this.userLoaded = !this.userLoaded;
    this.userLoaded = !this.userLoaded;
  }
  receiveStepAndGetData(step: number) {
    this.receiveStep(step);

    if (this.finalStep === 5 && step !== 5) {
      this.souscriptionEtape = 5;
    }
  }
  backToFirstStep() {
    window.location.reload();
  }
  toDashboard() {
    window.location.reload();
  }

  downloadGuide() {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/pdf/Carte_CorporateInternationale.pdf';
    link.download = 'Carte_CorporateInternationale.pdf';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  viewPdf(card: Cards) {
    let url: string;

    if (
      card != null &&
      card != undefined &&
      card.alias === CardPlafonds.EscalePro
    ) {
      url = 'assets/pdf/Carte_Escale_Pr_VF.pdf';
      this.pdfSrc = 'assets/pdf/Carte_Escale_Pr_VF.pdf';
    }
    if (
      card != null &&
      card != undefined &&
      card.alias === CardPlafonds.Deposit
    ) {
      url = 'assets/pdf/Carte_Global_Deposit_VF.pdf';
      this.pdfSrc = 'assets/pdf/Carte_Global_Deposit_VF.pdf';
    }
    if (
      card != null &&
      card != undefined &&
      card.alias === CardPlafonds.Business
    ) {
      url = 'assets/pdf/Carte_Visa_Business_VF.pdf';
      this.pdfSrc = 'assets/pdf/Carte_Visa_Business_VF.pdf';
    }
    this.fileSrc = url;
    this.fileReference = 'carte bancaire';
    this.showFileViewer = true;
  }

  closePopinEmail() {
    this.souscriptionEtape = 0;
  }
  receiveStep(step: number) {
    this.souscriptionEtape = step;
    this.proposUpdate;
    if (
      this.souscriptionEtape !== 0 &&
      this.groupedForms != null &&
      this.groupedForms != undefined
    ) {
      this.proposUpdate = this.groupedForms.receivedInfoData;
      this.ceilingUpdate = this.groupedForms.receivedCeilingData;
      this.contactUpdate = this.groupedForms.receivedContactData;
      // this.accountUpdated = this.groupedForms.receivedAccountData;
    }

    this.showContact = true;
    if (this.souscriptionEtape == 4) {
      this.showAdress = true;
    }
    if (this.souscriptionEtape == 0) {
      this.renitialiserForm();
    }
    if (step == 5) {
      this.finalStep = 5;
    }
    if (step == 6) {
      this.sendMail();
    }
  }
  renitialiserForm() {
    this.finalStep = 0;
    if (!this.userLoaded) {
      this.proposUpdate = {
        firstname: '',
        cin: '',
        lastname: '',
        birthDate: '',
        expirationDate: '',
        birthCountry: '',
        birthCity: '',
      };
    } else if (this.userLoaded) {
      this.loadUser();
    }
    this.ceilingUpdate = { email: '', phoneNumber: '' };
    this.contactUpdate = {
      taxAddress: '',
      birthCountry: '',
      birthCity: '',
      mailCountry: '',
      mailCity: '',
      postcode: '',
    };

    this.accountUpdated = { acount: '' };
    this.groupedForms = {
      receivedInfoData: '',
      receivedContactData: '',
      receivedCeilingData: '',
      receivedAccountData: '',
    };
  }
  receiveFormAccountInfo(data: any) {
    this.receivedAccountData = data;
    this.accountUpdated = data;
    this.groupedForms = {
      receivedInfoData: this.receivedInfoData,
      receivedContactData: this.receivedContactData,
      receivedCeilingData: this.receivedCeilingData,
      receivedAccountData: this.receivedAccountData,
    };
  }

  receiveFormDataInfo(data: any) {
    this.receivedInfoData = data;
  }
  receiveFormDataContact(data: any) {
    this.receivedContactData = data;
    this.groupedForms = {
      receivedInfoData: this.receivedInfoData,
      receivedContactData: this.receivedContactData,
      receivedAccountData: this.receivedAccountData,
    };
  }

  receiveStatusPopin(closedPopin: boolean) {
    if (closedPopin == true) {
      this.souscriptionEtape = 0;
      this.finalStep = 0;
    }
  }

  receiveFormDataCeiling(data: any) {
    this.receivedCeilingData = data;
    this.groupedForms = {
      receivedInfoData: this.receivedInfoData,
      receivedContactData: this.receivedContactData,
      receivedCeilingData: this.receivedCeilingData,
      receivedAccountData: this.receivedAccountData,
    };
  }
  openPopup() {
    if (this.askCard === 1) {
      this.userLoaded = true;
      this.stepFiveTitle = 'Demander Pour un Moi meme';
      this.loadUser();
    }
    if (this.askCard === 2) {
      this.stepFiveTitle = 'Demander Pour un Bénéficaire';
      this.userLoaded = false;
    }
    this.showPopup = true;
    this.showPropos = true;
    this.showContact = true;
    this.showAdress = true;
  }

  closePopup() {
    //this.contact={...this.contactUpdated};
    //  this.propos={...this.proposUpdated};
    this.adress = { ...this.adressUpdated };
    this.showPopup = false;
    this.showPropos = false;
    this.showContact = false;
    this.showAdress = false;
    this.confirmSendMail = false;
  }
  back() {
    if (this.numStep == 1) {
      this.backToFirstStep();
    }

    if (this.numStep == 2) {
      this.checked = null;
      this.numStep = this.numStep - 1;
      this.askCard = null;
      this.showPopup = false;
      this.showPropos = false;
      this.showContact = false;
      this.showAdress = false;
      this.propos = {
        firstname: '',
        cin: '',
        lastname: '',
        birthDate: '',
        expirationDate: '',
        birthCountry: '',
        birthCity: '',
        selectedIdType: '',
        recto: null as File | null,
        verso: null as File | null,
      };
      //   this.proposUpdated= {... this.propos};
      this.contact = { email: '', phoneNumber: '', operator: '' };
      //  this.contactUpdated= {... this.contact};
      this.adress = {
        taxAddress: '',
        birthCountry: '',
        birthCity: '',
        mailCountry: '',
        mailCity: '',
        postcode: '',
      };
      this.adressUpdated = { ...this.adress };
      this.proposfill = false;
      this.adressfill = false;
      this.contactsfill = false;
      this.numStepForm = 0;
      this.souscriptionEtape = 0;
    }
  }

  formatNumber(value: any): string {
    if (value == null || value === undefined) {
      return ''; // ou une autre valeur par défaut si nécessaire
    }
    const stringValue = String(value); // Convertir la valeur en chaîne
    const numericValue = parseFloat(stringValue.replace(/\s/g, ''));
    return !isNaN(numericValue) ? numericValue.toLocaleString() : stringValue;
  }
}
