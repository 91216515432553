import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';

import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { AccountService } from 'src/app/service/account.service';
import { ClientService } from 'src/app/service/client.service';
import { CustomerDetail } from '../../models/customer-detail';
import { Client } from 'src/app/models/client';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-sell-card-account',
  templateUrl: './sell-card-account.component.html',
  styleUrls: ['./sell-card-account.component.scss'],
})
export class SellCardAccountComponent implements OnInit {
  account = { acount: '' };
  selectedAccount: any;
  title = 'Demander Pour un Bénéficaire';
  @Input() compteSelected: any;
  @Input() userLoaded: boolean = false;
  @Output() stepEvent = new EventEmitter<number>();
  @Output() closedPopine = new EventEmitter<Boolean>();
  @Output() formAccountData = new EventEmitter<any>();

  customerNumberSelected: string = '';
  customerDetail: CustomerDetail;
  client: Client;
  accounts: string[] = [];
  accountFormGroup: FormGroup;
  @Input() isPopupVisible: boolean = true;
  @Input() accountUpdate: any;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  constructor(
    private store: Store<State>,
    private accountService: AccountService,
    private fb: FormBuilder,
    private clientService: ClientService
  ) {}

  ngOnInit() {
    this.accountFormGroup = this.fb.group({
      accountForm: [null],
    });

    if (this.accountUpdate != null && this.accountUpdate != undefined) {
      this.account = this.accountUpdate;
      this.accountForm.setValue(this.accountUpdate);
      this.selectedAccount = this.accountUpdate;
    }

    this.selectedCustomerNumber$.subscribe((customNumber) => {
      if (customNumber && customNumber !== this.customerNumberSelected) {
        this.customerNumberSelected = customNumber;
        forkJoin(
          this.clientService.getCustomerDetail(this.customerNumberSelected),
          this.clientService.getClientDetail(this.customerNumberSelected)
        ).subscribe(([customer, client]) => {
          this.customerDetail = customer;
          this.client = client;
          const clientType = client.clientType;
          this.accountService
            .getAccountByClientAndTypeForCard(
              this.customerNumberSelected,
              clientType
            )
            .subscribe((account) => {
              this.accounts = account;
            });
        });
      }
    });
  }

  get accountForm(): FormControl {
    return this.accountFormGroup.get('accountForm') as FormControl;
  }

  send() {
    this.account = this.accountForm.value;
    this.formAccountData.emit(this.account);
    this.stepEvent.emit(2);
  }
  receiveStatusPopin(closedPopin: boolean) {
    if (closedPopin == true) {
      this.account = { acount: '' };
    }
    this.closedPopine.emit(closedPopin);
  }
  public selectIsOpened: boolean;
  selectIsopened() {
    this.selectIsOpened = true;
  }
  selectIsclosed() {
    this.selectIsOpened = false;
  }
  ngOnChanges(changes: SimpleChanges) {
    if ('userLoaded' in changes) {
      this.accountFormGroup = this.fb.group({
        accountForm: [null],
      });
      this.accountForm.setValue(null);
      this.selectedAccount = null;
      if (this.userLoaded) {
        this.title = 'Demander Pour  Moi meme';
      }
      if (!this.userLoaded) {
        this.title = 'Demander Pour un Bénéficaire';
      }
    }
  }
}
