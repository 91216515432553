import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFormatNumber]',
})
export class FormatNumberDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[^\d\s]/g, ''); // Supprimer tous les caractères sauf les chiffres et les espaces
    value = this.formatNumber(value);
    this.renderer.setProperty(input, 'value', value);

    // Mettez à jour le contrôle avec la valeur traitée
    this.updateControlValue(value);
  }

  private formatNumber(value: string): string {
    // Formater le nombre en conservant les espaces
    return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  private updateControlValue(value: string): void {
    // Mettez à jour le contrôle avec la valeur traitée
    // Assurez-vous que le contrôle existe avant de l'utiliser
    if (this.el.nativeElement && this.el.nativeElement.control) {
      this.el.nativeElement.control.setValue(value);

      // Vérifiez si la valeur contient une virgule ou un point et définissez l'erreur en conséquence

      const hasInvalidCharacter = value.includes('.') || value.includes(',');
      const hasError = hasInvalidCharacter;

      if (hasError) {
        // Définir le statut d'erreur et le message personnalisé
        this.el.nativeElement.control.setErrors({ invalidInput: true });
      } else {
        // Effacer les erreurs si la valeur est valide
        this.el.nativeElement.control.setErrors(null);
      }
    }
  }
}
