import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { initialState, UserState } from './user.state';

const userReducer = createReducer(
  initialState,
  on(UserActions.fetchUserSuccess, (state, { user }) => {
    return {
      ...state,
      connectedUser: user,
    };
  }),
  on(
    UserActions.getSelectedCustomerNumberSuccess,
    UserActions.changeSelectedCustomerNumberSuccess,
    (state, { obj }) => {
      let customerNumberCache = null;
      if (obj && obj.customerNumber) {
        if (obj.customerNumber.includes('customerNumber')) {
          customerNumberCache = JSON.parse(obj.customerNumber).customerNumber;
        } else {
          customerNumberCache = obj.customerNumber;
        }

        if (customerNumberCache !== state.selectedCustomerNumber) {
          return {
            ...state,
            selectedCustomerNumber: customerNumberCache,
          };
        } else {
          return {
            ...state,
          };
        }
      } else {
        return {
          ...state,
        };
      }
    }
  ),
  on(UserActions.getUserApplications, (state, { userApplications }) => {
    return {
      ...state,
      userApplications,
    };
  }),
  on(UserActions.getUserClientsSuccess, (state, { userClients }) => {
    return {
      ...state,
      userClients,
    };
  }),
  on(UserActions.getUserSoftTokenSuccess, (state, { hasSoftToken }) => {
    return {
      ...state,
      hasSoftToken,
    };
  })
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
