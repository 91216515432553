import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ESingIframeComponent } from './e-sing-iframe/e-sing-iframe.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [],
  },
  { path: 'e-sign-iframe', component: ESingIframeComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ESignRoutingModule {}
