import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { CardEscaleProDTO } from '../../../models/CardEscaleProDTO';
import { CardEscaleProDetailsDTO } from '../../../models/CardEscaleProDetailsDTO';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import {
  fetchCards,
  selectCard,
  selectSelectedCard,
  getCardDetails,
  selectCards,
  EscaleProSelectedCard,
  selectRefillCard,
  initCardCallStateData,
  getAccountToBeDebited,
  selectAccountToBeDebited,
  selectCardDetails,
  initCardsListing,
  fetchCardsHistory,
  fetchCardsFilter,
  getEscaleProChallengeCode,
  signEscaleProRequest,
  selectChallengeCode,
  selectSignRequest,
  initSignatoryFromInit,
} from 'src/app/core/store/state/escale-pro';
import { EpCardDetailsComponent } from '../ep-card-details/ep-card-details.component';
import { handleCallState, CallStateResponse } from 'src/app/models/CallState';
import { CardReloadDTO } from 'src/app/models/CardReloadDTO';
import { EpChargeCardComponent } from '../ep-charge-card/ep-charge-card.component';
import { CardRefillDto } from 'src/app/models/CardRefillDto';
import { Page } from 'src/app/models/page';
import { EpCardFilterComponent } from '../ep-card-filter/ep-card-filter.component';
import { CardRefillSearchRequestDto } from '../../../models/CardRefillSearchRequestDto';
import { CardSearchRequestDTO } from 'src/app/models/CardSearchRequestDTO';
import { CardRefillElementDto } from 'src/app/models/CardRefillElementDto';
import { SignatureChallengeAndDresponse } from 'src/app/models/signatureChallengeAndDresponse';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-ep-init',
  templateUrl: './ep-init.component.html',
  styleUrls: ['./ep-init.component.scss'],
})
export class EpInitComponent implements OnInit, OnDestroy {
  cardEscaleProList: Array<CardEscaleProDTO> = [];
  currentCardEscaleProList: Array<CardEscaleProDTO> = [];
  openCardDetails = false;
  cardDetails: CardEscaleProDetailsDTO;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectCards$ = this.store.select(selectCards);
  selectSelectedCard$ = this.store.select(selectSelectedCard);
  selectedCard: EscaleProSelectedCard;
  selectRefillCard$ = this.store.select(selectRefillCard);
  selectRefillCard: number;
  displayError: boolean;
  uploadDefaultMessageError =
    'Le service est indisponible,  merci de réessayer ultérieurement';
  uploadDefaultMessageSuccess = 'Opération terminée avec succès';
  openPopinMessage = this.uploadDefaultMessageError;
  openPopinMessageSuccess = false;
  selectAccountToBeDebited$ = this.store.select(selectAccountToBeDebited);
  selectAccountToBeDebited: CardReloadDTO = null;
  selectCardDetails$ = this.store.select(selectCardDetails);
  showLoader: boolean = false;
  page = new Page();

  selectedCustomerNumberSub: Subscription;
  selectCardsSub: Subscription;
  selectAccountToBeDebitedSub: Subscription;
  selectRefillCardSub: Subscription;
  selectCardDetailsSub: any;
  selectSelectedCardSub: any;

  handleCallStateCards: CallStateResponse;
  handleCallStateAccountToBeDebited: CallStateResponse;
  handleCallStateCardDetails: CallStateResponse;
  handleCallStateRefill: CallStateResponse;

  filter: CardSearchRequestDTO;
  selectedRefillRequestId: number;
  openPopinSignatureFlag: boolean;
  challengeCode = '';

  selectChallengeCodeSub: Subscription;
  selectChallengeCode$ = this.store.select(selectChallengeCode);
  selectSignRequest$ = this.store.select(selectSignRequest);
  selectSignRequestSub: Subscription;
  handleCallStateChallengeCode: CallStateResponse;
  handleCallStateSignRequest: CallStateResponse;
  openChargeCardFlag: boolean = false;
  challengeKey: string;

  constructor(
    private store: Store<State>,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  initHandleCallState() {
    this.handleCallStateCards = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
    this.handleCallStateAccountToBeDebited = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
    this.handleCallStateCardDetails = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
    this.handleCallStateRefill = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
    this.handleCallStateChallengeCode = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
    this.handleCallStateSignRequest = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
  }
  getShowLoader(): boolean {
    let showLoader =
      (this.handleCallStateCards.showLoader ||
        this.handleCallStateAccountToBeDebited.showLoader ||
        this.handleCallStateCardDetails.showLoader ||
        this.handleCallStateRefill.showLoader ||
        this.handleCallStateSignRequest.showLoader) &&
      !this.displayError;

    return showLoader;
  }

  openFilterPopup(): void {
    const dialogRef = this.dialog.open(EpCardFilterComponent, {
      data: {
        filter: this.filter,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filter = result;
        this.store.dispatch(
          fetchCardsFilter({
            customerNumber: this.selectedCustomerNumber,
            cardFilterDto: this.filter,
          })
        );
      }
    });
  }

  getDisplayError(): boolean {
    let displayError =
      this.handleCallStateCards.displayError ||
      this.handleCallStateAccountToBeDebited.displayError ||
      this.handleCallStateCardDetails.displayError ||
      this.handleCallStateRefill.displayError ||
      (this.handleCallStateChallengeCode.displayError &&
        !this.handleCallStateChallengeCode.openPopinMessageSuccess) ||
      this.handleCallStateSignRequest.displayError;

    if (displayError) {
      if (this.handleCallStateCards.displayError) {
        this.openPopinMessage = this.handleCallStateCards.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateCards.openPopinMessageSuccess;
        this.handleCallStateCards.displayError = false;
      }
      if (this.handleCallStateAccountToBeDebited.displayError) {
        this.openPopinMessage =
          this.handleCallStateAccountToBeDebited.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateAccountToBeDebited.openPopinMessageSuccess;
        this.handleCallStateAccountToBeDebited.displayError = false;
      }
      if (this.handleCallStateCardDetails.displayError) {
        this.openPopinMessage =
          this.handleCallStateCardDetails.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateCardDetails.openPopinMessageSuccess;
        this.handleCallStateCardDetails.displayError = false;
      }
      if (this.handleCallStateRefill.displayError) {
        this.openPopinMessage = this.handleCallStateRefill.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateRefill.openPopinMessageSuccess;
        this.handleCallStateRefill.displayError = false;
      }
      if (this.handleCallStateChallengeCode.displayError) {
        this.openPopinMessage =
          this.handleCallStateChallengeCode.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateChallengeCode.openPopinMessageSuccess;
        this.handleCallStateChallengeCode.displayError = false;
      }
      if (this.handleCallStateSignRequest.displayError) {
        this.openPopinSignatureFlag = false;
        this.openPopinMessage =
          this.handleCallStateSignRequest.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateSignRequest.openPopinMessageSuccess;
        this.handleCallStateSignRequest.displayError = false;
      }
    }
    return displayError;
  }

  ngOnDestroy() {
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectCardsSub.unsubscribe();
    this.selectAccountToBeDebitedSub.unsubscribe();
    this.selectRefillCardSub.unsubscribe();
    this.selectCardDetailsSub.unsubscribe();
    this.selectSelectedCardSub.unsubscribe();
    this.selectChallengeCodeSub.unsubscribe();
    this.store.dispatch(initCardsListing());
    this.store.dispatch(initSignatoryFromInit());
  }
  ngOnInit() {
    this.initHandleCallState();
    this.initFilter();

    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
          this.store.dispatch(
            fetchCards({
              customerNumber: this.selectedCustomerNumber,
              cardFilterDto: null,
            })
          );
        }
      }
    );

    this.selectCardsSub = this.selectCards$.subscribe((cards) => {
      this.page.init();
      if (cards && cards.result) {
        this.cardEscaleProList = cards.result;
        if (this.cardEscaleProList.length > 0) {
          this.page.totalPages = Math.ceil(this.cardEscaleProList.length / 12);
          this.page.totalElements = this.cardEscaleProList.length;
          this.getCurrentPageCards();
          this.store.dispatch(
            getAccountToBeDebited({
              customerNumber: this.selectedCustomerNumber,
            })
          );
        }
      }
      this.handleCallStateCards = handleCallState(cards);
      this.displayError = this.getDisplayError();
      this.showLoader = this.getShowLoader();
    });

    this.selectAccountToBeDebitedSub = this.selectAccountToBeDebited$.subscribe(
      (accountToBeDebited) => {
        if (accountToBeDebited && accountToBeDebited.result) {
          this.selectAccountToBeDebited = accountToBeDebited.result;
        }

        this.handleCallStateAccountToBeDebited =
          handleCallState(accountToBeDebited);
        this.displayError = this.getDisplayError();
        this.showLoader = this.getShowLoader();
      }
    );

    this.selectSelectedCardSub = this.selectSelectedCard$.subscribe(
      (selectedCard: EscaleProSelectedCard) =>
        (this.selectedCard = selectedCard)
    );

    this.selectCardDetailsSub = this.selectCardDetails$.subscribe(
      (cardDetails) => {
        if (cardDetails && cardDetails.result) {
          this.cardDetails = cardDetails.result;
          if (this.cardDetails) {
            const dialogRef = this.dialog.open(EpCardDetailsComponent, {
              data: {
                cardDetails: this.cardDetails,
              },
            });
            dialogRef.afterClosed().subscribe((res) => {
              res === 'CHARGE_CARD' && this.chargeCard();
            });
          }
        }
        this.handleCallStateCardDetails = handleCallState(cardDetails);
        this.displayError = this.getDisplayError();
        this.showLoader = this.getShowLoader();
      }
    );

    this.selectRefillCardSub = this.selectRefillCard$.subscribe(
      (refillCardState) => {
        if (refillCardState && refillCardState.result) {
          this.selectRefillCard = refillCardState.result;
          this.selectedRefillRequestId = refillCardState.result;
          if (
            this.selectAccountToBeDebited &&
            this.selectAccountToBeDebited.userAloneInSigningSchema &&
            this.selectedRefillRequestId > 0
          ) {
            this.onSign(this.selectedRefillRequestId);
          }
        }
        this.handleCallStateRefill = handleCallState(refillCardState);
        this.displayError = this.getDisplayError();
        this.showLoader = this.getShowLoader();
      }
    );

    this.selectChallengeCodeSub = this.selectChallengeCode$.subscribe(
      (challengeCode) => {
        if (challengeCode && challengeCode.result) {
          this.challengeCode = challengeCode.result.challenge;
          this.challengeKey = challengeCode.result.challengeKey;
        }
        this.handleCallStateChallengeCode = handleCallState(challengeCode);
        this.displayError = this.getDisplayError();
        this.showLoader = this.getShowLoader();
      }
    );

    this.selectSignRequestSub = this.selectSignRequest$.subscribe(
      (signRequest) => {
        if (signRequest && signRequest.result) {
          this.openPopinSignatureFlag = false;
        }
        this.handleCallStateSignRequest = handleCallState(signRequest);
        this.displayError = this.getDisplayError();
        /*if (this.getDisplayError()) {
          this._snackBar.open(this.openPopinMessage, 'Fermer', {
            panelClass: ['my-snackbar-error'],
            horizontalPosition: 'center', // Adjust position as needed
            verticalPosition: 'bottom', // Adjust position as needed});
          });
        }*/
        this.showLoader = this.getShowLoader();
      }
    );
  }

  closeCardDetails() {
    this.openCardDetails = false;
  }

  openDetails(card: CardEscaleProDTO, event: Event) {
    event.stopPropagation();
    this.selectCard(card);
    this.store.dispatch(
      getCardDetails({
        customerNumber: this.selectedCustomerNumber,
        cardNumber: card.cardNumber,
        accountNumber: card.accountNumber,
      })
    );
  }

  selectCard(card: CardEscaleProDTO) {
    if (
      this.selectedCard.card &&
      this.selectedCard.card.cardNumber == card.cardNumber
    )
      this.store.dispatch(selectCard(null));
    else this.store.dispatch(selectCard({ card }));
  }

  closePopin() {
    this.displayError = false;
  }

  chargeCard() {
    if (this.selectAccountToBeDebited) {
      this.openChargeCardFlag = true;
    } else {
      this.displayError = true;
    }
  }

  getCurrentPageCards(): void {
    const start = this.page.currentPage * 12;
    const end =
      this.cardEscaleProList.length >= start + 12
        ? start + 12
        : start + (this.cardEscaleProList.length % 12);
    this.currentCardEscaleProList = this.cardEscaleProList.slice(start, end);
  }

  initFilter() {
    this.filter = new CardSearchRequestDTO(
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  closeSignaturePopin() {
    this.openPopinSignatureFlag = false;
  }

  refreshChallengeCode() {
    this.store.dispatch(getEscaleProChallengeCode());
  }
  onSign(selectedRefillRequestId: number) {
    this.selectedRefillRequestId = selectedRefillRequestId;
    this.refreshChallengeCode();
    this.openPopinSignatureFlag = true;
  }
  signHandler(data: SignatureChallengeAndDresponse) {
    this.store.dispatch(
      signEscaleProRequest({
        customerNumber: this.selectedCustomerNumber,
        requestId: this.selectedRefillRequestId,
        challengeKey: data.challengeKey,
        dResponse: data.dResponse,
      })
    );
  }

  countFilterRes(): string {
    return (this.filter.firstName || this.filter.lastName) &&
      this.page.totalElements
      ? this.page.totalElements.toString()
      : '';
  }
}
