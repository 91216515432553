<div class="files-container">
  <div class="empty-data" *ngIf="getTotalNumber() == 0">
    <img src="/assets/images/api-empty.png" alt="'menu-icon" />
    <span>Vous n'avez pas d'effet impayé</span>
  </div>
  <div class="error-data" *ngIf="getTotalNumber() == -1">
    <img src="/assets/images/api-error.png" alt="'menu-icon" />
    <div>
      <h3>Erreur de chargement de la page</h3>
      <p>
        Lorem ipsum dolor hamet Lorem ipsum dolor hamet Lorem ipsum dolor hamet.
      </p>
    </div>
  </div>
  <ng-container *ngIf="getTotalNumber() > 0">
    <div class="file-tab" *ngFor="let effet of currentUnpaidEffetsList">
      <div class="file-info">
        <div class="col-info">
          <div class="file-elm">
            <p class="label">N° de compte du client</p>
            <p class="info">{{ effet.compteRemettant }}</p>
          </div>
          <div class="file-elm">
            <p class="label">Nom du client</p>
            <p class="info">{{ effet.nomRemettant | uppercase }}</p>
          </div>
        </div>
        <div class="col-info">
          <div class="file-elm">
            <p class="label">N° de compte du client émetteur</p>
            <p class="info">{{ effet.compteEmetteur }}</p>
          </div>
          <div class="file-elm">
            <p class="label">Nom de l'émetteur</p>
            <p class="info">{{ effet.nomEmetteur }}</p>
          </div>
        </div>
        <div class="col-info">
          <div class="file-elm">
            <p class="label">Montant</p>
            <p class="info">
              {{ effet.montant | currency : " " : "" : "" : "fr" }} MAD
            </p>
          </div>
          <div class="file-elm">
            <p class="label">N° LCN</p>
            <p class="info">{{ effet.numLcn }}</p>
          </div>
        </div>
        <div class="col-info">
          <div class="file-elm">
            <p class="label">Motif du rejet</p>
            <p class="info">{{ effet.motifRejet }}</p>
          </div>
          <div class="file-elm">
            <p class="label">N° de remise</p>
            <p class="info">{{ effet.numeroRemise }}</p>
          </div>
        </div>
      </div>
    </div>
    <app-pagination
      [page]="page"
      (getPage)="getCurrentPageUnpaidEffet()"
    ></app-pagination>
  </ng-container>
  <app-sv-download
    *ngIf="getTotalNumber() >= 0"
    [downloadOneSvTypeText]="'Télécharger mes effets impayés'"
    [svTotalNumber]="getTotalNumber()"
    (dispatchDownloadAction)="download()"
    (dispatchDownloadAllSvAction)="downloadAll()"
  ></app-sv-download>
</div>

<!-------------------------------- Loader management --------------------------------------->
<div class="loader" *ngIf="showLoader">
  <img class="loader-icon" src="/assets/images/Ellipsis-2s-80px.gif" alt="" />
</div>
<!----------------------------------------------------------------------------------------->
