import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State } from '../../../core/store/state';
import {
  downloadAllSv,
  downloadLcnUnpaid,
  selectDownloadAllSv,
  selectDownloadLcnUnpaid,
  selectEffetImpayeFromListing,
  initDownloadCallStateData,
} from '../../../core/store/state/soge-valeur';
import { selectSelectedCustomerNumber } from '../../../core/store/state/user';
import { CallStateResponse, handleCallState } from '../../../models/CallState';
import { LcnNotPaidResponse } from '../../../models/SogeValeurDto';
import { Page } from 'src/app/models/page';
import { LcnNotPaidDTO } from 'src/app/models/LcnNotPaidDTO';

@Component({
  selector: 'app-sv-effet-impaye',
  templateUrl: './sv-effet-impaye.component.html',
  styleUrls: ['./sv-effet-impaye.component.scss'],
})
export class SvEffetImpayeComponent implements OnInit {
  selectEffetImpayeSub: Subscription;
  effetsImpayeResponse: LcnNotPaidResponse;
  currentUnpaidEffetsList: LcnNotPaidDTO[] = [];
  selectEffetImpaye$ = this.store.select(selectEffetImpayeFromListing);
  selectedCustomerNumberSub: Subscription;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber;
  selectDownloadLcnUnpaidFileSub: Subscription;
  selectDownloadLcnUnpaidFile$ = this.store.select(selectDownloadLcnUnpaid);
  handleCallStateDownloadLcnUnpaidFile: CallStateResponse;
  selectDownloadAllSvFileSub: Subscription;
  selectDownloadAllSvFile$ = this.store.select(selectDownloadAllSv);
  handleCallStateDownloadAllSvFileFile: CallStateResponse;
  showLoader = false;
  page = new Page();

  constructor(private store: Store<State>) {}

  ngOnDestroy(): void {
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectEffetImpayeSub.unsubscribe();
    this.selectDownloadLcnUnpaidFileSub.unsubscribe();
    this.selectDownloadAllSvFileSub.unsubscribe();
    this.store.dispatch(initDownloadCallStateData(this.selectedCustomerNumber));
  }

  ngOnInit() {
    this.page.init();
    this.selectEffetImpayeSub = this.selectEffetImpaye$.subscribe(
      (sogeValeurs) => {
        if (sogeValeurs && sogeValeurs) {
          this.effetsImpayeResponse = sogeValeurs.result;
          this.page.totalPages = Math.ceil(
            this.effetsImpayeResponse.lcns.length / 12
          );
          this.page.totalElements = this.effetsImpayeResponse.lcns.length;
          this.getCurrentPageUnpaidEffet();
        }
      }
    );

    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
        }
      }
    );

    this.selectDownloadLcnUnpaidFileSub =
      this.selectDownloadLcnUnpaidFile$.subscribe((file) => {
        if (file && file.result) {
          const blob = new Blob([file.result]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'MES EFFETS IMPAYES.xlsx';
          link.click();
        }
        this.handleCallStateDownloadLcnUnpaidFile = handleCallState(file);
        this.showLoader = this.handleCallStateDownloadLcnUnpaidFile.showLoader;
      });

    this.selectDownloadAllSvFileSub = this.selectDownloadAllSvFile$.subscribe(
      (file) => {
        console.log(file);
        if (file && file.result) {
          const blob = new Blob([file.result]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'MES VALEURS.xlsx';
          link.click();
        }
        this.handleCallStateDownloadAllSvFileFile = handleCallState(file);
        this.showLoader = this.handleCallStateDownloadAllSvFileFile.showLoader;
      }
    );
  }

  getCurrentPageUnpaidEffet(): void {
    const start = this.page.currentPage * 12;
    const end =
      this.effetsImpayeResponse.lcns.length >= start + 12
        ? start + 12
        : start + (this.effetsImpayeResponse.lcns.length % 12);
    this.currentUnpaidEffetsList = this.effetsImpayeResponse.lcns.slice(
      start,
      end
    );
  }

  getTotalNumber(): number {
    return this.effetsImpayeResponse !== null &&
      this.effetsImpayeResponse !== undefined
      ? this.effetsImpayeResponse.total
      : -2;
  }

  download(): void {
    this.showLoader = true;
    this.store.dispatch(
      downloadLcnUnpaid({
        customerNumber: this.selectedCustomerNumber,
      })
    );
  }

  downloadAll(): void {
    this.showLoader = true;
    this.store.dispatch(
      downloadAllSv({
        customerNumber: this.selectedCustomerNumber,
      })
    );
  }
}
