import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserClientConfigService } from 'src/app/service/user-client-config.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
@Component({
  selector: 'app-dialog-common',
  templateUrl: './dialog-common.component.html',
  styleUrls: ['./dialog-common.component.scss'],
})
export class DialogCommonComponent implements OnInit {
  headerText: string;
  messageText: string;
  buttonText: string;
  redirection: string;
  image: string;
  flag;
  string;

  form = new FormGroup({
    hideDialog: new FormControl(false, [
      Validators.required,
      Validators.required,
    ]),
  });

  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogCommonComponent>,
    private userClientConfigService: UserClientConfigService,
    private router: Router,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.headerText = this.data['headerText'];
    this.messageText = this.data['messageText'];
    this.buttonText = this.data['buttonText'];
    this.redirection = this.data['redirection'];
    this.image = this.data['image'];
    this.flag = this.data['flag'];
    this.selectedCustomerNumber$.subscribe((customerNumber) => {
      this.selectedCustomerNumber = customerNumber;
    });
  }

  get hideDialog(): any {
    return this.form.get('hideDialog');
  }
  onNoClick(): void {
    if (this.hideDialog.value) {
      let customNumber = this.selectedCustomerNumber;
      this.userClientConfigService
        .hidePopup(customNumber, this.flag)
        .subscribe(() => {});
    }
    this.form.setValue({ hideDialog: false });
    this.dialogRef.close();
  }

  redirect() {
    let url = this.router.createUrlTree([this.redirection]);
    this.dialogRef.close();
    window.open(url.toString(), '_blank');
  }
}
