import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EscaleProState } from './escale-pro.state';

export const selectEscalePro =
  createFeatureSelector<EscaleProState>('escalePro');

export const selectCardsHistory = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.cardsHistory.cards
);

export const selectCards = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.cardsListing.cards
);

export const selectSelectedCard = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.cardsListing.selectedCard
);

export const selectCardDetails = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.cardsListing.selectedCard.details
);

export const selectAccountToBeDebited = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.cardsListing.accountToBeDebited
);

export const selectRefillCard = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.cardsListing.refillCard
);

export const selectTrackedRefillRequests = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.cardsTracking.refillRequests
);

export const selectCancelRequest = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.cardsTracking.cancelRequest
);

export const selectEscaleProUserRoles = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.userRoles
);

export const selectSignRequest = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.signatory.signRequest
);

export const selectChallengeCode = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.signatory.challengeCode
);

export const selectDownloadRecapFile = createSelector(
  selectEscalePro,
  (state: EscaleProState) => state.cardsHistory.downloadRecapFile
);
