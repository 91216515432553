import { Action, createReducer, on } from '@ngrx/store';
import * as EscaleProActions from './escale-pro.actions';
import { initialState, EscaleProState } from './escale-pro.state';
import { CardEscaleProDetailsDTO } from 'src/app/models/CardEscaleProDetailsDTO';
import { CardEscaleProDTO } from 'src/app/models/CardEscaleProDTO';
import { CardRefillElementDto } from 'src/app/models/CardRefillElementDto';
import { CardEpStatus } from 'src/app/models/CardStatusEnum';
import { fetchCardsHistoryFilter } from './escale-pro.actions';

const escaleProReducer = createReducer(
  initialState,
  on(
    EscaleProActions.fetchCardsHistory,
    EscaleProActions.fetchCardsHistoryFilter,
    (state) => {
      return {
        ...state,
        cardsHistory: {
          ...state.cardsHistory,
          cards: {
            result: null,
            callState: {
              error: null,
              isLoaded: false,
              isLoading: true,
              displayMessage: true,
            },
          },
        },
      };
    }
  ),
  on(EscaleProActions.fetchCardsHistorySuccess, (state, { cards }) => {
    return {
      ...state,
      cardsHistory: {
        ...state.cardsHistory,
        cards: {
          result: cards,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(EscaleProActions.fetchCardsHistoryError, (state, { error }) => {
    return {
      ...state,
      cardsHistory: {
        ...state.cardsHistory,
        cards: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(
    EscaleProActions.fetchCards,
    EscaleProActions.fetchCardsFilter,
    (state, { customerNumber }) => {
      return {
        ...state,
        cardsListing: {
          ...state.cardsListing,
          cards: {
            result: null,
            callState: {
              error: null,
              isLoaded: false,
              isLoading: true,
              displayMessage: true,
            },
          },
        },
      };
    }
  ),
  on(EscaleProActions.fetchCardsSuccess, (state, { cards }) => {
    return {
      ...state,
      cardsListing: {
        ...state.cardsListing,
        cards: {
          result: cards,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(EscaleProActions.fetchCardsFailed, (state, { error }) => {
    return {
      ...state,
      cardsListing: {
        ...state.cardsListing,
        cards: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.getCardDetails, (state, args) => {
    return {
      ...state,
      cardsListing: {
        ...state.cardsListing,
        selectedCard: {
          ...state.cardsListing.selectedCard,
          details: {
            result: null,
            callState: {
              error: null,
              isLoaded: false,
              isLoading: true,
              displayMessage: true,
            },
          },
        },
      },
    };
  }),
  on(EscaleProActions.getCardDetailsSuccess, (state, { cardDetails }) => {
    return {
      ...state,
      cardsListing: {
        ...state.cardsListing,
        selectedCard: {
          ...state.cardsListing.selectedCard,
          details: {
            result: cardDetails,
            callState: {
              error: null,
              isLoaded: true,
              isLoading: false,
              displayMessage: false,
            },
          },
        },
      },
    };
  }),
  on(EscaleProActions.getCardDetailsFailed, (state, { error }) => {
    return {
      ...state,
      cardsListing: {
        ...state.cardsListing,
        selectedCard: {
          ...state.cardsListing.selectedCard,
          details: {
            result: null,
            callState: {
              error: error,
              isLoaded: false,
              isLoading: false,
              displayMessage: true,
            },
          },
        },
      },
    };
  }),
  on(EscaleProActions.selectCard, (state, { card }) => {
    return {
      ...state,
      cardsListing: {
        ...state.cardsListing,
        selectedCard: {
          card,
          details: null,
        },
      },
    };
  }),
  on(EscaleProActions.getAccountToBeDebited, (state, args) => {
    return {
      ...state,
      cardsListing: {
        ...state.cardsListing,
        accountToBeDebited: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(
    EscaleProActions.getAccountToBeDebitedSuccess,
    (state, { accountToBeDebited }) => {
      return {
        ...state,
        cardsListing: {
          ...state.cardsListing,
          accountToBeDebited: {
            result: accountToBeDebited,
            callState: {
              error: null,
              isLoaded: true,
              isLoading: false,
              displayMessage: false,
            },
          },
        },
      };
    }
  ),
  on(EscaleProActions.getAccountToBeDebitedFailed, (state, { error }) => {
    return {
      ...state,
      cardsListing: {
        ...state.cardsListing,
        accountToBeDebited: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: false,
            displayMessage: false,
          },
        },
      },
    };
  }),
  on(EscaleProActions.refillCard, (state, args) => {
    return {
      ...state,
      cardsListing: {
        ...state.cardsListing,
        refillCard: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.refillCardSuccess, (state, args) => {
    return {
      ...state,
      cardsListing: {
        ...state.cardsListing,
        refillCard: {
          result: args.resp,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.refillCardFailed, (state, { error }) => {
    return {
      ...state,
      cardsListing: {
        ...state.cardsListing,
        refillCard: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.fetchTrackedRefillRequests, (state, { page, filter }) => {
    return {
      ...state,
      cardsTracking: {
        ...state.cardsTracking,
        refillRequests: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(
    EscaleProActions.fetchTrackedRefillRequestsSuccess,
    (state, { refillRequests }) => {
      return {
        ...state,
        cardsTracking: {
          ...state.cardsTracking,
          refillRequests: {
            result: refillRequests,
            callState: {
              error: null,
              isLoaded: true,
              isLoading: false,
              displayMessage: false,
            },
          },
        },
      };
    }
  ),
  on(EscaleProActions.fetchTrackedRefillRequestsFailed, (state, { error }) => {
    return {
      ...state,
      cardsTracking: {
        ...state.cardsTracking,
        refillRequests: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.cancelRefillRequest, (state, args) => {
    return {
      ...state,
      cardsTracking: {
        ...state.cardsTracking,
        cancelRequest: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.cancelRefillRequestSuccess, (state) => {
    return {
      ...state,
      cardsTracking: {
        ...state.cardsTracking,
        cancelRequest: {
          result: true,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.cancelRefillRequestFailed, (state, { error }) => {
    return {
      ...state,
      cardsTracking: {
        ...state.cardsTracking,
        cancelRequest: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.initCardsListing, (state) => {
    return {
      ...state,
      cardsListing: {
        cards: null,
        selectedCard: {
          card: null,
          details: null,
        },
        accountToBeDebited: null,
        refillCard: null,
      },
    };
  }),

  on(EscaleProActions.fetchEscaleProUserRoles, (state, args) => {
    return {
      ...state,
      userRoles: {
        result: null,
        callState: {
          error: null,
          isLoaded: false,
          isLoading: true,
          displayMessage: true,
        },
      },
    };
  }),
  on(EscaleProActions.fetchEscaleProUserRolesSuccess, (state, args) => {
    return {
      ...state,
      userRoles: {
        result: args.roles,
        callState: {
          error: null,
          isLoaded: true,
          isLoading: false,
          displayMessage: true,
        },
      },
    };
  }),
  on(EscaleProActions.fetchEscaleProUserRolesFailed, (state, { error }) => {
    return {
      ...state,
      userRoles: {
        result: null,
        callState: {
          error: error,
          isLoaded: false,
          isLoading: false,
          displayMessage: true,
        },
      },
    };
  }),

  on(
    EscaleProActions.getEscaleProChallengeCode,
    EscaleProActions.getEscaleProChallengeCodeFromInit,
    (state, args) => {
      return {
        ...state,
        signatory: {
          ...state.signatory,
          challengeCode: {
            result: null,
            callState: {
              error: null,
              isLoaded: false,
              isLoading: true,
              displayMessage: true,
            },
          },
        },
      };
    }
  ),
  on(EscaleProActions.getEscaleProChallengeCodeSuccess, (state, args) => {
    return {
      ...state,
      signatory: {
        ...state.signatory,
        challengeCode: {
          result: args.challengeCode,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.getEscaleProChallengeCodeFailed, (state, { error }) => {
    return {
      ...state,
      signatory: {
        ...state.signatory,
        challengeCode: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),

  on(
    EscaleProActions.signEscaleProRequest,
    EscaleProActions.signEscaleProRequestFromInit,
    (state, args) => {
      return {
        ...state,
        signatory: {
          ...state.signatory,
          signRequest: {
            result: null,
            callState: {
              error: null,
              isLoaded: false,
              isLoading: true,
              displayMessage: true,
            },
          },
        },
      };
    }
  ),
  on(EscaleProActions.signEscaleProRequestSuccess, (state, args) => {
    return {
      ...state,
      signatory: {
        ...state.signatory,
        signRequest: {
          result: true,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.signEscaleProRequestFailed, (state, { error }) => {
    return {
      ...state,
      signatory: {
        ...state.signatory,
        signRequest: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),

  on(EscaleProActions.downloadRecapFile, (state, args) => {
    return {
      ...state,
      cardsHistory: {
        ...state.cardsHistory,
        downloadRecapFile: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.downloadRecapFileSuccess, (state, args) => {
    return {
      ...state,
      cardsHistory: {
        ...state.cardsHistory,
        downloadRecapFile: {
          result: args.file,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.downloadRecapFileFailed, (state, { error }) => {
    return {
      ...state,
      cardsHistory: {
        ...state.cardsHistory,
        downloadRecapFile: {
          result: null,
          callState: {
            error: error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(EscaleProActions.initCardsTracking, (state) => {
    return {
      ...state,
      cardsTracking: {
        refillRequests: null,
        cancelRequest: null,
      },
    };
  }),
  on(EscaleProActions.initCardsHistory, (state) => {
    return {
      ...state,
      cardsHistory: {
        cards: null,
        downloadRecapFile: null,
      },
    };
  }),
  on(
    EscaleProActions.initSignatory,
    EscaleProActions.initSignatoryFromInit,
    (state) => {
      return {
        ...state,
        signatory: {
          signRequest: null,
          challengeCode: null,
        },
      };
    }
  )
);

export function reducer(state: EscaleProState | undefined, action: Action) {
  return escaleProReducer(state, action);
}
