import { createAction, props } from '@ngrx/store';
import { SogeValeurDto } from '../../../../models/SogeValeurDto';

export const initDownloadCallStateData = createAction(
  '[SOGE VALEUR] Init CallState From SogeValeur tab',
  props<{
    customerNumber: string;
  }>()
);

export const fetchSogeValeur = createAction(
  '[SOGE VALEUR] Get soge valeurs from Dashboard',
  props<{
    customerNumber: string;
  }>()
);

export const fetchSogeValeurSuccess = createAction(
  '[SOGE VALEUR] Get soge valeurs from Dashboard Success',
  props<{ sogeValeurs: SogeValeurDto }>()
);

export const fetchSogeValeurError = createAction(
  '[SOGE VALEUR] Get soge valeurs from Dashboard Failed',
  props<{ error: any }>()
);

export const downloadChequesUnpaid = createAction(
  '[SOGE VALEUR] Cheques un paid File From ChequesUnpaid',
  props<{ customerNumber: string }>()
);

export const downloadChequesUnpaidSuccess = createAction(
  '[SOGE VALEUR] Cheques un paid File From ChequesUnpaid Success',
  props<{ file: ArrayBuffer }>()
);

export const downloadChequesUnpaidFailed = createAction(
  '[SOGE VALEUR] Cheques un paid File From ChequesUnpaid Failed',
  props<{ error: any }>()
);

export const downloadChequesToPay = createAction(
  '[SOGE VALEUR] Cheques To Pay File From ChequesToPay',
  props<{ customerNumber: string }>()
);

export const downloadChequesToPaySuccess = createAction(
  '[SOGE VALEUR] Cheques To Pay File From ChequesToPay Success',
  props<{ file: ArrayBuffer }>()
);

export const downloadChequesToPayFailed = createAction(
  '[SOGE VALEUR] Cheques To Pay File From ChequesToPay Failed',
  props<{ error: any }>()
);

export const downloadLcnToPay = createAction(
  '[SOGE VALEUR] Lcn To Pay File From lcnToPay',
  props<{ customerNumber: string }>()
);

export const downloadLcnToPaySuccess = createAction(
  '[SOGE VALEUR] Lcn To Pay File From lcnToPay Success',
  props<{ file: ArrayBuffer }>()
);

export const downloadLcnToPayFailed = createAction(
  '[SOGE VALEUR] Lcn To Pay File From lcnToPay Failed',
  props<{ error: any }>()
);

export const downloadLcnUnpaid = createAction(
  '[SOGE VALEUR] Lcn unpaid File From lcnToUnpaid',
  props<{ customerNumber: string }>()
);

export const downloadLcnUnpaidSuccess = createAction(
  '[SOGE VALEUR] Lcn unpaid File From lcnToUnpaid Success',
  props<{ file: ArrayBuffer }>()
);

export const downloadLcnUnpaidFailed = createAction(
  '[SOGE VALEUR] Lcn unpaid File From lcnToUnpaid Failed',
  props<{ error: any }>()
);

export const downloadAllSv = createAction(
  '[SOGE VALEUR] Soge valeurs file from downloadAll',
  props<{ customerNumber: string }>()
);

export const downloadAllSvSuccess = createAction(
  '[SOGE VALEUR] Soge valeurs file from downloadAll success',
  props<{ file: ArrayBuffer }>()
);

export const downloadAllSvFailed = createAction(
  '[SOGE VALEUR] Soge valeurs file from downloadAll failed',
  props<{ error: any }>()
);
