<app-pdf-viewer *ngIf="showFileViewer" [pdfSrc]="pdfSrc" [pdfReference]="fileReference"
  (closeFileViewer)="showFileViewer = false"></app-pdf-viewer>

<div class="table-wrapper">
  <mat-table [dataSource]="eSignList">
    <ng-container matColumnDef="reference">
      <mat-header-cell *matHeaderCellDef>Référence</mat-header-cell>
      <mat-cell *matCellDef="let envelope">
        <span class="label">Réf de demande</span>
        <span class="value">{{ envelope.envelope.requestId }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="clientName">
      <mat-header-cell *matHeaderCellDef>Nom du Client</mat-header-cell>
      <mat-cell *matCellDef="let envelope">
        <span class="label">Nom du Client</span>
        <span class="value">{{ envelope.clientName }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="advisorName">
      <mat-header-cell *matHeaderCellDef>Nom du Gestionnaire</mat-header-cell>
      <mat-cell *matCellDef="let envelope">
        <span class="label">Nom du Gestionnaire</span>
        <span class="value">{{ envelope.advisorName }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sentAt">
      <mat-header-cell *matHeaderCellDef>Date Envoie</mat-header-cell>
      <mat-cell *matCellDef="let envelope">
        <span class="label">Date Envoie</span>
        <span class="value">{{ envelope.sentAt ? (envelope.sentAt | date : "dd/MM/yyyy") : "-" }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let envelope">
        <span class="label">Status</span>
        <div class="client-status" [style.background-color]="getColor[envelope.envelope.status].bgColor"
          [style.color]="getColor[envelope.envelope.status].color"
          [style.border-left-color]="getColor[envelope.envelope.status].color">
          {{ getColor[envelope.envelope.status].label }}
        </div>
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="signed">
      <mat-header-cell *matHeaderCellDef>Signé</mat-header-cell>
      <mat-cell *matCellDef="let envelope">
        <button [disabled]="envelope.status !== 'PENDING' && envelope.status !== 'IN_PROGRESS'" type="button"
          class="sign-btn" (click)="initEsignatures(envelope.envelope.requestId)">
          <img src="/assets/images/sign-icon.png" alt="sign-menu" />
          <span>SIGNER</span>
        </button>
      </mat-cell>

    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Signé</mat-header-cell>
      <mat-cell *matCellDef="let envelope">

        <button class="action-menu-btn" [matMenuTriggerFor]="menu">
          <img src="/assets/images/dots-menu.svg" alt="dots-menu" />
        </button>
        <mat-menu #menu="matMenu" class="actionsMenu">
          <button mat-menu-item (click)="redirectToSignatoryWithParam(envelope.envelope.requestId, envelope.envelope)">
            <img src="/assets/images/infos-signataires.svg" alt="item-icon" />
            <span>Informations signataires</span>
          </button>
          <button mat-menu-item (click)="viewPdf(envelope.envelope.requestId)">
            <img src="/assets/images/visualiser.svg" alt="item-icon" />
            <span>Visualiser</span>
          </button>
          <button mat-menu-item (click)="downloadPdf(envelope.envelope.requestId,true)">
            <img src="/assets/images/telecharger.svg" alt="item-icon" />
            <span>Télécharger</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>


    <div *ngIf="status==='PENDING'">

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </div>
    <div *ngIf="status==='HISTORIQUE'">

      <mat-header-row *matHeaderRowDef="displayedColumnsHistorique"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsHistorique"></mat-row>
    </div>
  </mat-table>
</div>

<app-pagination [page]="page" (getPage)="fetchData()"></app-pagination>