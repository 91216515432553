import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SellCardListComponent } from './sell-card-list/sell-card-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from '../shared-module/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxSelectModule } from 'ngx-select-ex';
import { SellCardRoutingModule } from './sell-card-routing.module';
import { SellCardPersonalInfoComponent } from './sell-card-list/sell-card-personal-info/sell-card-personal-info.component';
import { SellCardContactInfoComponent } from './sell-card-list/sell-card-contact-info/sell-card-contact-info.component';
import { SellCardCeilingInfoComponent } from './sell-card-list/sell-card-ceiling-info/sell-card-ceiling-info.component';
import { SellCardDataComponent } from './sell-card-list/sell-card-data/sell-card-data.component';
import { SellCardStepperComponent } from './sell-card-list/sell-card-stepper/sell-card-stepper.component';
import { SellCardAccountComponent } from './sell-card-list/sell-card-account/sell-card-account.component';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { DecimalPipe } from '@angular/common';
import { SellCardCellingFormComponent } from './sell-card-list/sell-card-ceiling-info/sell-card-celling-form/sell-card-celling-form.component';
import { FormatNumberDirective } from './sell-card-list/format-number/format-number.directive';
@NgModule({
  providers: [DecimalPipe],
  declarations: [
    SellCardListComponent,
    SellCardPersonalInfoComponent,
    SellCardContactInfoComponent,
    SellCardCeilingInfoComponent,
    SellCardDataComponent,
    SellCardStepperComponent,
    FormatNumberDirective,
    SellCardAccountComponent,
    SellCardCellingFormComponent,
    FormatNumberDirective,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    MatNativeDateModule,
    FormsModule,
    NgxSelectModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    SellCardRoutingModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
})
export class SellCardModule {}
