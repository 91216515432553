import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as SogeValeurActions from './soge-valeur.actions';

import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {SogeValeurService} from '../../../../service/soge-valeur.service';
import * as EscaleProActions from '../escale-pro/escale-pro.actions';

@Injectable()
export class SogeValeurEffects {

  constructor(
    private actions$: Actions<any>,
    private sogeValeurService: SogeValeurService,
  ) {}

  fetchSogeValeur$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SogeValeurActions.fetchSogeValeur),
      switchMap((action) =>
        this.sogeValeurService
          .getSogeValeur(action.customerNumber)
          .pipe(
            map((sogeValeurs) => {
              return SogeValeurActions.fetchSogeValeurSuccess({sogeValeurs});}),
            catchError((error) =>
              of(SogeValeurActions.fetchSogeValeurError({ error })),
            ),
          ),
      ),
    ),
  );

  downloadChequesUnpaid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SogeValeurActions.downloadChequesUnpaid),
      switchMap((action) =>
        this.sogeValeurService
          .downloadChequesUnpaid(action.customerNumber)
          .pipe(
            map((file) => {
              return SogeValeurActions.downloadChequesUnpaidSuccess({ file });
            }),
            catchError((error) =>
              of(
                SogeValeurActions.downloadChequesUnpaidFailed({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  downloadChequesToPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SogeValeurActions.downloadChequesToPay),
      switchMap((action) =>
        this.sogeValeurService
          .downloadChequesToPay(action.customerNumber)
          .pipe(
            map((file) => {
              return SogeValeurActions.downloadChequesToPaySuccess({ file });
            }),
            catchError((error) =>
              of(
                SogeValeurActions.downloadChequesToPayFailed({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  downloadAllSv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SogeValeurActions.downloadAllSv),
      switchMap((action) =>
        this.sogeValeurService
          .downloadAllSv(action.customerNumber)
          .pipe(
            map((file) => {
              return SogeValeurActions.downloadAllSvSuccess({ file });
            }),
            catchError((error) =>
              of(
                SogeValeurActions.downloadAllSvFailed({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  downloadLcnToPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SogeValeurActions.downloadLcnToPay),
      switchMap((action) =>
        this.sogeValeurService
          .downloadLcnToPay(action.customerNumber)
          .pipe(
            map((file) => {
              return SogeValeurActions.downloadLcnToPaySuccess({ file });
            }),
            catchError((error) =>
              of(
                SogeValeurActions.downloadLcnToPayFailed({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  downloadLcnUnpaid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SogeValeurActions.downloadLcnUnpaid),
      switchMap((action) =>
        this.sogeValeurService
          .downloadLcnUnpaid(action.customerNumber)
          .pipe(
            map((file) => {
              return SogeValeurActions.downloadLcnUnpaidSuccess({ file });
            }),
            catchError((error) =>
              of(
                SogeValeurActions.downloadLcnUnpaidFailed({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

}
