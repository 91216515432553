export class CardSearchRequestDTO {
  firstName: string;
  lastName: string;
  minBalance: number;
  maxBalance: number;
  startDate: Date;
  endDate: Date;
  expiryDate: Date;

  constructor(
    firstName: string,
    lastName: string,
    minBalance: number,
    maxBalance: number,
    startDate: Date,
    endDate: Date,
    expiryDate: Date
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.minBalance = minBalance;
    this.maxBalance = maxBalance;
    this.startDate = startDate;
    this.endDate = endDate;
    this.expiryDate = expiryDate;
  }
}
