import { Component, OnInit, OnDestroy } from '@angular/core';
import { CardRefillElementDto } from '../../../models/CardRefillElementDto';
import {
  downloadRecapFile,
  fetchCardsFilter,
  fetchCardsHistory,
  fetchCardsHistoryFilter,
  initCardsHistory,
  selectCardsHistory,
  selectDownloadRecapFile,
} from '../../../core/store/state/escale-pro';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store/state';
import { Subscription } from 'rxjs';
import { selectSelectedCustomerNumber } from '../../../core/store/state/user';
import { Page } from '../../../models/page';
import { CardRefillSearchRequestDto } from '../../../models/CardRefillSearchRequestDto';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import {
  CardEpStatus,
  mappingCardEpStatus,
} from '../../../models/CardStatusEnum';
import { CardFillElementDtoList } from '../../../models/CardFillElementDtoList';
import { EpCardFilterComponent } from '../ep-card-filter/ep-card-filter.component';
import { MatDialog } from '@angular/material/dialog';
import { EpCardTrackingFilterComponent } from '../ep-card-tracking-filter/ep-card-tracking-filter.component';
import { CallStateResponse, handleCallState } from 'src/app/models/CallState';

@Component({
  selector: 'app-ep-history',
  templateUrl: './ep-history.component.html',
  styleUrls: ['./ep-history.component.scss'],
})
export class EpHistoryComponent implements OnInit {
  url: SafeUrl;
  cards: Array<CardRefillElementDto>;
  cardSelected: CardRefillElementDto;
  selectedCustomerNumberSub: Subscription;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber;
  selectCardsSub: Subscription;
  page = new Page();
  filter: CardRefillSearchRequestDto;
  selectCardsHistory$ = this.store.select(selectCardsHistory);
  historyCardList: CardFillElementDtoList;
  protected readonly CardEpStatus = CardEpStatus;
  showLoader = true;
  selectDownloadRecapFileSub: Subscription;
  selectDownloadRecapFile$ = this.store.select(selectDownloadRecapFile);
  handleCallStateCardsHistory: CallStateResponse;
  handleCallStateDownloadRecapFile: CallStateResponse;
  displayError: boolean;

  uploadDefaultMessageError =
    'Le service est indisponible,  merci de réessayer ultérieurement';
  uploadDefaultMessageSuccess = 'Opération terminée avec succès';
  openPopinMessage = this.uploadDefaultMessageError;
  openPopinMessageSuccess = false;

  constructor(
    private store: Store<State>,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {
    this.page.init();
  }

  initFilter() {
    this.cardSelected = null;
    this.filter = new CardRefillSearchRequestDto(
      this.selectedCustomerNumber,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [
        CardEpStatus.COMPLETED,
        CardEpStatus.CANCELED,
        CardEpStatus.EXECUTED,
        CardEpStatus.REJECTED,
      ]
    );
  }

  ngOnInit(): void {
    this.initHandleCallState();

    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
          this.initFilter();
          this.store.dispatch(
            fetchCardsHistory({
              page: this.page.currentPage,
              filter: this.filter,
            })
          );
        }
      }
    );

    this.selectCardsSub = this.selectCardsHistory$.subscribe((cardsHistory) => {
      if (cardsHistory && cardsHistory.result) {
        this.historyCardList = cardsHistory.result;
        if (this.historyCardList.contentList.length > 0) {
          this.page.totalPages = this.historyCardList.totalPages;
          this.page.totalElements = this.historyCardList.totalElements;
        }
      }
      this.handleCallStateCardsHistory = handleCallState(cardsHistory);
      this.displayError = this.getDisplayError();
      this.showLoader = this.getShowLoader();
    });
    this.selectDownloadRecapFileSub = this.selectDownloadRecapFile$.subscribe(
      (file) => {
        if (file && file.result) {
          const arrayBuffer = file.result;
          this.downLoadFilePdf(
            arrayBuffer,
            'application/pdf',
            this.cardSelected.beneficiaryFullName +
              this.cardSelected.operationDate
          );
        }

        this.handleCallStateDownloadRecapFile = handleCallState(file);
        this.displayError = this.getDisplayError();
        this.showLoader = this.getShowLoader();
      }
    );
  }

  downLoadFilePdf(data: ArrayBuffer, type: string, fileName: string) {
    const blob = new Blob([data], { type: type });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + '.pdf';

    link.click();

    window.URL.revokeObjectURL(link.href);
  }
  getCurrentPageCards(): void {
    this.store.dispatch(
      fetchCardsHistory({
        page: this.page.currentPage,
        filter: this.filter,
      })
    );
  }

  mappingEpCardStatus(signatureStatus): string {
    return mappingCardEpStatus(signatureStatus);
  }

  ngOnDestroy(): void {
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectCardsSub.unsubscribe();
    this.selectDownloadRecapFileSub.unsubscribe();
    this.store.dispatch(initCardsHistory());
  }

  openFilterPopup(): void {
    const dialogRef = this.dialog.open(EpCardTrackingFilterComponent, {
      data: {
        defaultStatus: [
          CardEpStatus.COMPLETED,
          CardEpStatus.CANCELED,
          CardEpStatus.EXECUTED,
          CardEpStatus.REJECTED,
        ],
        filter: this.filter,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filter = result;
        this.filter.customerNumber = this.selectedCustomerNumber;
        this.page.init();
        this.store.dispatch(
          fetchCardsHistoryFilter({
            page: this.page.currentPage,
            filter: this.filter,
          })
        );
      }
    });
  }

  dowloadFile(id: number, card: CardRefillElementDto) {
    this.cardSelected = card;
    this.store.dispatch(
      downloadRecapFile({
        customerNumber: this.selectedCustomerNumber,
        requestId: id,
      })
    );
  }

  getShowLoader(): boolean {
    let showLoader =
      (this.handleCallStateCardsHistory.showLoader ||
        this.handleCallStateDownloadRecapFile.showLoader) &&
      !this.displayError;

    return showLoader;
  }

  getDisplayError(): boolean {
    let displayError =
      this.handleCallStateCardsHistory.displayError ||
      this.handleCallStateDownloadRecapFile.displayError;

    if (displayError) {
      if (this.handleCallStateCardsHistory.displayError) {
        this.openPopinMessage =
          this.handleCallStateCardsHistory.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateCardsHistory.openPopinMessageSuccess;
        this.handleCallStateCardsHistory.displayError = false;
      }
      if (this.handleCallStateDownloadRecapFile.displayError) {
        this.openPopinMessage =
          this.handleCallStateDownloadRecapFile.openPopinMessage;
        this.openPopinMessageSuccess =
          this.handleCallStateDownloadRecapFile.openPopinMessageSuccess;
        this.handleCallStateDownloadRecapFile.displayError = false;
      }
    }
    return displayError;
  }
  initHandleCallState() {
    this.handleCallStateCardsHistory = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
    this.handleCallStateDownloadRecapFile = {
      displayError: false,
      showLoader: false,
      openPopinMessage: '',
      openPopinMessageSuccess: false,
    };
  }

  closePopin() {
    this.cardSelected = null;
    this.displayError = false;
  }

  countFilterRes(): string {
    return (this.filter.firstName ||
      this.filter.lastName ||
      this.filter.startOperationDate ||
      this.filter.endOperationDate ||
      this.filter.signatureStatus.length == 1) &&
      this.page.totalElements
      ? this.page.totalElements.toString()
      : '';
  }
}
