import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ClientService } from 'src/app/service/client.service';
import { UserClientConfigService } from 'src/app/service/user-client-config.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { GenericGuard } from 'src/app/core/auth-utils/guards/GenericGuard';

import {
  appLoaded,
  selectConnectedUser,
  selectSelectedCustomerNumber,
} from 'src/app/core/store/state/user';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root',
})
export class BalanceSheetGuard extends GenericGuard {
  selectConnectedUser$ = this.store.select(selectConnectedUser);
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  user: User;
  selectedCustomerNumberSub: any;

  constructor(
    private userClientConfigService: UserClientConfigService,
    private clientService: ClientService,
    private router: Router,
    store: Store<State>
  ) {
    super(store);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    resolve: any,
    reject: any
  ) {
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        this.userClientConfigService
          .balanceSheetCheckPopUp(customerNumber, false)
          .subscribe({
            next: (value: boolean) => {
              if (!value) this.router.navigate(['/']);
              resolve(value);
            },
            error: (error) => {
              this.router.navigate(['/']);
              reject(false);
            },
          });
        if (this.selectedCustomerNumberSub !== undefined)
          this.selectedCustomerNumberSub.unsubscribe();
      }
    );
  }

  /*canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return new Promise((resolve, reject) => {
      this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
        (customerNumber) => {
          if (customerNumber) {
            this.userClientConfigService
              .balanceSheetCheckPopUp(customerNumber, false)
              .subscribe({
                next: (value: boolean) => {
                  if (!value) this.router.navigate(['/']);
                  resolve(value);
                },
                error: (error) => {
                  this.router.navigate(['/']);
                  reject(false);
                },
              });
            if (this.selectedCustomerNumberSub !== undefined)
              this.selectedCustomerNumberSub.unsubscribe();
          } else {
            this.store.dispatch(appLoaded());
          }
        }
      );
    });
  }*/
}
