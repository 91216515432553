import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CardConsultSearchRequestDTO } from 'src/app/models/CardConsultSearchRequestDTO';
import { CardType } from 'src/app/models/CardTypeEnum';
import { ConsultCardSearchRequestDTO } from 'src/app/models/ConsultCardSearchRequestDTO';

@Component({
  selector: 'app-consult-cards-filter',
  templateUrl: './consult-cards-filter.component.html',
  styleUrls: ['./consult-cards-filter.component.scss'],
})
export class ConsultCardsFilterComponent implements OnInit {
  firstName: string;
  lastName: string;
  cardsType: Array<CardType>;
  cardsTypeInitVal: Array<CardType>;
  filter: CardConsultSearchRequestDTO;
  CardTypeEnum = CardType;

  constructor(
    public dialogRef: MatDialogRef<ConsultCardsFilterComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: { filter: CardConsultSearchRequestDTO }
  ) {
    this.filter = data.filter;
    this.cardsTypeInitVal = Object.values(CardType);
  }

  ngOnInit() {
    this.firstName = this.filter.firstName || '';
    this.lastName = this.filter.lastName || '';
    this.cardsType = this.filter.cardsType || new Array<CardType>();
  }

  applyFilter(): void {
    this.filter.firstName = this.firstName;
    this.filter.lastName = this.lastName;
    this.filter.cardsType = this.cardsType;
    this.dialogRef.close(this.filter);
  }

  reinitForm(): void {
    this.firstName = '';
    this.lastName = '';
    this.cardsType = new Array<CardType>();
  }

  close(): void {
    this.applyFilter();
    //this.dialogRef.close();
  }

  cancelFilter() {
    this.reinitForm();
    this.applyFilter();
  }

  toogleCardType(cardType: CardType) {
    const index = this.cardsType.indexOf(cardType, 0);
    if (index > -1) {
      this.cardsType.splice(index, 1);
    } else {
      this.cardsType.push(cardType);
    }
  }
}
