import {ChequeNotPaidDTO} from './ChequeNotPaidDTO';
import {ChequeToPayDTO} from './ChequeToPayDTO';
import {LcnNotPaidDTO} from './LcnNotPaidDTO';
import {LcnToPayDTO} from './LcnToPayDTO';

export class SogeValeurDto {
  chequesToPay: ChequesToPayResponse;
  lcnsToPay: LcnToPayResponse;
  chequesNotPaid: ChequesNotPaidResponse;
  lcnsNotPaid: LcnNotPaidResponse;

  constructor(chequesToPay: ChequesToPayResponse, lcnsToPay: LcnToPayResponse, chequesNotPaid: ChequesNotPaidResponse, lcnsNotPaid: LcnNotPaidResponse) {
    this.chequesToPay = chequesToPay;
    this.lcnsToPay = lcnsToPay;
    this.chequesNotPaid = chequesNotPaid;
    this.lcnsNotPaid = lcnsNotPaid;
  }
}
export class ChequesToPayResponse {
  cheques: ChequeToPayDTO[];
  total: number;

  constructor(cheques: ChequeToPayDTO[], totalChequesPayer: number) {
    this.cheques = cheques;
    this.total = totalChequesPayer;
  }
}

export class ChequesNotPaidResponse {
  cheques: ChequeNotPaidDTO[];
  total: number;

  constructor(cheques: ChequeNotPaidDTO[], totalChequesImpayes: number) {
    this.cheques = cheques;
    this.total = totalChequesImpayes;
  }
}

export class LcnToPayResponse {
  lcns: LcnToPayDTO[];
  total: number;

  constructor(effets: LcnToPayDTO[], totalEffetsPayer: number) {
    this.lcns = effets;
    this.total = totalEffetsPayer;
  }
}


export class LcnNotPaidResponse {
  lcns: LcnNotPaidDTO[];
  total: number;

  constructor(effets: LcnNotPaidDTO[], totalEffetsImpayes: number) {
    this.lcns = effets;
    this.total = totalEffetsImpayes;
  }
}
