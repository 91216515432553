import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { Operator } from 'src/app/models/operator';
import { OperatorService } from 'src/app/service/operator.service';
import { UserService } from 'src/app/service/user.service';
import { Cards } from 'src/app/models/cards';
import { CardPlafonds } from '../sell-card-enum/card_plafonds';
@Component({
  selector: 'app-sell-card-contact-info',
  templateUrl: './sell-card-contact-info.component.html',
  styleUrls: ['./sell-card-contact-info.component.scss'],
})
export class SellCardContactInfoComponent implements OnInit {
  @Input() userLoaded: boolean = false;
  step: number = 4;
  @Output() stepEvent = new EventEmitter<number>();
  @Output() formContactData = new EventEmitter<any>();
  @Input() contactUpdate: any;
  @Output() closedPopine = new EventEmitter<Boolean>();
  @Input() cardSelected: Cards;
  closed: boolean = true;
  activateCss: Boolean;
  indexs: string;
  contact = { email: '', phoneNumber: '', operator: '' };
  public operators: Operator[];
  public operatorSelect: Operator;
  contactsfill: boolean = false;
  title = 'Demander Pour un Bénéficaire';
  @Input() isPopupVisible: boolean = true;
  constructor(
    private userService: UserService,
    private operatorService: OperatorService
  ) {}

  ngOnInit() {
    let operator = new Operator();
    this.activateCss = true;
    operator.code = 'more';
    operator.libelle = 'Autre Opérateur';
    this.operatorService.getOperators().subscribe((operators) => {
      this.operators = operators;
      this.operators.push(operator);
      operators.forEach((op) => {
        op.icone =
          '/assets/images/softToken/ic_op_' + op.libelle.toLowerCase() + '.svg';
        if (op.code == 'more') {
          op.icone = '/assets/images/softToken/ic_op_more.svg';
        }
      });
    });
    if (this.userLoaded) {
      this.title = 'Demander Pour  Moi meme';
      this.loadUser();
    }
    if (this.contactUpdate != null && this.contactUpdate != undefined) {
      this.contact = this.contactUpdate;
    }
  }
  loadUser() {
    this.userService.getUser().subscribe((user) => {
      this.contact.email = user.email;
      this.contact.phoneNumber = user.phoneNumber;
    });
  }
  send() {
    if (this.operatorSelect != null && this.operatorSelect != undefined) {
      this.contact.operator = this.operatorSelect.libelle;
    }
    if (
      this.cardSelected != null &&
      this.cardSelected != undefined &&
      this.cardSelected.alias === CardPlafonds.Deposit
    ) {
      this.step = 5;
    }
    this.stepEvent.emit(this.step);
    this.formContactData.emit(this.contact);
  }
  closePopup() {
    this.stepEvent.emit(0);
  }

  selectOperator(value: Operator, index: string) {
    this.activateCss = false;

    this.indexs = index;
    this.operatorSelect = value;
  }
  receiveStatusPopin(closedPopin: boolean) {
    this.closedPopine.emit(closedPopin);
  }
  ngOnChanges(changes: SimpleChanges) {
    if ('userLoaded' in changes) {
      if (this.userLoaded) {
        this.title = 'Demander Pour  Moi meme';

        this.loadUser();
      }
      if (!this.userLoaded) {
        this.contact.email = null;
        this.contact.phoneNumber = null;
        this.operatorSelect = null;
      }
    }
  }
}
