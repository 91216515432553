import { CardEpStatus } from './CardStatusEnum';
export class CardRefillSearchRequestDto {
  customerNumber: string;
  firstName: string;
  lastName: string;
  minBalance: number;
  maxBalance: number;
  startOperationDate: Date;
  endOperationDate: Date;
  expiryDate: Date;
  signatureStatus: Array<CardEpStatus>;

  constructor(
    customerNumber: string,
    firstName: string,
    lastName: string,
    minBalance: number,
    maxBalance: number,
    startOperationDate: Date,
    endOperationDate: Date,
    expiryDate: Date,
    signatureStatus: Array<CardEpStatus>
  ) {
    this.customerNumber = customerNumber;
    this.firstName = firstName;
    this.lastName = lastName;
    this.minBalance = minBalance;
    this.maxBalance = maxBalance;
    this.startOperationDate = startOperationDate;
    this.endOperationDate = endOperationDate;
    this.expiryDate = expiryDate;
    this.signatureStatus = signatureStatus;
  }
}
