import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EscaleProState, selectEscalePro } from '../escale-pro';
import { SogeValeurState } from './soge-valeur.state';

export const selectSogeValeur =
  createFeatureSelector<SogeValeurState>('sogeValeur');

export const selectSogeValeurListing = createSelector(
  selectSogeValeur,
  (state: SogeValeurState) => state.sogeValeursListing.sogeValeurs
);

export const selectChequePayerFromListing = createSelector(
  selectSogeValeur,
  (state: SogeValeurState) => state.chequesToPayListing.chequesToPay
);

export const selectChequeImpayeFromListing = createSelector(
  selectSogeValeur,
  (state: SogeValeurState) => state.chequesNotPaidListing.chequesNotPaid
);

export const selectEffetImpayeFromListing = createSelector(
  selectSogeValeur,
  (state: SogeValeurState) => state.lcnNotPaidListing.lcnNotPaid
);

export const selectEffetPayerFromListing = createSelector(
  selectSogeValeur,
  (state: SogeValeurState) => state.lcnToPayListing.lcnToPay
);

export const selectDownloadChequesUnpaid = createSelector(
  selectSogeValeur,
  (state: SogeValeurState) => state.chequesNotPaidListing.downloadChequesUnpaid
);
export const selectDownloadChequesToPay = createSelector(
  selectSogeValeur,
  (state: SogeValeurState) => state.chequesToPayListing.downloadChequesToPay
);
export const selectDownloadLcnUnpaid = createSelector(
  selectSogeValeur,
  (state: SogeValeurState) => state.lcnNotPaidListing.downloadLcnUnpaid
);
export const selectDownloadLcnToPay = createSelector(
  selectSogeValeur,
  (state: SogeValeurState) => state.lcnToPayListing.downloadLcnToPay
);

export const selectDownloadAllSv = createSelector(
  selectSogeValeur,
  (state: SogeValeurState) => state.downloadAllSv
);
