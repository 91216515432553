import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../service/user.service';
import { Roles } from '../../../utils/roles';
import { ClientService } from '../../../service/client.service';
import { Client } from '../../../models/client';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import {
  selectConnectedUser,
  selectUserClients,
  selectSelectedCustomerNumber,
} from 'src/app/core/store/state/user';
import { User } from 'src/app/models/user';
import { map, switchMap, skip } from 'rxjs/operators';

@Component({
  selector: 'app-transfer-dashboard',
  templateUrl: './transfer-dashboard.component.html',
  styleUrls: ['./transfer-dashboard.component.scss'],
})
export class TransferDashboardComponent implements OnInit {
  showSignedTab = false;
  showToSignTab = false;
  tabActive;
  isDataLoaded = false;
  client: Client;
  selectConnectedUser$ = this.store.select(selectConnectedUser);
  user: User;
  selectUserClients$ = this.store.select(selectUserClients);
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);

  constructor(
    private userService: UserService,
    private clientService: ClientService,
    private store: Store<State>
  ) {
    this.tabActive = 'TO-SIGN';
  }

  ngOnInit(): void {
    this.selectConnectedUser$.subscribe((user) => (this.user = user));
    this.selectedCustomerNumber$
      .pipe(
        switchMap((customerNumber) =>
          this.selectUserClients$.pipe(
            map((clients) => [customerNumber, clients])
          )
        )
      )
      .subscribe(([customerNumber, clients]) => {
        let selectedCustomerNumber = '';
        if (customerNumber) {
          selectedCustomerNumber = customerNumber;
          this.client = null;
          clients.forEach((client) => {
            if (client.customerNumber === selectedCustomerNumber) {
              this.getCurrentUserRoles(client.id);
              this.client = client;
              this.isDataLoaded = true;
            }
          });
        }
      });
  }

  getCurrentUserRoles(clientId): void {
    this.userService
      .getUserRoles(clientId, this.user.username)
      .toPromise()
      .then((result) => {
        this.showToSignTab =
          Roles.hasRole(result, Roles.OTHER_CHANEL_CONSULTANT) ||
          Roles.hasRole(result, Roles.OTHER_CHANEL_VALIDATOR);
        this.showSignedTab =
          Roles.hasRole(result, Roles.OTHER_CHANEL_CONSULTANT) ||
          Roles.hasRole(result, Roles.OTHER_CHANEL_VALIDATOR);
      });
  }

  selectTab(tabLabel: string): void {
    this.tabActive = tabLabel;
  }

  isActive(tabLabel: string): boolean {
    return (
      this.tabActive === tabLabel &&
      this.client !== undefined &&
      this.client != null
    );
  }
}
