<div class="transfer-container">
  <h2 class="title-heading">E-Sign</h2>
  <div class="tabs-container">
    <div class="tabs-header">
      <button class="tab-item" (click)="selectTab('PENDING')" [class.active]="isActive('PENDING')">
        Suivi des Envelopes
        <span>{{countingPending}}</span>
      </button>
      <ng-container>
        <button class="tab-item" (click)="selectTab('HISTORIQUE')" [class.active]="isActive('HISTORIQUE')">
          HISTORIQUE DES ENVELOPPES
          <span>{{countingHistoric}}</span>
        </button>
      </ng-container>
    </div>
    <div class="tabs-content">
      <app-e-sing-list [status]="statusPENDING" *ngIf="isActive('PENDING')"></app-e-sing-list>
      <app-e-sing-list [status]="statusHistorique" *ngIf="isActive('HISTORIQUE')"></app-e-sing-list>
    </div>
  </div>
</div>