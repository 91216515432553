import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Page } from '../../../models/page';
import { SignatureChallengeAndDresponse } from '../../../models/signatureChallengeAndDresponse';
import { UserRolesDTO } from '../../../models/UserRolesDto';
import { ExternalTransferService } from '../../../service/external-transfer.service';
import { UserService } from '../../../service/user.service';
import { DialogSignComponent } from '../../shared-module/dialog-sign/dialog-sign.component';
import { SignMessages } from '../../shared-module/dialog-sign/signMessages';
import { Roles } from '../../../utils/roles';
import { GetFileRequestDto } from '../models/getFileRequestDto';
import { GetFileResponseDto } from '../models/getFileResponseDto';
import { ExternalTransferClient } from '../models/externalTransferClient';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import {
  selectConnectedUser,
  selectSelectedCustomerNumber,
} from 'src/app/core/store/state/user';
import { User } from 'src/app/models/user';
import { ChallengeService } from 'src/app/service/challenge.service';

@Component({
  selector: 'app-to-sign',
  templateUrl: './to-sign.component.html',
  styleUrls: ['./to-sign.component.scss'],
})
export class ToSignComponent implements OnInit {
  files: Array<GetFileResponseDto>;
  page = new Page();
  fileProcessRequest: GetFileRequestDto = new GetFileRequestDto();
  searchCriteria: string;
  noFilesFound;
  userCanSign;
  signingOrderId = null;
  showSignatureForm = false;
  signMessages: SignMessages;
  @ViewChild(DialogSignComponent) signingDialog: DialogSignComponent;
  @Input() client: ExternalTransferClient;
  selectConnectedUser$ = this.store.select(selectConnectedUser);
  user: User;

  uploadDefaultMessageError =
    'Le service est indisponible,  merci de réessayer ultérieurement';
  openPopinMessage = this.uploadDefaultMessageError;
  openPopinMessageFlag = false;
  openPopinSignatureFlag = false;
  selectedFileId = '';
  openPopinMessageSuccess = false;
  challengeCode = '';
  challengeKey = '';
  isPendingRefreshChalCodBtn = false;
  isSignaturePending = false;
  canceldMessageError = false;
  openCancelPopinConfirmFlag = false;
  selectedFile: GetFileResponseDto;
  confirmCancelMessage: string;
  openPopinMessageError =
    'Le service est indisponible,  merci de réessayer ultérieurement';
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectedCustomerNumberSub: any;

  constructor(
    private externalTransfer: ExternalTransferService,
    private userService: UserService,
    private store: Store<State>,
    private challengeService: ChallengeService
  ) {
    this.page.init();
    this.fileProcessRequest.reference = '';
    this.signMessages = new SignMessages('Sign order', null, null);
  }

  ngOnInit(): void {
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
        }
      }
    );

    if (this.client) {
      this.getCurrentPageTransfers();
      this.selectConnectedUser$.subscribe((user) => (this.user = user));
      this.userService
        .getUserRoles(this.client.id, this.user.username)
        .toPromise()
        .then((result) => {
          this.userCanSign = this.hasRole(result, Roles.OTHER_CHANEL_VALIDATOR);
        });
    } else {
      this.files = [];
    }
  }

  private hasRole(result: UserRolesDTO, role: string): boolean {
    return result.userRoles.indexOf(role) !== -1;
  }

  getCurrentPageTransfers(): void {
    this.externalTransfer
      .getFilesWithInProgressSignature(
        this.client.id,
        this.page.currentPage,
        this.fileProcessRequest
      )
      .toPromise()
      .then((result) => {
        this.files = result.contentList;
        this.page.totalPages = result.totalPages;
        this.page.totalElements = result.totalElements;
        this.noFilesFound =
          result.contentList == null || result.contentList.length <= 0;
      })
      .catch(() => {});
  }

  searchFileProcess(): void {
    this.page.currentPage = 0;
    this.fileProcessRequest.reference = this.searchCriteria;
    this.getCurrentPageTransfers();
  }

  signOrder(data: SignatureChallengeAndDresponse): void {
    this.externalTransfer
      .signTransfer(
        this.client.id,
        this.signingOrderId,
        data.challengeKey,
        data.dResponse
      )
      .toPromise()
      .then((result) => {
        this.signingOrderId = null;
        this.closeSignaturePopin();
        this.openPopinMessageSuccess = true;
        this.openPopinMessageFlag = true;
        this.openPopinMessage = 'Votre signature a été prise en compte';
        this.getCurrentPageTransfers();
      })
      .catch((error) => {
        this.closeSignaturePopin();
        this.openPopinMessageSuccess = false;
        this.openPopinMessageFlag = true;
        if (error.status == 404) {
          this.openPopinMessage = error.error.message;
        } else {
          this.openPopinMessage = this.uploadDefaultMessageError;
        }
      })
      .finally(() => (this.isSignaturePending = false));
  }

  cancelValidation(): void {
    this.signingOrderId = null;
    this.showSignatureForm = false;
    this.signMessages.signatureErrorMessage = null;
  }

  startSigningOrder(id): void {
    this.signingOrderId = id;
    this.openPopinSignatureFlag = true;
    this.refreshChallengeCode();
  }

  startCancelOrder(id): void {
    this.signingOrderId = id;
    this.openCancelPopinConfirmFlag = true;
  }

  closeCancelConfirmPopin(res: boolean) {
    this.canceldMessageError = false;
    this.openCancelPopinConfirmFlag = false;
    if (res) {
      this.externalTransfer
        .cancelOrder(this.signingOrderId, this.selectedCustomerNumber)
        .toPromise()
        .then(() => {
          this.signingOrderId = null;
          this.openPopinMessageSuccess = true;
          this.openPopinMessageFlag = true;
          this.openPopinMessage = 'Opération terminée avec succès';
          this.getCurrentPageTransfers();
        })
        .catch((error) => {
          this.openPopinMessageFlag = true;
          this.openPopinMessage = this.openPopinMessageError;
        });
    }
  }

  //****************************************************************************** */
  closeSignaturePopin() {
    this.openPopinSignatureFlag = false;
    this.signingOrderId = '';
  }

  refreshChallengeCode() {
    if (!this.isPendingRefreshChalCodBtn) {
      this.isPendingRefreshChalCodBtn = true;
      this.challengeService
        .getChallenge()
        .toPromise()
        .then((result) => {
          if (result.challenge) {
            this.challengeCode = result.challenge;
            this.challengeKey = result.challengeKey;
            this.openPopinSignatureFlag = true;
          } else {
            this.closeSignaturePopin();
            this.openPopinMessageSuccess = false;
            this.openPopinMessage = this.uploadDefaultMessageError;
            this.openPopinMessageFlag = true;
          }
        })
        .catch((error) => {
          this.closeSignaturePopin();
          this.openPopinMessageSuccess = false;
          this.openPopinMessageFlag = true;
          if (error.status == 400) {
            this.openPopinMessage = error.error.message;
          } else {
            this.openPopinMessage = this.uploadDefaultMessageError;
          }
        })
        .finally(() => (this.isPendingRefreshChalCodBtn = false));
    }
  }

  signHandler(data: SignatureChallengeAndDresponse) {
    if (!this.isSignaturePending) {
      this.signOrder(data);
    }
  }

  closePopin() {
    this.openPopinMessageFlag = false;
    this.getCurrentPageTransfers();
  }
}
