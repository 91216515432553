import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-elem',
  templateUrl: './news-elem.component.html',
  styleUrls: ['./news-elem.component.scss'],
})
export class NewsElemComponent implements OnInit {
  @Input() image: string;
  @Input() headerTitle: string;
  @Input() description: string;

  @Input() link: string;
  @Input() cssClass: string = '';

  constructor(private router: Router) {}

  ngOnInit() {}

  redirect(redirection) {
    let url = this.router.createUrlTree([redirection]);
    window.open(url.toString(), '_blank');
  }
}
