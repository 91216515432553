<div class="main-popin-container show" *ngIf="openPopinFlag">
  <div class="popin-content-body small-popin" *ngIf="mode != 'escal-pro'">
    <button
      *ngIf="mode != 'loader'"
      type="button"
      class="close-btn"
      (click)="closeTab()"
    >
      x
    </button>
    <div class="popin-container">
      <div *ngIf="mode == 'loader'; else message_template">
        <img
          class="popin-image"
          src="/assets/images/Ellipsis-2s-80px.gif"
          alt=""
        />
      </div>
      <ng-template #message_template>
        <img
          *ngIf="success; else error_template"
          class="popin-image"
          src="/assets/images/message-ok.png"
          alt=""
        />
        <ng-template #error_template
          ><img class="popin-image" src="/assets/images/message-ko.png" alt=""
        /></ng-template>
      </ng-template>

      <div class="message">
        <p>{{ message }}</p>
      </div>
    </div>
  </div>
  <div class="popin-container-escal-pro" *ngIf="mode === 'escal-pro'">
    <button type="button" class="close-btn-escal" (click)="closeTab()">
      <img src="assets/images/close-white.svg" alt="" />
    </button>
    <img
      *ngIf="success; else error_template"
      class="popin-image-escal"
      src="/assets/images/success.svg"
      alt=""
    />
    <ng-template #error_template
      ><img
        class="popin-image-escal"
        src="/assets/images/error-system.svg"
        alt=""
    /></ng-template>
    <div class="popin-conetent-escal">
      <h2>RECHARGE AVEC SUCCÈS</h2>
      <p>La carte escale pro a été rechargé avec succès.</p>
    </div>
    <div class="popin-actions-escal">
      <button class="white-btn">RETOUR L’ACCUEIL</button>
      <button class="red-btn">RETOUR LES CARTES</button>
    </div>
  </div>
</div>
