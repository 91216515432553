<div class="list-header" translate="no">
  <h2 class="title-heading">MES CARTES</h2>
  <div class="list-filter-wrapper">
    <div class="list-filter">
      <span *ngIf="filterOn">ANNULER LE FILTRE</span>
      <button *ngIf="filterOn" (click)="renitialiser()" class="btn-filter">
        <img src="/assets/images/filter-cancel.svg" alt="filter-icon" />
      </button>
    </div>
    <hr *ngIf="filterOn" />
    <div class="list-filter">
      <span *ngIf="!filterOn">Filtre</span>
      <span *ngIf="filterOn">MODIFIER LE FILTRE</span>
      <button (click)="openFilterPopup()" class="btn-filter" [ngClass]="filterOn ? 'edit' : ''"
        [attr.data-after]="countFilterRes()">
        <ng-container *ngIf="filterOn; else templateFilter">
          <img src="/assets/images/filter-edit.svg" alt="'menu-icon" />
        </ng-container>

        <ng-template #templateFilter>
          <img src="/assets/images/filter.svg" alt="'menu-icon" />
        </ng-template>
      </button>
    </div>
  </div>
</div>

<div class="body-wrapper" translate="no">
  <div class="files-container">
    <div class="file-tab" *ngFor="let card of currentCardsList">
      <div class="file-info-container">
        <div class="file-info">
          <div class="file-elm file-reference">
            <p class="label">Titulaire</p>
            <p class="info">{{ card.familyName + " " + card.firstName }}</p>
          </div>
          <div class="file-elm-img">
            <div [ngSwitch]="card.productName">
              <img *ngSwitchCase="'CARTE ESCALE PRO'" src="/assets/images/cartes/ESCALEPRO.png" alt="filter-icon" />
              <img *ngSwitchCase="'CORPORATE INTERNATIONALE'" src="/assets/images/cartes/CORPORATE.png"
                alt="filter-icon" />
              <img *ngSwitchCase="'BUSINESS'" src="/assets/images/cartes/BUSINESS.png" alt="filter-icon" />
              <img *ngSwitchCase="'DEVISES'" src="/assets/images/cartes/DEVISES.png" alt="filter-icon" />
              <img *ngSwitchCase="'GOLD'" src="/assets/images/cartes/GOLD.png" alt="filter-icon" />
              <img *ngSwitchCase="'ENTREPRISE'" src="/assets/images/cartes/ENTREPRISE.png" alt="filter-icon" />
              <img *ngSwitchCase="'INFINITE'" src="/assets/images/cartes/INFINITE.png" alt="filter-icon" />
              <img *ngSwitchCase="'GLOBAL DEPOSIT'" src="/assets/images/cartes/GLOBALDEPOSIT.png" alt="filter-icon" />
              <img *ngSwitchDefault src="/assets/images/cartes/ESCALEPRO.png" alt="filter-icon" />
            </div>
            <div class="file-elm">
              <p class="label">Type</p>
              <p class="info" *ngIf="card.productName!=='CARTE ESCALE PRO'">{{ card.productName }}</p>
              <p class="info" *ngIf="card.productName==='CARTE ESCALE PRO'">ESCALE PRO</p>
            </div>
          </div>
          <div class="file-elm">
            <p class="label">Numéro</p>
            <p class="info">{{ card.cardNumber }}</p>
          </div>
          <div class="file-elm">
            <p class="label">Expire Fin</p>
            <p class="info">{{ card.expiryDate | date : "dd/MM/yyyy" }}</p>
          </div>
          <div class="file-elm">
            <p class="label">Plafond Achat</p>
            <p class="info" *ngIf="card.productName!=='CARTE ESCALE PRO'">
              {{ card.ceilingPayment | currency : " " : "" : "" : "fr" }}
              {{ card.currencyCodeAlpha }}
            </p>
            <p class="info" *ngIf="card.productName==='CARTE ESCALE PRO'">
              {{ card.ceilingPaymentInternational | currency : " " : "" : "" : "fr" }}
              {{ card.currencyCodeAlpha }}
            </p>
          </div>
          <div class="file-elm">
            <p class="label">Plafond Retrait</p>
            <p class="info" *ngIf="card.productName!=='CARTE ESCALE PRO'">
              {{ card.ceilingWithdrawal | currency : " " : "" : "" : "fr" }}
              {{ card.currencyCodeAlpha }}
            </p>
            <p class="info" *ngIf="card.productName==='CARTE ESCALE PRO'">
              {{ card.ceilingWithdrawalInternational | currency : " " : "" : "" : "fr" }}
              {{ card.currencyCodeAlpha }}
            </p>
          </div>
          <div class="file-elm action" *ngIf="card.productName==='CARTE ESCALE PRO'">
            <p class="label">Solde</p>
            <p class="info" [ngClass]="card.balance > 0 ? 'positive' : 'negative'">
              {{ card.balance | currency : " " : "" : "" : "fr" }}
              {{ card.currencyCodeAlpha }}
            </p>
          </div>
          <div class="file-elm action" *ngIf="card.productName!=='CARTE ESCALE PRO'">

          </div>
          <div class="actions-container">
            <div>
              <div class="file-action">
                <button (click)="getOperations(card)" class="action-menu-btn" [ngClass]="
                    showOperation &&
                    card.cardNumber === currentCardSelectionner.cardNumber
                      ? 'active'
                      : ''
                  ">
                  <div *ngIf="
                      showOperation &&
                        card.cardNumber === currentCardSelectionner.cardNumber;
                      else templateName
                    ">
                    <img src="assets/images/oeil-minus.svg" alt="'menu-icon" />
                  </div>

                  <ng-template #templateName>
                    <img src="assets/images/oeil-plus.svg" alt="'menu-icon" />
                  </ng-template>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="operation-list" *ngIf="
          showOperation &&
          card.cardNumber === currentCardSelectionner.cardNumber
        ">
        <app-consult-cards-operations [requestHistoric]="requestHistoric"
          [currentCardSelectionner]="currentCardSelectionner"></app-consult-cards-operations>
      </div>
    </div>
  </div>
</div>

<!-----------------------------------------------------------------------------------------
<app-confirm-popin
  [openPopinFlag]="openCancelPopinConfirmFlag"
  message="Etes vous sûr de vouloir annuler votre demande de recharge ?"
  (popinClosedEvent)="closeCancelConfirmPopin($event)"
></app-confirm-popin>
------------------------------------------------------------------------------------->

<!-------------------------------- Loader management --------------------------------------->
<div class="loader" *ngIf="showLoader" translate="no">
  <img class="loader-icon" src="/assets/images/Ellipsis-2s-80px.gif" alt="" />
</div>
<!----------------------------------------------------------------------------------------->

<!-------------------------------- Error management ---------------------------------------
<app-message-popin
  [openPopinFlag]="displayError"
  mode=""
  [message]="openPopinMessage"
  [success]="openPopinMessageSuccess"
  (popinClosedEvent)="closePopin()"
></app-message-popin>
--------------------------------------------------------------------------------------->