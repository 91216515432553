import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { ConsultCardsRoutingModule } from './consult-cards-routing.module';
import { ConsultCardsDashboardComponent } from './consult-cards-dashboard/consult-cards-dashboard.component';
import { ConsultCardsInitComponent } from './consult-cards-init/consult-cards-init.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import {
  MatIconModule,
  MatMenuModule,
} from '@angular/material';
import { SharedModule } from '../shared-module/shared.module';
import { ConsultCardsFilterComponent } from './consult-cards-filter/consult-cards-filter.component';
import { ConsultCardsOperationsComponent } from './consult-cards-operations/consult-cards-operations.component';
import { ConsultCardsOprerationsFilterComponent } from './consult-cards-oprerations-filter/consult-cards-oprerations-filter.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ConsultCardsDashboardComponent,
    ConsultCardsInitComponent,
    ConsultCardsFilterComponent,
    ConsultCardsOperationsComponent,
    ConsultCardsOprerationsFilterComponent,
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    ConsultCardsRoutingModule,
    MatDialogModule,
    SharedModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatNativeDateModule,
    ReactiveFormsModule
  ],
  providers: [DatePipe],
  entryComponents: [
    ConsultCardsFilterComponent,
    ConsultCardsOprerationsFilterComponent,
  ],
})
export class ConsultCardsModule {}
