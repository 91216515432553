import { Component, OnInit, OnDestroy } from '@angular/core';
import { DirectDebitSignatureStatus } from 'src/app/models/DirectDebitSignatureStatus';
import { ClientService } from 'src/app/service/client.service';
import { UserService } from 'src/app/service/user.service';
import { Roles } from 'src/app/utils/roles';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { map, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
  selectConnectedUser,
  selectSelectedCustomerNumber,
} from 'src/app/core/store/state/user';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-db-dashboard',
  templateUrl: './db-dashboard.component.html',
  styleUrls: ['./db-dashboard.component.scss'],
})
export class DbDashboardComponent implements OnInit, OnDestroy {
  tabActive;
  isDataLoaded = false;
  toSignTabSignatureStatus: DirectDebitSignatureStatus[] = [
    DirectDebitSignatureStatus.PENDING,
    DirectDebitSignatureStatus.IN_PROGRESS,
  ];
  hisoryTabStatus: DirectDebitSignatureStatus[] = [
    DirectDebitSignatureStatus.UNPAID,
    DirectDebitSignatureStatus.REJECTED,
    DirectDebitSignatureStatus.PAID,
    DirectDebitSignatureStatus.CANCELED,
    DirectDebitSignatureStatus.EXECUTING,
    DirectDebitSignatureStatus.EXECUTED,
    DirectDebitSignatureStatus.COMPLETED,
    DirectDebitSignatureStatus.WAITING_EXECUTION,
    DirectDebitSignatureStatus.WAITING_VALIDATION,
    DirectDebitSignatureStatus.WAITING_REJECTION,
  ];

  userCanConsult: boolean;
  userCanSign: boolean;
  userCanDisplayFile: boolean;
  userCanImport: boolean;

  selectConnectedUser$ = this.store.select(selectConnectedUser);
  user: User;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectedCustomerNumberSub: Subscription;

  constructor(
    private userService: UserService,
    private clientService: ClientService,
    private store: Store<State>
  ) {}

  ngOnDestroy() {
    this.selectedCustomerNumberSub.unsubscribe();
  }
  ngOnInit() {
    this.selectedCustomerNumberSub = this.selectConnectedUser$
      .pipe(
        switchMap((user) =>
          this.selectedCustomerNumber$.pipe(
            map((customerNumber) => [user, customerNumber])
          )
        )
      )
      .subscribe(([user, customerNumber]) => {
        this.init();
        this.user = user;
        if (this.user && customerNumber) {
          this.selectedCustomerNumber = customerNumber;
          this.checkUserRoles(this.selectedCustomerNumber);
        }
      });
  }

  init() {
    this.tabActive = '';
    this.isDataLoaded = false;
    this.userCanConsult = false;
    this.userCanSign = false;
    this.userCanDisplayFile = false;
    this.userCanImport = false;
  }

  selectTab(tabLabel: string): void {
    this.tabActive = tabLabel;
  }

  isActive(tabLabel: string): boolean {
    return this.tabActive === tabLabel;
  }

  checkUserRoles(customerNumber) {
    this.userService
      .getUserRolesByCustomerNumber(customerNumber, this.user.username)
      .toPromise()
      .then((result) => {
        if (result.userRoles && result.userRoles.length > 0) {
          this.userCanConsult = Roles.hasRole(
            result,
            Roles.DIRECT_DEBIT_VIEWER
          );
          this.userCanSign = Roles.hasRole(
            result,
            Roles.DIRECT_DEBIT_SIGNATORY
          );
          this.userCanDisplayFile = Roles.hasRole(
            result,
            Roles.DIRECT_DEBIT_CHECKER
          );
          this.userCanImport = Roles.hasRole(
            result,
            Roles.DIRECT_DEBIT_INITIATOR
          );
        }

        if (this.userCanImport) this.tabActive = 'INIT';
        else if (
          this.userCanConsult ||
          this.userCanSign ||
          this.userCanDisplayFile
        )
          this.tabActive = 'TO-SIGN';
        else this.tabActive = 'ROLES-ERROR';
      });
  }
}
