import { Injectable } from '@angular/core';
import { ConsultCardsResponseDTO } from '../models/ConsultCardsResponseDTO';
import { HistoricCardResponse } from '../modules/consult-cards/models/historic-card-response.model';
import { RequestHistoricCard } from '../modules/consult-cards/models/request-historic-card.model';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { CardConsultSearchRequestDTO } from '../models/CardConsultSearchRequestDTO';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ConsultCardService {
  constructor(private http: HttpClient) {}

  getCardOperations(
    requestHistoricCard: RequestHistoricCard
  ): Observable<HistoricCardResponse> {
    return this.http.post<HistoricCardResponse>(
      `${environment.apiUrl}/cards/card-historic`,
      requestHistoricCard,
      httpOptions
    );
  }
  getCards(
    customerNumber: string,
    cardFilterDto: CardConsultSearchRequestDTO
  ): Observable<ConsultCardsResponseDTO> {
    return this.http.get<ConsultCardsResponseDTO>(
      `${environment.apiUrl}/cards/${customerNumber}/list-all`,
      httpOptions
    );
  }
}
