import { Action, createReducer, on } from '@ngrx/store';
import * as EscaleProActions from '../escale-pro/escale-pro.actions';
import { initialState, SogeValeurState } from '../soge-valeur';
import * as SogeValeurActions from './soge-valeur.actions';

const sogeValeurReducer = createReducer(
  initialState,
  on(SogeValeurActions.initDownloadCallStateData, (state) => {
    return {
      ...state,
      chequesNotPaidListing: {
        ...state.chequesNotPaidListing,
        downloadChequesUnpaid: null,
      },
      chequesToPayListing: {
        ...state.chequesToPayListing,
        downloadChequesToPay: null,
      },
      lcnNotPaidListing: {
        ...state.lcnNotPaidListing,
        downloadLcnUnpaid: null,
      },
      lcnToPayListing: {
        ...state.lcnToPayListing,
        downloadLcnToPay: null,
      },
      downloadAllSv: null,
    };
  }),
  on(SogeValeurActions.fetchSogeValeur, (state) => {
    return {
      ...state,
      sogeValeursListing: {
        ...state.sogeValeursListing,
        sogeValeurs: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(SogeValeurActions.fetchSogeValeurSuccess, (state, { sogeValeurs }) => {
    return {
      ...state,
      sogeValeursListing: {
        ...state.sogeValeursListing,
        sogeValeurs: {
          result: sogeValeurs,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
      lcnToPayListing: {
        ...state.lcnToPayListing,
        lcnToPay: {
          result: sogeValeurs.lcnsToPay,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
      lcnNotPaidListing: {
        ...state.lcnNotPaidListing,
        lcnNotPaid: {
          result: sogeValeurs.lcnsNotPaid,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
      chequesNotPaidListing: {
        ...state.chequesNotPaidListing,
        chequesNotPaid: {
          result: sogeValeurs.chequesNotPaid,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
      chequesToPayListing: {
        ...state.chequesToPayListing,
        chequesToPay: {
          result: sogeValeurs.chequesToPay,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(SogeValeurActions.fetchSogeValeurError, (state, { error }) => {
    return {
      ...state,
      sogeValeursListing: {
        ...state.sogeValeursListing,
        sogeValeurs: {
          result: null,
          callState: {
            error,
            isLoaded: false,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(SogeValeurActions.downloadChequesUnpaid, (state, args) => {
    return {
      ...state,
      chequesNotPaidListing: {
        ...state.chequesNotPaidListing,
        downloadChequesUnpaid: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(SogeValeurActions.downloadChequesUnpaidSuccess, (state, args) => {
    return {
      ...state,
      chequesNotPaidListing: {
        ...state.chequesNotPaidListing,
        downloadChequesUnpaid: {
          result: args.file,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(SogeValeurActions.downloadChequesUnpaidFailed, (state, { error }) => {
    return {
      ...state,
      chequesNotPaidListing: {
        ...state.chequesNotPaidListing,
        downloadChequesUnpaid: {
          result: null,
          callState: {
            error: error,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),

  on(SogeValeurActions.downloadChequesToPay, (state, args) => {
    return {
      ...state,
      chequesToPayListing: {
        ...state.chequesToPayListing,
        downloadChequesToPay: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(SogeValeurActions.downloadChequesToPaySuccess, (state, args) => {
    return {
      ...state,
      chequesToPayListing: {
        ...state.chequesToPayListing,
        downloadChequesToPay: {
          result: args.file,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(SogeValeurActions.downloadChequesToPayFailed, (state, { error }) => {
    return {
      ...state,
      chequesToPayListing: {
        ...state.chequesToPayListing,
        downloadChequesToPay: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),

  on(SogeValeurActions.downloadLcnUnpaid, (state, args) => {
    return {
      ...state,
      lcnNotPaidListing: {
        ...state.lcnNotPaidListing,
        downloadLcnUnpaid: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(SogeValeurActions.downloadLcnUnpaidSuccess, (state, args) => {
    return {
      ...state,
      lcnNotPaidListing: {
        ...state.lcnNotPaidListing,
        downloadLcnUnpaid: {
          result: args.file,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(SogeValeurActions.downloadLcnUnpaidFailed, (state, { error }) => {
    return {
      ...state,
      lcnNotPaidListing: {
        ...state.lcnNotPaidListing,
        downloadLcnUnpaid: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),

  on(SogeValeurActions.downloadLcnToPay, (state, args) => {
    return {
      ...state,
      lcnToPayListing: {
        ...state.lcnToPayListing,
        downloadLcnToPay: {
          result: null,
          callState: {
            error: null,
            isLoaded: false,
            isLoading: true,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(SogeValeurActions.downloadLcnToPaySuccess, (state, args) => {
    return {
      ...state,
      lcnToPayListing: {
        ...state.lcnToPayListing,
        downloadLcnToPay: {
          result: args.file,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),
  on(SogeValeurActions.downloadLcnToPayFailed, (state, { error }) => {
    return {
      ...state,
      lcnToPayListing: {
        ...state.lcnToPayListing,
        downloadLcnToPay: {
          result: null,
          callState: {
            error: null,
            isLoaded: true,
            isLoading: false,
            displayMessage: true,
          },
        },
      },
    };
  }),

  on(SogeValeurActions.downloadAllSv, (state, args) => {
    return {
      ...state,
      downloadAllSv: {
        ...state.downloadAllSv,
        result: null,
        callState: {
          error: null,
          isLoaded: false,
          isLoading: true,
          displayMessage: true,
        },
      },
    };
  }),
  on(SogeValeurActions.downloadAllSvSuccess, (state, args) => {
    return {
      ...state,
      downloadAllSv: {
        ...state.downloadAllSv,
        result: args.file,
        callState: {
          error: null,
          isLoaded: true,
          isLoading: false,
          displayMessage: true,
        },
      },
    };
  }),
  on(SogeValeurActions.downloadAllSvFailed, (state, { error }) => {
    return {
      ...state,

      downloadAllSv: {
        ...state.downloadAllSv,
        result: null,
        callState: {
          error: error,
          isLoaded: true,
          isLoading: false,
          displayMessage: true,
        },
      },
    };
  })
);
export function reducer(state: SogeValeurState | undefined, action: Action) {
  return sogeValeurReducer(state, action);
}
