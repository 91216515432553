import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { eSignPagination } from 'src/app/modules/e-sign/models/eSignPagination';
import { ESignatory } from 'src/app/modules/e-sign/models/eSignatory';

import { InitSignatureResponse } from 'src/app/modules/e-sign/models/init-signature-response';

const httpOptions = {
  headers: new HttpHeaders({
    enctype: 'multipart/form-data',
    Accept: 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class ESingService {
  constructor(private http: HttpClient) {}

  public findEnvelopeByFilter(
    currentPage: number,
    status: string[],
    customerNumber: string
  ): Observable<eSignPagination> {
    return this.http.post<eSignPagination>(
      `${environment.apiUrl}/envelopes/clients/?customerNumber=${customerNumber}&page=${currentPage}`,
      status,
      httpOptions
    );
  }
  public downloadPdf(requestId: string) {
    return this.http.get(
      `${environment.apiUrl}/envelopes/${requestId}/clients/download`,
      {
        responseType: 'arraybuffer',
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    );
  }
  public initEnvelope(requestId: string): Observable<InitSignatureResponse> {
    return this.http.post<InitSignatureResponse>(
      `${environment.apiUrl}/envelopes/init?requestId=${requestId}`,
      httpOptions
    );
  }

  public checkEnvelope(requestId: string): Observable<Boolean> {
    return this.http.post<Boolean>(
      `${environment.apiUrl}/envelopes/check?requestId=${requestId}`,
      httpOptions
    );
  }

  public signeEnvelope(
    signatureTransactionId: string,
    requestId: string
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/envelopes/sign?signatureTransactionId=${signatureTransactionId}&requestId=${requestId}`,
      httpOptions
    );
  }
  public findESignatory(requestId: string): Observable<ESignatory[]> {
    return this.http.get<ESignatory[]>(
      `${environment.apiUrl}/envelopes/esignatory?requestId=${requestId}`,
      httpOptions
    );
  }
  public timeoutInit(
    signatureTransactionId: string,
    requestId: string
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/envelopes/time-out?signatureTransactionId=${signatureTransactionId}&requestId=${requestId}`,
      httpOptions
    );
  }

  public findtCountEnvelopesByUserAndStatus(
    customerNumber: string,
    status: string[]
  ): Observable<number> {
    return this.http.post<number>(
      `${environment.apiUrl}/envelopes/clients/counting?customerNumber=${customerNumber}`,
      status,
      httpOptions
    );
  }

  public closeSignature(signatureTransactionId: string): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/envelopes/close?signatureTransactionId=${signatureTransactionId}`,
      httpOptions
    );
  }
}
