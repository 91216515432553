import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CardSearchRequestDTO } from '../../../models/CardSearchRequestDTO';

@Component({
  selector: 'app-ep-card-filter',
  templateUrl: './ep-card-filter.component.html',
  styleUrls: ['./ep-card-filter.component.scss'],
})
export class EpCardFilterComponent implements OnInit {
  firstName: string;
  lastName: string;
  filter: CardSearchRequestDTO;

  constructor(
    public dialogRef: MatDialogRef<EpCardFilterComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: { filter: CardSearchRequestDTO }
  ) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.firstName = this.filter.firstName || '';
    this.lastName = this.filter.lastName || '';
  }

  applyFilter(): void {
    const filterObject = new CardSearchRequestDTO(
      this.firstName,
      this.lastName,
      null,
      null,
      null,
      null,
      null
    );
    this.dialogRef.close(filterObject);
  }

  reinitForm(): void {
    this.firstName = '';
    this.lastName = '';
    this.applyFilter();
  }

  close(): void {
    this.dialogRef.close();
  }

  cancelFilter() {
    this.reinitForm();
    this.applyFilter();
  }
}
