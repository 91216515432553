import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConsultCardsState } from './consult-cards.state';

export const selectConsultCards =
  createFeatureSelector<ConsultCardsState>('consultCards');

export const selectCards = createSelector(
  selectConsultCards,
  (state: ConsultCardsState) => state.consultCardsListing.cards
);

export const selectSelectedCard = createSelector(
  selectConsultCards,
  (state: ConsultCardsState) => state.consultCardsListing.cards
);

export const selectCardOperations = createSelector(
  selectConsultCards,
  (state: ConsultCardsState) => state.operations
);
