<div class="body-wrapper">
  <div class="list-header">
    <div class="list-filter">
      <span>Filtre</span>
      <button (click)="openFilterPopup()" [ngClass]="countFilterRes() != '' ? 'btn-filter-res' : ''" class="btn-filter"
        [attr.data-after]="countFilterRes()">
        <img src="/assets/images/filter.svg" alt="filter-icon" />
      </button>
    </div>
  </div>
  <div class="files-container" *ngIf="
      !showLoader && !historyCardList != null && historyCardList != undefined
    ">
    <div class="file-tab" *ngFor="let card of historyCardList.contentList">
      <div class="file-info">
        <div class="file-elm">
          <p class="label">Numéro de la carte</p>
          <p class="info">{{ card.cardNumber }}</p>
        </div>
        <div class="file-elm">
          <p class="label">Nom de porteur</p>
          <p class="info">{{ card.beneficiaryFullName | uppercase }}</p>
        </div>
        <div class="file-elm">
          <p class="label">Date d'action</p>
          <p class="info">{{ card.operationDate | date : "dd/MM/yyyy" }}</p>
        </div>

        <div class="file-elm">
          <p class="label">Montant</p>
          <p class="info">
            {{ card.amount | currency : " " : "" : "" : "fr" }} MAD
          </p>
        </div>

        <div class="file-elm">
          <p class="label">Statut</p>
          <p class="info statut annuler" *ngIf="card.signatureStatus == CardEpStatus.CANCELED">
            {{ mappingEpCardStatus(card.signatureStatus) }}
          </p>
          <p class="info statut execute" *ngIf="card.signatureStatus == CardEpStatus.EXECUTED">
            {{ mappingEpCardStatus(card.signatureStatus) }}
          </p>
          <p class="info statut rejete" *ngIf="card.signatureStatus == CardEpStatus.REJECTED">
            {{ mappingEpCardStatus(card.signatureStatus) }}
          </p>
          <p class="info statut completed" *ngIf="card.signatureStatus == CardEpStatus.COMPLETED">
            {{ mappingEpCardStatus(card.signatureStatus) }}
          </p>
        </div>

        <div class="file-elm" *ngIf="card.signatureStatus == CardEpStatus.CANCELED">
          <p class="label">Annulé par</p>
          <p class="info">{{ card.canceler }}</p>
        </div>

        <div class="actions-container">
          <div class="file-action" *ngIf="card.signatureStatus == 'EXECUTED'"
            matTooltip="Télécharger le récapitulatif en PDF" matTooltipPosition="left"
            (click)="dowloadFile(card.id,card)">
            <img src="../../../../assets/images/upload-cloud-red.svg" alt="'menu-icon" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="list-pagination">
    <!----------------------------------------------------------------------------------------->
    <app-pagination [page]="page" (getPage)="getCurrentPageCards()"></app-pagination>
    <!----------------------------------------------------------------------------------------->
  </div>
</div>

<!-------------------------------- Loader management --------------------------------------->
<div class="loader" *ngIf="showLoader">
  <img class="loader-icon" src="/assets/images/Ellipsis-2s-80px.gif" alt="" />
</div>
<!----------------------------------------------------------------------------------------->

<!-------------------------------- Error management --------------------------------------->
<app-message-popin [openPopinFlag]="displayError" mode="" [message]="openPopinMessage"
  [success]="openPopinMessageSuccess" (popinClosedEvent)="closePopin()"></app-message-popin>
<!----------------------------------------------------------------------------------------->