import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CardSignatoryHistoryDTO } from 'src/app/models/CardSignatoryHistoryDTO';

@Component({
  selector: 'app-ep-signature-history',
  templateUrl: './ep-signature-history.component.html',
  styleUrls: ['./ep-signature-history.component.scss'],
})
export class EpSignatureHistoryComponent implements OnInit {
  signatureHistory: Array<CardSignatoryHistoryDTO>;
  constructor(
    private dialogRef: MatDialogRef<EpSignatureHistoryComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: { signatureHistory: Array<CardSignatoryHistoryDTO> }
  ) {
    this.signatureHistory = data.signatureHistory;
  }
  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
  }
}
