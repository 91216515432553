import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BeneficiaryDto } from 'src/app/models/beneficiaryDto';
import { Client } from 'src/app/models/client';
import { ClientSharedService } from 'src/app/service/client-shared.service';
import Swal from 'sweetalert2';

import { BeneficiaryListDto } from '../../../../models/BeneficiaryListDto';
import { Page } from '../../../../models/page';
import { BeneficiaryService } from '../../../../service/beneficiary.service';
import { AlertComponent } from '../../alert/alert.component';
import { DialogSignComponent } from '../../dialog-sign/dialog-sign.component';

@Component({
  selector: 'app-beneficiaries-to-sign',
  templateUrl: './beneficiaries-to-sign.component.html',
  styleUrls: ['./beneficiaries-to-sign.component.scss'],
})
export class BeneficiariesToSignComponent implements OnInit {
  public beneficiaryDtoList: BeneficiaryListDto;
  public technicalError: boolean;
  public isBeneficiaryToSign: boolean = false;
  public benficiaryId: number;
  public client: Client;
  page = new Page();
  message = `Quelle est votre décision concernant cette opération suite à l'acceptation de votre initiateur ?`;
  signMessage =
    'Vous êtes sur le point de signer cette opération. Entrer la code de signature.';
  VALIDATE = 'validate';
  EMPTY = '';
  actionButtonClicked: { button: string } = { button: this.EMPTY };
  snackbarMessage = "L'opération a été rejetée";
  rejectProviMessage = "L'opération a été rejetée provisoirement";
  validationMessage = "L'opération a bien été signée";
  validationButton = {
    button: null,
  };
  @ViewChild('validateBeneficiaryAlert')
  validateBeneficiaryAlert: AlertComponent;
  @ViewChild('app_dialog_beneficiary_sign')
  appDialogBeneficiarySign: DialogSignComponent;
  public commentObj: {
    comment: string;
    challengeKey: string;
    dpResponse: string;
  };
  validateMessage =
    'Vous êtes sur le point de valider cette décision. Êtes-vous sûr de vouloir continuer ?';

  constructor(
    private beneficiaryService: BeneficiaryService,
    private router: Router,
    private clientSharedService: ClientSharedService
  ) {
    this.page.init();
  }

  ngOnInit(): void {
    this.commentObj = { comment: null, dpResponse: null, challengeKey: null };
    this.client = this.clientSharedService.getClient();
    this.beneficiaryService
      .findAllToSignBeneficiaries('354', 0)
      .toPromise()
      .then((result) => {
        this.beneficiaryDtoList = result;
        this.page.totalPages = this.beneficiaryDtoList.totalPages;
        this.page.totalElements = this.beneficiaryDtoList.totalElements;
      })
      .catch(() => (this.technicalError = true));
  }

  getBeneficiariesCurrentPage(): void {
    this.beneficiaryService
      .findAllToSignBeneficiaries('354', this.page.currentPage)
      .toPromise()
      .then((result) => {
        this.beneficiaryDtoList = result;
        this.page.totalPages = this.beneficiaryDtoList.totalPages;
        this.page.totalElements = this.beneficiaryDtoList.totalElements;
      });
  }

  cancelValidation(): void {
    this.isBeneficiaryToSign = false;
  }

  signBeneficiary(beneficiary: BeneficiaryDto): void {
    this.isBeneficiaryToSign = true;
    this.benficiaryId = beneficiary.id;
  }

  validate() {
    this.beneficiaryService
      .validateBeneficiary(
        354,
        this.benficiaryId,
        this.commentObj.dpResponse,
        this.commentObj.challengeKey
      )
      .subscribe(
        () => this.terminateAcceptValidation(),
        (error) => this.appDialogBeneficiarySign.onRefresh(null)
      );
  }

  terminateAcceptValidation() {
    Swal.fire({
      text: 'Le nouveau bénéficiaire a bien été signé. Vous pouvez le consulter dans le menu MES BENEFICIAIRES',
      icon: 'warning',
      dangerMode: false,
    } as any),
      window.location.reload();
  }
}
