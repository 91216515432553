<div class="actualites-item">
  <div
    [ngClass]="link ? 'show-underline' : ''"
    class="actualites-item-content"
    (click)="link && redirect(link)"
  >
    <p class="title">{{ headerTitle }}</p>
    <p class="description">{{ description }}</p>
  </div>
</div>
