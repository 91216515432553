<app-pdf-viewer *ngIf="showFileViewer" [pdfSrc]="pdfSrc" [pdfReference]="fileReference"
  (closeFileViewer)="showFileViewer = false"></app-pdf-viewer>
<div class="cards-wrapper" *ngIf="numStep == 0">
  <!-------------------------------- View File ------------------------------------>
  <div class="cards-header">
    <h1>CARTES BANCAIRES</h1>
    <p>
      Explorez nos cartes bancaires valables pour vous et vos collègues. Plongez
      dans un univers de privilèges exclusifs !
    </p>
  </div>

  <div class="card-container">
    <div class="card-item-wrapper">
      <div class="card-item" *ngFor="let card of cards; let i = index">
        <div class="card-content-container">
          <h2>{{ card.libelle }}</h2>
          <img src="{{ card.icone }}" alt="card" />
          <div class="card-content">
            <p class="card-description">{{ card.description }}</p>
          </div>
        </div>
        <div class="card-btn-container">
          <button class="card-btn-details" (click)="viewPdf(card)">
            <span>Fiche Produit</span>
            <img src="../../../../assets/images/oeil-plus.svg" alt="'menu-icon" />
          </button>
          <button class="card-btn-submit" (click)="selectCard(card); souscrire()">
            <span>Souscrire</span>
            <img src="../../../../assets/images/ic_check_white.svg" alt="'menu-icon" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="chosen-card-wrapper" *ngIf="numStep == 1">
  <button class="chosen-back-btn" (click)="back()">
    <span class="icon">
      <img src="assets/images/ic_arrow_right.svg" alt="chosen card" />
    </span>
    <span class="text">Retour</span>
  </button>
  <h2>SOUSCRIPTION</h2>
  <div class="chosen-card-content-wrapper">
    <div class="chosen-card-content">
      <div class="chosen-card-left-content">
        <img src="{{ cardSelectionner.icone }}" alt="card" />
        <div class="infos-container">
          <div class="infos">
            <span class="infos-title"> Carte demandée </span>
            <span class="infos-description">
              {{ cardSelectionner?.libelle }}
            </span>
          </div>
          <hr />

          <p>{{ cardSelectionner.description }}</p>
        </div>
      </div>
      <div class="chosen-card-right-content">
        <p>Veuillez sélectionner pour qui vous souhaitez demander la carte :</p>
        <div class="radio-container">
          <label>
            <input hidden type="radio" [value]="1" name="askCard" [(ngModel)]="askCard" />
            <div class="radio-content">
              <img src="/assets/images/MOI.svg" alt="choose card" />
              <span>Pour moi même</span>
            </div>
          </label>
          <label>
            <input hidden type="radio" [value]="2" name="askCard" [(ngModel)]="askCard" />
            <div class="radio-content">
              <img src="/assets/images/BENEFICIAIRE.svg" alt="choose card" />
              <span>pour un bénéficiare</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="chosen-action-btn" (click)="openPopup()" [disabled]="!askCard">
    <span>Demander</span>
    <img src="/assets/images/ic_arrow_next_white.svg" alt="next-arrow" />
  </button>
</div>
<app-popin-content [isHeader]="false" (closedPopine)="receiveStatusPopin($event)" [isPopupVisible]="showPopup"
  [width]="508 + 'px'" [height]="335 + 'px'">
  <div class="confirm-popup">
    <div class="confirm-content">
      <h4>Avez vous les pouvoirs pour demander cette cartes ?</h4>

      <p>
        les vérifications vont etre faites au niveau du dossier juridique de
        votre agence
      </p>
    </div>
    <div class="confirm-actions">
      <mat-slide-toggle [(ngModel)]="checked">
        <div *ngIf="checked">Oui</div>
        <div *ngIf="!checked">Non</div>
      </mat-slide-toggle>

      <button type="button" [disabled]="!checked" (click)="souscrire()">
        <span>Continuer</span>
        <img src="/assets/images/ic_arrow_next_white.svg" alt="next-arrow" />
      </button>
    </div>
  </div>
</app-popin-content>

<app-sell-card-account (formAccountData)="receiveFormAccountInfo($event)" [accountUpdate]="accountUpdated"
  (stepEvent)="receiveStepAndGetData($event)" (closedPopine)="receiveStatusPopin($event)" [userLoaded]="userLoaded"
  [isPopupVisible]="souscriptionEtape == 1"></app-sell-card-account>

<app-sell-card-personal-info (formInfoData)="receiveFormDataInfo($event)" [proposUpdate]="proposUpdate"
  (stepEvent)="receiveStepAndGetData($event)" (closedPopine)="receiveStatusPopin($event)" [userLoaded]="userLoaded"
  [isPopupVisible]="souscriptionEtape == 2"></app-sell-card-personal-info>
<app-sell-card-contact-info [contactUpdate]="contactUpdate" (formContactData)="receiveFormDataContact($event)"
  [cardSelected]="cardSelectionner" (stepEvent)="receiveStepAndGetData($event)" [userLoaded]="userLoaded"
  [isPopupVisible]="souscriptionEtape == 3 && showContact"></app-sell-card-contact-info>
<app-sell-card-ceiling-info *ngIf="cardSelectionner" [ceilingUpdate]="ceilingUpdate"
  (stepEvent)="receiveStepAndGetData($event)" [userLoaded]="userLoaded"
  (formCeilingData)="receiveFormDataCeiling($event)" [cardSelected]="cardSelectionner"
  [isPopupVisible]="souscriptionEtape == 4 && showAdress"></app-sell-card-ceiling-info>
<app-popin-content [isHeader]="true" [title]="stepFiveTitle" (closed)="receiveStatusPopin($event)"
  [isPopupVisible]="souscriptionEtape == 5">
  <app-sell-card-data (stepEvent)="receiveStep($event)" [groupedForms]="groupedForms" [step]="step"
    [userLoaded]="userLoaded" [cardSelected]="cardSelectionner"></app-sell-card-data>
</app-popin-content>

<app-popin-content (closed)="receiveStatusPopin($event)" [isPopupVisible]="souscriptionEtape == 7 && emailSended">
  <div class="message-content">
    <img src="/assets/images/success.svg" alt="Soft Token" />
    <div class="text-content">
      <h3>Nous vous remercions pour votre Demande</h3>
      <p>Votre gestionnaire vous contactera incessamment pour la récupérer,après vérification de votre dossier juridique
      </p>
    </div>
    <div class="btn-actions">
      <button type="button" class="white-bg" (click)="toDashboard()">
        AUTRE CARTE
      </button>
      <button type="button" (click)="firstStep()">NOUVELLE SOUSCRIPTION</button>
    </div>
  </div>
</app-popin-content>
<app-popin-content (closed)="receiveStatusPopin($event)" [isPopupVisible]="souscriptionEtape == 7 && !emailSended">
  <div class="message-content">
    <img src="/assets/images/error-system.svg" alt="Soft Token" />
    <div class="text-content">
      <h3>Désolé, votre message n'a pas pu être envoyé</h3>
      <p>Veuillez réessayer la souscription</p>
    </div>
    <div class="btn-actions error-actions">
      <button type="button" class="white-bg" (click)="toDashboard()">
        AUTRE CARTE
      </button>
      <button type="button" (click)="firstStep()">Réessayer</button>
    </div>
  </div>
</app-popin-content>