import { Component, OnInit } from '@angular/core';
import { StatusESign } from 'src/app/modules/e-sign/models/status-esign.enum';
import { State } from '../../../core/store/state';
import {
  fetchESignCountingHistoric,
  fetchESignCountingPending,
} from '../../../core/store/state/e-sign';
import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { selectESignListCoutingPending } from '../../../core/store/state/e-sign';
import { selectESignListCoutingHistoric } from '../../../core/store/state/e-sign';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  tabActive;
  statusPENDING = StatusESign.PENDING;
  statusHistorique = StatusESign.HISTORIQUE;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumberSub: any;
  statusSuivi = ['PENDING', 'IN_PROGRESS'];
  statusHistoric = ['COMPLETED', 'CANCELED', 'EXPIRED', 'SIGNED'];
  selectedCustomerNumber = '';
  countingPending: number = 0;
  countingHistoric: number = 0;
  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        this.store.dispatch(
          fetchESignCountingPending({
            status: this.statusSuivi,
            customerNumber: customerNumber,
          })
        );
        this.store.dispatch(
          fetchESignCountingHistoric({
            status: this.statusHistoric,
            customerNumber: customerNumber,
          })
        );
      }
    );
    this.store
      .select(selectESignListCoutingPending)
      .subscribe((eSignPending) => {
        if (eSignPending.result != null) {
          this.countingPending = eSignPending.result;
        }
      });
    this.store
      .select(selectESignListCoutingHistoric)
      .subscribe((eSignHistoric) => {
        if (eSignHistoric.result != null) {
          this.countingHistoric = eSignHistoric.result;
        }
      });
    this.selectTab('PENDING');
  }
  selectTab(tabLabel: string): void {
    this.tabActive = tabLabel;
  }
  isActive(tabLabel: string): boolean {
    return this.tabActive === tabLabel;
  }
}
