export class CardRefillDto {
  firstName: string;
  lastName: string;
  amount: number;
  motif: string;
  expiryDate: Date;
  refillDate: Date;
  rib: string;
  orderingAccountRib: string;

  constructor(
    firstName: string,
    lastName: string,
    amount: number,
    motif: string,
    expiryDate: Date,
    refillDate: Date,
    rib: string,
    orderingAccountRib: string
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.amount = amount;
    this.motif = motif;
    this.expiryDate = expiryDate;
    this.refillDate = refillDate;
    this.rib = rib;
    this.orderingAccountRib = orderingAccountRib;
  }
}
