export enum CardType {
  ESCALEPRO = 'CARTE ESCALE PRO',
  CORPORATE = 'CORPORATE INTERNATIONALE',
  BUSINESS = 'BUSINESS',
  DEVISES = 'DEVISES',
  GOLD = 'GOLD',
  ENTREPRISE = 'ENTREPRISE',
  INFINITE = 'INFINITE',
  GLOBALDEPOSIT = 'GLOBAL DEPOSIT',
}
