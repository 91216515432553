<div class="table-row">
  <div class="data-col bordered-col">
    <div class="data-col-content">
      <div class="data-col-content-wrapper">
        <div class="data-col-content-body">
          <span class="data-val">{{ transfer.reference }}</span
          ><br />
          <span class="data-label">Envoyé le {{ transfer.receptionDate }}</span>
        </div>
        <div
          class="data-col-action"
          *ngIf="transfer.signatureStatus == 'PENDING'"
        >
          <span class="tag" data-status="to-sign"> A signer </span>
        </div>
        <div
          class="data-col-action"
          *ngIf="transfer.signatureStatus == 'IN_PROGRESS'"
        >
          <span class="tag" data-status="to-conter-sign">
            A contre-signer
          </span>
        </div>
        <div
          class="data-col-action"
          *ngIf="transfer.signatureStatus == 'COMPLETED'"
        >
          <span class="tag" data-status="to-sign"> Pris en charge </span>
        </div>
      </div>
    </div>
  </div>
  <div class="data-col bordered-col fixed-size" style="width: 165px">
    <div class="data-col-content">
      <div class="data-col-content-wrapper">
        <div class="data-col-content-body">
          <span class="data-label"
            ><span class="data-num">{{
              transfer.beneficiariesCount
            }}</span></span
          >
          <span class="data-val">Bénéficiaires</span>
        </div>
      </div>
    </div>
  </div>
  <div class="data-col bordered-col">
    <div class="data-col-content">
      <div class="data-col-content-wrapper">
        <div class="data-col-content-icon">
          <img src="../../../assets//images/money-icon.png" alt="" />
        </div>
        <div class="data-col-content-body">
          <span class="data-label">Montant total</span>
          <span class="data-val">{{ transfer.amount }} DHS</span>
        </div>
      </div>
    </div>
  </div>
  <div class="data-col bordered-col">
    <div class="data-col-content">
      <div class="data-col-content-wrapper">
        <div class="data-col-content-icon">
          <img src="../../../assets//images/card-icon.png" alt="" />
        </div>
        <div class="data-col-content-body">
          <span class="data-label">Compte donneur d’ordre</span>
          <span class="data-val">{{ transfer.accountNumber }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="data-col fixed-size action-col">
    <span>
      <button
        *ngIf="userCanSign && !transfer.signed"
        (click)="startSigningOrder(transfer.id)"
      >
        <img src="../../../assets//images/Signature-icon.png" alt="" />
        <span>Signer</span>
      </button>
    </span>
  </div>
  <div class="data-col fixed-size action-col">
    <span>
      <button
        *ngIf="userCanSign && !transfer.signed"
        (click)="startCancelOrder(transfer.id)"
      >
        <img width="20px" src="../../../assets/images/Delete-five.svg" alt="" />
        <span>Annuler</span>
      </button>
    </span>
  </div>
</div>
