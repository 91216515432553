import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State } from '../../../core/store/state';
import {
  downloadAllSv,
  downloadLcnToPay,
  selectDownloadAllSv,
  selectDownloadLcnToPay,
  selectEffetPayerFromListing,
  initDownloadCallStateData,
} from '../../../core/store/state/soge-valeur';
import { selectSelectedCustomerNumber } from '../../../core/store/state/user';
import { CallStateResponse, handleCallState } from '../../../models/CallState';
import { LcnToPayResponse } from '../../../models/SogeValeurDto';
import { Page } from 'src/app/models/page';
import { LcnToPayDTO } from 'src/app/models/LcnToPayDTO';

@Component({
  selector: 'app-sv-effet-payer',
  templateUrl: './sv-effet-payer.component.html',
  styleUrls: ['./sv-effet-payer.component.scss'],
})
export class SvEffetPayerComponent implements OnInit {
  selecteffetPayerSub: Subscription;
  effetsPayerResponse: LcnToPayResponse;
  currentEffetsList: LcnToPayDTO[] = [];
  selecteffetPayer$ = this.store.select(selectEffetPayerFromListing);
  selectedCustomerNumberSub: Subscription;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber;
  selectDownloadLcnToPayFileSub: Subscription;
  selectDownloadLcnToPayFile$ = this.store.select(selectDownloadLcnToPay);
  handleCallStateDownloadLcnToPayFile: CallStateResponse;
  selectDownloadAllSvFileSub: Subscription;
  selectDownloadAllSvFile$ = this.store.select(selectDownloadAllSv);
  handleCallStateDownloadAllSvFileFile: CallStateResponse;
  showLoader = false;
  page = new Page();

  constructor(private store: Store<State>) {}

  ngOnDestroy(): void {
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectDownloadLcnToPayFileSub.unsubscribe();
    this.selecteffetPayerSub.unsubscribe();
    this.selectDownloadAllSvFileSub.unsubscribe();
    this.store.dispatch(initDownloadCallStateData(this.selectedCustomerNumber));
  }

  ngOnInit(): void {
    this.page.init();
    this.selecteffetPayerSub = this.selecteffetPayer$.subscribe(
      (sogeValeurs) => {
        if (sogeValeurs && sogeValeurs) {
          this.effetsPayerResponse = sogeValeurs.result;
          this.page.totalPages = Math.ceil(
            this.effetsPayerResponse.lcns.length / 12
          );
          this.page.totalElements = this.effetsPayerResponse.lcns.length;
          this.getCurrentPageEffet();
        }
      }
    );

    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
        }
      }
    );

    this.selectDownloadLcnToPayFileSub =
      this.selectDownloadLcnToPayFile$.subscribe((file) => {
        if (file && file.result) {
          const blob = new Blob([file.result]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'MES EFFETS A PAYER.xlsx';
          link.click();
        }
        this.handleCallStateDownloadLcnToPayFile = handleCallState(file);
        this.showLoader = this.handleCallStateDownloadLcnToPayFile.showLoader;
      });

    this.selectDownloadAllSvFileSub = this.selectDownloadAllSvFile$.subscribe(
      (file) => {
        if (file && file.result) {
          const blob = new Blob([file.result]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'MES VALEURS.xlsx';
          link.click();
        }
        this.handleCallStateDownloadAllSvFileFile = handleCallState(file);
        this.showLoader = this.handleCallStateDownloadAllSvFileFile.showLoader;
      }
    );
  }

  getTotalNumber(): number {
    return this.effetsPayerResponse !== null &&
      this.effetsPayerResponse !== undefined
      ? this.effetsPayerResponse.total
      : -2;
  }

  getCurrentPageEffet(): void {
    const start = this.page.currentPage * 12;
    const end =
      this.effetsPayerResponse.lcns.length >= start + 12
        ? start + 12
        : start + (this.effetsPayerResponse.lcns.length % 12);
    this.currentEffetsList = this.effetsPayerResponse.lcns.slice(start, end);
   
  }

  download(): void {
    this.showLoader = true;
    this.store.dispatch(
      downloadLcnToPay({
        customerNumber: this.selectedCustomerNumber,
      })
    );
  }

  downloadAll(): void {
    this.showLoader = true;
    this.store.dispatch(
      downloadAllSv({
        customerNumber: this.selectedCustomerNumber,
      })
    );
  }
}
