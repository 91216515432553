import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { DirectDebitService } from 'src/app/service/direct-debit.service';
import { Page } from 'src/app/models/page';
import { DirectDebit } from 'src/app/models/directDebit';
import { ClientService } from 'src/app/service/client.service';
import {
  mappingDirectDebitSignatureStatus,
  getMappedDirectDebitSignatureStatus,
  DirectDebitSignatureStatus,
  DirectDebitSignatureMappedStatus,
} from 'src/app/models/DirectDebitSignatureStatus';
import { SignatureChallengeAndDresponse } from 'src/app/models/signatureChallengeAndDresponse';
import { ChallengeService } from 'src/app/service/challenge.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-db-to-sign',
  templateUrl: './db-to-sign.component.html',
  styleUrls: ['./db-to-sign.component.scss'],
})
export class DbToSignComponent implements OnInit, OnDestroy {
  page = new Page();
  files: Array<DirectDebit>;
  noFilesFound: boolean;
  @Input() userCanConsult: boolean = false;
  @Input() userCanSign: boolean = false;
  @Input() userCanDisplayFile: boolean = false;
  @Output() validateCancelEvent = new EventEmitter<any>();
  searchCriteria: string;
  loading: boolean = false;
  fileSrc: string;
  fileReference: string;
  showFileViewer: boolean;
  @Input() signatureStatus: Array<string>;
  @Input() historyMode: boolean;
  uploadDefaultMessageError =
    'Le service est indisponible,  merci de réessayer ultérieurement';
  openPopinMessage = this.uploadDefaultMessageError;
  openPopinMessageFlag = false;
  openPopinSignatureFlag = false;
  selectedFileId = '';
  openPopinMessageSuccess = false;
  challengeCode = '';
  challengeKey = '';
  isPendingRefreshChalCodBtn = false;
  isSignaturePending = false;
  canceldMessageError = false;
  openCancelPopinConfirmFlag = false;
  selectedDirectDebit: DirectDebit;
  confirmCancelMessage: string;
  openPopinMessageError =
    'Le service est indisponible,  merci de réessayer ultérieurement';
  cancellableStatus: DirectDebitSignatureStatus[] = [
    DirectDebitSignatureStatus.PENDING,
    DirectDebitSignatureStatus.IN_PROGRESS,
  ];
  directDebitSignatureMappedStatus = DirectDebitSignatureMappedStatus;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectedCustomerNumberSub: Subscription;

  constructor(
    private directDebitService: DirectDebitService,
    private clientService: ClientService,
    private challengeService: ChallengeService,
    private store: Store<State>
  ) {}

  ngOnDestroy() {
    this.selectedCustomerNumberSub.unsubscribe();
  }
  ngOnInit() {
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
          this.page.init();
          this.getCurrentPageDirectDebit();
        }
      }
    );
  }

  closeSignaturePopin() {
    this.openPopinSignatureFlag = false;
    this.selectedFileId = '';
  }

  refreshChallengeCode() {
    if (!this.isPendingRefreshChalCodBtn) {
      this.isPendingRefreshChalCodBtn = true;
      this.challengeService
        .getChallenge()
        .toPromise()
        .then((result) => {
          if (result) {
            this.challengeCode = result.challenge;
            this.challengeKey = result.challengeKey;
            this.openPopinSignatureFlag = true;
          } else {
            this.closeSignaturePopin();
            this.openPopinMessageSuccess = false;
            this.openPopinMessage = this.uploadDefaultMessageError;
            this.openPopinMessageFlag = true;
          }
        })
        .catch((error) => {
          this.closeSignaturePopin();
          this.openPopinMessageSuccess = false;
          this.openPopinMessageFlag = true;
          if (error.status == 400) {
            this.openPopinMessage = error.error.message;
          } else {
            this.openPopinMessage = this.uploadDefaultMessageError;
          }
        })
        .finally(() => (this.isPendingRefreshChalCodBtn = false));
    }
  }

  onSign(fileID) {
    this.selectedFileId = fileID;
    this.refreshChallengeCode();
  }

  signHandler(data: SignatureChallengeAndDresponse) {
    if (!this.isSignaturePending) {
      this.isSignaturePending = true;
      this.directDebitService
        .signDirectDebit(
          this.selectedCustomerNumber,
          this.selectedFileId,
          data.challengeKey,
          data.dResponse
        )
        .toPromise()
        .then(() => {
          this.closeSignaturePopin();
          this.openPopinMessageSuccess = true;
          this.openPopinMessageFlag = true;
          this.openPopinMessage = 'Votre signature a été prise en compte';
        })
        .catch((error) => {
          this.closeSignaturePopin();
          this.openPopinMessageSuccess = false;
          this.openPopinMessageFlag = true;
          if (error.status == 404) {
            this.openPopinMessage = error.error.message;
          } else {
            this.openPopinMessage = this.uploadDefaultMessageError;
          }
        })
        .finally(() => (this.isSignaturePending = false));
    }
  }

  getCurrentPageDirectDebit() {
    const body = {
      customerNumber: this.selectedCustomerNumber,
      signatureStatus: this.signatureStatus,
    };
    this.loading = true;
    this.directDebitService
      .getDirectDebitFilesWithInProgressSignature(
        this.page.currentPage,
        this.searchCriteria,
        body
      )
      .toPromise()
      .then((result) => {
        this.files = result.contentList;
        this.page.totalPages = result.totalPages;
        this.page.totalElements = result.totalElements;
        this.noFilesFound =
          result.contentList == null || result.contentList.length <= 0;
      })
      .catch(() => {
        this.noFilesFound = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  searchFileProcess(): void {
    this.page.currentPage = 0;
    //alert("Seeking files for criteria: "+this.searchCriteria+" PAGE: 0");
  }

  openCancelConfirmPopup(directDebit: DirectDebit) {
    this.selectedDirectDebit = directDebit;
    this.openCancelPopinConfirmFlag = true;
    this.confirmCancelMessage =
      'Voulez-vous vraiment annuler le prélévement ' +
      directDebit.reference +
      ' ?';
  }

  onFileSelect(input: HTMLInputElement): void {
    const file = input.files[0];
    this.directDebitService
      .upload(file)
      .toPromise()
      .then((result) => {
        this.getCurrentPageDirectDebit();
      })
      .catch((error) => {});
  }

  viewFile(ref: string) {
    this.directDebitService
      .downloadFile(this.selectedCustomerNumber, ref)
      .subscribe(
        (response) => {
          let blob = new Blob([response]);
          let url = window.URL.createObjectURL(blob);
          this.fileSrc = url;
          this.fileReference = ref;
          this.showFileViewer = true;
        },
        (error) => {
          this.openPopinMessageFlag = true;
        }
      );
  }

  downloadDirectDebitFile(ref: string, origin: string) {
    let typeDD =
      origin.toUpperCase() == 'UPLOAD'
        ? 'text/plain'
        : 'application/octet-stream';
    this.directDebitService
      .downloadFile(this.selectedCustomerNumber, ref)
      .subscribe(
        (response) => {
          let blob = new Blob([response], { type: typeDD });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = ref;
          link.click();
        },
        (error) => {
          this.openPopinMessageFlag = true;
        }
      );
  }

  downloadSortFile(id: string, ref: string) {
    this.directDebitService
      .downloadSortFile(this.selectedCustomerNumber, id)
      .subscribe(
        (response) => {
          let blob = new Blob([response], { type: 'application/octet-stream' });
          let url = window.URL.createObjectURL(blob);
          this.dowloadLink(url, ref);
        },
        (error) => {
          this.openPopinMessageFlag = true;
        }
      );
  }

  dowloadLink(url, ref) {
    var link = document.createElement('a');
    link.href = url;
    link.download = ref;
    link.click();
  }

  closePopin() {
    this.openPopinMessageFlag = false;
    this.getCurrentPageDirectDebit();
  }

  closeCancelConfirmPopin(res: boolean) {
    this.canceldMessageError = false;
    this.openCancelPopinConfirmFlag = false;
    if (res) {
      this.cancelDirectDebitAfterInitiation(this.selectedDirectDebit.id);
    }
  }

  mappingDirectDebitSignatureStatus(stat): string {
    return mappingDirectDebitSignatureStatus(stat);
  }

  getMappedDirectDebitSignatureStatus(stat): string {
    return getMappedDirectDebitSignatureStatus(stat);
  }

  checkIfCancellable(status: string): boolean {
    return this.cancellableStatus.includes(
      status as DirectDebitSignatureStatus
    );
  }

  cancelDirectDebitAfterInitiation(id: any) {
    this.directDebitService
      .cancelDirectDebitAfterInitialization(id, this.selectedCustomerNumber)
      .toPromise()
      .then(() => {
        this.validateCancelEvent.emit();
      })
      .catch((error) => {
        this.openPopinMessageFlag = true;
        this.openPopinMessage = this.openPopinMessageError;
      });
  }
}
