import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State } from '../../../core/store/state';
import { initCardsListing } from '../../../core/store/state/escale-pro';
import {
  fetchSogeValeur,
  selectSogeValeurListing,
} from '../../../core/store/state/soge-valeur';
import { selectSelectedCustomerNumber } from '../../../core/store/state/user';
import {
  ChequesNotPaidResponse,
  SogeValeurDto,
} from '../../../models/SogeValeurDto';

@Component({
  selector: 'app-sv-dashboard',
  templateUrl: './sv-dashboard.component.html',
  styleUrls: ['./sv-dashboard.component.scss'],
})
export class SvDashboardComponent implements OnInit {
  tabActive = 'CHEQUE_PAYER';
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  private selectedCustomerNumberSub: any;
  private selectedCustomerNumber: any;
  private selectSogeValeur$ = this.store.select(selectSogeValeurListing);
  private sogeValeurResponse: SogeValeurDto;
  constructor(private store: Store<State>) {}

  ngOnDestroy(): void {
    this.selectedCustomerNumberSub.unsubscribe();
  }
  ngOnInit(): void {
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
          this.store.dispatch(
            fetchSogeValeur({
              customerNumber: this.selectedCustomerNumber,
            })
          );
        }
      }
    );

    this.selectSogeValeur$.subscribe((sogeValeurs) => {
      if (sogeValeurs && sogeValeurs.result) {
        this.sogeValeurResponse = sogeValeurs.result;
      }
    });
  }

  selectTab(tabLabel: string): void {
    this.tabActive = tabLabel;
  }

  isActive(tabLabel: string): boolean {
    return this.tabActive === tabLabel;
  }

  getChequesPayerTotal(): number {
    return this.sogeValeurResponse !== null &&
      this.sogeValeurResponse !== undefined &&
      this.sogeValeurResponse.chequesToPay !== null &&
      this.sogeValeurResponse.chequesToPay !== undefined
      ? this.sogeValeurResponse.chequesToPay.total
      : -2;
  }

  getChequesImpayesTotal(): number {
    return this.sogeValeurResponse !== null &&
      this.sogeValeurResponse !== undefined &&
      this.sogeValeurResponse.chequesNotPaid !== null &&
      this.sogeValeurResponse.chequesNotPaid !== undefined
      ? this.sogeValeurResponse.chequesNotPaid.total
      : -2;
  }

  getEffetsImpayesTotal(): number {
    return this.sogeValeurResponse !== null &&
      this.sogeValeurResponse !== undefined &&
      this.sogeValeurResponse.lcnsNotPaid !== null &&
      this.sogeValeurResponse.lcnsNotPaid !== undefined
      ? this.sogeValeurResponse.lcnsNotPaid.total
      : -2;
  }

  getEffetsPayerTotal(): number {
    return this.sogeValeurResponse !== null &&
      this.sogeValeurResponse !== undefined &&
      this.sogeValeurResponse.lcnsToPay !== null &&
      this.sogeValeurResponse.lcnsToPay !== undefined
      ? this.sogeValeurResponse.lcnsToPay.total
      : -2;
  }
}
