import {
  Component,
  OnInit,
  Output,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { DirectDebitService } from 'src/app/service/direct-debit.service';
import { DirectDebitRecap } from 'src/app/models/DirectDebitRecap';
import { ClientService } from 'src/app/service/client.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-db-init',
  templateUrl: './db-init.component.html',
  styleUrls: ['./db-init.component.scss'],
})
export class DbInitComponent implements OnInit, OnDestroy {
  @Output() fileValidatedEvent = new EventEmitter<any>();
  file: File;
  directDebitRecap: DirectDebitRecap;
  showValidationRecap: boolean = false;
  openPopinMessageFlag: boolean = false;
  openPopinMessageSuccess = 'Opération terminée avec succès';
  openPopinMessageError =
    'Le service est indisponible,  merci de réessayer ultérieurement';
  openPopinMessage = '';
  popinMode = '';
  loaderUploadMessage = 'Merci de patienter';
  loaderMode = false;
  uploadDefaultMessage =
    'Glissez et déposez votre fichier ou cliquez sur le bouton';
  uploadMessage = this.uploadDefaultMessage;
  today = new Date();
  openPopinConfirmFlag = false;
  uploadMessageError = false;
  validateDirectDebitLoader = false;
  fileSrc: string;
  fileReference: string;
  showFileViewer: boolean;
  openPopinSuccessFlag: boolean;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectedCustomerNumberSub: Subscription;

  constructor(
    private directDebitService: DirectDebitService,
    private clientService: ClientService,
    private store: Store<State>
  ) {}

  ngOnDestroy() {
    this.selectedCustomerNumberSub.unsubscribe();
  }
  ngOnInit() {
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
        }
      }
    );
  }

  _onSelect(event) {
    //this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    //this.files.splice(this.files.indexOf(event), 1);
  }

  onSelect(event) {
    this.uploadMessageError = false;
    if (event.addedFiles.length > 0) {
      this.file = event.addedFiles[0];
      this.loaderMode = true;
      this.uploadMessage = this.loaderUploadMessage;

      this.directDebitService
        .upload(this.file, this.selectedCustomerNumber)
        .toPromise()
        .then((result) => {
          this.directDebitRecap = result;
          this.showValidationRecap = true;
        })
        .catch((error) => {
          this.openPopinMessageFlag = true;
          if (error.status == 400) {
            this.openPopinMessage = error.error.message;
          } else {
            this.openPopinMessage = this.openPopinMessageError;
          }
        })
        .finally(() => {
          this.loaderMode = false;
          this.uploadMessage = this.uploadDefaultMessage;
          this.file = null;
        });
    }

    if (event.rejectedFiles.length > 0) {
      this.uploadMessageError = true;
    }
  }

  validateDirectDebit(id) {
    this.validateDirectDebitLoader = true;
    this.directDebitService
      .validateDirectDebitFile(id, this.selectedCustomerNumber)
      .toPromise()
      .then(() => {
        this.openPopinSuccessFlag = true;
      })
      .catch((error) => {
        this.openPopinMessageFlag = true;
        if (error.status == 400) {
          this.openPopinMessage = error.error.message;
        } else {
          this.openPopinMessage = this.openPopinMessageError;
        }
      })
      .finally(() => {
        this.validateDirectDebitLoader = false;
      });
  }

  cancelDirectDebit(id) {
    this.directDebitService
      .cancelDirectDebitFile(id, this.selectedCustomerNumber)
      .toPromise()
      .then(() => {
        this.directDebitRecap = null;
        this.showValidationRecap = false;
        this.loaderMode = false;
        this.uploadMessage = this.uploadDefaultMessage;
        this.file = null;
      })
      .catch((error) => {
        //this.openPopinMessageFlag = true;
        this.closePopin();
        if (error.status == 400) {
          this.openPopinMessage = error.error.message;
        } else {
          this.openPopinMessage = this.openPopinMessageError;
        }
      });
  }

  closePopin() {
    this.openPopinMessageFlag = false;
    this.showValidationRecap = false;
    this.loaderMode = false;
    this.uploadMessage = this.uploadDefaultMessage;
    this.file = null;
    this.directDebitRecap = null;
  }

  closeConfirmPopin(res: boolean) {
    this.uploadMessageError = false;
    this.openPopinConfirmFlag = false;
    if (res) {
      this.cancelDirectDebit(this.directDebitRecap.id);
    }
  }

  viewFile(ref: string) {
    this.directDebitService
      .downloadFile(this.selectedCustomerNumber, ref)
      .subscribe((response) => {
        let blob = new Blob([response]);
        let url = window.URL.createObjectURL(blob);
        this.fileSrc = url;
        this.fileReference = ref;
        this.showFileViewer = true;
      });
  }

  closeSuccessPopin() {
    this.fileValidatedEvent.emit();
  }
}
