<button
  class="download-btn"
  matTooltip="{{ getTooltipText() }}"
  matTooltipPosition="left"
  mat-button
  [matMenuTriggerFor]="menu"
>
  <img src="../../../../assets/images/upload-cloud.svg" alt="'menu-icon" />
</button>
<mat-menu #menu="matMenu" yPosition="above">
  <button mat-menu-item (click)="downloadAllSv()">
    {{ "Télécharger toutes mes valeurs".toUpperCase() }}
  </button>
</mat-menu>
