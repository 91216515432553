import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State } from '../../../core/store/state';
import {
  downloadAllSv,
  downloadChequesUnpaid,
  selectChequeImpayeFromListing,
  selectDownloadAllSv,
  selectDownloadChequesUnpaid,
  initDownloadCallStateData,
} from '../../../core/store/state/soge-valeur';
import { selectSelectedCustomerNumber } from '../../../core/store/state/user';
import { CallStateResponse, handleCallState } from '../../../models/CallState';
import { ChequesNotPaidResponse } from '../../../models/SogeValeurDto';
import { Page } from 'src/app/models/page';
import { ChequeNotPaidDTO } from 'src/app/models/ChequeNotPaidDTO';

@Component({
  selector: 'app-sv-cheque-impaye',
  templateUrl: './sv-cheque-impaye.component.html',
  styleUrls: ['./sv-cheque-impaye.component.scss'],
})
export class SvChequeImpayeComponent implements OnInit {
  selectChequeImpayeSub: Subscription;
  chequesImpayeResponse: ChequesNotPaidResponse;
  currentUnpaidChequesList: ChequeNotPaidDTO[] = [];
  selectChequeImpaye$ = this.store.select(selectChequeImpayeFromListing);
  selectedCustomerNumberSub: Subscription;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber;
  selectDownloadChequesUnpaidFileSub: Subscription;
  selectDownloadChequesUnpaidFile$ = this.store.select(
    selectDownloadChequesUnpaid
  );

  selectDownloadAllSvFileSub: Subscription;
  selectDownloadAllSvFile$ = this.store.select(selectDownloadAllSv);

  handleCallStateDownloadChequesUnpaidFile: CallStateResponse;
  handleCallStateDownloadAllSvFileFile: CallStateResponse;
  showLoader = false;
  page = new Page();

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.page.init();
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          this.selectedCustomerNumber = customerNumber;
        }
      }
    );
    this.selectChequeImpayeSub = this.selectChequeImpaye$.subscribe(
      (sogeValeurs) => {
        if (sogeValeurs && sogeValeurs) {
          this.chequesImpayeResponse = sogeValeurs.result;
          this.page.totalPages = Math.ceil(
            this.chequesImpayeResponse.cheques.length / 12
          );
          this.page.totalElements = this.chequesImpayeResponse.cheques.length;
          this.getCurrentPageUnpaidCheques();
        }
      }
    );

    this.selectDownloadChequesUnpaidFileSub =
      this.selectDownloadChequesUnpaidFile$.subscribe((file) => {
        if (file && file.result) {
          const blob = new Blob([file.result]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'MES CHEQUES IMPAYES.xlsx';
          link.click();
        }
        this.handleCallStateDownloadChequesUnpaidFile = handleCallState(file);
        this.showLoader =
          this.handleCallStateDownloadChequesUnpaidFile.showLoader;
      });

    this.selectDownloadAllSvFileSub = this.selectDownloadAllSvFile$.subscribe(
      (file) => {
        console.log(file);
        if (file && file.result) {
          console.log(file.result);
          const blob = new Blob([file.result]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'MES VALEURS.xlsx';
          link.click();
        }
        this.handleCallStateDownloadAllSvFileFile = handleCallState(file);
        this.showLoader = this.handleCallStateDownloadAllSvFileFile.showLoader;
      }
    );
  }

  ngOnDestroy(): void {
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectedCustomerNumberSub.unsubscribe();
    this.selectChequeImpayeSub.unsubscribe();
    this.selectDownloadChequesUnpaidFileSub.unsubscribe();
    this.selectDownloadAllSvFileSub.unsubscribe();
    this.store.dispatch(initDownloadCallStateData(this.selectedCustomerNumber));
  }

  getCurrentPageUnpaidCheques(): void {
    const start = this.page.currentPage * 12;
    const end =
      this.chequesImpayeResponse.cheques.length >= start + 12
        ? start + 12
        : start + (this.chequesImpayeResponse.cheques.length % 12);
    this.currentUnpaidChequesList = this.chequesImpayeResponse.cheques.slice(
      start,
      end
    );
  }

  getTotalNumber(): number {
    return this.chequesImpayeResponse !== null &&
      this.chequesImpayeResponse !== undefined
      ? this.chequesImpayeResponse.total
      : -2;
  }

  download(): void {
    this.showLoader = true;
    this.store.dispatch(
      downloadChequesUnpaid({
        customerNumber: this.selectedCustomerNumber,
      })
    );
  }

  downloadAll(): void {
    this.showLoader = true;
    this.store.dispatch(
      downloadAllSv({
        customerNumber: this.selectedCustomerNumber,
      })
    );
  }
}
