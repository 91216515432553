import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import {
  appLoaded,
  selectConnectedUser,
  selectSelectedCustomerNumber,
} from 'src/app/core/store/state/user';

@Injectable({
  providedIn: 'root',
})
export abstract class GenericGuard implements CanActivate {
  selectConnectedUser$ = this.store.select(selectConnectedUser);
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectedCustomerNumberSub: any;

  constructor(public store: Store<State>) {}

  abstract canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    resolve,
    reject
  );

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((resolve, reject) => {
      this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
        (customerNumber) => {
          if (customerNumber) {
            this.canActivateChild(next, state, resolve, reject);

            if (this.selectedCustomerNumberSub !== undefined)
              this.selectedCustomerNumberSub.unsubscribe();
          } else {
            this.store.dispatch(appLoaded());
          }
        }
      );
    });
  }
}
