import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ESignState } from './e-sign.state';

export const selectESign = createFeatureSelector<ESignState>('eSign');

export const selectESignList = createSelector(
  selectESign,
  (state: ESignState) => state.eSignListing.eSigns
);
export const initESignature = createSelector(
  selectESign,
  (state: ESignState) => state.initialSignature.initialSign
);
export const checkESign = createSelector(
  selectESign,
  (state: ESignState) => state.checkSignature.checkESign
);
export const selectDownloadFile = createSelector(
  selectESign,
  (state: ESignState) => state.downloadFileESign.downloadFile
);
export const selectESignatoryList = createSelector(
  selectESign,
  (state: ESignState) => state.eSignatoryListing.eSignatorys
);
export const selectESignListCoutingPending = createSelector(
  selectESign,
  (state: ESignState) => state.countingeSignatoryPending.countingPending
);
export const selectESignListCoutingHistoric = createSelector(
  selectESign,
  (state: ESignState) => state.countingeSignatoryHistoric.countingHistoric
);
