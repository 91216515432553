export class RequestHistoricCard {
  public customerNumber: string;
  public cardNumber: string;
  public search: string;
  public rib: string;
  public page: number;
  public pageSize: number;
  public startDate: Date;
  public endDate: Date;
}
