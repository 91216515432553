import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EscaleProRoutingModule } from './escale-pro-routing.module';
import { EpDashboardComponent } from './ep-dashboard/ep-dashboard.component';
import { EpInitComponent } from './ep-init/ep-init.component';
import { EpToSignComponent } from './ep-to-sign/ep-to-sign.component';
import { EpCardDetailsComponent } from './ep-card-details/ep-card-details.component';
import {
  MatCardModule,
  MatIconModule,
  MatSlideToggleModule,
  MatSnackBarModule,
} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../shared-module/shared.module';
import { EpChargeCardComponent } from './ep-charge-card/ep-charge-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { EpHistoryComponent } from './ep-history/ep-history.component';
import { MatMenuModule } from '@angular/material/menu';
import { EpCardFilterComponent } from './ep-card-filter/ep-card-filter.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EpCardTrackingFilterComponent } from './ep-card-tracking-filter/ep-card-tracking-filter.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { EpSignatureHistoryComponent } from './ep-signature-history/ep-signature-history.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    EpDashboardComponent,
    EpInitComponent,
    EpToSignComponent,
    EpCardDetailsComponent,
    EpChargeCardComponent,
    EpCardFilterComponent,
    EpHistoryComponent,
    EpCardTrackingFilterComponent,
    EpSignatureHistoryComponent,
  ],
  imports: [
    CommonModule,
    EscaleProRoutingModule,
    MatCardModule,
    MatDialogModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    NgxSelectModule,
    MatSelectModule,
    MatSnackBarModule,
  ],
  entryComponents: [
    EpCardDetailsComponent,
    EpCardTrackingFilterComponent,
    EpCardFilterComponent,
    EpSignatureHistoryComponent,
  ],
})
export class EscaleProModule {}
