export interface CallState {
  error: any;
  isLoading: boolean;
  isLoaded: boolean;
  displayMessage: boolean;
}

export interface CallStateResponse {
  displayError: boolean;
  showLoader: boolean;
  openPopinMessage: string;
  openPopinMessageSuccess: boolean;
}

export function handleCallState(state): CallStateResponse {
  let displayError = false;
  let showLoader = false;
  let openPopinMessage = '';
  let openPopinMessageSuccess = false;
  const uploadDefaultMessageError =
    'Le service est indisponible,  merci de réessayer ultérieurement';
  const uploadDefaultMessageSuccess = 'Opération terminée avec succès';

  if (state && state.callState) {
    let callState = state.callState;

    //Loader Case
    if (callState.isLoading && callState.displayMessage) {
      showLoader = true;
    } else {
      showLoader = false;
    }

    //Error Case
    if (
      !callState.isLoading &&
      !callState.isLoaded &&
      callState.error &&
      callState.displayMessage
    ) {
      if (callState.error.status == 400) {
        openPopinMessage = callState.error.error.message;
      } else {
        openPopinMessage = uploadDefaultMessageError;
      }
      displayError = true;
    }

    //Success Case
    if (
      callState.isLoaded &&
      !callState.isLoading &&
      !callState.error &&
      callState.displayMessage
    ) {
      openPopinMessage = uploadDefaultMessageSuccess;
      openPopinMessageSuccess = true;
      displayError = true;
    }
  }

  return {
    displayError,
    showLoader,
    openPopinMessage,
    openPopinMessageSuccess,
  };
}
