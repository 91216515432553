import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { User } from 'src/app/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { waitForMap } from '@angular/router/src/utils/collection';
import { LoaderService } from 'src/app/service/loader.service';
import { iif, of, Subscription } from 'rxjs';
import { LoaderState } from 'src/app/models/loader';
import { mapTo, mergeMap } from 'rxjs/operators';
import { ClientSharedService } from 'src/app/service/client-shared.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectUserApplications } from 'src/app/core/store/state/user';

@Component({
  selector: 'app-app-iframe',
  templateUrl: './app-iframe.component.html',
  styleUrls: ['./app-iframe.component.scss'],
})
export class AppIframeComponent implements OnInit {
  disableContent = false;
  selectUserApplications$ = this.store.select(selectUserApplications);

  constructor(
    private userService: UserService,
    private activatdRoute: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
    private clientSharedService: ClientSharedService,
    private store: Store<State>
  ) {}

  @ViewChild('iframe') iframe: ElementRef;
  url: string;
  apps = [];
  public user: User;
  public userApps = [];
  private subscription: Subscription;
  customerNumber: string;

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        of(state)
          .pipe(
            mergeMap(() =>
              iif(() => !state.show, of(state).pipe(mapTo(null)), of(null))
            )
          )
          .subscribe(() => {
            this.disableContent = state.show;
          });
      }
    );

    this.selectUserApplications$.subscribe((userApplications) => {
      this.userApps = userApplications;
      const appName = this.activatdRoute.snapshot.params['code'];
      this.userApps.forEach((app) => {
        if (app.code == appName) {
          this.url = app.link;
        }
      });
      if (this.url != undefined) {
        this.iframe.nativeElement.setAttribute('src', this.url);
      } else {
        if (userApplications.length > 0) this.router.navigate(['/']);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
