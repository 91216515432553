<div class="wrapper" translate="no">
  <div class="header-container">
    <h2>RECHERCHE AVANCÉE</h2>
    <button type="button" class="close-btn" (click)="close()">
      <img src="assets/images/close-white.svg" alt="" />
    </button>
  </div>
  <div class="inputs-container">
    <div class="custom-fields-wrapper">
      <h4>Titulaire</h4>
      <div class="custom-fields-container">
        <mat-form-field class="custom-field">
          <mat-label>Nom</mat-label>
          <input [(ngModel)]="lastName" type="text" id="lastName" name="lastName" matInput />
        </mat-form-field>
        <mat-form-field class="custom-field">
          <mat-label>Prénom</mat-label>
          <input [(ngModel)]="firstName" type="text" id="firstName" name="firstName" matInput />
        </mat-form-field>
      </div>
    </div>
    <div class="cards-wrapper">
      <h4>
        Cartes : <span>Vous pouvez sélectionner une ou plusieurs cartes</span>
      </h4>
      <div class="card-type-container">
        <div (click)="toogleCardType(cardType)" class="card-type-container-elem"
          *ngFor="let cardType of cardsTypeInitVal" [ngClass]="
            cardsType.indexOf(cardType, 0) > -1 ? 'selected-card-type ' : ''
          ">


          <div *ngIf="cardType == CardTypeEnum.ESCALEPRO">
            <p> Escale Pro </p>
          </div>
          <div *ngIf="cardType == CardTypeEnum.INFINITE">
            <p> Infinite </p>
          </div>
          <div *ngIf="cardType == CardTypeEnum.GLOBALDEPOSIT">
            <p> Global Deposit </p>
          </div>
          <div *ngIf="cardType == CardTypeEnum.CORPORATE">
            <p> Corporate </p>
          </div>
          <div *ngIf="cardType == CardTypeEnum.BUSINESS">
            <p> Business </p>
          </div>
          <div *ngIf="cardType == CardTypeEnum.DEVISES">
            <p> Devises </p>
          </div>
          <div *ngIf="cardType == CardTypeEnum.GOLD">
            <p> Gold</p>
          </div>
          <div *ngIf="cardType == CardTypeEnum.ENTREPRISE">
            <p> Entreprise </p>
          </div>
          <img *ngIf="cardType == CardTypeEnum.ESCALEPRO" src="/assets/images/cartes/ESCALEPRO.png" alt="filter-icon" />
          <img *ngIf="cardType == CardTypeEnum.CORPORATE" src="/assets/images/cartes/CORPORATE.png" alt="filter-icon" />
          <img *ngIf="cardType == CardTypeEnum.BUSINESS" src="/assets/images/cartes/BUSINESS.png" alt="filter-icon" />
          <img *ngIf="cardType == CardTypeEnum.DEVISES" src="/assets/images/cartes/DEVISES.png" alt="filter-icon" />
          <img *ngIf="cardType == CardTypeEnum.GOLD" src="/assets/images/cartes/GOLD.png" alt="filter-icon" />
          <img *ngIf="cardType == CardTypeEnum.ENTREPRISE" src="/assets/images/cartes/ENTREPRISE.png"
            alt="filter-icon" />
          <img *ngIf="cardType == CardTypeEnum.INFINITE" src="/assets/images/cartes/INFINITE.png" alt="filter-icon" />
          <img *ngIf="cardType == CardTypeEnum.GLOBALDEPOSIT" src="/assets/images/cartes/GLOBALDEPOSIT.png"
            alt="filter-icon" />
        </div>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="white-bg" [disabled]="
        !(lastName !== '' || firstName !== '' || cardsType.length > 0)
      " (click)="reinitForm()">
      RÉINITIALISER
    </button>
    <button (click)="applyFilter()" [disabled]="
        !(lastName !== '' || firstName !== '' || cardsType.length > 0)
      ">
      FILTRER
    </button>
  </div>
</div>