import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Cards } from 'src/app/models/cards';
import { CardCeiling } from 'src/app/models/cardCeiling';
import { CardPlafonds } from '../sell-card-enum/card_plafonds';

import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-sell-card-ceiling-info',
  templateUrl: './sell-card-ceiling-info.component.html',
  styleUrls: ['./sell-card-ceiling-info.component.scss'],
})
export class SellCardCeilingInfoComponent implements OnInit {
  withdrawalNationalSg: number[];
  max: number = 50000;
  min: number = 100;

  withdrawalNationalColleagues: number[];
  step: boolean = true;
  purchaseNational: number[];
  purchaseNationalOnline: number[];
  withdrawalInterNational: number[];
  purchaseInternational: number[];
  purchaseInternationalOnline: number[];
  cardCeiling: CardCeiling[];
  @Input() userLoaded: boolean = false;
  @Output() stepEvent = new EventEmitter<number>();
  @Output() formCeilingData = new EventEmitter<any>();
  @Input() ceilingUpdate: any;
  @Input() cardSelected: Cards;
  @Output() closedPopine = new EventEmitter<Boolean>();
  adressfill: boolean = false;
  closed: boolean = true;
  @Input() isPopupVisible: boolean = true;
  title = 'Demander Pour un Bénéficaire';
  ceilingFormGroup: FormGroup;
  ceiling = {
    withdrawal_national_sg: '',
    withdrawal_national_colleagues: '',
    withdrawal_international: '',
    purchase_national: '',
    purchase_national_online: '',
    purchase_international: '',
    purchase_international_online: '',
  };

  myForm: FormGroup;
  isEditing = false;
  showValidationMessage: boolean = false;
  currentEditingForm: FormControl;
  constructor(private fb: FormBuilder) {
    this.ceilingFormGroup = this.fb.group({
      withdrawal_national_sgForm: [
        null,
        [Validators.required, this.customValidator],
      ],
      withdrawalNationalColleaguesForm: [
        null,
        [Validators.required, this.customValidator],
      ],
      purchaseNationalForm: [null, [Validators.required, this.customValidator]],
      withdrawalInterNationalForm: [
        null,
        [Validators.required, this.customValidator],
      ],
      purchaseNationalOnlineForm: [
        null,
        [Validators.required, this.customValidator],
      ],
      purchaseInternationalForm: [
        null,
        [Validators.required, this.customValidator],
      ],
      purchaseInternationalOnlineForm: [
        null,
        [Validators.required, this.customValidator],
      ],
    });
  }

  ngOnInit() {
    if (this.ceilingUpdate != null && this.ceilingUpdate != undefined) {
      this.withdrawal_national_sgForm.setValue(
        this.ceilingUpdate.withdrawal_national_sg
      );
      this.withdrawalNationalColleaguesForm.setValue(
        this.ceilingUpdate.withdrawal_national_colleagues
      );
      this.purchaseInternationalForm.setValue(
        this.ceilingUpdate.purchase_international
      );
      this.purchaseInternationalOnlineForm.setValue(
        this.ceilingUpdate.purchase_international_online
      );
      this.purchaseNationalForm.setValue(this.ceilingUpdate.purchase_national);
      this.withdrawalInterNationalForm.setValue(
        this.ceilingUpdate.withdrawal_international
      );
      this.purchaseNationalOnlineForm.setValue(
        this.ceilingUpdate.purchase_national_online
      );
      this.ceiling = this.ceilingUpdate;
    }
    if (this.userLoaded) {
      this.title = 'Demander Pour  Moi meme';
    }

    if (
      this.cardSelected != null &&
      this.cardSelected != undefined &&
      this.cardSelected.alias === CardPlafonds.EscalePro
    ) {
      this.step = false;
      this.max = 60000;
      this.min = 0;
      this.purchaseNationalForm.setValue(0);
      this.withdrawalNationalColleaguesForm.setValue(0);
      this.withdrawal_national_sgForm.setValue(0);
      this.purchaseNationalOnlineForm.setValue(0);
    }
  }
  send() {
    this.ceiling.withdrawal_national_sg = this.withdrawal_national_sgForm.value;
    this.ceiling.withdrawal_national_colleagues =
      this.withdrawalNationalColleaguesForm.value;
    this.ceiling.purchase_international = this.purchaseInternationalForm.value;
    this.ceiling.purchase_international_online =
      this.purchaseInternationalOnlineForm.value;
    this.ceiling.purchase_national = this.purchaseNationalForm.value;
    this.ceiling.purchase_national_online =
      this.purchaseNationalOnlineForm.value;
    this.ceiling.withdrawal_international =
      this.withdrawalInterNationalForm.value;

    this.formCeilingData.emit(this.ceiling);
    this.stepEvent.emit(5);
  }
  closePopup() {
    this.stepEvent.emit(0);
  }
  receiveStatusPopin(closedPopin: boolean) {
    this.closedPopine.emit(closedPopin);
    if (closedPopin == true) {
      this.ceiling = {
        withdrawal_national_sg: '',
        withdrawal_international: '',
        withdrawal_national_colleagues: '',
        purchase_national: '',
        purchase_national_online: '',
        purchase_international: '',
        purchase_international_online: '',
      };
    }
  }
  public selectIsOpened: boolean;
  selectIsopened() {
    this.selectIsOpened = true;
  }
  selectIsclosed() {
    this.selectIsOpened = false;
  }
  get withdrawal_national_sgForm(): FormControl | null {
    return this.ceilingFormGroup
      ? (this.ceilingFormGroup.get('withdrawal_national_sgForm') as FormControl)
      : null;
  }

  get withdrawalNationalColleaguesForm(): FormControl | null {
    return this.ceilingFormGroup
      ? (this.ceilingFormGroup.get(
          'withdrawalNationalColleaguesForm'
        ) as FormControl)
      : null;
  }

  get purchaseNationalForm(): FormControl | null {
    return this.ceilingFormGroup
      ? (this.ceilingFormGroup.get('purchaseNationalForm') as FormControl)
      : null;
  }

  get withdrawalInterNationalForm(): FormControl | null {
    return this.ceilingFormGroup
      ? (this.ceilingFormGroup.get(
          'withdrawalInterNationalForm'
        ) as FormControl)
      : null;
  }

  get purchaseNationalOnlineForm(): FormControl | null {
    return this.ceilingFormGroup
      ? (this.ceilingFormGroup.get('purchaseNationalOnlineForm') as FormControl)
      : null;
  }

  get purchaseInternationalForm(): FormControl | null {
    return this.ceilingFormGroup
      ? (this.ceilingFormGroup.get('purchaseInternationalForm') as FormControl)
      : null;
  }

  get purchaseInternationalOnlineForm(): FormControl | null {
    return this.ceilingFormGroup
      ? (this.ceilingFormGroup.get(
          'purchaseInternationalOnlineForm'
        ) as FormControl)
      : null;
  }

  validateNumber(control) {
    const value = control.value;
    if (value % 100 !== 0 || value > 5000) {
      return { invalidNumber: true };
    }
    return null;
  }

  customValidator = (control) => {
    const value = control.value;

    if (value == null) {
      return { invalidInput: false };
    }
    const hasDot = (value + '').includes('.');

    // Si la valeur contient un point, déclarer une erreur
    if (hasDot) {
      return { invalidInput: true };
    }
    // Supprimez les espaces de la valeur pour la validation
    const numericValue = +(value + '').replace(/\s/g, '');

    console.log('u value', numericValue);

    const isValid: boolean =
      this.cardSelected != null &&
      this.cardSelected != undefined &&
      this.cardSelected.alias !== CardPlafonds.EscalePro
        ? numericValue % 100 === 0 &&
          numericValue >= 0 &&
          numericValue <= this.max
        : numericValue >= 0 && numericValue <= this.max;

    return isValid ? null : { invalidInput: true };
  };

  ngOnChanges(changes: SimpleChanges) {
    if (this.ceilingFormGroup != null && this.ceilingFormGroup != undefined) {
      if ('userLoaded' in changes) {
        this.clearForm();
        if (this.userLoaded) {
          this.title = 'Demander Pour  Moi meme';
        }
        if (!this.userLoaded) {
          this.title = 'Demander Pour un Bénéficaire';
        }
      }
    }
  }
  clearForm() {
    // Clear the ceiling object
    this.ceiling = {
      withdrawal_national_sg: '',
      withdrawal_international: '',
      withdrawal_national_colleagues: '',
      purchase_national: '',
      purchase_national_online: '',
      purchase_international: '',
      purchase_international_online: '',
    };

    // Reset form controls
    if (this.ceilingFormGroup) {
      this.ceilingFormGroup.reset({
        withdrawal_national_sg: '',
        withdrawal_international: '',
        withdrawal_national_colleagues: '',
        purchase_national: '',
        purchase_national_online: '',
        purchase_international: '',
        purchase_international_online: '',
      });
      if (
        this.cardSelected != null &&
        this.cardSelected != undefined &&
        this.cardSelected.alias === CardPlafonds.EscalePro
      ) {
        this.step = false;
        this.max = 60000;
        this.min = 0;
        this.purchaseNationalForm.setValue(0);
        this.withdrawalNationalColleaguesForm.setValue(0);
        this.withdrawal_national_sgForm.setValue(0);
        this.purchaseNationalOnlineForm.setValue(0);
      }
    }

    // Update the ceilingUpdate object if necessary
    this.ceilingUpdate = this.ceiling;
  }
}
