import { UserRolesDTO } from '../models/UserRolesDto';

export abstract class Roles {
  public static BENEFICIARY_INITIATOR = 'BENEFICIARY_INITIATOR';
  public static BENEFICIARY_VALIDATOR = 'BENEFICIARY_VALIDATOR';

  public static PROVISION_INITIATOR = 'PROVISION_INITIATOR';
  public static PROVISION_VALIDATOR = 'PROVISION_VALIDATOR';

  public static OTHER_CHANEL_VALIDATOR = 'OTHER_CHANEL_VALIDATOR';
  public static OTHER_CHANEL_CONSULTANT = 'OTHER_CHANEL_CONSULTANT';

  public static DIRECT_DEBIT_SIGNATORY = 'DIRECT_DEBIT_SIGNATORY';
  public static DIRECT_DEBIT_VIEWER = 'DIRECT_DEBIT_VIEWER';
  public static DIRECT_DEBIT_CHECKER = 'DIRECT_DEBIT_CHECKER';
  public static DIRECT_DEBIT_INITIATOR = 'DIRECT_DEBIT_INITIATOR';

  public static ESCALE_PRO_SIGNATORY = 'ESCALE_PRO_SIGNATORY';
  public static ESCALE_PRO_INITIATOR = 'ESCALE_PRO_INITIATOR';

  public static hasRole(result: UserRolesDTO, role: string): boolean {
    return result.userRoles.indexOf(role) !== -1;
  }

  public static roleActionsMapping: any = {
    PROVISION_INITIATOR: ['init_provision'],
    BENEFICIARY_INITIATOR: ['init_beneficiary', 'show_beneficiary'],
    BENEFICIARY_VALIDATOR: ['show_beneficiary', 'validate_beneficiary'],
  };
}
