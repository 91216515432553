import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  EscaleProSelectedCard,
  refillCard,
} from 'src/app/core/store/state/escale-pro';
import { CardEscaleProDetailsDTO } from 'src/app/models/CardEscaleProDetailsDTO';
import {
  CardReloadDTO,
  CardRelodAccountsDTO,
} from 'src/app/models/CardReloadDTO';

import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { CardRefillDto } from 'src/app/models/CardRefillDto';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-ep-charge-card',
  templateUrl: './ep-charge-card.component.html',
  styleUrls: ['./ep-charge-card.component.scss'],
})
export class EpChargeCardComponent implements OnInit {
  @Output() closeTab = new EventEmitter<void>();
  @Input() selectedCard: EscaleProSelectedCard;
  @Input() accountToBeDebited: CardReloadDTO;
  amount: string;
  motif: string;
  curDate: Date;
  @Input() selectedCustomerNumber: string;
  selectedAccountToBeDebited: string;
  selectedAccountToBeDebitedObject: CardRelodAccountsDTO;

  constructor(private store: Store<State>) {}
  ngOnInit(): void {
    this.curDate = new Date();
    this.selectedAccountToBeDebitedObject = this.accountToBeDebited.accounts[0];
    this.selectedAccountToBeDebited =
      this.accountToBeDebited.accounts[0].ibanFormatAccount;
  }
  close() {
    this.closeTab.emit();
  }
  isAmountValid(): boolean {
    if (isNaN(parseFloat(this.amount))) return false;
    return true;
  }
  refillCard() {
    this.store.dispatch(
      refillCard({
        customerNumber: this.selectedCustomerNumber,
        cardNumber: this.selectedCard.card.cardNumber,
        cardRefillInfos: new CardRefillDto(
          '',
          '',
          parseFloat(this.amount),
          this.motif,
          this.selectedCard.card.expiryDate,
          new Date(),
          this.selectedCard.card.accountNumber,
          this.selectedAccountToBeDebited
        ),
      })
    );
    this.close();
  }

  selectionChanges(event) {
    this.selectedAccountToBeDebitedObject =
      this.accountToBeDebited.accounts.filter(
        (account) =>
          account.ibanFormatAccount == this.selectedAccountToBeDebited
      )[0];
  }
}
