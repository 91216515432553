import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cards } from '../models/cards';
import { environment } from '../../environments/environment';
import { CardMail } from 'src/app/models/cardMail';
const httpOptions = {
  headers: new HttpHeaders({
    enctype: 'multipart/form-data',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class SellCardService {
  constructor(private http: HttpClient) {}

  public findCards(): Observable<Cards[]> {
    return this.http.get<Cards[]>(`${environment.apiUrl}/cards`, httpOptions);
  }
  public sendMailToBeneficiaries(cardMail: CardMail): Observable<void> {
    const formData = new FormData();
    formData.append('name', cardMail.name);
    formData.append('birthDate', cardMail.birthDate);
    formData.append('expirationDate', cardMail.expirationDate);
    formData.append('operator', cardMail.operator);
    formData.append('email', cardMail.email);
    formData.append('phoneNumber', cardMail.phoneNumber);
    formData.append('birthCountry', cardMail.birthCountry);
    formData.append('birthCity', cardMail.birthCity);
    formData.append('cin', cardMail.cin);
    formData.append('codeClient', cardMail.codeClient);
    formData.append('libelleCard', cardMail.libelleCard);
    formData.append('withdrawalNationalSg', cardMail.withdrawalNationalSg);
    formData.append('purchaseNationalOnline', cardMail.purchaseNationalOnline);
    formData.append(
      'withdrawalInternational',
      cardMail.withdrawalInternational
    );
    formData.append('purchaseInternational', cardMail.purchaseInternational);
    formData.append(
      'purchaseInternationalOnline',
      cardMail.purchaseInternationalOnline
    );
    formData.append('purchaseNational', cardMail.purchaseNational);
    formData.append(
      'withdrawalNationalColleagues',
      cardMail.withdrawalNationalColleagues
    );
    formData.append('account', cardMail.account);
    formData.append('selectedIdType', cardMail.selectedIdType);
    formData.append('recto', cardMail.recto);
    formData.append('verso', cardMail.verso);
    formData.append('idType', cardMail.selectedIdType);

    return this.http.put<void>(
      `${environment.apiUrl}/cards/sendmail/beneficiaries`,
      formData,
      httpOptions
    );
  }

  public sendMailToUser(cardMail: CardMail): Observable<void> {
    const formData = new FormData();
    formData.append('name', cardMail.name);
    formData.append('birthDate', cardMail.birthDate);
    formData.append('expirationDate', cardMail.expirationDate);
    formData.append('operator', cardMail.operator);
    formData.append('email', cardMail.email);
    formData.append('phoneNumber', cardMail.phoneNumber);
    formData.append('birthCountry', cardMail.birthCountry);
    formData.append('birthCity', cardMail.birthCity);
    formData.append('cin', cardMail.cin);
    formData.append('codeClient', cardMail.codeClient);
    formData.append('libelleCard', cardMail.libelleCard);
    formData.append('withdrawalNationalSg', cardMail.withdrawalNationalSg);
    formData.append('purchaseNationalOnline', cardMail.purchaseNationalOnline);
    formData.append(
      'withdrawalInternational',
      cardMail.withdrawalInternational
    );
    formData.append('purchaseInternational', cardMail.purchaseInternational);
    formData.append(
      'purchaseInternationalOnline',
      cardMail.purchaseInternationalOnline
    );
    formData.append('purchaseNational', cardMail.purchaseNational);
    formData.append(
      'withdrawalNationalColleagues',
      cardMail.withdrawalNationalColleagues
    );
    formData.append('account', cardMail.account);
    formData.append('selectedIdType', cardMail.selectedIdType);
    formData.append('recto', cardMail.recto);
    formData.append('verso', cardMail.verso);
    formData.append('idType', cardMail.selectedIdType);
    return this.http.put<void>(
      `${environment.apiUrl}/cards/sendmail/users`,
      formData,
      httpOptions
    );
  }
}
