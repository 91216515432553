<div class="custom-edit-input-wrapper">
  <h2>
    {{ label }}
  </h2>
  <mat-form-field *ngIf="control" floatLabel="never" class="custom-edit-field" [ngClass]="{
    editing: isEditing,
    valid: validationSuccess,
    invalid: control.hasError('invalidInput')
  }">
    <input matInput [formControl]="control" [disabled]="!isEditing" [readonly]="!isEditing" appFormatNumber />


    <span *ngIf="!isEditing && !control.value" class="placeholder">Saisissez un nombre (max {{ max }} MAD)</span>

    <button class="edit-btn" *ngIf="!isEditing" matSuffix (click)="startEditing()">
      <img src="/assets/images/icon-edit.svg" alt="edit icon" />
    </button>

    <div class="editing-actions" *ngIf="isEditing">
      <button mat-icon-button class="cancel-btn" (click)="cancelEditing()">
        <img src="/assets/images/close_plafonds.svg" alt="cancel icon" />
      </button>
      <button mat-icon-button class="validate-btn" (click)="validateEditing()" [disabled]="
          control.hasError('required') || control.hasError('invalidInput')
        ">
        <img src="/assets/images/ic_check_plafonds.svg" alt="validate icon" />
      </button>
    </div>
  </mat-form-field>
  <div *ngIf="validationSuccess" class="success-message tooltip-behavior">
    <p>La valeur est valide!</p>
  </div>
  <div *ngIf="control.hasError('invalidInput') && !isCardescalePro" class="error-message tooltip-behavior">
    <p>
      La valeur n'est pas valide. Elle doit être un multiple de
      <span>100</span> et être entre <span>{{ min }}</span> et
      <span>{{ max }}</span> ,et ne doit pas contenir de caractère.

    </p>
  </div>

  <div *ngIf="control.hasError('invalidInput') && isCardescalePro" class="error-message tooltip-behavior">
    <p>
      La valeur n'est pas valide. Elle doit être entre <span>{{ min }}</span> et
      <span>{{ max }}</span>,et ne doit pas contenir de caractère.

    </p>
  </div>