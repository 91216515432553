import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Operator } from 'src/app/models/operator';
import { User } from 'src/app/models/user';
import { OperatorService } from 'src/app/service/operator.service';
import { UserService } from 'src/app/service/user.service';
import { SoftTokenActivationService } from 'src/app/service/soft-token-activation.service';
import { SoftTokenActivation } from 'src/app/models/softTokenActivation';
import { Util } from 'src/app/utils/util';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectConnectedUser } from 'src/app/core/store/state/user';
@Component({
  selector: 'app-on-boading-first-step',
  templateUrl: './on-boading-first-step.component.html',
  styleUrls: ['./on-boading-first-step.component.scss'],
})
export class OnBoadingFirstStepComponent implements OnInit {
  form = new FormGroup({
    options: new FormControl('', Validators.required),
  });
  public user: User;
  public operators: Operator[];
  softTokenActivation: SoftTokenActivation;
  uuid: string;
  userId: string;
  public operatorSelect: Operator;
  phoneNumber = '';
  check: Boolean;
  subject = new BehaviorSubject('default');
  orders = [];
  options = [];
  selectConnectedUser$ = this.store.select(selectConnectedUser);

  constructor(
    private userService: UserService,
    private router: Router,
    private softTokenActivationService: SoftTokenActivationService,
    private operatorService: OperatorService,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.check = true;
    let operator = new Operator();
    operator.code = 'Autre';
    operator.libelle = 'Autre';
    this.selectConnectedUser$.subscribe((user) => {
      if (user) {
        this.phoneNumber = Util.replacePhone(user.phoneNumber, 2, '*');
        this.user = user;
      }
    });

    this.operatorService.getOperators().subscribe((operators) => {
      this.operators = operators;
      this.operators.push(operator);
    });
  }
  activateSoftToken() {
    if (
      this.operatorSelect != null &&
      this.operatorSelect != undefined &&
      this.operatorSelect.code !== 'Autre'
    ) {
      this.user.operator = this.operatorSelect;
    }

    this.softTokenActivationService
      .activerSoftToken(this.user)
      .subscribe(() => {
        localStorage.setItem('numberstep', '1');

        this.softTokenActivationService.hasSoftToken().subscribe((data) => {
          this.softTokenActivation = data;
          const queryParams = {
            uuid: this.softTokenActivation.uuid,
          };
          let url = this.router.createUrlTree(['/soft-token'], {
            queryParams: queryParams,
          });
          window.open(url.toString(), '_blank');
        });
      });
  }
  selectOperator() {
    this.check = false;
  }
}
