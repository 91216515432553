import { Component, OnInit } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Operator } from 'src/app/models/operator';
import { User } from 'src/app/models/user';
import { OperatorService } from 'src/app/service/operator.service';
import { UserService } from 'src/app/service/user.service';
import { SoftTokenActivationService } from 'src/app/service/soft-token-activation.service';
import { SoftTokenActivation } from 'src/app/models/softTokenActivation';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectConnectedUser } from 'src/app/core/store/state/user';

@Component({
  selector: 'app-soft-token-activate',
  templateUrl: './soft-token-activate.component.html',
  styleUrls: ['./soft-token-activate.component.scss'],
})
export class SoftTokenActivateComponent implements OnInit {
  form = new FormGroup({
    options: new FormControl('', Validators.required),
  });
  public user: User;
  public operators: Operator[];
  softTokenActivation: SoftTokenActivation;
  uuid: string;
  userId: string;
  public operatorSelect: Operator;
  phoneNumber = '';
  check: string;
  send: Boolean;
  notsend: Boolean;
  subject = new BehaviorSubject('default');
  orders = [];
  options = [];
  activateCss: Boolean;
  operatorCssActive: string;
  operatorSelectionner: string;
  indexs: string;
  messageError: string;
  selectConnectedUser$ = this.store.select(selectConnectedUser);

  constructor(
    private userService: UserService,
    private router: Router,
    private softTokenActivationService: SoftTokenActivationService,
    private _snackBar: MatSnackBar,
    private operatorService: OperatorService,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.messageError =
      'Désolé !! Vous avez atteint le nombre maximale d’envoies du code. Nous vous prions de contacter nos support';
    this.send = false;
    this.notsend = false;
    this.activateCss = true;
    this.check = 'false';
    let operator = new Operator();
    operator.code = 'more';
    operator.libelle = 'Autre Opérateur';
    this.selectConnectedUser$.subscribe((user) => {
      if (user) {
        this.phoneNumber = user.phoneNumber;
        this.user = user;
      }
    });

    this.operatorService.getOperators().subscribe((operators) => {
      this.operators = operators;
      this.operators.push(operator);

      operators.forEach((op) => {
        op.icone =
          '/assets/images/softToken/ic_op_' + op.libelle.toLowerCase() + '.svg';
        if (op.code == 'more') {
          op.icone = '/assets/images/softToken/ic_op_more.svg';
        }
      });
    });
  }
  activateSoftToken() {
    if (
      this.operatorSelect != null &&
      this.operatorSelect != undefined &&
      this.operatorSelect.code !== 'more'
    ) {
      this.user.operator = this.operatorSelect;
    }
    this.check = 'true';
    this.softTokenActivationService
      .activerSoftToken(this.user)
      .toPromise()

      .then(() => {
        localStorage.setItem('numberstep', '1');
        this.send = true;
        this.softTokenActivationService
          .hasSoftToken()
          .toPromise()

          .then((data) => {
            this.softTokenActivation = data;
            const queryParams = {
              uuid: this.softTokenActivation.uuid,
            };
            let url = this.router.createUrlTree(['/soft-token'], {
              queryParams: queryParams,
            });
            window.open(url.toString(), '_blank');

            this.send = true;
          })
          .catch(() => {
            this.messageError =
              'Désolé !! un erreur est servenu Nous vous prions de contacter nos support';
            this.notsend = true;
            this.openSnackBar(this.messageError);
          });
      })
      .catch(() => {
        this.messageError =
          'Désolé !! un erreur est servenu Nous vous prions de contacter nos support';
        this.notsend = true;
        this.openSnackBar(this.messageError);
      });
  }
  selectOperator(value: Operator, index: string) {
    this.activateCss = false;

    this.indexs = index;
    this.operatorSelect = value;
  }
  downloadGuide() {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/pdf/mybusiness_guide.pdf';
    link.download = 'mybusiness_guide.pdf';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      duration: 20000,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: ['snack-error'],
    });
  }
  toDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
