import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sv-download',
  templateUrl: './sv-download.component.html',
  styleUrls: ['./sv-download.component.scss'],
})
export class SvDownloadComponent implements OnInit {
  showActionsMenu: boolean;
  @Input()
  downloadOneSvTypeText: string;
  @Input()
  svTotalNumber: number;
  @Output()
  dispatchDownloadAction = new EventEmitter<void>();
  @Output()
  dispatchDownloadAllSvAction = new EventEmitter<void>();
  constructor() {
    this.showActionsMenu = false;
  }

  ngOnInit() {}

  getTooltipText() {
    return this.svTotalNumber === 0
      ? 'Télécharger toutes mes valeurs'
      : this.downloadOneSvTypeText + ' ou toutes mes valeurs';
  }
  toggleActionsMenu(): void {
    this.showActionsMenu = !this.showActionsMenu;
  }

  downloadAllSv(): void {
    this.dispatchDownloadAllSvAction.emit();
  }

  downloadSv(): void {
    this.dispatchDownloadAction.emit();
  }
}
