import { ESignatory } from 'src/app/modules/e-sign/models/eSignatory';
import { CallState } from '../../../../models/CallState';
import { eSignPagination } from '../../../../modules/e-sign/models/eSignPagination';
import { InitSignatureResponse } from '../../../../modules/e-sign/models/init-signature-response';
export interface ESignState {
  eSignListing: {
    eSigns: { result: eSignPagination; callState: CallState };
  };
  initialSignature: {
    initialSign: { result: InitSignatureResponse; callState: CallState };
  };
  checkSignature: {
    checkESign: { result: Boolean; callState: CallState };
  };
  downloadFileESign: {
    downloadFile: { result: ArrayBuffer | null; callState: CallState };
  };
  signeEnvelope: {
    sign: { result: string; callState: CallState };
  };
  eSignatoryListing: {
    eSignatorys: { result: ESignatory[]; callState: CallState };
  };
  timeOutInit: {
    timeOut: { result: string; callState: CallState };
  };
  countingeSignatoryPending: {
    countingPending: { result: number; callState: CallState };
  };
  countingeSignatoryHistoric: {
    countingHistoric: { result: number; callState: CallState };
  };
  closeSignature: {
    close: { result: string; callState: CallState };
  };
}
export const initialState: ESignState = {
  eSignListing: { eSigns: null },
  initialSignature: { initialSign: null },
  checkSignature: { checkESign: null },
  downloadFileESign: { downloadFile: null },
  signeEnvelope: { sign: null },
  timeOutInit: { timeOut: null },
  eSignatoryListing: { eSignatorys: null },
  countingeSignatoryPending: { countingPending: null },
  countingeSignatoryHistoric: { countingHistoric: null },
  closeSignature: { close: null },
};
