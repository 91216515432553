<div class="actualites-container">
  <div class="actualites-header">
    <p>ACTUALITÉS</p>
  </div>
  <div class="actualites-content">
    <app-news-elem image="/assets/images/campagne-bilans -fiscaux.png"
      headerTitle="Campagne collecte des bilans fiscaux" description="Pour les clients ayant des autorisations de crédit, nous vous remercions d'importer le bilan fiscal 2024
      Société Générale Maroc de votre entreprise dans le cadre du renouvellement de vos dossiers de crédit."
      link="/balance-sheet"></app-news-elem>
    <app-news-elem image="/assets/images/soft_token_news.png"
      headerTitle="My Business Pass la solution d’authentification forte"
      description="Vous pouvez vous connecter au portail My Business et signer vos opérations en ligne en toute sécurité."
      link="/onBoardingSoftTokens"></app-news-elem>

    <app-news-elem image="" headerTitle="My Business s’enrichit de nouveaux services"
      description="De nouveaux services de paiement de vos factures en ligne sont disponibles. Pour y accéder, allez vers l’espace Cash Management puis cliquez sur Paiement de facture."
      link="" cssClass=""></app-news-elem>
  </div>
</div>