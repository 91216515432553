<app-popin-content [isHeader]="true" [title]="title" (closed)="receiveStatusPopin($event)"
  [isPopupVisible]="isPopupVisible">
  <hr class="separator" />

  <div class="step-header">
    <app-sell-card-stepper [stepper]="1" [totalStepper]="5"></app-sell-card-stepper>
    <h4>CHOIX DU COMPTE</h4>
  </div>

  <div class="step-one-header-infos">
    <div class="info">
      <span class="title">Nom d’entreprise</span>
      <span class="descriptin">{{ client?.name }}</span>
    </div>
    <div class="info">
      <span class="title">Pays d’entreprise</span>
      <span class="descriptin">{{ customerDetail?.country }}</span>
    </div>
    <div class="info">
      <span class="title">Ville d’entreprise</span>
      <span class="descriptin">{{ customerDetail?.city }}</span>
    </div>
  </div>

  <form (ngSubmit)="send()" [formGroup]="accountFormGroup">
    <div class="step-container">
      <img src="/assets/images/step1.svg" alt="step 1 picture" />
      <div class="select-wrapper">
        <span class="select-title">Veuillez sélectionner le compte à débiter :</span>
        <div class="select-container">
          <mat-form-field class="custom-field-account">
            <mat-label>SÉLÉCTIONNEZ VOTRE COMPTE</mat-label>
            <mat-select [formControl]="accountForm" [(ngModel)]="selectedAccount" disableOptionCentering
              panelClass="custom-panel-account">
              <mat-option *ngFor="let item of accounts" [value]="item">
                {{ item }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <button type="button" class="step-action-btn" type="submit" [disabled]="!selectedAccount">
      SUIVANT
    </button>
  </form>
</app-popin-content>