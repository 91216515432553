import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {
  SogeValeurDto,
} from '../models/SogeValeurDto';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class SogeValeurService {

  constructor(private http: HttpClient) {
  }

  public downloadAllSv(customerNumber: string): Observable<ArrayBuffer> {
    return this.http.get(
      `${environment.apiUrl}/soge-valeurs/${customerNumber}/download`,
      {
        responseType: 'arraybuffer',
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          attachement: 'MES VALEURS',
        }),
      },
    );
  }

  public downloadChequesUnpaid(customerNumber: string): Observable<ArrayBuffer> {
    console.log('downloadChequesImpayes');
    return this.http.get(
      `${environment.apiUrl}/soge-valeurs/cheques-not-paid/${customerNumber}/download`,
      {
        responseType: 'arraybuffer',
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          attachement: 'MES CHEQUES IMPAYEES',
        }),
      },
    );
  }

  public downloadChequesToPay(customerNumber: string): Observable<ArrayBuffer> {
    console.log('downloadChequesToPay');
    return this.http.get(
      `${environment.apiUrl}/soge-valeurs/cheques-to-pay/${customerNumber}/download`,
      {
        responseType: 'arraybuffer',
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          attachement: 'MES CHEQUES A PAYER',
        }),
      },
    );
  }

  public downloadLcnUnpaid(customerNumber: string): Observable<ArrayBuffer> {
    console.log('downloadLcnUnpaid');
    return this.http.get(
      `${environment.apiUrl}/soge-valeurs/lcns-not-paid/${customerNumber}/download`,
      {
        responseType: 'arraybuffer',
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          attachement: 'MES EFFETS IMPAYEES',
        }),
      },
    );
  }

  public downloadLcnToPay(customerNumber: string): Observable<ArrayBuffer> {
    console.log('downloadLcnToPay');
    return this.http.get(
      `${environment.apiUrl}/soge-valeurs/lcns-to-pay/${customerNumber}/download`,
      {
        responseType: 'arraybuffer',
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          attachement: 'MES EFFETS A PAYER',
        }),
      },
    );
  }

  public getSogeValeur(customerNumber: string): Observable<SogeValeurDto> {
     return this.http.get<SogeValeurDto>(
       `${environment.apiUrl}/soge-valeurs/${customerNumber}`,
       httpOptions
     );
  }
}
