import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Cards } from 'src/app/models/cards';

import { CardPlafonds } from '../sell-card-enum/card_plafonds';
@Component({
  selector: 'app-sell-card-data',
  templateUrl: './sell-card-data.component.html',
  styleUrls: ['./sell-card-data.component.scss'],
})
export class SellCardDataComponent implements OnInit {
  @Output() stepEvent = new EventEmitter<number>();
  @Input() groupedForms: any;
  title = 'DEMANDER POUR UN BÉNÉFICIAIRE';
  @Input() isPopupVisible: boolean = true;
  fileReference: string;
  @Input() userLoaded: boolean = false;
  @Input() public cardSelected: Cards;
  @Input() step: number;
  cardBusiness: boolean = true;
  showFileViewer = false;
  pdfSrc = '';

  pdfReference = '';
  constructor() {}

  ngOnInit() {
    this.cardBusiness = true;
    this.step = 5;
    if (
      this.cardSelected != null &&
      this.cardSelected != undefined &&
      this.cardSelected.alias === CardPlafonds.Deposit
    ) {
      this.step = 4;
    }
  }
  sendaccount() {
    this.stepEvent.emit(1);
  }
  sendInfo() {
    this.stepEvent.emit(2);
  }
  sendContact() {
    this.stepEvent.emit(3);
  }
  sendCeiling() {
    this.stepEvent.emit(4);
  }
  sendMail() {
    this.stepEvent.emit(6);
  }
  close() {
    this.stepEvent.emit(0);
  }
  ngOnChanges(changes: SimpleChanges) {
    if ('userLoaded' in changes) {
      if (this.userLoaded) {
        this.title = 'Demander Pour  Moi meme';
        if (
          this.cardSelected != null &&
          this.cardSelected != undefined &&
          this.cardSelected.alias === CardPlafonds.EscalePro
        ) {
          this.cardBusiness = false;
        } else if (this.cardSelected.alias === CardPlafonds.Business) {
          this.cardBusiness = true;
        }
      }
      if (!this.userLoaded) {
        this.title = 'Demander Pour un Bénéficaire';
        if (
          this.cardSelected != null &&
          this.cardSelected != undefined &&
          this.cardSelected.alias === CardPlafonds.EscalePro
        ) {
          this.cardBusiness = false;
        } else if (
          this.cardSelected != null &&
          this.cardSelected != undefined &&
          this.cardSelected.alias === CardPlafonds.Business
        ) {
          this.cardBusiness = true;
        }
      }
    }
  }

  formatNumber(value: any): string {
    if (value == null || value === undefined) {
      return ''; // ou une autre valeur par défaut si nécessaire
    }
    const stringValue = String(value); // Convertir la valeur en chaîne
    const numericValue = parseFloat(stringValue.replace(/\s/g, ''));
    return !isNaN(numericValue) ? numericValue.toLocaleString() : stringValue;
  }

  viewFile(file: File) {
    let blob = new Blob([file], { type: 'application/pdf' });
    let url = window.URL.createObjectURL(blob);
    this.pdfSrc = url;
    this.pdfReference = this.groupedForms.receivedInfoData.verso + '.pdf';
    this.showFileViewer = true;
  }
}
