import { createAction, props } from '@ngrx/store';
import { User } from '../../../../models/user';
import { Application } from 'src/app/models/application';
import { Client } from 'src/app/models/client';

export const appLoaded = createAction('[App] App Loaded');

export const fetchUserSuccess = createAction(
  '[APP LOADING] Fetch User Success',
  props<{ user: User }>()
);

export const fetchUserFailed = createAction(
  '[APP LOADING] Fetch User Failed',
  props<{ error: any }>()
);

export const getSelectedCustomerNumberMultipleTime = createAction(
  '[APP LOADING] Fetch Selected Customer Number Multiple Time',
  props<{ username: String }>()
);

export const getSelectedCustomerNumber = createAction(
  '[APP LOADING] Fetch Selected Customer Number',
  props<{ username: String }>()
);

export const getSelectedCustomerNumberSuccess = createAction(
  '[APP LOADING] Fetch Selected Customer Number Success',
  props<{ obj: any }>()
);

export const getSelectedCustomerNumberFailed = createAction(
  '[APP LOADING] Fetch Selected Customer Number Failed',
  props<{ error: any }>()
);

export const changeSelectedCustomerNumber = createAction(
  '[FROM THE HEADER] Change Selected Customer Number',
  props<{ customerNumber: string; userName: string }>()
);

export const changeSelectedCustomerNumberSuccess = createAction(
  '[FROM THE HEADER] Change Selected Customer Number Success',
  props<{ obj: any }>()
);

export const changeSelectedCustomerNumberFailed = createAction(
  '[FROM THE HEADER] Change Selected Customer Number Failed',
  props<{ error: any }>()
);

export const getUserApplications = createAction(
  '[APP LOADING] Fetch User Applications',
  props<{ userApplications: Array<Application> }>()
);

export const getUserClients = createAction('[APP LOADING] Fetch User Clients');

export const getUserClientsSuccess = createAction(
  '[APP LOADING] Fetch User Clients Success',
  props<{ userClients: Array<Client> }>()
);

export const getUserClientsFailed = createAction(
  '[APP LOADING] Fetch User Clients Failed',
  props<{ error: any }>()
);

export const getUserSoftToken = createAction(
  '[APP LOADING] Fetch User SoftToken'
);

export const getUserSoftTokenSuccess = createAction(
  '[APP LOADING] Fetch User SoftToken Success',
  props<{ hasSoftToken: Boolean }>()
);

export const getUserSoftTokenFailed = createAction(
  '[APP LOADING] Fetch User SoftToken Failed',
  props<{ error: any }>()
);
