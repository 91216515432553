<app-popin-content [isHeader]="true" [title]="title" (closed)="receiveStatusPopin($event)"
  [isPopupVisible]="isPopupVisible">
  <app-pdf-viewer *ngIf="showFileViewer" [pdfSrc]="pdfSrc" [pdfReference]="fileReference"
    (closeFileViewer)="showFileViewer = false"></app-pdf-viewer>
  <hr class="separator" />

  <div class="step-header">
    <app-sell-card-stepper [stepper]="2" [totalStepper]="5"></app-sell-card-stepper>
    <h4>IDENTITÉ PORTEUR</h4>
  </div>
  <div class="sell-card-form-container" *ngIf="!selectedImage">
    <div class="nb-paragraph" *ngIf="propos.selectedIdType">
      <span>NB :</span>
      <p>
        La photo de CIN ne doit pas dépasser 3Mo et les formats acceptés sont
        PDF, JPG, PNG.
      </p>
    </div>
    <form #proposForm="ngForm">
      <div class="step-container">
        <img src="/assets/images/step2.svg" alt="step 2 picture" />
        <div class="inputs-container" [ngStyle]="{ height: propos.selectedIdType ? '600px' : 'auto' }">
          <mat-form-field class="custom-field">
            <mat-label>Nom</mat-label>
            <input [(ngModel)]="propos.firstname" [disabled]="userLoaded" type="text" id="firstname" name="firstname"
              maxlength="122" #firstname="ngModel" required matInput />
          </mat-form-field>
          <mat-form-field class="custom-field">
            <mat-label>Prénom</mat-label>
            <input [(ngModel)]="propos.lastname" [disabled]="userLoaded" type="text" id="lastName" name="lastName"
              maxlength="122" required matInput />
          </mat-form-field>
          <div class="select-container">
            <mat-form-field class="custom-field-account">
              <mat-label>SÉLÉCTIONNEZ VOTRE TYPE D'IDENTIFICATION</mat-label>
              <mat-select id="selectedIdType" name="selectedIdType" [(ngModel)]="propos.selectedIdType"
                (selectionChange)="onSelectionChange($event)" disableOptionCentering panelClass="custom-panel-account">
                <mat-option *ngFor="let item of idTypeCodes" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="grouped-inputs">
            <mat-form-field>
              <mat-label>{{
                propos.selectedIdType ? propos.selectedIdType : "CIN"
                }}</mat-label>
              <input [(ngModel)]="propos.cin" [disabled]="userLoaded" type="text" id="cin" name="cin" required
                matInput />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Date d’expiration</mat-label>
              <input [(ngModel)]="propos.expirationDate" name="expirationDate" [matDatepicker]="picker2"
                [max]="maxDateExpiration" [min]="maxExpirationDate" [disabled]="isDatePickerDisabled" type="text"
                matInput required readonly />
              <mat-datepicker-toggle [ngClass]="{ 'has-value': propos.expirationDate }" matSuffix matIconSuffix
                [for]="picker2">
                <img matDatepickerToggleIcon src="/assets/images/date-picker.svg" alt="picker-icon"
                  class="icon-picker" />
              </mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="upload-container">
            <span class="title-upload" *ngIf="!filenameRecto">Importer la photo du {{ propos.selectedIdType }}
              recto</span>
            <span class="file-uploaded-name" *ngIf="filenameRecto" (click)="viewFile('recto')">{{ filenameRecto
              }}</span>
            <label *ngIf="!filenameRecto">
              <img src="../../../../assets/images/upload-cloud-red.svg" alt="'menu-icon" />
              <input type="file" name="recto" id="recto" #fileInput (change)="onFileChange($event, 'recto')"
                accept="image/*" required hidden />
            </label>
            <button mat-menu-item (click)="clearUploadRecto()" *ngIf="filenameRecto">
              <img src="../../../../assets/images/Delete-five-white.svg" alt="'menu-icon" />
            </button>
          </div>
          <div class="upload-container" *ngIf="propos.selectedIdType !== 'CARTE SEJOUR'">
            <span class="title-upload" *ngIf="!filenameVerso">Importer la photo du {{ propos.selectedIdType }}
              verso</span>
            <span class="file-uploaded-name" *ngIf="filenameVerso" (click)="viewFile('verso')">{{ filenameVerso
              }}</span>
            <label *ngIf="!filenameVerso">
              <img src="../../../../assets/images/upload-cloud-red.svg" alt="'menu-icon" />
              <input type="file" name="verso" id="verso" #fileInput (change)="onFileChange($event, 'verso')"
                accept="image/*" required hidden />
            </label>

            <button mat-menu-item (click)="clearUploadVerso()" *ngIf="filenameVerso">
              <img src="../../../../assets/images/Delete-five-white.svg" alt="'menu-icon" />
            </button>
          </div>
          <mat-form-field class="custom-field">
            <mat-label>Date de naissance</mat-label>
            <input [(ngModel)]="propos.birthDate" name="birthDate" [matDatepicker]="picker" [min]="minDate"
              [disabled]="isBirthDatePickerDisabled" [max]="maxDate" type="text" matInput readonly />
            <mat-datepicker-toggle [ngClass]="{ 'has-value': propos.birthDate }" matSuffix matIconSuffix [for]="picker">
              <img matDatepickerToggleIcon src="/assets/images/date-picker.svg" alt="picker-icon" class="icon-picker" />
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="custom-field">
            <mat-label>Pays de naissance</mat-label>
            <input [(ngModel)]="propos.birthCountry" type="text" id="birthCountry" name="birthCountry" required
              maxlength="255" matInput />
          </mat-form-field>
          <mat-form-field class="custom-field">
            <mat-label>Ville de naissance</mat-label>
            <input [(ngModel)]="propos.birthCity" type="text" id="birthCity" name="birthCity" required matInput
              maxlength="255" />
          </mat-form-field>
          <div class="select-container" *ngIf="!userLoaded">
            <mat-form-field class="custom-field-account">
              <mat-label>SÉLÉCTIONNEZ VOTRE TYPE D'IDENTIFICATION</mat-label>
              <mat-select
                id="selectedIdType"
                name="selectedIdType"
                [(ngModel)]="propos.selectedIdType"
                (selectionChange)="onSelectionChange($event)"
                disableOptionCentering
                [required]="!userLoaded"
                panelClass="custom-panel-account"
              >
                <mat-option *ngFor="let item of idTypeCodes" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="grouped-inputs">
            <mat-form-field>
              <mat-label>{{
                propos.selectedIdType ? propos.selectedIdType : "CIN"
              }}</mat-label>
              <input
                [(ngModel)]="propos.cin"
                [disabled]="userLoaded"
                type="text"
                id="cin"
                name="cin"
                required
                matInput
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Date d’expiration</mat-label>
              <input
                [(ngModel)]="propos.expirationDate"
                name="expirationDate"
                [matDatepicker]="picker2"
                [max]="maxDateExpiration"
                [min]="maxExpirationDate"
                [disabled]="isDatePickerDisabled"
                type="text"
                matInput
                required
                readonly
              />
              <mat-datepicker-toggle
                [ngClass]="{ 'has-value': propos.expirationDate }"
                matSuffix
                matIconSuffix
                [for]="picker2"
              >
                <img
                  matDatepickerToggleIcon
                  src="/assets/images/date-picker.svg"
                  alt="picker-icon"
                  class="icon-picker"
                />
              </mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="upload-container" *ngIf="!userLoaded">
            <span class="title-upload" *ngIf="!filenameRecto"
              >Importer la photo du {{ propos.selectedIdType }} recto</span
            >
            <span
              class="file-uploaded-name"
              *ngIf="filenameRecto"
              (click)="viewFile('recto')"
              >{{ filenameRecto }}</span
            >
            <label *ngIf="!filenameRecto">
              <img
                src="../../../../assets/images/upload-cloud-red.svg"
                alt="'menu-icon"
              />
              <input
                type="file"
                name="recto"
                id="recto"
                #fileInput
                (change)="onFileChange($event, 'recto')"
                accept="image/*"
                required
                hidden
              />
            </label>
            <button
              mat-menu-item
              (click)="clearUploadRecto()"
              *ngIf="filenameRecto"
            >
              <img
                src="../../../../assets/images/Delete-five-white.svg"
                alt="'menu-icon"
              />
            </button>
          </div>
          <div
            class="upload-container"
            *ngIf="propos.selectedIdType !== 'CARTE SEJOUR' && !userLoaded"
          >
            <span class="title-upload" *ngIf="!filenameVerso"
              >Importer la photo du {{ propos.selectedIdType }} verso</span
            >
            <span
              class="file-uploaded-name"
              *ngIf="filenameVerso"
              (click)="viewFile('verso')"
              >{{ filenameVerso }}</span
            >
            <label *ngIf="!filenameVerso">
              <img
                src="../../../../assets/images/upload-cloud-red.svg"
                alt="'menu-icon"
              />
              <input
                type="file"
                name="verso"
                id="verso"
                #fileInput
                (change)="onFileChange($event, 'verso')"
                accept="image/*"
                required
                hidden
              />
            </label>

            <button
              mat-menu-item
              (click)="clearUploadVerso()"
              *ngIf="filenameVerso"
            >
              <img
                src="../../../../assets/images/Delete-five-white.svg"
                alt="'menu-icon"
              />
            </button>
          </div>
          <mat-form-field class="custom-field">
            <mat-label>Date de naissance</mat-label>
            <input
              [(ngModel)]="propos.birthDate"
              name="birthDate"
              [matDatepicker]="picker"
              [min]="minDate"
              [disabled]="isBirthDatePickerDisabled"
              [max]="maxDate"
              type="text"
              matInput
              readonly
            />
            <mat-datepicker-toggle
              [ngClass]="{ 'has-value': propos.birthDate }"
              matSuffix
              matIconSuffix
              [for]="picker"
            >
              <img
                matDatepickerToggleIcon
                src="/assets/images/date-picker.svg"
                alt="picker-icon"
                class="icon-picker"
              />
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="custom-field">
            <mat-label>Pays de naissance</mat-label>
            <input
              [(ngModel)]="propos.birthCountry"
              type="text"
              id="birthCountry"
              name="birthCountry"
              required
              maxlength="255"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="custom-field">
            <mat-label>Ville de naissance</mat-label>
            <input
              [(ngModel)]="propos.birthCity"
              type="text"
              id="birthCity"
              name="birthCity"
              required
              matInput
              maxlength="255"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="actions-container">

        <button *ngIf="!proposfill" [disabled]="proposForm.invalid" type="button" class="step-action-btn"
          (click)="send()">
          SUIVANT
        </button>
      </div>
    </form>
  </div>

  <div class="preview-picture-container" *ngIf="selectedImage">
    <div>
      <button class="chosen-back-btn" (click)="back()">
        <span class="icon">
          <img src="assets/images/ic_arrow_right.svg" alt="preview picture"/>
        </span>
        <span class="text">Retour</span>
      </button>
    </div>

    <div class="preview-body">
      <div class="card">
        <img [src]="selectedImage" alt="UploadedImage" />
        <div class="card-body">
          <span class="image-type">{{ propos.selectedIdType }}:</span>
          <span class="image-name">{{ filenameVerso || filenameRecto }}</span>
        </div>
      </div>
    </div>
  </div>
</app-popin-content>