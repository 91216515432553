<div class="popup-overlay" *ngIf="isPopupVisible">
  <div
    [style.min-width]="width ? width : '920px'"
    [style.min-height]="height ? height : '820px'"
    class="popup"
  >
    <div class="header">
      <span *ngIf="!isHeader"></span>
      <h1 *ngIf="isHeader">{{ title }}</h1>
      <button type="button" class="btn btn-close" aria-label="Close" (click)="closePopup()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
