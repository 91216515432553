<div class="popup-header-signe">
  <h2></h2>
  <button type="button" class="close-btn" (click)="closeDialog()">
    <img src="assets/images/close-white.svg" alt="" />
  </button>
</div>

<div class="dialog-content">
  <div class="dialog-content">
    <iframe
      #iframeRef
      [src]="linkEsign"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
  </div>
</div>
