import { environment } from '../../environments/environment';

export enum ApplicationsEnum {
  REPORTING = 'REPORTING',
  EXTERNAL_TRANSFER = 'OTHER_CHANEL',
  SOGECASHNET = 'SOGECASHNET',
  SOGETRADE = 'SOGETRADE',
  PROVISION = 'PROVISION',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  //ESCALE_PRO = 'ESCALE_PRO',
  ESIGN = 'ESIGN',
  SOGE_VALEURS = 'SOGE_VALEURS',
  CONSULTATION_CARD = 'CONSULTATION_CARD',
}

export function navigateToApp(code, router) {
  environment.routerMode == 'IN'
    ? navigateToApp_new(code, router)
    : navigateToApp_old(code, router);
}

export function navigateToApp_new(code, router) {
  let url;
  switch (code) {
    case ApplicationsEnum.REPORTING:
      router.navigate(['/reporting']);
      break;
    case ApplicationsEnum.PROVISION:
      router.navigate(['/provision']);
      break;
    case ApplicationsEnum.EXTERNAL_TRANSFER:
      router.navigate(['/external-transfer']);
      break;
    case ApplicationsEnum.DIRECT_DEBIT:
      router.navigate(['/direct-debit']);
      break;
    case ApplicationsEnum.ESIGN:
      router.navigate(['/e-sign']);
      break;
    /* case ApplicationsEnum.ESIGN:
      router.navigate(['/escale-pro']);
      break;*/
    case ApplicationsEnum.CONSULTATION_CARD:
      router.navigate(['/consult-cards']);
      break;
    default:
      url = router.createUrlTree(['/toapp/' + code]);
      router.navigate(['/toapp/' + code]);
  }
}

export function navigateToApp_old(code, router) {
  let url = router.createUrlTree(['/toapp/' + code]);
  if (code == ApplicationsEnum.REPORTING) {
    url = router.createUrlTree(['/reporting']);
  }
  if (code == ApplicationsEnum.EXTERNAL_TRANSFER) {
    url = router.createUrlTree(['/external-transfer']);
  }
  if (code == ApplicationsEnum.PROVISION) {
    url = router.createUrlTree(['/provision']);
  }
  if (code == ApplicationsEnum.DIRECT_DEBIT) {
    url = router.createUrlTree(['/direct-debit']);
  }
  /*  if (code == ApplicationsEnum.ESCALE_PRO) {
    url = router.createUrlTree(['/escale-pro']);
  }*/
  if (code == ApplicationsEnum.ESIGN) {
    url = router.createUrlTree(['/e-sign']);
  }
  if (code == ApplicationsEnum.SOGE_VALEURS) {
    url = router.createUrlTree(['/soge-valeur']);
  }
  if (code == ApplicationsEnum.CONSULTATION_CARD) {
    url = router.createUrlTree(['/consult-cards']);
  }
  window.open(url.toString(), '_blank');
}
