import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Cards } from 'src/app/models/cards';
import { CardPlafonds } from '../../sell-card-enum/card_plafonds';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-sell-card-celling-form',
  templateUrl: './sell-card-celling-form.component.html',
  styleUrls: ['./sell-card-celling-form.component.scss'],
})
export class SellCardCellingFormComponent implements OnInit {
  @Input() control: FormControl;
  oldValue: string;
  isEditing: boolean = false;
  isCardescalePro: boolean = true;
  @Input() label: string;
  @Input() max: number;
  @Input() min: number;
  @Input() cardSelected: Cards;
  validationSuccess: boolean = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit() {
    if (
      this.cardSelected != null &&
      this.cardSelected != undefined &&
      this.cardSelected.alias === CardPlafonds.EscalePro
    ) {
      this.isCardescalePro = true;
    } else if (
      this.cardSelected != null &&
      this.cardSelected != undefined &&
      this.cardSelected.alias !== CardPlafonds.EscalePro
    ) {
      this.isCardescalePro = false;
    }
  }

  startEditing() {
    if (this.control.value !== null && this.control.value !== undefined) {
      this.oldValue = this.control.value;
    }
    this.isEditing = true;
  }

  cancelEditing() {
    this.isEditing = false;
    this.control.setValue(this.oldValue);
    this.control.markAsTouched();
  }

  validateEditing() {
    this.isEditing = false;
    this.control.markAsTouched();
  }
}
