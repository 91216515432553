import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import {
  appLoaded,
  selectSelectedCustomerNumber,
} from 'src/app/core/store/state/user';

@Component({
  selector: 'app-secure-root-component',
  templateUrl: './secure-root-component.component.html',
  styleUrls: ['./secure-root-component.component.scss'],
})
export class SecureRootComponentComponent implements OnInit {
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectedCustomerNumberSub: any;
  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.selectedCustomerNumberSub = this.selectedCustomerNumber$.subscribe(
      (customerNumber) => {
        if (customerNumber) {
          // context already loaded in the appComponent
          if (this.selectedCustomerNumberSub !== undefined)
            this.selectedCustomerNumberSub.unsubscribe();
        } else this.store.dispatch(appLoaded());
      }
    );
  }
}
