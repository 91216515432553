import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { interval, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { CardService } from '../../../../service/card.service';
import { UserService } from '../../../../service/user.service';
import * as EscaleProActions from './escale-pro.actions';
import {
  fetchCardsHistoryFilter,
  fetchTrackedRefillRequestsFilter,
} from './escale-pro.actions';
import { ChallengeService } from 'src/app/service/challenge.service';

@Injectable()
export class EscaleProEffects {
  constructor(
    private actions$: Actions<any>,
    private cardService: CardService,
    private userService: UserService,
    private challengeService: ChallengeService
  ) {}

  fetchCardsHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EscaleProActions.fetchCardsHistory,
        EscaleProActions.fetchCardsHistoryFilter
      ),
      switchMap((action) =>
        this.cardService
          .getEscaleProRefillCards(action.page, action.filter)
          .pipe(
            map((cards) => {
              return EscaleProActions.fetchCardsHistorySuccess({ cards });
            }),
            catchError((error) =>
              of(EscaleProActions.fetchCardsHistoryError({ error }))
            )
          )
      )
    )
  );

  fetchCards$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(EscaleProActions.fetchCards, EscaleProActions.fetchCardsFilter),
      switchMap((action) =>
        this.cardService
          .getEscaleProCards(action.customerNumber, action.cardFilterDto)
          .pipe(
            map((cards) => {
              return EscaleProActions.fetchCardsSuccess({ cards });
            }),
            catchError((error) =>
              of(EscaleProActions.fetchCardsFailed({ error: error }))
            )
          )
      )
    )
  );

  getCardDetails$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(EscaleProActions.getCardDetails),
      switchMap((action) =>
        this.cardService
          .getEscaleProCardsDetails(
            action.customerNumber,
            action.cardNumber,
            action.accountNumber
          )
          .pipe(
            map((cardDetails) => {
              return EscaleProActions.getCardDetailsSuccess({ cardDetails });
            }),
            catchError((error) =>
              of(EscaleProActions.getCardDetailsFailed({ error: error }))
            )
          )
      )
    )
  );

  getAccountToBeDebited$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(EscaleProActions.getAccountToBeDebited),
      switchMap((action) =>
        this.cardService.getAccountToBeDebited(action.customerNumber).pipe(
          map((accountToBeDebited) => {
            return EscaleProActions.getAccountToBeDebitedSuccess({
              accountToBeDebited,
            });
          }),
          catchError((error) =>
            of(EscaleProActions.getAccountToBeDebitedFailed({ error: error }))
          )
        )
      )
    )
  );

  refillCard$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(EscaleProActions.refillCard),
      switchMap((action) =>
        this.cardService
          .refillEscaleProCard(
            action.customerNumber,
            action.cardNumber,
            action.cardRefillInfos
          )
          .pipe(
            map((resp) => {
              return EscaleProActions.refillCardSuccess({ resp });
            }),
            catchError((error) =>
              of(EscaleProActions.refillCardFailed({ error: error }))
            )
          )
      )
    )
  );

  fetchTrackedRefillRequests$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(
        EscaleProActions.fetchTrackedRefillRequests,
        EscaleProActions.fetchTrackedRefillRequestsFilter
      ),
      switchMap((action) =>
        this.cardService
          .getEscaleProRefillCards(action.page, action.filter)
          .pipe(
            map((refillRequests) => {
              return EscaleProActions.fetchTrackedRefillRequestsSuccess({
                refillRequests,
              });
            }),
            catchError((error) =>
              of(
                EscaleProActions.fetchTrackedRefillRequestsFailed({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  cancelRefillRequest$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(EscaleProActions.cancelRefillRequest),
      switchMap((action) =>
        this.cardService
          .cancelEscaleProRefillRequest(action.customerNumber, action.requestId)
          .pipe(
            map(() => {
              return EscaleProActions.cancelRefillRequestSuccess();
            }),
            catchError((error) =>
              of(
                EscaleProActions.cancelRefillRequestFailed({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  fetchEscaleProUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(EscaleProActions.fetchEscaleProUserRoles),
      switchMap((action) =>
        this.userService
          .getUserRolesByCustomerNumber(action.customerNumber, action.username)
          .pipe(
            map((roles) => {
              return EscaleProActions.fetchEscaleProUserRolesSuccess({ roles });
            }),
            catchError((error) =>
              of(
                EscaleProActions.fetchEscaleProUserRolesFailed({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  getEscaleProChallengeCode$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(
        EscaleProActions.getEscaleProChallengeCode,
        EscaleProActions.getEscaleProChallengeCodeFromInit
      ),
      switchMap(() =>
        this.challengeService.getChallenge().pipe(
          map((challengeCode) => {
            return EscaleProActions.getEscaleProChallengeCodeSuccess({
              challengeCode,
            });
          }),
          catchError((error) =>
            of(
              EscaleProActions.getEscaleProChallengeCodeFailed({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  signEscaleProRequest$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(
        EscaleProActions.signEscaleProRequest,
        EscaleProActions.signEscaleProRequestFromInit
      ),
      switchMap((action) =>
        this.cardService
          .signEscaleProRequest(
            action.customerNumber,
            action.requestId,
            action.challengeKey,
            action.dResponse
          )
          .pipe(
            map(() => {
              return EscaleProActions.signEscaleProRequestSuccess();
            }),
            catchError((error) =>
              of(
                EscaleProActions.signEscaleProRequestFailed({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  downloadRecapFile$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(EscaleProActions.downloadRecapFile),
      switchMap((action) =>
        this.cardService
          .downloadFile(action.customerNumber, action.requestId)
          .pipe(
            map((file) => {
              return EscaleProActions.downloadRecapFileSuccess({ file });
            }),
            catchError((error) =>
              of(
                EscaleProActions.downloadRecapFileFailed({
                  error: error,
                })
              )
            )
          )
      )
    )
  );
}
