import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sell-card-stepper',
  templateUrl: './sell-card-stepper.component.html',
  styleUrls: ['./sell-card-stepper.component.scss'],
})
export class SellCardStepperComponent implements OnInit {
  @Input() stepper: any;
  @Input() totalStepper: any;
  normalizedRadius: any;
  circumference: any;
  progress: any;
  strokeDashoffset: any;
  stroke = 2;
  radius = 28;

  constructor() {}

  ngOnInit() {
    this.normalizedRadius = this.radius - this.stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
    this.progress = parseInt(this.stepper) / parseInt(this.totalStepper);
    this.strokeDashoffset =
      this.circumference - this.progress * this.circumference;
  }
}
