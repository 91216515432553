import {CallState} from '../../../../models/CallState';
import {
  ChequesNotPaidResponse,
  ChequesToPayResponse, LcnNotPaidResponse,
  LcnToPayResponse,
  SogeValeurDto,
} from '../../../../models/SogeValeurDto';

export interface SogeValeurState {
  sogeValeursListing: {
    sogeValeurs: { result: SogeValeurDto, callState: CallState },
  };
  lcnToPayListing: {
    lcnToPay: { result: LcnToPayResponse, callState: CallState },
    downloadLcnToPay: { result: ArrayBuffer | null; callState: CallState },
  };
  lcnNotPaidListing: {
    lcnNotPaid: { result: LcnNotPaidResponse, callState: CallState },
    downloadLcnUnpaid: { result: ArrayBuffer | null; callState: CallState },
  };
  chequesToPayListing: {
    chequesToPay: { result: ChequesToPayResponse, callState: CallState },
    downloadChequesToPay: { result: ArrayBuffer | null; callState: CallState },
  };
  chequesNotPaidListing: {
    chequesNotPaid: { result: ChequesNotPaidResponse, callState: CallState },
    downloadChequesUnpaid: { result: ArrayBuffer | null; callState: CallState },
  };

  downloadAllSv: { result: ArrayBuffer | null; callState: CallState };

}

export const initialState: SogeValeurState = {
  sogeValeursListing: {sogeValeurs: null},
  lcnToPayListing: {lcnToPay: null, downloadLcnToPay: null},
  lcnNotPaidListing: {lcnNotPaid: null, downloadLcnUnpaid: null},
  chequesToPayListing: {chequesToPay: null, downloadChequesToPay: null},
  chequesNotPaidListing: {chequesNotPaid : null, downloadChequesUnpaid: null},
  downloadAllSv: null,
};
