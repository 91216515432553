<app-popin-content
  [isHeader]="true"
  [title]="title"
  (closed)="receiveStatusPopin($event)"
  [isPopupVisible]="isPopupVisible"
>
  <hr class="separator" />

  <div class="step-header">
    <app-sell-card-stepper
      [stepper]="3"
      [totalStepper]="step"
    ></app-sell-card-stepper>
    <h4>CONTACT</h4>
  </div>

  <form #contactsForm="ngForm">
    <div class="step-container">
      <img src="/assets/images/step3.svg" alt="Step 3 picture" />
      <div class="inputs-container">
        <mat-form-field class="custom-field">
          <mat-label>Adresse e-mail</mat-label>
          <input
            [(ngModel)]="contact.email"
            [disabled]="userLoaded"
            type="text"
            id="email"
            name="email"
            required
            maxlength="180"
            matInput
          />
        </mat-form-field>
        <div class="operators-container">
          <div
            *ngFor="let operator of operators; let i = index"
            [ngClass]="
              operatorSelect?.libelle === operator.libelle
                ? 'item selected'
                : 'item'
            "
            (click)="selectOperator(operator, i)"
          >
            <img
              [class]="'icon-' + i"
              src="{{ operator.icone }}"
              alt="opertaor"
            />
          </div>
        </div>
        <mat-form-field class="custom-field">
          <mat-label>N° de téléphone</mat-label>
          <input
            [(ngModel)]="contact.phoneNumber"
            [disabled]="userLoaded"
            type="text"
            required
            pattern="[- +()0-9]{10,12}"
            id="phoneNumber"
            name="phoneNumber"
            required
            matInput
          />
        </mat-form-field>
      </div>
    </div>

    <button
      *ngIf="!contactsfill"
      [disabled]="contactsForm.invalid"
      class="step-action-btn"
      type="button"
      (click)="send()"
    >
      SUIVANT
    </button>
  </form>
</app-popin-content>
