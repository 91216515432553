<div class="wrapper">
  <div class="header-container">
    <h2>INFORMATIONS SIGNATAIRES</h2>
    <button type="button" class="close-btn" (click)="close()">
      <img src="assets/images/close-white.svg" alt="" />
    </button>
  </div>
  <div class="content-container">
    <div
      *ngFor="let signatureHistoryElem of signatureHistory; index as i"
      class="caracteristic"
    >
      <div class="header">
        <h3>{{ i + 1 }} {{ i == 0 ? "er" : "ème" }} Signataire</h3>
      </div>
      <div class="body">
        <div>
          <label>Nom du porteur</label>
          <p>
            {{ signatureHistoryElem.firstName }}
            {{ signatureHistoryElem.lastName }}
          </p>
        </div>
        <div>
          <label>Date de signature</label>
          <p>{{ signatureHistoryElem.signatureDate | date : "dd/MM/yyyy" }}</p>
        </div>
        <div>
          <label>Heure de signature</label>
          <p>
            {{ signatureHistoryElem.signatureDate | date : "HH:mm" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
