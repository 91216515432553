<div class="wrapper">
  <div class="header-container">
    <h2>RECHERCHE AVANCÉE</h2>
    <button type="button" class="close-btn" (click)="close()">
      <img src="assets/images/close-white.svg" alt="" />
    </button>
  </div>
  <div class="inputs-container">
    <mat-form-field class="custom-field">
      <mat-label>Nom</mat-label>
      <input [(ngModel)]="lastName" type="text" id="lastName" name="lastName" matInput />
    </mat-form-field>
    <mat-form-field class="custom-field">
      <mat-label>Prénom</mat-label>


      <input [(ngModel)]="firstName" type="text" id="firstName" name="firstName" matInput />
    </mat-form-field>
  </div>
  <div class="buttons">
    <button class="white-bg" [disabled]="!(lastName || firstName !== '')" (click)="reinitForm()">
      RÉINITIALISER
    </button>
    <button (click)="applyFilter()">FILTRER</button>
  </div>
</div>