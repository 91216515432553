import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserConfigService } from 'src/app/service/user-config-service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectConnectedUser } from 'src/app/core/store/state/user';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-soft-token-popin',
  templateUrl: './soft-token-popin.component.html',
  styleUrls: ['./soft-token-popin.component.scss'],
})
export class SoftTokenPopinComponent implements OnInit {
  message: Boolean;
  errors: Boolean;
  form = new FormGroup({
    hideDialog: new FormControl(false, [
      Validators.required,
      Validators.required,
    ]),
  });
  selectConnectedUser$ = this.store.select(selectConnectedUser);
  user: User;

  constructor(
    public dialogRef: MatDialogRef<SoftTokenPopinComponent>,
    private userConfigService: UserConfigService,
    private router: Router,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.message = false;
    this.errors = false;
    this.selectConnectedUser$.subscribe((user) => (this.user = user));
  }

  onNoClick(): void {
    if (this.hideDialog.value) {
      this.userConfigService
        .hideSoftTokenPopup(this.user.username)
        .subscribe(() => {});
    }
    this.form.setValue({ hideDialog: false });
    this.dialogRef.close();
  }

  activerSoftToken() {
    let url = this.router.createUrlTree(['/onBoardingSoftTokens']);
    window.open(url.toString(), '_blank');
    this.dialogRef.close();
  }

  showSoftTokenDetails() {
    let url = this.router.createUrlTree(['/detailSoftTokens/']);
    window.open(url.toString(), '_blank');
  }

  get hideDialog(): any {
    return this.form.get('hideDialog');
  }
}
