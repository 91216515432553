import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfSrc: string;
  @Input() pdfReference: string;
  @Output() closeFileViewer = new EventEmitter<void>();
  @Input() downloadBtn: boolean = true;
  @Input() viewer: string = 'pdf';

  constructor() {}

  ngOnInit() {
     
  }

  downloadFile() {
    var link = document.createElement('a');
    link.href = this.pdfSrc;
    link.download = this.pdfReference;
    link.click();
  }
}
