<div class="files-container">
  <div class="empty-data" *ngIf="getTotalNumber() == 0">
    <img src="/assets/images/api-empty.png" alt="'menu-icon" />
    <span>Vous n'avez pas de chèque à payer</span>
  </div>
  <div class="error-data" *ngIf="getTotalNumber() == -1">
    <img src="/assets/images/api-error.png" alt="'menu-icon" />
    <div>
      <h3>Erreur de chargement de la page</h3>
      <p>
        Lorem ipsum dolor hamet Lorem ipsum dolor hamet Lorem ipsum dolor hamet.
      </p>
    </div>
  </div>
  <ng-container *ngIf="getTotalNumber() > 0">
    <div class="file-tab" *ngFor="let cheque of currentChequesList">
      <div class="file-info">
        <div class="file-elm">
          <p class="label">Code banque du remettant</p>
          <p class="info">{{ cheque.codeBanqueRemettant }}</p>
        </div>
        <div class="file-elm">
          <p class="label">Nom de remettant</p>
          <p class="info">{{ cheque.nomRemettant | uppercase }}</p>
        </div>
        <div class="file-elm">
          <p class="label">N° du compte client</p>
          <p class="info">{{ cheque.numCompte }}</p>
        </div>

        <div class="file-elm">
          <p class="label">N° du chèque</p>
          <p class="info">{{ cheque.numCheque }}</p>
        </div>

        <div class="file-elm">
          <p class="label">Montant</p>
          <p class="info red-text">
            {{ cheque.montant | currency : " " : "" : "" : "fr" }} MAD
          </p>
        </div>
      </div>
    </div>
    <app-pagination
      [page]="page"
      (getPage)="getCurrentPageCheques()"
    ></app-pagination>
  </ng-container>
  <app-sv-download
    *ngIf="getTotalNumber() >= 0"
    [downloadOneSvTypeText]="'Télécharger mes chèques à payer '"
    [svTotalNumber]="getTotalNumber()"
    (dispatchDownloadAction)="download()"
    (dispatchDownloadAllSvAction)="downloadAll()"
  ></app-sv-download>
</div>

<!-------------------------------- Loader management --------------------------------------->
<div class="loader" *ngIf="showLoader">
  <img class="loader-icon" src="/assets/images/Ellipsis-2s-80px.gif" alt="" />
</div>
<!----------------------------------------------------------------------------------------->
