<div style="display: flex">
  <!-- <div class="step-number">{{ stepper }} / {{ totalStepper }}</div> -->
  <svg
    [attr.height]="radius * 2"
    [attr.width]="radius * 2"
    class="stepper-progress"
  >
    <circle
      [attr.cx]="radius"
      [attr.cy]="radius"
      [attr.r]="normalizedRadius"
      stroke-width="1px"
      fill="none"
      stroke="#E1E2E3"
    />
    <circle
      fill="transparent"
      [attr.stroke-width]="stroke"
      [attr.stroke-dasharray]="circumference"
      [style.stroke-dashoffset]="strokeDashoffset"
      [attr.r]="normalizedRadius"
      [attr.cx]="radius"
      [attr.cy]="radius"
      stroke="#EE5B6F"
    />
    <text
      x="50%"
      y="50%"
      dominant-baseline="middle"
      text-anchor="middle"
      stroke-width="1px"
    >
      {{ stepper }}
    </text>
  </svg>
</div>
