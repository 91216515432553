import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ESignActions from './e-sign.actions';

import { ESingService } from '../../../../modules/e-sign/services/e-sign.service';

@Injectable()
export class ESignEffects {
  constructor(
    private actions$: Actions<any>,
    private eSingService: ESingService
  ) {}

  fetchEign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ESignActions.fetchESign),
      switchMap((action) =>
        this.eSingService
          .findEnvelopeByFilter(
            action.page,
            action.status,
            action.customerNumber
          )
          .pipe(
            map((eSign) => {
              return ESignActions.fetchESignSuccess({ eSign });
            }),
            catchError((error) => of(ESignActions.fetchESignFailure({ error })))
          )
      )
    )
  );

  initESign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ESignActions.initSignature),
      switchMap((action) =>
        this.eSingService.initEnvelope(action.requestId).pipe(
          map((initESign) => {
            return ESignActions.initSignatureSucces({ initESign });
          }),
          catchError((error) =>
            of(ESignActions.initSignatureFailure({ error }))
          )
        )
      )
    )
  );

  checkESign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ESignActions.checkSignature),
      switchMap((action) =>
        this.eSingService.checkEnvelope(action.requestId).pipe(
          map((checkESign) => {
            return ESignActions.checkSignatureSucces({ checkESign });
          }),
          catchError((error) =>
            of(ESignActions.checkSignatureFailure({ error }))
          )
        )
      )
    )
  );

  downloadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ESignActions.downloadFile),
      switchMap((action) =>
        this.eSingService.downloadPdf(action.requestId).pipe(
          map((file) => {
            return ESignActions.downloadFileSuccess({ file });
          }),
          catchError((error) => of(ESignActions.downloadFileFailed({ error })))
        )
      )
    )
  );

  signeEnvelope$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ESignActions.signeEnvelope),
      switchMap((action) =>
        this.eSingService
          .signeEnvelope(action.signatureTransactionId, action.requestId)
          .pipe(
            map(() => {
              return ESignActions.signeEnvelopeSuccess();
            }),
            catchError((error) =>
              of(ESignActions.checkSignatureFailure({ error }))
            )
          )
      )
    )
  );

  fetchESignator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ESignActions.fetcheSignatory),
      switchMap((action) =>
        this.eSingService.findESignatory(action.requestId).pipe(
          map((eSignatory) => {
            return ESignActions.fetcheSignatorySucces({ eSignatory });
          }),
          catchError((error) =>
            of(ESignActions.fetcheSignatorFailed({ error }))
          )
        )
      )
    )
  );
  timeOutInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ESignActions.timeOutInit),
      switchMap((action) =>
        this.eSingService
          .timeoutInit(action.signatureTransactionId, action.requestId)
          .pipe(
            map(() => {
              return ESignActions.timeOutInitSuccess();
            }),
            catchError((error) => of(ESignActions.timeOutInitFailed({ error })))
          )
      )
    )
  );

  countingPending$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ESignActions.fetchESignCountingPending),
      switchMap((action) =>
        this.eSingService
          .findtCountEnvelopesByUserAndStatus(
            action.customerNumber,
            action.status
          )
          .pipe(
            map((countingPending) => {
              return ESignActions.fetchESignCountingPendingSuccess({
                countingPending,
              });
            }),
            catchError((error) =>
              of(ESignActions.fetchESignCountingPendingFailure({ error }))
            )
          )
      )
    )
  );
  countingHistoric$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ESignActions.fetchESignCountingHistoric),
      switchMap((action) =>
        this.eSingService
          .findtCountEnvelopesByUserAndStatus(
            action.customerNumber,
            action.status
          )
          .pipe(
            map((countingHistoric) => {
              return ESignActions.fetchESignCountingHistoricSuccess({
                countingHistoric,
              });
            }),
            catchError((error) =>
              of(ESignActions.fetchESignCountingHistoricFailure({ error }))
            )
          )
      )
    )
  );
  closeSignature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ESignActions.closeSignature),
      switchMap((action) =>
        this.eSingService.closeSignature(action.signatureTransactionId).pipe(
          map(() => {
            return ESignActions.closeSignatureSuccess();
          }),
          catchError((error) =>
            of(ESignActions.closeSignatureFailed({ error }))
          )
        )
      )
    )
  );
}
