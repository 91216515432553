export class CardMail {
  public name: string;
  public birthDate: string;
  public expirationDate: string;
  public operator: string;
  public email: string;
  public phoneNumber: string;
  public birthCountry: string;
  public birthCity: string;
  public cin: string;
  public codeClient: string;
  public libelleCard: string;
  public withdrawalNationalSg: string;
  public purchaseNationalOnline: string;
  public withdrawalInternational: string;
  public purchaseInternational: string;
  public purchaseInternationalOnline: string;
  public purchaseNational: string;
  public withdrawalNationalColleagues: string;
  public account: string;
  public selectedIdType: string;
  public recto: File;
  public verso: File;

  constructor() {}
}
