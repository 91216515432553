import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../service/client.service';
import { Client } from '../../../app/models/client';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { Util } from '../../utils/app-init';
import { FileService } from '../../../app/service/file.service';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';
import { SearchNoticeCriteria } from '../../models/search-notice-criteria';
import { SearchNcrmCriteria } from '../../models/search-ncrm-criteria';
import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { GedDocument } from '../../models/gedDocument';
import jsPDF from 'jspdf';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import {
  selectSelectedCustomerNumber,
  selectUserClients,
} from 'src/app/core/store/state/user';
import { map, switchMap, skip } from 'rxjs/operators';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
})
export class ReportingComponent implements OnInit {
  constructor(
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private fileService: FileService,
    private http: HttpClient,
    private store: Store<State>
  ) {}

  public filterForm: FormGroup;

  public selectIsOpened: boolean;

  public clientListIsOpened: boolean = false;

  public loaded: boolean = true;
  public noResult: boolean = false;
  public technicalError: boolean = false;
  public exceeded: boolean = false;
  public sensImage: string = ' ';
  public showWebgedResult: boolean = false;

  searchClient;

  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectUserClients$ = this.store.select(selectUserClients);

  selectIsopened() {
    this.selectIsOpened = true;
  }
  selectIsclosed() {
    this.selectIsOpened = false;
  }
  index = 0;
  public currencies: string[];

  public clientList: Array<Client> = [];

  public searchNoticeCriteria: SearchNoticeCriteria;
  public clientsListSubscription: Subscription;

  public searchNcrmCriteria: SearchNcrmCriteria;

  public transactions: any;

  public listBankCode: any;

  datePickerConfig = Util.date_input_config;

  filtredClientList;
  getClientList(): void {
    this.selectedCustomerNumber$
      .pipe(
        switchMap((customerNumber) =>
          this.selectUserClients$.pipe(
            map((clients) => [customerNumber, clients])
          )
        )
      )
      .subscribe(([customerNumber, clients]) => {
        this.selectedCustomerNumber = customerNumber;
        if (this.selectedCustomerNumber && clients.length > 0) {
          this.clientList = clients.sort((cli2, cli1) => {
            return cli2.name.localeCompare(cli1.name);
          });
          this.filtredClientList = this.clientList;
          this.onChange(
            this.clientList.find((client) => {
              return client.customerNumber === this.selectedCustomerNumber;
            })
          );
        }
      });
  }

  changeStatus() {
    this.filtredClientList = Util.transform(this.clientList, this.searchClient);
  }

  selectClient(cl) {
    this.customerNumber = cl.customerNumber;
    this.clientListIsOpened = false;
  }

  families;
  customerNumber;
  selectedClient;
  loadedclient: boolean = true;
  messageError = '';
  typeClient;
  onChange(client) {
    this.customerNumber = client.customerNumber;
    this.typeClient = client.clientType;
    this.selectedClient = client;
    this.clientListIsOpened = false;
    this.loadedclient = false;
    this.messageError = '';
    this.transactions = {};
    this.noResult = false;
    this.technicalError = false;
    this.exceeded = false;
    this.searchClient = '';
    this.filtredClientList = this.clientList;

    this.clientService.getTypeDocuments(this.customerNumber).subscribe(
      (res) => {
        this.families = res;
        this.loadedclient = true;
        this.selectedFamily = this.families[0];
        this.getAccountsClient();
      },
      (err) => {
        this.messageError = "Veuillez contacter l'adminitrateur";
        this.loadedclient = true;
      }
    );

    if (this.filterForm) this.clearAllCriterias();
  }

  setMinDate(): Date {
    let date = new Date();
    date = new Date(
      new Date().getFullYear() - 10,
      new Date().getMonth(),
      new Date().getDate()
    );
    if (
      this.selectedClient &&
      this.selectedFamily &&
      !this.selectedClient.isSubscribed &&
      this.selectedFamily.family.code != 'IMG_VALEUR'
    ) {
      date = new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate()
      );
    }
    return date;
  }

  setMaxDate(): Date {
    return new Date();
  }

  getAccountsClient() {
    if (this.selectedFamily) {
      this.clientService
        .getAccountsByClient(
          this.customerNumber,
          this.selectedFamily.family.code
        )
        .subscribe((res) => {
          let accts = []; // { code: 'ALL', label: 'Tous les comptes' }
          res.forEach((acc) => accts.push({ code: acc, label: acc }));
          this.accounts = accts;
        });
    }
  }

  ngOnInit() {
    // list clients
    this.getClientList();
    this.currencies = Util.currencies;
    this.listBankCode = Util.codesBankeTire;
    this.filterForm = this.formBuilder.group({
      documentType: ['', Validators.required],
      account: [['ALL'], Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      montantMin: [''],
      montantMax: [''],
      currency: [''],
      numValue: [''],
      numRemise: [''],
      codeBanqueTire: [''],
    });
  }

  accounts;
  getAccounts(): any {
    return this.accounts;
  }

  getDefaultAccount() {
    if (this.accounts) {
      return [this.accounts[0].code];
    }
    return [];
  }

  chooseDocType(event) {
    this.index = this.findWithAttr(
      this.selectedFamily.documentTypes,
      'documentTypeCode',
      event
    );

    this.showWebgedResult = false;
  }

  findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  getDefaultClient(): string[] {
    if (this.selectedFamily == null && this.families) {
      this.selectedFamily = this.families[0];
    }
    if (this.clientList[0]) {
      return [this.clientList[0].customerNumber];
    }
    return [];
  }

  getCurrencies(): any {
    let currencies = [{ code: 'ALL', label: 'Toutes les devises' }];
    if (this.currencies) {
      this.currencies.forEach((cur) =>
        currencies.push({ code: cur, label: cur })
      );
    }
    return currencies;
  }

  public selectedFamily: any = null;
  chooseFamily(family) {
    this.selectedFamily = family;
    this.clearAllCriterias();
  }

  clearAllCriterias() {
    this.noResult = false;
    this.technicalError = false;
    this.exceeded = false;
    this.transactions = {};
    this.getAccountsClient();

    this.startDate.reset();
    this.endDate.reset();
    this.montantMax.reset();
    this.montantMin.reset();
    this.currency.reset();
    this.numRemise.reset();
    this.numValue.reset();
    this.codeBanqueTire.reset();
  }

  getDocumentTypes(): any {
    if (this.selectedFamily) {
      let documentTypeList = [];
      this.selectedFamily.documentTypes.forEach((d) => {
        let docType = {};
        docType['code'] = d.documentTypeCode;
        docType['label'] = d.documentTypeLabel;
        documentTypeList.push(docType);
      });
      return documentTypeList;
    }
    return [];
  }

  getDefaultDocumentType(): string[] {
    if (this.selectedFamily) {
      return [this.selectedFamily.documentTypes[0].documentTypeCode];
    }
    return [];
  }

  public submitted = false;
  public onSubmitFilter(): void {
    this.submitted = true;
    if (this.filterForm.invalid) {
      this.filterForm.controls['startDate'].markAsTouched();
      this.filterForm.controls['endDate'].markAsTouched();
      return;
    }
    // call service search doc
    this.searchTransactions();
    this.showWebgedResult = true;
  }

  get documentType(): FormControl {
    return this.filterForm.get('documentType') as FormControl;
  }

  get account(): FormControl {
    return this.filterForm.get('account') as FormControl;
  }

  get startDate(): FormControl {
    return this.filterForm.get('startDate') as FormControl;
  }

  get endDate(): FormControl {
    return this.filterForm.get('endDate') as FormControl;
  }
  get montantMin(): FormControl {
    return this.filterForm.get('montantMin') as FormControl;
  }
  get montantMax(): FormControl {
    return this.filterForm.get('montantMax') as FormControl;
  }
  get currency(): FormControl {
    return this.filterForm.get('currency') as FormControl;
  }

  get numValue(): FormControl {
    return this.filterForm.get('numValue') as FormControl;
  }

  get numRemise(): FormControl {
    return this.filterForm.get('numRemise') as FormControl;
  }

  get codeBanqueTire(): FormControl {
    return this.filterForm.get('codeBanqueTire') as FormControl;
  }

  resetTransactions() {
    this.clearAllCriterias();
    this.showWebgedResult = false;
  }

  private buildSearNoticeCriteria(): SearchNoticeCriteria {
    this.loaded = false;

    this.transactions = null;
    this.searchNoticeCriteria = new SearchNoticeCriteria();
    this.searchNoticeCriteria.documentType = this.documentType.value;
    this.searchNoticeCriteria.account = this.account.value;
    this.searchNoticeCriteria.montantMin = this.montantMin.value;
    this.searchNoticeCriteria.montantMax = this.montantMax.value;
    this.searchNoticeCriteria.devise = this.currency.value;
    this.searchNoticeCriteria.startDate = formatDate(
      new Date(this.startDate.value),
      'yyyy-MM-dd',
      'en'
    );
    this.searchNoticeCriteria.endDate = formatDate(
      new Date(this.endDate.value),
      'yyyy-MM-dd',
      'en'
    );

    return this.searchNoticeCriteria;
  }

  private buildSearNcrmCriteria(): SearchNcrmCriteria {
    this.loaded = false;
    this.transactions = null;
    this.searchNcrmCriteria = new SearchNcrmCriteria();
    this.searchNcrmCriteria.typeDocument = this.documentType.value;
    this.searchNcrmCriteria.accountNumber = this.account.value;
    this.searchNcrmCriteria.montantMin = this.montantMin.value;
    this.searchNcrmCriteria.montantMax = this.montantMax.value;
    this.searchNcrmCriteria.dateMin = formatDate(
      new Date(this.startDate.value),
      'yyyy-MM-dd',
      'en'
    );
    this.searchNcrmCriteria.dateMax = formatDate(
      new Date(this.endDate.value),
      'yyyy-MM-dd',
      'en'
    );

    this.searchNcrmCriteria.numValue = this.numValue.value;
    this.searchNcrmCriteria.numRemise = this.numRemise.value;
    this.searchNcrmCriteria.codeBanqueTire = this.codeBanqueTire.value;

    return this.searchNcrmCriteria;
  }

  searchTransactions(): void {
    if (this.selectedFamily.family.code == 'IMG_VALEUR') {
      this.serachArchipel();
    } else {
      this.searchGedDoc();
    }
  }

  showWebGedResult() {
    return this.showWebgedResult;
  }

  searchGedDoc() {
    let searchNoticeCriteria = this.buildSearNoticeCriteria();
    if (searchNoticeCriteria == null) {
      searchNoticeCriteria = new SearchNoticeCriteria();
    }
    this.noResult = false;
    this.technicalError = false;
    this.exceeded = false;
    this.clientsListSubscription = this.clientService
      .findDocumentsbyCriteria(this.customerNumber, this.searchNoticeCriteria)
      .subscribe(
        (noticeList) => {
          this.transactions = this.transform(noticeList, false);
          this.loaded = true;
          if (this.transactions.documents.length === 0) {
            this.noResult = true;
          }
        },
        (err) => {
          if (err.error.key === 'NO_RESULT_FOUND') {
            this.noResult = true;
          } else if (err.error.key === 'SEUIL_DEPASSE') {
            this.exceeded = true;
          } else {
            this.technicalError = true;
          }
          this.loaded = true;
          this.transactions = [];
        }
      );
  }

  serachArchipel() {
    let searchNcrmCriteria = this.buildSearNcrmCriteria();
    if (searchNcrmCriteria == null) {
      searchNcrmCriteria = new SearchNcrmCriteria();
    }
    this.noResult = false;
    this.technicalError = false;
    this.clientsListSubscription = this.clientService
      .findImagesbyCriteria(this.searchNcrmCriteria)
      .subscribe(
        (noticeList) => {
          this.transactions = this.transform(noticeList, true);
          this.loaded = true;
          if (
            (this.transactions === null &&
              this.transactions.documents === null) ||
            this.transactions.documents.length === 0
          ) {
            this.noResult = true;
          }
        },
        (err) => {
          if (err.error.key === 'NO_RESULT_FOUND') {
            this.noResult = true;
          } else if (err.error.key === 'SEUIL_DEPASSE') {
            this.exceeded = true;
          } else {
            this.technicalError = true;
          }
          this.loaded = true;
          this.transactions = [];
        }
      );
  }

  downloadFile(transaction) {
    let response = {};
    if (this.selectedFamily.family.code == 'IMG_VALEUR') {
      this.http
        .post<any>(`/portal-customer/ged/ncrm/get-images`, {
          documentsIDS: transaction['idDocument'],
        })
        .subscribe((res) => {
          response['filename'] = this.generateFilename(transaction);
          response['doc1'] = res.resultgetimages.images[0].frontImage;
          response['doc2'] = res.resultgetimages.images[0].rearImage;
          this.downloadImage(response);
        });
    } else {
      this.http
        .post<GedDocument>(
          `/portal-customer/ged/get-document/${this.customerNumber}`,
          {
            idDocument: transaction['idDocument'],
            numDocument: transaction['numDocument'],
            baseName: transaction['baseName'],
            documentFamily: this.selectedFamily.family.code,
            accountNumber: this.account.value,
          }
        )
        .subscribe((res) => {
          response['filename'] = this.generateFilename(transaction);
          response['doc1'] = res.document;
          response['doc2'] = null;

          this.download(response);
        });
    }
  }

  download(response) {
    let blobData = this.fileService.convertBase64ToBlobData(
      response['doc1'],
      'application/pdf',
      512
    );
    FileSaver.saveAs(blobData, response['filename']);
    if (response['doc2']) {
      blobData = this.fileService.convertBase64ToBlobData(
        response['doc2'],
        'application/pdf',
        512
      );
      FileSaver.saveAs(blobData, response['filename']);
    }
  }

  downloadImage(response) {
    let blobData = this.fileService.convertBase64ToBlobData(
      response['doc1'],
      'image/jpeg',
      1024
    );
    response['filename'] = response['filename'].replace(
      this.sensImage,
      '_recto'
    );
    FileSaver.saveAs(blobData, response['filename']);

    if (response['doc2']) {
      response['filename'] = response['filename'].replace('_recto', '_verso');
      blobData = this.fileService.convertBase64ToBlobData(
        response['doc2'],
        'image/jpeg',
        1024
      );
      FileSaver.saveAs(blobData, response['filename']);
    }
  }

  formatAviTosDate(date: String) {
    return date.toString();
  }

  generateFilename(transaction) {
    let filename = '';
    let datePipe = new DatePipe('en-US');
    let formatDate = 'yyyy-MM-dd';
    switch (this.selectedFamily.family.code) {
      case 'RELEVE': {
        let date = '';
        if (
          [
            'RELCPT',
            'TITRES',
            'ECHELLE',
            'MONETIQUE',
            'RELEVE_RECAP',
            'CAUTIONS',
          ].includes(this.documentType.value)
        ) {
          date = datePipe.transform(transaction['dateDocument'], formatDate);
        }
        if (['RELEVE_TOS'].includes(this.documentType.value)) {
          date = datePipe.transform(
            this.reverseDate(transaction['PERIODE']),
            formatDate
          );
        }
        filename =
          this.documentType.value + '_' + this.account.value + '_' + date;
        break;
      }
      case 'SWIFTS': {
        let date = '';
        let devise = '';
        let montant = '';
        if (['SWIFT_GOS'].includes(this.documentType.value)) {
          date = datePipe.transform(
            this.reverseDate(transaction['DATE RECEPTION']),
            formatDate
          );
          filename =
            this.documentType.value + '_' + this.account.value + '_' + date;
          break;
        }
        if (['SWIFTS', 'SWIFT_SWIFTR'].includes(this.documentType.value)) {
          let dateDocument =
            this.documentType.value === 'SWIFTS'
              ? transaction['creationDate']
              : transaction['dateDocument'];

          date =
            dateDocument != ''
              ? datePipe.transform(this.reverseDate(dateDocument), formatDate)
              : '';
          devise = transaction['devise'];
          montant = transaction['montantdocument'];
          if (montant && montant.includes('.')) {
            filename =
              this.documentType.value +
              '_' +
              this.account.value +
              '_' +
              montant +
              devise +
              '_' +
              date +
              '.pdf';
            break;
          } else {
            filename =
              this.documentType.value +
              '_' +
              this.account.value +
              '_' +
              montant +
              devise +
              '_' +
              date;
            break;
          }
        }
      }
      case 'AVIS': {
        let date = '';
        let devise = '';
        let montant = '';
        if (
          ['AVIS_OP', 'AVIS_TOS_CREDIT', 'AVIS_TOS_DEBIT'].includes(
            this.documentType.value
          )
        ) {
          date = datePipe.transform(
            this.reverseDate(transaction['creationDate']),
            formatDate
          );
          devise = transaction['DEVISE'];
          montant = transaction['MONTANT'];
        }
        if (montant.includes('.')) {
          filename =
            this.documentType.value +
            '_' +
            this.account.value +
            '_' +
            montant +
            devise +
            '_' +
            date +
            '.pdf';
          break;
        } else {
          filename =
            this.documentType.value +
            '_' +
            this.account.value +
            '_' +
            montant +
            devise +
            '_' +
            date;
          break;
        }
      }
      case 'IMPAYES_CHQ_LCN': {
        let date = '';
        let devise = '';
        let montant = '';
        if (
          ['IMPAYES_CHQ', 'IMPAYES_EFFET'].includes(
            this.documentType.value
          )
        ) {
          date = datePipe.transform(
            this.reverseDate(transaction['creationDate']),
            formatDate
          );
          devise = transaction['DEVISE'];
          montant = transaction['MONTANT'];
        }
        if (montant.includes('.')) {
          filename =
            this.documentType.value +
            '_' +
            this.account.value +
            '_' +
            montant +
            devise +
            '_' +
            date +
            '.pdf';
          break;
        } else {
          filename =
            this.documentType.value +
            '_' +
            this.account.value +
            '_' +
            montant +
            devise +
            '_' +
            date;
          break;
        }
      }
      case 'TAB_AMORTISSEMENT': {
        let date = '';
        let devise = '';
        let montant = '';
        if (['AMORTISSEMENT_AMORTISSEMENT'].includes(this.documentType.value)) {
          date = datePipe.transform(transaction['dateDocument'], formatDate);
          devise = transaction['devise'];
          montant = transaction['montantdocument'];
        }
        if (montant.includes('.')) {
          filename =
            this.documentType.value +
            '_' +
            this.account.value +
            '_' +
            montant +
            devise +
            '_' +
            date +
            '.pdf';
          break;
        } else {
          filename =
            this.documentType.value +
            '_' +
            this.account.value +
            '_' +
            montant +
            devise +
            '_' +
            date;
          break;
        }
      }
      case 'FORMULES': {
        let date = '';
        let devise = '';
        let montant = '';
        if (
          [
            'FORMULES_FORMULE3',
            'FORMULES_FORMULE5',
            'FORMULES_FORMULE6',
          ].includes(this.documentType.value)
        ) {
          date = datePipe.transform(transaction['dateDocument'], formatDate);
          devise = transaction['devise'];
          montant = transaction['montantdocument'];
        }
        if (
          ['FORMULE1', 'FORMULE2'].includes(
            this.documentType.value
          )
        ) {
          date = datePipe.transform(
            this.reverseDate(transaction['creationDate']),
            formatDate
          );
          devise = transaction['DEVISE'];
          montant = transaction['MONTANT EN DEVISE'];
        }
        if (montant.includes('.')) {
          filename =
            this.documentType.value +
            '_' +
            this.account.value +
            '_' +
            montant +
            devise +
            '_' +
            date +
            '.pdf';
          break;
        } else {
          filename =
            this.documentType.value +
            '_' +
            this.account.value +
            '_' +
            montant +
            devise +
            '_' +
            date;
          break;
        }
      }
      case 'IMG_VALEUR': {
        if (['REMCHQ', 'REMLCN'].includes(this.documentType.value)) {
          if (transaction['montant'].includes('.')) {
            filename =
              this.documentType.value +
              '_' +
              transaction['numRemise'] +
              '_' +
              transaction['montant'] +
              '_' +
              datePipe.transform(transaction['dtetrt'], formatDate) +
              this.sensImage +
              '.jpeg';
          } else {
            filename =
              this.documentType.value +
              '_' +
              transaction['numRemise'] +
              '_' +
              transaction['montant'] +
              '_' +
              datePipe.transform(transaction['dtetrt'], formatDate) +
              this.sensImage;
          }
        }
        if (
          ['CHQA', 'CHQR', 'LCNA', 'LCNR'].includes(this.documentType.value)
        ) {
          if (transaction['montant'].includes('.')) {
            filename =
              this.documentType.value +
              '_' +
              transaction['numValue'] +
              '_' +
              transaction['montant'] +
              '_' +
              datePipe.transform(transaction['dtetrt'], formatDate) +
              this.sensImage +
              '.jpeg';
          } else {
            filename =
              this.documentType.value +
              '_' +
              transaction['numValue'] +
              '_' +
              transaction['montant'] +
              '_' +
              datePipe.transform(transaction['dtetrt'], formatDate) +
              this.sensImage;
          }
        }
        break;
      }
      default: {
        return '';
      }
    }
    return filename;
  }

  viewFile(id, numDocument, baseName) {
    let response = {};
    if (this.selectedFamily.family.code == 'IMG_VALEUR') {
      this.http
        .post<any>(`/portal-customer/ged/ncrm/get-images`, {
          documentsIDS: id,
        })
        .subscribe((res) => {
          response['doc1'] = res.resultgetimages.images[0].frontImage;
          response['doc2'] = res.resultgetimages.images[0].rearImage;
          this.viewImage(response);
        });
    } else {
      this.http
        .post<GedDocument>(
          `/portal-customer/ged/get-document/${this.customerNumber}`,
          {
            idDocument: id,
            numDocument: numDocument,
            baseName: baseName,
            documentFamily: this.selectedFamily.family.code,
            accountNumber: this.account.value,
          }
        )
        .subscribe((res) => {
          response['doc1'] = res.document;
          response['doc2'] = null;
          this.view(response);
        });
    }
  }

  view(doc) {
    let blobData = this.fileService.convertBase64ToBlobData(
      doc['doc1'],
      'application/pdf',
      512
    );
    let fileURL = URL.createObjectURL(blobData);
    window.open(fileURL, '_blank');
    if (doc['doc2']) {
      blobData = this.fileService.convertBase64ToBlobData(
        doc['doc2'],
        'application/pdf',
        512
      );
      fileURL = URL.createObjectURL(blobData);
      window.open(fileURL, '_blank');
    }
  }

  viewImage(image) {
    let blobData = this.fileService.convertBase64ToBlobData(
      image['doc1'],
      'image/jpeg',
      1024
    );
    let fileURL = URL.createObjectURL(blobData);
    window.open(fileURL, '_blank');
    if (image['doc2']) {
      blobData = this.fileService.convertBase64ToBlobData(
        image['doc2'],
        'image/jpeg',
        1024
      );
      fileURL = URL.createObjectURL(blobData);
      window.open(fileURL, '_blank');
    }
  }



  // downloadAllFiles() {
  //   this.transactions.documents.forEach((s) => {
  //     setTimeout(s=>{
  //       this.downloadFile(s);
  //     })
  //   },100);
  // }
  downloadAllFiles() {
    this.transactions.documents.forEach((s, index) => {
      setTimeout(() => {
        this.downloadFile(s);
      }, index * 1500); 
    });
  }

  checkStatement(st) {
    st.checked = !st.checked;
  }

  downloadSelectedFiles() {
    this.transactions.documents
      .filter((t) => t.checked)
      .forEach((s) => {
        this.downloadFile(s);
      });
  }

  transform(result, isArchipel: boolean): any {
    let edocResponse = {};
    edocResponse['documents'] = [];
    if (!isArchipel) {
      edocResponse['docType'] = result.documentType;
      result.searchDocumentResponseDTO.document.forEach((d) => {
        let doc = {};
        doc['checked'] = false;
        doc['idDocument'] = d.idDocument;
        doc['numDocument'] = d.numDocument;
        d.metaDataDocument.forEach((m) => {
          doc[m.name] = m.value;
        });
        edocResponse['documents'].push(doc);
      });
    } else {
      //edocResponse['docType'] = result.documentType;
      result.resultgetdocuments.docs.forEach((d) => {
        d['checked'] = false;
        edocResponse['documents'].push(d);
      });
    }
    return edocResponse;
  }

  reverseDate(date: String) {
    return date.split('/').reverse().join('/');
  }
}
