export class ConsultCardsDTO {
  public cardNumber: string;
  public cardType: string;
  public accountNumber: string;
  public expiryDate: Date;
  public familyName: string;
  public firstName: string;
  public favoriteCard: boolean;
  public balance: number;
  public ceilingWithdrawal: number;
  public ceilingPayment: number;
  public emissionDate: Date;
  public statusCode: string;
  public currencyCodeAlpha: string;
  public productName: string;

  //le flag pour indiquer si la carte est activée ou désactivée
  public activationFlag: string;

  //le flag pour indiquer si la carte est hors ligne
  public offlineFlag: string;

  //le flag pour indiquer si la carte est éditable pour les plafands national
  public editNationalCeiling: string;

  //le flag pour indiquer si la carte est éditable pour les plafands international
  public editInternationalCeiling: string;

  //statut de transaction de retrait international
  public statusTrxRetrait: boolean;

  //statut de transaction d'achat international
  public statusTrxAchat: boolean;

  //statut de transaction E-commerce international
  public statusTrxEcom: boolean;

  //statut de transaction contactless
  public statusTrxContacles: boolean;
  constructor() {}
}
