import { CardType } from './CardTypeEnum';

export class CardConsultSearchRequestDTO {
  firstName: string;
  lastName: string;
  cardsType: Array<CardType>;

  constructor(firstName: string, lastName: string, cardsType: Array<CardType>) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.cardsType = cardsType;
  }
}
