<div class="body-wrapper">
  <div class="list-header">
    <div class="list-filter">
      <span>Filtre</span>
      <button
        (click)="openFilterPopup()"
        [ngClass]="countFilterRes() != '' ? 'btn-filter-res' : ''"
        class="btn-filter"
        [attr.data-after]="countFilterRes()"
      >
        <img src="/assets/images/filter.svg" alt="filter-icon" />
      </button>
    </div>
  </div>
  <div class="files-container">
    <div class="file-tab" *ngFor="let request of refillRequests">
      <div class="file-info">
        <div class="file-elm file-reference">
          <p class="label">Numéro de la carte</p>
          <p class="info">{{ request.cardNumber }}</p>
        </div>
        <div class="file-elm">
          <p class="label">Nom du porteur</p>
          <p class="info">{{ request.beneficiaryFullName }}</p>
        </div>
        <div class="file-elm">
          <p class="label">Date de la demande</p>
          <p class="info">{{ request.operationDate | date : "dd/MM/yyyy" }}</p>
        </div>
        <div class="file-elm">
          <p class="label">Montant</p>
          <p class="info">
            {{ request.amount | currency : " " : "" : "" : "fr" }} MAD
          </p>
        </div>
        <div class="file-elm">
          <p class="label">Statut</p>

          <!--En attente de signature-->
          <p
            class="info statut pending"
            *ngIf="request.signatureStatus == escalProStatus.PENDING"
          >
            {{ mapEscaleProStatus(request.signatureStatus) }}
          </p>

          <!--En cours de signature-->
          <p
            class="info statut in-progress"
            *ngIf="request.signatureStatus == escalProStatus.IN_PROGRESS"
          >
            {{ mapEscaleProStatus(request.signatureStatus) }}
          </p>
        </div>
        <div class="actions-container">
          <div>
            <div class="file-action">
              <button
                *ngIf="
                  userCanSign &&
                  !request.signed &&
                  request.signatureStatus == 'PENDING'
                "
                class="action-btn"
                (click)="onSign(request)"
              >
                <img
                  src="../../../../assets/images/signe.svg"
                  alt="'menu-icon"
                />
                <span> Signer </span>
              </button>
              <button
                *ngIf="
                  userCanSign &&
                  !request.signed &&
                  request.signatureStatus == 'IN_PROGRESS'
                "
                class="action-btn white-bg"
                (click)="onSign(request)"
              >
                <img
                  src="../../../../assets/images/signe-red.svg"
                  alt="'menu-icon"
                />
                <span> CTR signer </span>
              </button>

              <!------------------------------------------->
              <div
                *ngIf="
                  request.signatureStatus == escalProStatus.IN_PROGRESS;
                  else btnTemplate
                "
              >
                <button
                  class="action-menu-btn"
                  [matMenuTriggerFor]="menu"
                  *ngIf="userCanSign && !request.signed; else btnTemplate2"
                >
                  <img
                    src="../../../../assets/images/dots-menu.svg"
                    alt="'menu-icon"
                  />
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    *ngIf="
                      !request.signed &&
                      (userCanSign ||
                        (userCanConsult &&
                          request.signatureStatus == escalProStatus.PENDING))
                    "
                    (click)="openCancelConfirmPopin(request)"
                  >
                    <img
                      src="../../../../assets/images/Delete-five.svg"
                      alt="'menu-icon"
                      width="13px"
                    />
                    <span>Annuler</span>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="
                      request.signatureStatus == escalProStatus.IN_PROGRESS &&
                      request.signatureHistory &&
                      request.signatureHistory.length > 0
                    "
                    (click)="openSignatureHistory(request.signatureHistory)"
                  >
                    <img
                      src="../../../../assets/images/oeil-plus.svg"
                      alt="'menu-icon"
                      width="13px"
                    />
                    <span>Voir les signataires</span>
                  </button>
                </mat-menu>

                <ng-template #btnTemplate2>
                  <button
                    class="cancel-btn"
                    *ngIf="
                      request.signatureHistory &&
                      request.signatureHistory.length > 0
                    "
                    (click)="openSignatureHistory(request.signatureHistory)"
                  >
                    <img
                      src="../../../../assets/images/oeil-plus.svg"
                      alt="'menu-icon"
                      width="13px"
                    />
                  </button>
                </ng-template>
              </div>
              <ng-template #btnTemplate>
                <button
                  class="cancel-btn"
                  *ngIf="
                    (userCanConsult || userCanSign) &&
                    request.signatureStatus == escalProStatus.PENDING
                  "
                  (click)="openCancelConfirmPopin(request)"
                >
                  <img
                    src="../../../../assets/images/Delete-five.svg"
                    alt="'menu-icon"
                    width="13px"
                  />
                </button>
              </ng-template>

              <!--------------------------------------------->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="list-pagination">
    <!----------------------------------------------------------------------------------------->
    <app-pagination [page]="page" (getPage)="getCurrentPage()"></app-pagination>
    <!----------------------------------------------------------------------------------------->
  </div>
</div>

<!----------------------------------------------------------------------------------------->
<app-confirm-popin
  [openPopinFlag]="openCancelPopinConfirmFlag"
  message="Etes vous sûr de vouloir annuler votre demande de recharge ?"
  (popinClosedEvent)="closeCancelConfirmPopin($event)"
></app-confirm-popin>
<!----------------------------------------------------------------------------------------->

<!-------------------------------- Loader management --------------------------------------->
<div class="loader" *ngIf="showLoader">
  <img class="loader-icon" src="/assets/images/Ellipsis-2s-80px.gif" alt="" />
</div>
<!----------------------------------------------------------------------------------------->

<!-------------------------------- Error management --------------------------------------->
<app-message-popin
  [openPopinFlag]="displayError"
  mode=""
  [message]="openPopinMessage"
  [success]="openPopinMessageSuccess"
  (popinClosedEvent)="closePopin()"
></app-message-popin>
<!----------------------------------------------------------------------------------------->

<!-------------------------------- Signature management ----------------------------------->
<app-signature
  *ngIf="openPopinSignatureFlag"
  [challengeCode]="challengeCode"
  [challengeKey]="challengeKey"
  [loaderMode]="isPendingRefreshChalCodBtn || isSignaturePending"
  (refreshChallengeCode)="refreshChallengeCode()"
  (closeSignaturePopup)="closeSignaturePopin()"
  (onSign)="signHandler($event)"
></app-signature>
<!----------------------------------------------------------------------------------------->
