import { CardEscaleProDTO } from 'src/app/models/CardEscaleProDTO';
import { CardEscaleProDetailsDTO } from 'src/app/models/CardEscaleProDetailsDTO';
import { CardReloadDTO } from 'src/app/models/CardReloadDTO';
import { CallState } from 'src/app/models/CallState';
import { CardRefillElementDto } from 'src/app/models/CardRefillElementDto';
import { CardFillElementDtoList } from 'src/app/models/CardFillElementDtoList';
import { Roles } from 'src/app/utils/roles';
import { UserRolesDTO } from 'src/app/models/UserRolesDto';
import { IdentityChallengeDTO } from 'src/app/models/identityChallengeDTO';

export interface EscaleProSelectedCard {
  card: CardEscaleProDTO | null;
  details: { result: CardEscaleProDetailsDTO | null; callState: CallState };
}
export interface EscaleProState {
  cardsHistory: {
    cards: { result: CardFillElementDtoList; callState: CallState };
    downloadRecapFile: { result: ArrayBuffer | null; callState: CallState };
  };
  cardsListing: {
    cards: { result: Array<CardEscaleProDTO>; callState: CallState };
    selectedCard: EscaleProSelectedCard | null;
    accountToBeDebited: { result: CardReloadDTO | null; callState: CallState };
    refillCard: { result: number | null; callState: CallState };
  };
  cardsTracking: {
    refillRequests: {
      result: CardFillElementDtoList;
      callState: CallState;
    };
    cancelRequest: { result: boolean | null; callState: CallState };
  };
  signatory: {
    signRequest: { result: boolean | null; callState: CallState };
    challengeCode: {
      result: IdentityChallengeDTO | null;
      callState: CallState;
    };
  };
  userRoles: {
    result: UserRolesDTO;
    callState: CallState;
  };
}

export const initialState: EscaleProState = {
  cardsListing: {
    cards: null,
    selectedCard: {
      card: null,
      details: null,
    },
    accountToBeDebited: null,
    refillCard: null,
  },
  cardsTracking: {
    refillRequests: null,
    cancelRequest: null,
  },
  signatory: { signRequest: null, challengeCode: null },
  cardsHistory: {
    cards: null,
    downloadRecapFile: null,
  },
  userRoles: null,
};
